<script lang="ts" setup>
import { computed } from 'vue';
import { useLocalization } from '@/plugins/localization';
import { Definition, ChoicePlusType, ChoicePlusEntry, instanceOfChoicePlusEntry, ChoiceFieldTypes } from '.';
import { FormBuilderContract } from '@/components/builder/form';

export interface Props
{
    builder: FormBuilderContract;
    component: ChoicePlusType;
    index: number;
}

defineOptions({
    name: `${Definition.type}-filter`
});

const { $t } = useLocalization();

const props = defineProps({
  "builder": null,
  "component": null,
  "index": null
});
const component = computed(() => props.component);
const entry = computed(() => props.builder.document.initEntry(props.component, new ChoicePlusEntry(), instanceOfChoicePlusEntry) as ChoicePlusEntry);
const display = computed(() =>
{
    if (entry.value.data && entry.value.data.values.length == 1)
        return component.value.options.where(p => p.value == entry.value.value).select(p => p.text).first();

    if (entry.value.data && entry.value.data.values.length > 1)
        return $t('[[[Wybrane %0 na %1|||{0}|||{1}]]]', entry.value.data.values.length, component.value.options.length);

    return null;
});
const model = computed({
    get()
    {
        return multiple.value ? (entry.value.data.values ?? []) : entry.value.value;
    },
    set(value)
    {
        entry.value.data.values = Array.isArray(value) ? value : [value];
    }
});
const multiple = computed(() => component.value.fieldType == ChoiceFieldTypes.Checkbox);
const label = computed(() => props.builder.localization.translate(component.value.label));
const options = computed(() => component.value.options.map(p => ({ ...p, text: props.builder.localization.translate(p.text) })));

const reset = (): void =>
{
    entry.value.data = null;
};
</script>

<template>
    <ideo-filter :name="component.name" :label="label" :visible="component.showFilter" :index="index" :display="display" @clear="reset">
        <ideo-form-select name="status" v-model="model" :options="options" :size="Math.min(10, options.length)" :multiple="multiple"></ideo-form-select>
    </ideo-filter>
</template>
