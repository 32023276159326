<template>
    <ideo-form-group :label="$t('[[[Wybierz czołówkę]]]')">
        <div class="d-flex flex-column flex-md-row">
            <div class="flex-grow-1">
                <ideo-autocomplete
                    v-model="form.settings.headlineCoverId"
                    name="headlineCoverId"
                    track-by="id"
                    :placeholder="$t('[[[Wyszukaj czołówkę...]]]')"
                    :fetch="(id: number) => fetchHeadlineCover(id)"
                    :search="(query: string, limit: number) => searchHeadlineCover(query, limit)"
                >
                    <template #selected="{ option }">
                        <div class="text-wrap">
                            {{ option.name }}
                        </div>
                    </template>
                    <template #option="{ option }">
                        <div class="text-wrap">
                            {{ option.name }}
                        </div>
                    </template>
                </ideo-autocomplete>
            </div>
        </div>
    </ideo-form-group>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Emit, Prop, Watch } from '@/helpers/Decorators';
import { ValidatableComponent } from '@/components/cms';
import ArticleHeadlineCoversService, { ListItemModel } from '@/modules/cms/articles/services/ArticleHeadlineCoversService';
import merge from 'lodash/merge';

@Options({
    name: 'widget-headline-cover-settings'
})
export default class HeadlineCoverSettings extends Vue implements ValidatableComponent
{
    @Prop() public modelValue!: any;

    public module: string = 'articles';
    public defaults: any = {
        headlineCoverId: null
    };

    public get form(): any
    {
        return this.modelValue;
    }

    public async created(): Promise<void>
    {
        this.form.settings = merge(this.defaults, this.form.settings);
    }

    @Watch('form.settings', { deep: true })
    public onSettingsChanged(value: any): void
    {
        this.triggetModel();
    }

    @Emit('update:modelValue')
    public triggetModel(): any
    {
        return this.form;
    }

    public async validate(): Promise<void>
    {
    }

    public async fetchHeadlineCover(id: number): Promise<ListItemModel>
    {
        const response = await ArticleHeadlineCoversService.fetchAutocompleteModel(id);

        return response || null;
    }

    public async searchHeadlineCover(query: string, limit: number): Promise<ListItemModel[]>
    {
        return await ArticleHeadlineCoversService.getAutocompleteList(query, limit);
    }
}
</script>
