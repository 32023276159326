<template>
    <div class="dashboard-card chart-statistic">
        <ChartCourses />
    </div>
</template>
<script lang="ts">
import { Options, Vue } from "vue-class-component";
import ChartCourses from '../charts/ChartCourses.vue';

@Options({
    name: "Statistics",
    components: {
        ChartCourses
    },
})
export default class Statistics extends Vue
{
}
</script>

<style scoped lang="scss">
.chart-statistic {
    margin: 0 0 24px;
}

</style>
