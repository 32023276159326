import { $t } from '@/plugins/localization';
import { KeyValue, KeyValuePair } from "@/helpers/Interfaces";
import { IconOption, Option, ColorOption } from './Interfaces';


enum PublicationEnum {
    Draft = 'Draft',
    Finished = 'Finished',
    Rejected = 'Rejected',
    Accepted = 'Accepted'
}

const PublicationOptions = (): Option[] => ([
    { value: PublicationEnum.Draft, text: $t('[[[Szkic]]]'), icon: 'far fa-fw fa-circle text-secondary' },
    { value: PublicationEnum.Finished, text: $t('[[[Do akceptacji]]]'), icon: 'far fa-fw fa-dot-circle text-info' },
    { value: PublicationEnum.Rejected, text: $t('[[[Zwrócony]]]'), icon: 'far fa-fw fa-times-circle text-danger' },
    { value: PublicationEnum.Accepted, text: $t('[[[Zaakceptowany]]]'), icon: 'far fa-fw fa-check-circle text-success' }
]);

const AllPublicationOptions = (): Option[] => (
    [{ value: null, text: $t('[[[Wszystkie]]]') }].concat(PublicationOptions())
);

const PublicationName = (value: PublicationEnum): string => PublicationOptions().find(p => p.value === value)?.text || PublicationEnum[value];
const PublicationIcon = (value: PublicationEnum): string => PublicationOptions().find(p => p.value === value)?.icon || '';
const PublicationStyle = (classTemplate: string, status: PublicationEnum): Record<string, boolean> =>
{
    const classes: Record<string, boolean> = {};
    const re = new RegExp('\\{0\\}', 'g');

    classes[classTemplate.replace(re, 'secondary')] = status === PublicationEnum.Draft;
    classes[classTemplate.replace(re, 'info')] = status === PublicationEnum.Finished;
    classes[classTemplate.replace(re, 'danger')] = status === PublicationEnum.Rejected;
    classes[classTemplate.replace(re, 'success')] = status === PublicationEnum.Accepted;

    return classes;
};

export {
    PublicationEnum,
    PublicationOptions,
    PublicationName,
    PublicationStyle,
    PublicationIcon,
    AllPublicationOptions
};

// ----------------------------------------------------------------------------------------------------

enum EventTypeEnum {
    None = 'None',
    Course = 'Course',
    ExternalLink = 'ExternalLink'
}

const EventTypeOptions = (): Option[] => ([
    { value: EventTypeEnum.None, text: $t('[[[Brak]]]') },
    { value: EventTypeEnum.Course, text: $t('[[[Szkolenie]]]') },
    { value: EventTypeEnum.ExternalLink, text: $t('[[[Link]]]') }
]);

export {
    EventTypeEnum,
    EventTypeOptions
};

// ----------------------------------------------------------------------------------------------------

enum EntityState {
    Deleted = 'Deleted',
    Modified = 'Modified',
    Added = 'Added'
}

const EntityStateOptions = (): Option[] => ([
    { value: EntityState.Deleted, text: $t('[[[Usunięcie]]]') },
    { value: EntityState.Modified, text: $t('[[[Modyfikacja]]]') },
    { value: EntityState.Added, text: $t('[[[Dodanie]]]') }
]);

const EntityStateName = (value: EntityState): string => EntityStateOptions().find(p => p.value === value)?.text || EntityState[value];

export {
    EntityState,
    EntityStateOptions,
    EntityStateName
};

// ----------------------------------------------------------------------------------------------------

enum AccountType {
    Local = 'Local',
    Ldap = 'Ldap'
}

export {
    AccountType
};

// ----------------------------------------------------------------------------------------------------

enum DictionaryType {
    CoreUsers = 'CoreUsers',
    CoreRoles = 'CoreRoles',
    CoreTeams = 'CoreTeams',

    // CoreJobPositions = 'CoreJobPositions',
    // CoreCountries = 'CoreCountries',
    // CoreCities = 'CoreCities',
    // CoreProvinces = 'CoreProvinces',
    // ContactsEventTypes = 'ContactsEventTypes',
    // CalendarResourceCategories = 'CalendarResourceCategories'
}

const DictionaryTypeOptions = (): Option[] => ([
    { value: DictionaryType.CoreUsers, text: $t('[[[Administracja - Użytkownicy]]]') },
    { value: DictionaryType.CoreRoles, text: $t('[[[Administracja - Role]]]') },
    { value: DictionaryType.CoreTeams, text: $t('[[[Administracja - Zespoły]]]') }

    // { value: DictionaryType.CoreJobPositions, text: $t('[[[Administracja - Stanowiska pracy]]]') },
    // { value: DictionaryType.CoreCountries, text: $t('[[[Administracja - Kraje]]]') },
    // { value: DictionaryType.CoreCities, text: $t('[[[Administracja - Miasta]]]') },
    // { value: DictionaryType.CoreProvinces, text: $t('[[[Administracja - Województwa]]]') },
    // { value: DictionaryType.ContactsEventTypes, text: $t('[[[Kontakty - Typ działania]]]') },
    // { value: DictionaryType.CalendarResourceCategories, text: $t('[[[Kalendarz - Kategorie zasobów]]]') }
]);

const DictionaryTypeName = (value: DictionaryType): string => DictionaryTypeOptions().find(p => p.value === value)?.text || DictionaryType[value];

export {
    DictionaryType,
    DictionaryTypeOptions,
    DictionaryTypeName
};

// ----------------------------------------------------------------------------------------------------

enum QueueStage {
    DISPATCH = 100,
    QUEUED = 200,
    DELAYED = 300,
    RETRY = 400,
    REJECTED = 500,
    PROCESSING = 600,
    SUCCEEDED = 700,
    FAILED = 800
}

const QueueStageOptions = (): Option<QueueStage>[] => ([
    { value: QueueStage.DISPATCH, text: $t('[[[Wysłane]]]') },
    { value: QueueStage.QUEUED, text: $t('[[[Zakolejkowane]]]'), icon: 'fad fa-inbox-in text-info' },
    { value: QueueStage.DELAYED, text: $t('[[[Zaplanowane]]]'), icon: 'fad fa-clock text-info' },
    { value: QueueStage.RETRY, text: $t('[[[Powtórzone]]]') },
    { value: QueueStage.REJECTED, text: $t('[[[Odrzucone]]]') },
    { value: QueueStage.PROCESSING, text: $t('[[[Przetwarzane]]]'), icon: 'fad fa-gears text-warning' },
    { value: QueueStage.SUCCEEDED, text: $t('[[[Zakończone]]]'), icon: 'fad fa-check text-success' },
    { value: QueueStage.FAILED, text: $t('[[[Błędne]]]'), icon: 'fad fa-triangle-exclamation text-danger' }
]);

const QueueStageName = (value: QueueStage): string => QueueStageOptions().find(p => p.value === value)?.text || QueueStage[value];

export {
    QueueStage,
    QueueStageOptions,
    QueueStageName
};

// ----------------------------------------------------------------------------------------------------

enum JobStage {
    QUEUED = 210,
    PROCESSING = 610,
    SUCCEEDED = 620,
    FAILED = 630
}

const JobStageOptions = (): Option<JobStage>[] => ([
    { value: JobStage.QUEUED, text: $t('[[[Zakolejkowane]]]'), icon: 'fad fa-inbox-in text-info' },
    { value: JobStage.PROCESSING, text: $t('[[[Przetwarzane]]]'), icon: 'fad fa-gears text-warning' },
    { value: JobStage.SUCCEEDED, text: $t('[[[Zakończone]]]'), icon: 'fad fa-check text-success' },
    { value: JobStage.FAILED, text: $t('[[[Błędne]]]'), icon: 'fad fa-triangle-exclamation text-danger' }
]);

const JobStageName = (value: JobStage): string => JobStageOptions().find(p => p.value === value)?.text || JobStage[value];

export {
    JobStage,
    JobStageOptions,
    JobStageName
};

// ----------------------------------------------------------------------------------------------------

enum VideoTypeEnum {
    Video = 'Video',
    Url = 'Url'
}

const VideoTypeOptions = (): Option[] => ([
    { value: VideoTypeEnum.Video, text: $t('[[[Plik wideo]]]') },
    { value: VideoTypeEnum.Url, text: $t('[[[Adres url]]]') }
]);

const VideoTypeName = (value: VideoTypeEnum): string => VideoTypeOptions().find(p => p.value === value)?.text || VideoTypeEnum[value];

export {
    VideoTypeEnum,
    VideoTypeOptions,
    VideoTypeName
};

// ----------------------------------------------------------------------------------------------------

enum PageModeEnum {
    Page = 'Page',
    Home = 'Home',
    Auth = 'Auth',
    Alias = 'Alias',
    Link = 'Link'
}

const PageModeOptions = (): Option[] => ([
    { value: PageModeEnum.Page, text: $t('[[[Standardowa strona]]]') },
    { value: PageModeEnum.Home, text: $t('[[[Strona główna]]]') },
    { value: PageModeEnum.Auth, text: $t('[[[Strona logowania]]]') },
    { value: PageModeEnum.Alias, text: $t('[[[Link do wybranej strony serwisu]]]') },
    { value: PageModeEnum.Link, text: $t('[[[Link do zewnętrznej strony]]]') }
]);

const PageModeName = (value: PageModeEnum): string => PageModeOptions().find(p => p.value === value)?.text || PageModeEnum[value];

export {
    PageModeEnum,
    PageModeOptions,
    PageModeName
};

// ----------------------------------------------------------------------------------------------------

enum RegulationSetType {
    AccountRegistration = 'AccountRegistration',
    Subscription = 'Subscription',
    NonStandard = 'NonStandard'
}

const RegulationSetTypeOptions = (): Option[] => ([
    { value: RegulationSetType.AccountRegistration, text: $t('[[[Rejestracja konta]]]') },
    { value: RegulationSetType.Subscription, text: $t('[[[Subskrypcja]]]') },
    { value: RegulationSetType.NonStandard, text: $t('[[[Zestaw niestandardowy]]]') }
]);

const AllRegulationSetTypeOptions = (): Option[] => (
    [{ value: null, text: $t('[[[Wszystkie]]]') }].concat(RegulationSetTypeOptions())
);

const RegulationSetTypeName = (value: RegulationSetType): string => RegulationSetTypeOptions().find(p => p.value === value)?.text || RegulationSetType[value];

export {
    RegulationSetType,
    RegulationSetTypeOptions,
    AllRegulationSetTypeOptions,
    RegulationSetTypeName
};

// ----------------------------------------------------------------------------------------------------

enum RegulationOperationType {
    Accepted = 'Accepted',
    Rejected = 'Rejected',
}

const RegulationOperationTypeOptions = (): Option[] => ([
    { value: RegulationOperationType.Accepted, text: $t('[[[Rejestracja konta]]]') },
    { value: RegulationOperationType.Rejected, text: $t('[[[Subskrypcja]]]') }
]);

const RegulationOperationTypeName = (value: RegulationOperationType): string => RegulationSetTypeOptions().find(p => p.value === value)?.text || RegulationOperationType[value];

export {
    RegulationOperationType,
    RegulationOperationTypeOptions,
    RegulationOperationTypeName
};

// ----------------------------------------------------------------------------------------------------

enum MaterialType {
    Content = 'Content',
    Video = 'Video',
    SingleSelect = 'SingleSelect',
    MultiSelect = 'MultiSelect',
    TrueFalse = 'TrueFalse',
    SelectList = 'SelectList',
    FillTheGaps = 'FillTheGaps',
    CategoriesMatching = 'CategoriesMatching',
    ImagesMatching = 'ImagesMatching',
    MatchingPairs = 'MatchingPairs',
    SetInOrder = 'SetInOrder'
}

const MaterialTypeOptions = (): Option[] => ([
    { value: MaterialType.Content, text: $t('[[[Materiał opisowy]]]') },
    { value: MaterialType.Video, text: $t('[[[Materiał wideo]]]') },
    { value: MaterialType.SingleSelect, text: $t('[[[Jednokrotnego wyboru]]]') },
    { value: MaterialType.MultiSelect, text: $t('[[[Wielokrotnego wyboru]]]') },
    { value: MaterialType.TrueFalse, text: $t('[[[Prawda-Fałsz]]]') },
    { value: MaterialType.SelectList, text: $t('[[[Lista wyboru]]]') },
    { value: MaterialType.FillTheGaps, text: $t('[[[Uzupełnij luki]]]') },
    { value: MaterialType.CategoriesMatching, text: $t('[[[Dopasuj do kategorii]]]') },
    { value: MaterialType.ImagesMatching, text: $t('[[[Dopasuj do obrazka]]]') },
    { value: MaterialType.MatchingPairs, text: $t('[[[Połącz w pary]]]') },
    { value: MaterialType.SetInOrder, text: $t('[[[Ustaw w kolejności]]]') }
]);

enum TopicModeEnum {
    Page = 'Page',
    Alias = 'Alias',
    Link = 'Link'
}

const TopicModeOptions = (): Option[] => ([
    { value: PageModeEnum.Page, text: $t('[[[Element grupujący]]]') },
    { value: PageModeEnum.Alias, text: $t('[[[Link do wybranej strony serwisu]]]') },
    { value: PageModeEnum.Link, text: $t('[[[Link do zewnętrznej strony]]]') }
]);

export {
    MaterialType,
    MaterialTypeOptions,
    TopicModeEnum,
    TopicModeOptions
};

// ----------------------------------------------------------------------------------------------------

enum SourceUrl {
    FileRepository = 'FileRepository',
    ExternalService = 'ExternalService'
}

const SourceUrleOptions = (): Option[] => ([
    { value: SourceUrl.FileRepository, text: $t('[[[Repozytorium plików]]]') },
    { value: SourceUrl.ExternalService, text: $t('[[[Zewnętrzny serwis]]]') }
]);

const SourceUrlName = (value: SourceUrl): string => SourceUrleOptions().find(p => p.value === value)?.text || SourceUrl[value];

export {
    SourceUrl,
    SourceUrleOptions,
    SourceUrlName
};

// ----------------------------------------------------------------------------------------------------

enum CommentsStatusEnum {
    Waiting = 'Waiting',
    Accepted = 'Accepted',
    Rejected = 'Rejected'
}

const CommentsStatusOptions = (): Option[] => ([
    { value: CommentsStatusEnum.Waiting, text: $t('[[[Oczekujący na akceptację moderatora]]]') },
    { value: CommentsStatusEnum.Accepted, text: $t('[[[Zaakceptowany]]]') },
    { value: CommentsStatusEnum.Rejected, text: $t('[[[Odrzucony po zgłoszeniach]]]') }
]);

const CommentsStatusName = (value: CommentsStatusEnum): string => CommentsStatusOptions().find(p => p.value === value)?.text || CommentsStatusEnum[value];
const CommentsStatusStyle = (classTemplate: string, status: CommentsStatusEnum): Record<string, boolean> =>
{
    const classes: Record<string, boolean> = {};
    const re = new RegExp('\\{0\\}', 'g');

    classes[classTemplate.replace(re, 'secondary')] = status === CommentsStatusEnum.Waiting;
    classes[classTemplate.replace(re, 'success')] = status === CommentsStatusEnum.Accepted;
    classes[classTemplate.replace(re, 'danger')] = status === CommentsStatusEnum.Rejected;

    return classes;
};

export {
    CommentsStatusEnum,
    CommentsStatusOptions,
    CommentsStatusName,
    CommentsStatusStyle
};

// ----------------------------------------------------------------------------------------------------

enum RelatedArticleSectionsEnum {
    Right = 'Right',
    Inline = 'Inline'
}

const RelatedArticleSectionsOptions = (): Option[] => ([
    { value: RelatedArticleSectionsEnum.Right, text: $t('[[[Sekcja prawa]]]') },
    { value: RelatedArticleSectionsEnum.Inline, text: $t('[[[Sekcja śródtekstowa]]]') },
]);

const RelatedArticleSectionsName = (value: RelatedArticleSectionsEnum): string => RelatedArticleSectionsOptions().find(p => p.value === value)?.text || RelatedArticleSectionsEnum[value];

export {
    RelatedArticleSectionsEnum,
    RelatedArticleSectionsOptions,
    RelatedArticleSectionsName
};

// ----------------------------------------------------------------------------------------------------------

enum MenuIconEnum {
    Empty = "Empty",
    IconLaptop = 'IconLaptop',
    IconMonitor = 'IconMonitor',
    IconFilm = 'IconFilm',
    IconDownload = 'IconDownload',
    IconGroup = 'IconGroup',
    IconTask = 'IconTask',
    IconStarSmile = 'IconStarSmile',
    IconShoppingBag2 = 'IconShoppingBag2',
    IconMap2 = 'IconMap2',
    IconPagesLine = 'IconPagesLine',
    IconCopyrightLine = 'IconCopyrightLine',
    IconStackLine = 'IconStackLine',
    IconBarChartLine = 'IconBarChartLine',
    IconAwardLine = 'IconAwardLine',
    IconMailLine = 'IconMailLine',
    IconGalleryLine = 'IconGalleryLine',
    IconLinksLine = 'IconLinksLine'
}

const MenuIconOptions = (): IconOption[] => ([
    { value: MenuIconEnum.Empty, text: null, url: '' },
    { value: MenuIconEnum.IconLaptop, text: 'icon-laptop', url: '/static/menu_icons/icon-laptop.png' },
    { value: MenuIconEnum.IconMonitor, text: 'icon-monitor', url: '/static/menu_icons/icon-monitor.png' },
    { value: MenuIconEnum.IconFilm, text: 'icon-film', url: '/static/menu_icons/icon-film.png' },
    { value: MenuIconEnum.IconDownload, text: 'icon-download', url: '/static/menu_icons/icon-download.png' },
    { value: MenuIconEnum.IconGroup, text: 'icon-group', url: '/static/menu_icons/icon-group.png' },
    { value: MenuIconEnum.IconTask, text: 'icon-task', url: '/static/menu_icons/icon-task.png' },
    { value: MenuIconEnum.IconStarSmile, text: 'icon-star-smile', url: '/static/menu_icons/icon-star-smile.png' },
    { value: MenuIconEnum.IconShoppingBag2, text: 'icon-shopping-bag-2', url: '/static/menu_icons/icon-shopping-bag-2.png' },
    { value: MenuIconEnum.IconMap2, text: 'icon-map-2', url: '/static/menu_icons/icon-map-2.png' },
    { value: MenuIconEnum.IconPagesLine, text: 'icon-pages-line', url: '/static/menu_icons/icon-pages-line.png' },
    { value: MenuIconEnum.IconCopyrightLine, text: 'icon-copyright-line', url: '/static/menu_icons/icon-copyright-line.png' },
    { value: MenuIconEnum.IconStackLine, text: 'icon-stack-line', url: '/static/menu_icons/icon-stack-line.png' },
    { value: MenuIconEnum.IconBarChartLine, text: 'icon-bar-chart-line', url: '/static/menu_icons/icon-bar-chart-line.png' },
    { value: MenuIconEnum.IconAwardLine, text: 'icon-award-line', url: '/static/menu_icons/icon-award-line.png' },
    { value: MenuIconEnum.IconMailLine, text: 'icon-mail-line', url: '/static/menu_icons/icon-mail-line.png' },
    { value: MenuIconEnum.IconGalleryLine, text: 'icon-gallery-line', url: '/static/menu_icons/icon-gallery-line.png' },
    { value: MenuIconEnum.IconLinksLine, text: 'icon-links-line', url: '/static/menu_icons/icon-links-line.png' }
]);

const MenuIconName = (value: PublicationEnum): string => MenuIconOptions().find(p => p.value === value)?.text || MenuIconEnum[value];
const MenuIconUrl = (value: PublicationEnum): string => MenuIconOptions().find(p => p.value === value)?.url || '';

export {
    MenuIconEnum,
    MenuIconOptions,
    MenuIconName,
    MenuIconUrl
};

// ----------------------------------------------------------------------------------------------------------

enum CyclicPattern {
    Daily = 1,
    Weekly = 2,
    Monthly = 3,
    RelativeMonthly = 4,
    Yearly = 5,
    RelativeYearly = 6,
}

const DaysList = (): Option<number>[]=> ([
    { value: 1, text: $t('[[[Poniedziałek]]]')},
    { value: 2, text: $t('[[[Wtorek]]]') },
    { value: 3, text: $t('[[[Środa]]]') },
    { value: 4, text: $t('[[[Czwartek]]]') },
    { value: 5, text: $t('[[[Piątek]]]') },
    { value: 6, text: $t('[[[Sobota]]]') },
    { value: 0, text: $t('[[[Niedziela]]]') }
]);

enum InvitationStatus {
    ToAcceptance = 'ToAcceptance',
    Accepted = 'Accepted',
    Rejected = 'Rejected',
    Deleted = 'Deleted'
}

const InvitationStatusVariants: KeyValuePair[] = [
    { key: InvitationStatus.ToAcceptance, value: 'primary' },
    { key: InvitationStatus.Accepted, value: 'success' },
    { key: InvitationStatus.Rejected, value: 'danger' }
];

const InvitationStatuses: KeyValuePair[] = [
    { key: InvitationStatus.ToAcceptance, value: $t('[[[Do akceptacji]]]') },
    { key: InvitationStatus.Accepted, value: $t('[[[Zaakceptowane]]]') },
    { key: InvitationStatus.Rejected, value: $t('[[[Odrzucone]]]') },
    { key: InvitationStatus.Deleted, value: $t('[[[Usunięte]]]') }
];

export {
    CyclicPattern,
    DaysList,
    InvitationStatus,
    InvitationStatusVariants,
    InvitationStatuses
};

// ----------------------------------------------------------------------------------------------------------

enum EventCategoryColorEnum {
    Primary = "Primary",
    Dark = "Dark",
    Muted = "Muted",
    LightBlue = "LightBlue",
    Yellow = "Yellow",
    Red = "Red",
    Green = "Green",
    Gray = "Gray",
    Blue = "Blue"
}

const EventCategoryColorOptions = (): ColorOption[] => ([
    { value: EventCategoryColorEnum.Primary, text: $t('[[[Podstawowy]]]'), colorValue: '#0275d8' },
    { value: EventCategoryColorEnum.Dark, text: $t('[[[Czarny]]]'), colorValue: 'Black' },
    { value: EventCategoryColorEnum.Muted, text: $t('[[[Biały]]]'), colorValue: '#ababab' },
    { value: EventCategoryColorEnum.LightBlue, text: $t('[[[Jasny niebieski]]]'), colorValue: '#63C2DE' },
    { value: EventCategoryColorEnum.Yellow, text: $t('[[[Żółty]]]'), colorValue: '#FFC107' },
    { value: EventCategoryColorEnum.Red, text: $t('[[[Czerwony]]]'), colorValue: '#F86C6B' },
    { value: EventCategoryColorEnum.Green, text: $t('[[[Zielony]]]'), colorValue: '#4DBD74' },
    { value: EventCategoryColorEnum.Gray, text: $t('[[[Szary]]]'), colorValue: '#77797a' },
    { value: EventCategoryColorEnum.Blue, text: $t('[[[Niebieski]]]'), colorValue: '#00A2E8' },
]);

const EventCategoryColorOptionName = (value: EventCategoryColorEnum): string => EventCategoryColorOptions().find(p => p.value === value)?.text || EventCategoryColorEnum[value];

export {
    EventCategoryColorEnum,
    EventCategoryColorOptions,
    EventCategoryColorOptionName
};

// ----------------------------------------------------------------------------------------------------------

const MonthsList = (): Option<number>[]=> ([
    { value: 0, text: $t('[[[styczniu]]]')},
    { value: 1, text: $t('[[[lutym]]]') },
    { value: 2, text: $t('[[[marcu]]]') },
    { value: 3, text: $t('[[[kwietniu]]]') },
    { value: 4, text: $t('[[[maju]]]') },
    { value: 5, text: $t('[[[czerwcu]]]') },
    { value: 5, text: $t('[[[lipcu]]]') },
    { value: 7, text: $t('[[[sierpniu]]]') },
    { value: 8, text: $t('[[[wrześniu]]]') },
    { value: 9, text: $t('[[[październiku]]]') },
    { value: 10, text: $t('[[[listopadzie]]]') },
    { value: 11, text: $t('[[[grudniu]]]') }
]);

const MonthOptionName = (value: number): string => MonthsList().find(p => p.value === value)?.text;

export {
    MonthsList,
    MonthOptionName
};

export enum ModulesKey {
    LESSONS = 'el_lessons',
    TESTS = 'el_tests',
    VIDEO = 'el_video_lessons',
    SCORM = 'el_scorm_lessons',
    TOPICS = 'el_topics'
}

const ModulesNames = (): KeyValuePair[] => ([
    { key: 'el_lessons', value: $t('[[[Lekcja]]]') },
    { key: 'el_tests', value: $t('[[[Test]]]') },
    { key: 'el_video_lessons', value: $t('[[[Video]]]') },
    { key: 'el_scorm_lessons', value: $t('[[[Scorm]]]') },
    { key: 'el_topics', value: $t('[[[Temat]]]') }
]);

export const ModuleOptionName = (key: string): string => ModulesNames().find(p => p.key === key)?.value;


enum Locale {
    EN = 'en',
    PL = 'pl'
}

const LocaleOptions = (): Option[] => ([
    { value: Locale.EN, text: $t('[[[Angielski]]]') },
    { value: Locale.PL, text: $t('[[[Polski]]]') }
]);

export const LocaleOptionText = (key: Locale | string): string => LocaleOptions().find(p => p.value === key)?.text;


// ----------------------------------------------------------------------------------------------------------

enum UserGenderEnum {
    NoSpecified = 'NoSpecified',
    Male = 'Male',
    Female = 'Female'
}

const UserGenderEnumOptions = (): Option[] => ([
    { value: UserGenderEnum.Male, text: $t('[[[Mężczyzna]]]') },
    { value: UserGenderEnum.Female, text: $t('[[[Kobieta]]]')},
    { value: UserGenderEnum.NoSpecified, text: $t('[[[Nie podano]]]') }
]);

const UserGenderEnumOptionName = (value: UserGenderEnum): string => UserGenderEnumOptions().find(p => p.value === value)?.text || UserGenderEnum[value];

export {
    UserGenderEnum,
    UserGenderEnumOptions,
    UserGenderEnumOptionName
};

// ----------------------------------------------------------------------------------------------------------

enum StatusCourseEnum {
    NotStarted = 'NotStarted',
    InProgress = 'InProgress',
    Passed = 'Passed',
    NotPassed = 'NotPassed'
}

const StatusCourseEnumOptions = (): Option[] => ([
    { value: StatusCourseEnum.NotStarted, text: $t('[[[Nierozpoczęty]]]') },
    { value: StatusCourseEnum.InProgress, text: $t('[[[W trakcie]]]') },
    { value: StatusCourseEnum.Passed, text: $t('[[[Zaliczony]]]') },
    { value: StatusCourseEnum.NotPassed, text: $t('[[[Niezaliczony]]]') }
]);

const StatusCourseEnumOptionName = (value: StatusCourseEnum): string => StatusCourseEnumOptions().find(p => p.value === value)?.text || StatusCourseEnum[value];

export {
    StatusCourseEnum,
    StatusCourseEnumOptions,
    StatusCourseEnumOptionName
};
