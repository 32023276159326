import { axios } from '@/plugins/axios';

export default abstract class AbstractService
{
    public static async $getFile(url: string, filename: string, params?: any): Promise<any>
    {
        return axios.get(url, {
            params: {...params},
            responseType: 'blob'
        }).then(res =>
        {
            if (res.status == 200)
            {
                const blob = res.data;

                if ((window.navigator as any).msSaveOrOpenBlob)
                {
                    (navigator as any).msSaveBlob(blob, filename);
                }
                else
                {
                    const a = document.createElement('a');

                    document.body.appendChild(a);
                    a.href = window.URL.createObjectURL(blob);
                    a.download = filename;
                    a.target = '_blank';
                    a.click();
                    a.remove();
                }
            }
        });
    }
}
