<template>
    <div class="mx-2">
        <ideo-form-select
            name="locales"
            v-model="locale"
            :options="locales"
            @change="onChange()"
        >
        </ideo-form-select>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import AuthService from '@/modules/core/auth/services/AuthService';
import { Option } from '@/helpers/Interfaces';
import { useThemeStore } from '@/store/theme';

@Options({})
export default class Locales extends Vue
{
    public get locale() : string
    {
        return useThemeStore().locale;
    }

    public set locale(value: string)
    {
        useThemeStore().locale = value;
    }

    public formatLocales(locale: string): string
    {
        return locale.slice(0, 2).toUpperCase();
    }

    public get locales(): Option<string>[]
    {
        return Object.entries(this.$i18n.languages()).map(([key, value]) => ({ value: key, text: this.$t(value) }));
    }

    public get preferredLanguage(): string
    {
        return this.$auth.user()?.preferredLanguage;
    }

    public async mounted(): Promise<void>
    {
        if (!this.locale)
        {
            this.locale =  this.preferredLanguage != null ? this.preferredLanguage : this.$i18n.locale();
        }

        if (this.locale && this.preferredLanguage && this.locale != this.preferredLanguage)
        {
            await this.onChange();
        }

        this.$nextTick(() =>
        {
            this.$i18n.setLocale(this.locale);
        });
    }

    public async onChange(): Promise<void>
    {
        if (this.$auth.check())
        {
            await AuthService.updatePreferredLanguage(this.locale);
        }

        this.$i18n.setLocale(this.locale);
    }
}
</script>

<style lang="scss" scoped>
select.form-control {
    width: 95%;
    margin-left: 5px;
}
</style>
