import { axios } from '@/plugins/axios';
import merge from 'lodash/merge';
import Pager from '@/helpers/Pager';
import { KeyValue, Pagination, Resource, Statement } from '@/helpers/Interfaces';

/**
 * RegulationsSetsService
 */
export default class RegulationsSetsService
{
    /**
     * @param filter FilterModel
     * @param pager Pager
     *
     * @returns Promise<Pagination<Resource<ListItemModel>>>
     */
    public static async getList(filter: FilterModel, pager: Pager): Promise<Pagination<Resource<ListItemModel>>>
    {
        return (await axios.get<Pagination<Resource<ListItemModel>>>('admin/cms/regulations/sets', {
            params: merge({}, filter, pager.data())
        })).data;
    }

    /**
     * @param id number
     *
     * @returns Promise<Statement>
     */
    public static async remove(id: number): Promise<Statement>
    {
        return (await axios.delete(`admin/cms/regulations/sets/${id}`)).data as Statement;
    }

    /**
     * @param id number
     *
     * @returns Promise<Resource<ViewModel>>
     */
    public static async fetch(id: number): Promise<Resource<ViewModel>>
    {
        return (await axios.get<Resource<ViewModel>>(`admin/cms/regulations/sets/${id}`)).data;
    }

    /**
      * @param model FormModel
      *
      * @returns Promise<Resource<ViewModel>>
      */
    public static async create(model: FormModel): Promise<Resource<ViewModel>>
    {
        return (await axios.post<Resource<ViewModel>>(`admin/cms/regulations/sets`, model)).data;
    }

    /**
      * @param id number
      * @param model FormModel
      *
      * @returns Promise<Resource<FormModel>>
      */
    public static async update(id: number, model: FormModel): Promise<Resource<ViewModel>>
    {
        return (await axios.put<Resource<ViewModel>>(`admin/cms/regulations/sets/${id}`, model)).data;
    }
}

export interface FilterModel
{
    name: string;
    type: string;
    language: string;
}

export interface FormModel
{
    id: number;
    type:string;
    name?:string;
    language: string;
    regulations: number[];
    websiteId: number;
    isRequiredAfterChanges:boolean;
}

export interface ViewModel
{
    id: number;
    type:string;
    name?:string;
    language: string;
    regulations: KeyValue<number, string>[];
    websiteId: number;
    isRequiredAfterChanges: boolean;
}

export interface ListItemModel{
    id:number;
    type:string;
    name?:string;
    language: string;
    regulations: string[];
}
