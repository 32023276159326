import { axios } from '@/plugins/axios';
import merge from 'lodash/merge';
import Pager from '@/helpers/Pager';
import { Pagination, Resource, Statement } from '@/helpers/Interfaces';
import { DateTime } from 'luxon';

/**
 * SubstitutionService
 */
export default class SubstitutionService
{
    /**
     * @param filter FilterModel
     * @param pager Pager
     *
     * @returns Promise<Pagination<Resource<ListItemModel>>>
     */
    public static async getList(filter: FilterModel, pager: Pager): Promise<Pagination<Resource<ListItemModel>>>
    {
        return (await axios.get<Pagination<Resource<ListItemModel>>>('admin/substitutions', {
            params: merge({}, filter, pager.data())
        })).data;
    }

    /**
     * @param id number
     *
     * @returns Promise<Resource<FormModel>>
     */
    public static async fetch(id: number): Promise<Resource<FormModel>>
    {
        return (await axios.get<Resource<FormModel>>(`admin/substitutions/${id}`)).data;
    }

    /**
     * @param model FormModel
     *
     * @returns Promise<Resource<FormModel>>
     */
    public static async create(model: FormModel): Promise<Resource<FormModel>>
    {
        return (await axios.post<Resource<FormModel>>(`admin/substitutions`, model)).data;
    }

    /**
     * @param id number
     * @param model FormModel
     *
     * @returns Promise<Statement>
     */
    public static async update(id: number, model: FormModel): Promise<Statement>
    {
        return (await axios.put<Statement>(`admin/substitutions/${id}`, model)).data;
    }

    /**
     * @param id number
     *
     * @returns Promise<Statement>
     */
    public static async remove(id: number): Promise<Statement>
    {
        return (await axios.delete(`admin/substitutions/${id}`)).data as Statement;
    }
}

export interface ListItemModel
{
    id: number;
    dateCreatedUtc: DateTime;
    dateBeginUtc: DateTime;
    dateEndUtc: DateTime;
    isPending: boolean;
    canBeDeleted: boolean;
    replacedByUserId: number;
    userBeingReplacedId: number;
    replacedByUserFirstName: string;
    replacedByUserLastName: string;
    userBeingReplacedFirstName: string;
    userBeingReplacedLastName: string;
    facePictureUrl: string;
}

export interface FilterModel
{
    userBeingReplaced: number;
    replacingUser: number;
    year: number;
    month: number;
    onlyActive?: boolean;
}

export interface FormModel
{
    id: number;
    dateBeginUtc: DateTime;
    dateEndUtc: DateTime;
    description: string;
    replacedByUserId: number;
    userBeingReplacedId: number;
}
