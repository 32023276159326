<script lang="ts" setup>
import { getCurrentInstance, computed } from 'vue';
import { computedAsync } from '@vueuse/core';
import { useAlerts } from '@/plugins/alerts';
import { useLogging } from '@/plugins/logging';
import { handleException } from '@/helpers/Utils';
import { Relational } from '@/components/builder/form/traits/Relational';
import { Blueprint } from '@/components/builder/base/blueprints/Blueprint';
import Pager from '@/helpers/Pager';
import ModulesService from '@/modules/studio/modules/services/ModulesService';
import { FormBuilderContract } from '@/components/builder/form';
import { FieldComponent } from '@/components/forms/properties';

defineOptions({
    name: 'field-component',
    components: {
        'field-component': FieldComponent
    }
});

const model = defineModel<Blueprint & Relational>();
const props = defineProps({
  "form": null
});

const instance = getCurrentInstance();
const uid = computed(() => `form-field-${instance?.uid}`);
const { $alert } = useAlerts();
const { $log } = useLogging();
const invalidFeedback = (property: string) => props.form.schema.errorMessage(model.value, property);

const modules = computedAsync(async () =>
{
    try
    {
        const pager = new Pager(1, 10000, 'Name', 'ASC');
        const response = await ModulesService.getList({} as any, pager);

        return response.items;
    }
    catch (ex)
    {
        handleException($log, ex, {
            400: (ex: any) => $alert.warning(ex.message)
        });

        return [];
    }
});
const moduleOptions = computed(() =>
{
    return [{value: null, text: ''}, ...(modules.value?.map(p => ({
        value: p.id,
        text: p.name
    })) || [])];
});
const module = computed({
    get() { return model.value.moduleId; },
    set(value) { model.value.moduleId = value; }
});

const sections = computedAsync(async () =>
{
    if (!model.value.moduleId) return [];

    try
    {
        const response = await ModulesService.fetch(model.value.moduleId);

        model.value.categoryId = response.result.categoryId;

        return response.result.sections.filter(p => p.template == 'Collection');
    }
    catch (ex)
    {
        handleException($log, ex, {
            400: (ex: any) => $alert.warning(ex.message)
        });

        return [];
    }
});
const sectionOptions = computed(() =>
{
    return [{value: null, text: ''}, ...(sections.value?.map(p => ({
        value: p.id,
        text: p.name
    })) || [])];
});
const section = computed({
    get() { return model.value.sectionId; },
    set(value)
    {
        model.value.sectionId = value;
        model.value.formId = sections.value.find(p => p.id == value)?.formId;
    }
});
</script>


<template>
    <div class="form-group">
        <label :for="`${uid}-module`">{{ $t('[[[Moduł]]]') }}</label>
        <ideo-form-select :id="`${uid}-module`" v-model="module" :options="moduleOptions" />
        <small class="form-text text-danger" v-if="invalidFeedback('modelId')">
            {{ $t(invalidFeedback('modelId')) }}
        </small>
    </div>
    <div class="form-group">
        <label :for="`${uid}-section`">{{ $t('[[[Sekcja]]]') }}</label>
        <ideo-form-select :id="`${uid}-module`" v-model="section" :options="sectionOptions" :disabled="!model.moduleId" />
        <small class="form-text text-danger" v-if="invalidFeedback('modelId')">
            {{ $t(invalidFeedback('sectionId')) }}
        </small>
    </div>
</template>
