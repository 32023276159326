<template>
    <ideo-form-group :label="$t('[[[Katalog]]]')" :invalid-feedback="form.$errors.first('settings.folder')" :state="form.$errors.state('settings.folder')" required>
        <ideo-form-select name="settings.folder" v-model="form.settings.folder" :options="folders" value-field="id" text-field="name">
            <template #first>
                <option :value="null">
                    {{ $t('[[[wybierz katalog]]]') }}
                </option>
            </template>
        </ideo-form-select>
    </ideo-form-group>
    <ideo-form-group :label="$t('[[[Od poziomu]]]')" :invalid-feedback="form.$errors.first('settings.fromLevel')" :state="form.$errors.state('settings.fromLevel')">
        <ideo-form-select name="settings.fromLevel" v-model="form.settings.fromLevel" :options="levels"></ideo-form-select>
    </ideo-form-group>
    <ideo-form-group :label="$t('[[[Do poziomu]]]')" :invalid-feedback="form.$errors.first('settings.toLevel')" :state="form.$errors.state('settings.toLevel')">
        <ideo-form-select name="settings.toLevel" v-model="form.settings.toLevel" :options="levels"></ideo-form-select>
    </ideo-form-group>
    <ideo-form-group :label="$t('[[[Rozwiń do]]]')" :invalid-feedback="form.$errors.first('settings.expand')" :state="form.$errors.state('settings.expand')">
        <ideo-form-select name="settings.expand" v-model="form.settings.expand" :options="levels"></ideo-form-select>
    </ideo-form-group>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Emit, Prop, Watch } from '@/helpers/Decorators';
import { Option } from '@/helpers/Interfaces';
import { FormType } from '@/helpers/Form';
import { ValidatableComponent } from '@/components/cms';
import PagesService, { FolderListItemModel } from '@/modules/cms/pages/services/PagesService';
import merge from 'lodash/merge';

interface FormSettings
{
    settings: any;
}

@Options({
    name: 'widget-menu-settings'
})
export default class MenuSettings extends Vue implements ValidatableComponent
{
    @Prop() public modelValue!: any;

    public folders: FolderListItemModel[] = [];
    public defaults: any = {
        folder: null,
        fromLevel: 1,
        toLevel: 1,
        expand: 1
    };

    public get form(): FormType<FormSettings>
    {
        return this.modelValue;
    }

    public get levels(): Option<number>[]
    {
        return new Array(2).fill(null).map((value, index) =>
        {
            return { value: index + 1, text: (index + 1).toString() };
        });

        // #15239 - drugi punkt, ograniczenie tylko do 2 poziomów
        // return new Array(10).fill(null).map((value, index) =>
        // {
        //     return { value: index + 1, text: (index + 1).toString() };
        // });
    }

    public async created(): Promise<void>
    {
        this.form.settings = merge(this.defaults, this.form.settings);

        await this.loadFolders();
    }

    public async loadFolders(): Promise<void>
    {
        try
        {
            this.folders = (await PagesService.getFolders(this.form.websiteId, this.form.locale)).items.map(p => p.result);
        }
        catch (ex)
        {
            this.folders = [];
            this.$log.debug(ex);
        }
    }

    @Watch('form.settings', { deep: true })
    public onSettingsChanged(value: any): void
    {
        this.triggetModel();
    }

    @Emit('update:modelValue')
    public triggetModel(): any
    {
        return this.form;
    }

    public async validate(): Promise<void>
    {
        this.form.validate(schema => ({
            'settings.folder': schema.string().nullable().required(this.$t("[[[Wybranie katalogu jest wymagane]]]"))
        }));
    }
}
</script>
