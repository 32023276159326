import { axios } from '@/plugins/axios';
import merge from 'lodash/merge';
import Pager from '@/helpers/Pager';
import { PublicationEnum } from '@/helpers/Enums';
import { Pagination, Resource, Statement, PagePublication, EntityVersion, MetaSettingsModel } from '@/helpers/Interfaces';
import { DateTime } from 'luxon';

/**
 * SurveysService
 */
export default class SurveysService
{
    /**
     * @param filter FilterModel
     * @param pager Pager
     *
     * @returns Promise<Pagination<Resource<ListItemModel>>>
     */
    public static async getList(filter: FilterModel, pager: Pager): Promise<Pagination<Resource<ListItemModel>>>
    {
        return (await axios.get<Pagination<Resource<ListItemModel>>>('admin/cms/surveys', {
            params: merge({}, filter, pager.data())
        })).data;
    }

    /**
     * @param id number
     *
     * @returns Promise<Resource<FormModel>>
     */
    public static async fetch(id: number): Promise<Resource<FormModel>>
    {
        return (await axios.get<Resource<FormModel>>(`admin/cms/surveys/${id}`)).data;
    }

    /**
     * @param model UpsertModel
     *
     * @returns Promise<Resource<FormModel>>
     */
    public static async create(model: UpsertModel): Promise<Resource<FormModel>>
    {
        return (await axios.post<Resource<FormModel>>(`admin/cms/surveys`, model)).data;
    }

    /**
     * @param id number
     * @param model UpsertModel
     *
     * @returns Promise<Resource<FormModel>>
     */
    public static async update(id: number, model: UpsertModel): Promise<Resource<FormModel>>
    {
        return (await axios.put<Resource<FormModel>>(`admin/cms/surveys/${id}`, model)).data;
    }

    /**
     * @param id number
     *
     * @returns Promise<Statement>
     */
    public static async remove(id: number): Promise<Statement>
    {
        return (await axios.delete(`admin/cms/surveys/${id}`)).data as Statement;
    }

    /**
     * @param id number
     * @param status PublicationEnum
     *
     * @returns Promise<Statement>
     */
    public static async status(id: number, status: PublicationEnum): Promise<Statement>
    {
        return (await axios.patch(`admin/cms/surveys/${id}/status`, {
            status: status
        })).data;
    }

    /**
     * @param id number
     * @param model PublishModel
     *
     * @returns Promise<Statement>
     */
    public static async publish(id: number, model: PublishModel): Promise<Statement>
    {
        return (await axios.patch(`admin/cms/surveys/${id}/publish`, model)).data;
    }

    /**
     * @param id number
     * @param model PublishModel
     *
     * @returns Promise<Statement>
     */
    public static async attach(id: number, pageId: number): Promise<Statement>
    {
        return (await axios.post(`admin/cms/surveys/${id}/page/${pageId}`)).data;
    }

    /**
     * @param id number
     * @param model PublishModel
     *
     * @returns Promise<Statement>
     */
    public static async detach(id: number, pageId: number): Promise<Statement>
    {
        return (await axios.delete(`admin/cms/surveys/${id}/page/${pageId}`)).data;
    }

    /**
     * @param id number
     *
     * @returns Promise<Pagination<EntityVersion>>
     */
    public static async getVersions(id: number, pager: Pager): Promise<Pagination<EntityVersion>>
    {
        return (await axios.get<Pagination<EntityVersion>>(`admin/cms/surveys/${id}/versions`, {
            params: pager.data()
        })).data;
    }

    /**
     * @param id number
     *
     * @returns Promise<PagePublication[]>
     */
    public static async getPagePublications(id: number): Promise<PagePublication[]>
    {
        return (await axios.get<PagePublication[]>(`admin/cms/surveys/${id}/pages`)).data;
    }

    /**
     * @param originId number
     *
     * @returns Promise<CourseVoteListModel[]>
     */
    public static async results(originId: number): Promise<CourseVoteListModel[]>
    {
        return (await axios.get<CourseVoteListModel[]>(`admin/cms/surveys/results/${originId}`)).data;
    }

    /**
     * @param search string
     * @param limit number
     *
     * @returns Promise<ListItemModel[]>
     */
    public static async getAutocompleteList(search: string, limit: number = 10): Promise<ListItemModel[]>
    {
        return (await axios.get<ListItemModel[]>(`admin/cms/surveys/autocomplete`, {
            params: {
                search: search,
                limit: limit
            }
        })).data;
    }

    /**
     * @param id number
     *
     * @returns Promise<Resource<ListItemModel>>
     */
    public static async fetchAutocompleteModel(id: number): Promise<ListItemModel>
    {
        return (await axios.get<ListItemModel>(`admin/cms/surveys/autocomplete/${id}`)).data;
    }

    /**
     * @param originId number
     *
     * @returns Promise<ListItemModel>
     */
    public static async fetchAutocompleteModelByOriginId(originId: number): Promise<ListItemModel>
    {
        return (await axios.get<ListItemModel>(`admin/cms/surveys/autocomplete/${originId}/origin`)).data;
    }

    /**
     * @param id number
     *
     * @returns Promise<Resource<ListItemModel>>
     */
    public static async fetchElAutocompleteModel(id: number): Promise<ListItemModel>
    {
        return (await axios.get<ListItemModel>(`admin/elearning/surveys/autocomplete/${id}`)).data;
    }
}

export interface ListItemModel
{
    id: number;
    dateCreatedUtc: DateTime;
    dateModifiedUtc: DateTime;
    createdBy: string;
    modifiedBy: string;
    question: string;
    status: PublicationEnum;
    revisionOrigin: number;
}

export interface FilterModel
{
    pageId?: number;
    userId: number;
    status: string;
    question: string;
    dateFromUtc?: DateTime;
    dateDueUtc?: DateTime;
    onlyMine: boolean;
    onlyTeam: boolean;
}

export interface UpsertModel
{
    id: number;
    question: string;
    answers: SurveyAnswer[];
    meta: MetaSettingsModel|null;
}

export interface SurveyAnswer
{
    id: number;
    text: string;
}

export interface FormModel extends UpsertModel
{
    datePublishedUtc?: DateTime;
    dateDismissedUtc?: DateTime;
    status: PublicationEnum;
}

export interface PublishModel
{
    datePublishedUtc?: DateTime;
    dateDismissedUtc?: DateTime;
}

export interface VoteListModel {
    answerId: number;
    answerName: string;
    percent: number;
    votes: number;
}

export interface CourseVoteListModel {
    coursePublicId: string;
    courseName: string;
    answers: VoteListModel;
}
