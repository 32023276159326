import { axios } from '@/plugins/axios';
import merge from 'lodash/merge';
import Pager from '@/helpers/Pager';
import { Resource as File } from '@/modules/core/files/services/StorageService';
import { PublicationEnum, RelatedArticleSectionsEnum } from '@/helpers/Enums';
import { Pagination, Resource, Statement, PagePublication, EntityVersion, MetaSettingsModel } from '@/helpers/Interfaces';
import { DateTime } from 'luxon';

/**
 * ArticlesService
 */
export default class ArticlesService
{
    /**
     * @param filter FilterModel
     * @param pager Pager
     *
     * @returns Promise<Pagination<Resource<ListItemModel>>>
     */
    public static async getList(filter: FilterModel, pager: Pager): Promise<Pagination<Resource<ListItemModel>>>
    {
        return (await axios.get<Pagination<Resource<ListItemModel>>>('admin/cms/articles', {
            params: merge({}, filter, pager.data())
        })).data;
    }

    /**
     * @param articles number[]
     *
     * @returns Promise<ListItemModel[]>
     */
    public static async getListByIds(articles: number[]): Promise<ListItemModel[]>
    {
        return (await axios.get<ListItemModel[]>('admin/cms/articles/get-by-ids', {
            params: {
                articles: articles.join(',')
            }
        })).data;
    }

    /**
     * @param id number
     *
     * @returns Promise<Resource<FormModel>>
     */
    public static async fetch(id: number): Promise<Resource<FormModel>>
    {
        return (await axios.get<Resource<FormModel>>(`admin/cms/articles/${id}`)).data;
    }

    /**
     * @param id number
     *
     * @returns Promise<ListItemModel[]>
     */
    public static async getRelated(id: number): Promise<ListItemModel[]>
    {
        return (await axios.get<ListItemModel[]>(`admin/cms/articles/${id}/related`)).data;
    }

    /**
     * @param id number
     *
     * @returns Promise<ListItemModel[]>
     */
    public static async getRelatedFromWidget(id: number): Promise<ListItemModel[]>
    {
        return (await axios.get<ListItemModel[]>(`admin/cms/articles/${id}/relatedFromWidget`)).data;
    }

    /**
     * @param model UpsertModel
     *
     * @returns Promise<Resource<FormModel>>
     */
    public static async create(model: UpsertModel): Promise<Resource<FormModel>>
    {
        return (await axios.post<Resource<FormModel>>(`admin/cms/articles`, model)).data;
    }

    /**
     * @param id number
     * @param model UpsertModel
     *
     * @returns Promise<Resource<FormModel>>
     */
    public static async update(id: number, model: UpsertModel): Promise<Resource<FormModel>>
    {
        return (await axios.put<Resource<FormModel>>(`admin/cms/articles/${id}`, model)).data;
    }

    /**
     * @param id number
     *
     * @returns Promise<Statement>
     */
    public static async remove(id: number): Promise<Statement>
    {
        return (await axios.delete(`admin/cms/articles/${id}`)).data as Statement;
    }

    /**
     * @param id number
     * @param status PublicationEnum
     *
     * @returns Promise<Statement>
     */
    public static async status(id: number, status: PublicationEnum): Promise<Statement>
    {
        return (await axios.patch(`admin/cms/articles/${id}/status`, {
            status: status
        })).data;
    }

    /**
     * @param id number
     * @param model PublishModel
     *
     * @returns Promise<Statement>
     */
    public static async publish(id: number, model: PublishModel): Promise<Statement>
    {
        return (await axios.patch(`admin/cms/articles/${id}/publish`, model)).data;
    }

    /**
     * @param id number
     * @param model PublishModel
     *
     * @returns Promise<Statement>
     */
    public static async attach(id: number, pageId: number): Promise<Statement>
    {
        return (await axios.post(`admin/cms/articles/${id}/page/${pageId}`)).data;
    }

    /**
     * @param id number
     * @param model PublishModel
     *
     * @returns Promise<Statement>
     */
    public static async detach(id: number, pageId: number): Promise<Statement>
    {
        return (await axios.delete(`admin/cms/articles/${id}/page/${pageId}`)).data;
    }

    /**
     * @param id number
     *
     * @returns Promise<Pagination<EntityVersion>>
     */
    public static async getVersions(id: number, pager: Pager): Promise<Pagination<EntityVersion>>
    {
        return (await axios.get<Pagination<EntityVersion>>(`admin/cms/articles/${id}/versions`, {
            params: pager.data()
        })).data;
    }

    /**
     * @param id number
     *
     * @returns Promise<PagePublication[]>
     */
    public static async getPagePublications(id: number): Promise<PagePublication[]>
    {
        return (await axios.get<PagePublication[]>(`admin/cms/articles/${id}/pages`)).data;
    }

    /**
     * @param search string
     * @param limit number
     *
     * @returns Promise<ListItemModel[]>
     */
    public static async getAutocompleteList(search: string, limit: number = 10, articleId: number = 0): Promise<ListItemModel[]>
    {
        return (await axios.get<ListItemModel[]>('admin/cms/articles/autocomplete', {
            params: {
                search: search,
                limit: limit,
                articleId: articleId
            }
        })).data;
    }

    /**
     * @param id number
     *
     * @returns Promise<ListItemModel>
     */
    public static async fetchAutocompleteModel(id: number): Promise<ListItemModel>
    {
        return (await axios.get<ListItemModel>(`admin/cms/articles/autocomplete/${id}`)).data;
    }

    /**
     * @param originId number
     *
     * @returns Promise<ListItemModel>
     */
    public static async fetchAutocompleteModelByOriginId(originId: number): Promise<ListItemModel>
    {
        return (await axios.get<ListItemModel>(`admin/cms/articles/autocomplete/${originId}/origin`)).data;
    }
}

export interface ListItemModel
{
    author: string;
    id: number;
    dateCreatedUtc: DateTime;
    dateModifiedUtc: DateTime;
    createdBy: string;
    modifiedBy: string;
    title: string;
    status: PublicationEnum;
    originId: number;
    isArchive: boolean;
}

export interface FilterModel
{
    authorId?: number;
    pageId?: number;
    userId: number;
    status: PublicationEnum;
    title: string;
    dateFromUtc?: DateTime;
    dateDueUtc?: DateTime;
    onlyMine: boolean;
    onlyTeam: boolean;
}

export interface UpsertModel
{
    id: number;
    originId: number;
    title: string;
    shortTitle: string;
    tags: string[];
    lead: string;
    content: string[];
    picture?: File;
    attachments: File[];
    contentOnly: boolean;
    meta: MetaSettingsModel | null;
    photoGallery:File[];
    video?: File;
    videoCover?: File;
    videoUrl?: string;
    relatedArticles: RelatedModel[];
    authorName?: string;
    authorId?: number;
    preTitle: string;
    surveyId: number;
    isSurveyOn: boolean;
    isPremium: boolean;
    isOpenPremium: boolean;
    premiumAttachments: File[];
    premiumPhotoGallery: File[];
    premiumVideo?: File;
    premiumVideoCover?: File;
    premiumVideoUrl?: string;
    isOpinion: boolean;
    commentsDisabled: boolean;
    labelId?: number;
    categoryId?: number;
    fullPageView: boolean;
    isContactFormOn: boolean;
    contactFormCategoryId?: number;
    contactFormId?: number;
}

export interface FormModel extends UpsertModel
{
    datePublishedUtc?: DateTime;
    dateDismissedUtc?: DateTime;
    status: PublicationEnum;
}

export interface PublishModel
{
    datePublishedUtc?: DateTime;
    dateDismissedUtc?: DateTime;
}

export interface RelatedModel
{
    id: number;
    section: RelatedArticleSectionsEnum | number;
}
