<script lang="ts" setup>
import { computed } from 'vue';
import { useLocalization } from '@/plugins/localization';
import { Definition, RelatedParentType, RelatedParentEntry, instanceOfRelatedParentEntry } from '.';
import { Option } from '@/helpers/Interfaces';
import { FormBuilderContract } from '@/components/builder/form';
import DocumentsService from '@/modules/studio/documents/services/DocumentsService';

export interface Props
{
    builder: FormBuilderContract;
    component: RelatedParentType;
    index: number;
}

defineOptions({
    name: `${Definition.type}-filter`
});

const { $t } = useLocalization();

const props = defineProps({
  "builder": null,
  "component": null,
  "index": null
});
const component = computed(() => props.component);
const entry = computed(() => props.builder.document.initEntry(props.component, new RelatedParentEntry(), instanceOfRelatedParentEntry) as RelatedParentEntry);
const display = computed(() => entry.value.data?.select(p => p.text).toArray().toString() ?? null);
const label = computed(() => props.builder.localization.translate(component.value.label));
const placeholder = computed(() => props.builder.localization.translate(component.value.placeholder) || $t('[[[wybierz...]]]'));
const model = computed({
    get()
    {
        return entry.value.data?.first();
    },
    set(model)
    {
        entry.value.data = model != null ? [model] : [];
    }
});
const value = computed(() => model.value?.value);

const reset = (): void =>
{
    entry.value.data = [];
};

const fetch = async (value: string): Promise<Option<number>> =>
{
    return new Promise(resolve => resolve(model.value));
};

const search = async (query: string): Promise<Option<number>[]> =>
{
    const response = await DocumentsService.getOptions(props.builder.blueprintId, props.component.id, [], null, query);

    return response.map(p =>
    {
        return {
            value: p.value,
            text: p.text
        };
    });
};

const onChanged = (value: Option<number>): void =>
{
    model.value = value;
};
</script>

<template>
    <ideo-filter :name="component.name" :label="label" :visible="component.showFilter" :index="index" :display="display" @clear="reset">
        <ideo-autocomplete :name="component.name" :placeholder="placeholder" :fetch="fetch" :search="search" :model-value="value" @changed="onChanged" />
    </ideo-filter>
</template>
