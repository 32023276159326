<template>
    <div>
        <div class="input-group">
            <picker
                class="form-control"
                @update:modelValue="updateModel"
                :modelValue="datetimeValue"
                :input-id="name"
                :name="name"
                :type="type"
                :zone="zone"
                :format="datetimeFormat"
                :disabled="disabled"
                :minute-step="minuteStep"
                :phrases="{ok: $t('[[[Ok]]]'), cancel: $t('[[[Anuluj]]]')}"
            />
            <ideo-button variant="secondary" :title="$t('[[[Usuń datę]]]')" @click.prevent="clear()" :disabled="disabled">
                <i class="fas fa-fw fa-times"></i>
            </ideo-button>
        </div>
        <div class="text-center small" v-if="datetimeValue && zone != 'UTC'">
            {{ $filters.date(datetimeValue, datetimeFormat, 'UTC') }} (UTC)
        </div>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Emit, Prop } from '@/helpers/Decorators';
import { Datetime } from '@/components/datetime';
import { DateTime } from 'luxon';

@Options({
    name: 'ideo-datetime',
    components: {
        picker: Datetime
    }
})
export default class IdeoDateTime extends Vue
{
    @Prop({ default: '' }) public name: string;
    @Prop({ default: 'date' }) public type: string;
    @Prop({ default: null }) public modelValue: DateTime;
    @Prop({ default: 'local' }) public zone: string;
    @Prop({ default: false }) public disabled: boolean;
    @Prop({ default: 5 }) public minuteStep: number;

    public get datetimeValue(): string
    {
        return this.modelValue instanceof DateTime ? this.modelValue.toISO() : this.isIsoDate(this.modelValue) ? this.modelValue : '';
    }

    public get datetimeFormat(): string
    {
        switch (this.type)
        {
            case 'datetime':
                return 'dd-MM-yyyy HH:mm';
            case 'date':
                return 'dd-MM-yyyy';
            case 'time':
                return 'HH:mm';
            default:
                return 'dd-MM-yyyy HH:mm';
        }
    }

    public isIsoDate(str: string): boolean | string
    {
        if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str))
            return false;

        const d = new Date(str);

        return d.toISOString() === str;
    }

    @Emit('update:modelValue')
    public updateModel(value: string): DateTime | null
    {
        return value ? DateTime.fromISO(value).setZone('UTC') : null;
    }

    @Emit('update:modelValue')
    public clear(): null
    {
        const element = this.$el.querySelector('.vdatetime-input');

        element.dispatchEvent(new CustomEvent('input', { bubbles: true }));

        return null;
    }
}
</script>

<style lang="scss">
    .vdatetime {
        padding: 0 !important;

        > input {
            border: none;
            padding: 6px 12px;
            width: 100%;
            height: 100%;

            &:disabled {
                background-color: var(--bs-tertiary-bg);
            }
        }

        .vdatetime-popup__header,
        .vdatetime-calendar__month__day--selected > span > span,
        .vdatetime-calendar__month__day--selected:hover > span > span {
            background: var(--bs-primary);
        }

        .vdatetime-year-picker__item--selected,
        .vdatetime-time-picker__item--selected,
        .vdatetime-popup__actions__button {
            color: var(--bs-info);
        }
    }

    .vdatetime-popup {
        z-index: 10013;
    }

    .vdatetime-overlay {
        z-index: 10012;
    }

    .vdatetime-wrapper {
        .input-group-append {
            .input-group-text {
                border-color: var(--bs-border-color);
                border-radius: 0 4px 4px 0;

                &:hover {
                    background: var(--bs-secondary-bg);
                    transition: background 0.3s;
                }
            }
        }
    }
</style>
