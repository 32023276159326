<template>
    <ideo-form-group :label="$t('[[[Strona z modułu konto użytkownika]]]')" :invalid-feedback="form.$errors.first('settings.page')" :state="form.$errors.state('settings.page')" required>
        <edito-page-selector v-model="form.settings.page" :module="module" label="" :website="false" :preview="false" class="mb-0" />
    </ideo-form-group>

    <hr>

    <ideo-form-group :label="$t('[[[Strona docelowa dla \'Polityka prywatności\']]]')" :invalid-feedback="form.$errors.first('settings.privacyPolicyPage')" :state="form.$errors.state('settings.privacyPolicyPage')">
        <edito-page-selector v-model="form.settings.privacyPolicyPage" :module="articleModule" label="" :website="false" :preview="false" class="mb-0" />
    </ideo-form-group>
    <ideo-form-group :label="$t('[[[Strona docelowa dla \'Pliki cookies\']]]')" :invalid-feedback="form.$errors.first('settings.cookiesPage')" :state="form.$errors.state('settings.cookiesPage')">
        <edito-page-selector v-model="form.settings.cookiesPage" :module="articleModule" label="" :website="false" :preview="false" class="mb-0" />
    </ideo-form-group>
    <ideo-form-group :label="$t('[[[Strona docelowa dla \'Kontakt z adminem\']]]')" :invalid-feedback="form.$errors.first('settings.adminContactPage')" :state="form.$errors.state('settings.adminContactPage')">
        <edito-page-selector v-model="form.settings.adminContactPage" :module="articleModule" label="" :website="false" :preview="false" class="mb-0" />
    </ideo-form-group>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Emit, Prop, Watch } from '@/helpers/Decorators';
import { FormType } from '@/helpers/Form';
import { ValidatableComponent } from '@/components/cms';
import merge from 'lodash/merge';

interface FormSettings
{
    settings: any;
}

@Options({
    name: 'widget-login-settings'
})
export default class LoginSettings extends Vue implements ValidatableComponent
{
    @Prop() public modelValue!: any;

    public module: string = 'el_user';
    public articleModule: string = 'articles';
    public defaults: any = {
        page: null,
        privacyPolicyPage: null,
        cookiesPage: null,
        adminContactPage: null
    };

    public get form(): FormType<FormSettings>
    {
        return this.modelValue;
    }

    public async created(): Promise<void>
    {
        this.form.settings = merge(this.defaults, this.form.settings);
    }

    @Watch('form.settings', { deep: true })
    public onSettingsChanged(value: any): void
    {
        this.triggetModel();
    }

    @Emit('update:modelValue')
    public triggetModel(): any
    {
        return this.form;
    }

    public async validate(): Promise<void>
    {
        this.form.validate(schema => ({
            'settings.page': schema.string().nullable().required(this.$t("[[[Wybranie strony jest wymagane]]]"))
        }));
    }
}
</script>
