import { App, Plugin } from 'vue';
import { Router } from 'vue-router';
import { FormType } from '@/helpers/Form';
import { Columns } from '@/helpers/Interfaces';
import Pager, { instanceOfPagerContract } from '@/helpers/Pager';
import { useViewStateStore, ViewStateStore } from '@/store/viewstate';
import { FilterState } from '@/modules/core/common/services/FilterService';

let currentInstance: ViewState | null = null;

export const restoreState = (filter?: FormType<any>, pager?: Pager, columns?: Columns, key?: string): void =>
{
    return currentInstance?.restore(filter, pager, columns, key);
};

export const persistState = (filter?: FormType<any>, pager?: Pager, columns?: Columns, key?: string): void =>
{
    return currentInstance?.persist(filter, pager, columns, key);
};

export const setFilterState = (state: FilterState): void =>
{
    return currentInstance?.setFilterState(state);
};

export const getFilterState = (): FilterState =>
{
    return currentInstance?.getFilterState();
};

export const setColumnsState = (state: Columns): void =>
{
    return currentInstance?.setColumnsState(state);
};

export const getColumnsState = (): Columns =>
{
    return currentInstance?.getColumnsState();
};

interface ViewState
{
    restore(filter: FormType<any>, pager: Pager, columns?: Columns, key?: string): void;
    persist(filter: FormType<any>, pager: Pager, columns?: Columns, key?: string): void;
    setFilterState(state: FilterState): void;
    getFilterState(): FilterState;
    setColumnsState(state: Columns): void;
    getColumnsState(): Columns;
}

export class ViewStateOptions
{
    public router: Router;
}

class ViewStateHelper implements ViewState
{
    private options: ViewStateOptions;
    private store: ViewStateStore;

    public constructor(app: App<any>, options: ViewStateOptions, store: ViewStateStore)
    {
        this.options = options;
        this.store = store;
    }

    private routeKey(): string
    {
        return this.options.router.currentRoute.value.name.toString();
    }

    public restore(filter?: FormType<any>, pager?: Pager, columns?: Columns, key?: string): void
    {
        this.store.key = key || this.routeKey();

        const filterState = this.store.getFilters(this.store.key);
        const pagerState = this.store.getPager(this.store.key);
        const columnsState = this.store.getColumns(this.store.key);

        if (filter != undefined)
        {
            filter.withData(filterState.form || JSON.parse(filterState.activeTemplate?.contentJson || '{}'));
        }

        if (pager != undefined && instanceOfPagerContract(pagerState))
        {
            pager.apply(pagerState);
        }

        if (columns != undefined)
        {
            columns.visible = columnsState.visible;
            columns.positions = columnsState.positions;
        }
    }

    public persist(filter?: FormType<any>, pager?: Pager, columns?: Columns, key?: string): void
    {
        this.store.key = key || this.routeKey();

        if (filter != undefined)
        {
            const filterState = this.store.getFilters(this.store.key);

            this.store.setFilters(this.store.key, {
                activeTemplate: filterState.activeTemplate,
                visibleFilters: filterState.visibleFilters,
                form: filter.data()
            });
        }

        if (pager != undefined)
        {
            this.store.setPager(this.store.key, pager.data());
        }

        if (columns != undefined)
        {
            this.store.setColumns(this.store.key, JSON.parse(JSON.stringify(columns)));
        }
    }

    public getFilterState(): FilterState
    {
        return this.store.getFilters(this.store.key || this.routeKey());
    }

    public setFilterState(state: FilterState): void
    {
        this.store.setFilters(this.store.key || this.routeKey(), state);
    }

    public getColumnsState(): Columns
    {
        return this.store.getColumns(this.store.key || this.routeKey());
    }

    public setColumnsState(state: Columns): void
    {
        this.store.setColumns(this.store.key || this.routeKey(), JSON.parse(JSON.stringify(state)));
    }
}

const ViewStatePlugin: Plugin =
{
    install(app, options)
    {
        if (!options || !options.router)
        {
            throw new Error("ViewStateOptions.router must be set.");
        }

        currentInstance = new ViewStateHelper(app, options, useViewStateStore());
    }
};

export default ViewStatePlugin;
