<template>
    <multiselect
        v-model="valueChanged" :options="options" :name="name" :loading="loading" track-by="id" :label="label" @search-change="onSearchChanged" @open="open()" @close="close()" @select="select" @remove="remove"
        :placeholder="placeholder || $t('[[[wybierz...]]]')" :select-label="''" :selected-label="''" :deselect-label="''" :multiple="multiple" :searchable="true" :options-limit="limit"
        :internal-search="false" :clear-on-select="false" :close-on-select="closeOnSelect" :max-height="300" :show-no-results="true" :hide-selected="false" :disabled="disabled"
    >
        <template #noOptions>{{ $t('[[[Lista jest pusta]]]') }}</template>
        <template #noResult>{{ $t('[[[Nie znaleziono żadnych wyników.]]]') }}</template>
        <template #singleLabel="{ option }"><slot name="selected" :option="option"></slot></template>
        <template #option="{ option }"><slot name="option" :option="option"></slot></template>
        <template #selection="{ values }"><slot name="selection" :option="values"></slot></template>
    </multiselect>
</template>

<script lang="ts">
import { isProxy, toRaw } from 'vue';
import { Options, Vue } from 'vue-class-component';
import { Prop, Debounce, Emit } from '@/helpers/Decorators';
import { Option } from '@/helpers/Interfaces';
import Multiselect from 'vue-multiselect/src/Multiselect.vue';

@Options({
    name: 'ideo-autocomplete-multi-select',
    components: {
        'multiselect': Multiselect
    }
})
export default class IdeoAutocompleteMultiSelect extends Vue
{
    @Prop({ default: '' }) public name: string;
    @Prop({ default: null }) public modelValue: any;
    @Prop({ default: 10 }) public limit: number;
    @Prop({ default: false }) public disabled: boolean;
    @Prop({ default: false }) public refresh: boolean;
    @Prop({ default: 'name' }) public label: string;
    @Prop() public placeholder: string;
    @Prop() public fetch!: (item: any) => Promise<Option>;
    @Prop() public search!: (query: string, limit: number) => Promise<Option[]>;
    @Prop({ default: true }) public multiple: boolean;
    @Prop({ default: true }) public closeOnSelect: boolean;

    public options: Option[] = [];
    public model: any = null || [];
    public multipleOptions: any = [];
    public loading: boolean = false;
    public track: string = 'id';

    public value: any = [];
    public elementsId: any = [];

    public get valueChanged(): any
    {
        return this.value;
    }

    public set valueChanged(item: any)
    {
        this.value = item;
    }

    public async created(): Promise<void>
    {
        await this.fetchModel(this.modelValue);
        await this.searchOptions('');
    }

    public async searchOptions(query: string): Promise<void>
    {
        if (query)
        {
            this.options = await this.search(query, this.limit);
        }
    }

    public async fetchModel(value: any): Promise<void>
    {
        if (value != undefined)
        {
            if (this.model == null || (this.model != null && this.model[this.track] != value))
            {
                if (Number.isInteger(value))
                {
                    if (value != 0)
                    {
                        await this.fetch(value).then((result) => { this.model.push(result); this.options.push(result); });
                    }
                }
                else if (value != null)
                {
                    const elements = Array.isArray(value) ? value : value.split(',');

                    elements.forEach(async (id: number) =>
                    {
                        if (id != 0)
                        {
                            await this.fetch(id).then((result) => { this.valueChanged.push(result); this.model.push(result); this.options.push(result); });
                        }
                    });
                }
                else
                {
                    this.model = null;
                }
            }
        }
    }

    @Debounce(500)
    public async onSearchChanged(query: string): Promise<void>
    {
        this.loading = true;
        this.searchOptions(query);
        this.loading = false;
    }

    public removeDuplicates(arr: Array<number>): Array<number>
    {
        return [...(new Set(arr))];
    }

    public removeItems(item: any): string
    {
        if (isProxy(this.valueChanged))
        {
            const rawValueChanged = toRaw(this.valueChanged);

            if (Array.isArray(rawValueChanged))
            {
                this.multipleOptions = rawValueChanged.filter((el: any) => el.id != item[this.track]);
            }
            else
            {
                this.multipleOptions = this.multipleOptions.filter((el: any) => el.id != rawValueChanged[this.track]);
            }
        }

        this.elementsId = [];
        this.multipleOptions.forEach((item: any) => { this.elementsId.push(item[this.track]); });

        this.elementsId = this.removeDuplicates(this.elementsId);

        return this.elementsId;
    }

    public isProxyRaw(arr: any): string
    {
        if (isProxy(arr))
        {
            const rawObjectOrArray = toRaw(arr);

            if (rawObjectOrArray.length > 0)
            {
                rawObjectOrArray.forEach((x: any) => { this.multipleOptions.push(x); });

                return this.updateMultipleOptions();
            }
            else
            {
                this.multipleOptions.push(rawObjectOrArray);

                return this.updateMultipleOptions();
            }
        }
    }

    public updateMultipleOptions(): string
    {
        if (isProxy(this.valueChanged))
        {
            const rawValueChanged = toRaw(this.valueChanged);

            if (Array.isArray(rawValueChanged))
            {
                rawValueChanged.forEach((el: any) => { this.multipleOptions.push(el); });
            }
            else
            {
                this.multipleOptions.push(rawValueChanged);
            }
        }

        this.multipleOptions.forEach((item: any) => { this.elementsId.push(item[this.track]); });

        this.elementsId = this.removeDuplicates(this.elementsId);

        return this.elementsId;
    }

    @Emit('update:modelValue')
    public updateModel(model: Option, remove: boolean = false): string
    {
        if (remove)
            return this.removeItems(model);
        else
            return model != null ? this.isProxyRaw(model) : null;
    }

    public open(): void
    {
        if (this.refresh)
        {
            this.searchOptions('');
        }
    }

    public close(): void
    {
        if (this.refresh)
        {
            if (this.model == null)
            {
                this.options = [];
            }
            else
            {
                this.options = this.options.filter(x => x == this.model);
            }
        }
    }

    public select(item: Option): void
    {
        this.updateModel(item);
    }

    public async remove(item: any): Promise<void>
    {
        this.updateModel(item, true);
    }
}
</script>

<style lang="scss" scoped>
.multiselect-badges {
    background: #eee;
    padding: 4px 8px;
    border-radius: 5px;
    margin-bottom: 8px;
}
</style>
