<template>
    <ul class="pagination justify-content-center mb-0">
        <li class="page-item" :class="{'disabled': before.length == 0}">
            <a class="page-link" href="#" @click.prevent="first()">
                <i :title="$t('[[[Pierwsza strona]]]')" class="far fa-angle-double-left"></i>
            </a>
        </li>
        <li class="page-item" :class="{'disabled': before.length == 0}">
            <a class="page-link" href="#" @click.prevent="prev()">
                <i :title="$t('[[[Poprzednia strona]]]')" class="far fa-angle-left"></i>
            </a>
        </li>
        <li v-for="n in before" :key="n" class="page-item">
            <a class="page-link" href="#" @click.prevent="page(n)">{{ n }}</a>
        </li>
        <li class="page-item active">
            <span class="page-link">{{ current }}</span>
        </li>
        <li v-for="n in after" :key="n" class="page-item">
            <a class="page-link" href="#" @click.prevent="page(n)">{{ n }}</a>
        </li>
        <li class="page-item" :class="{'disabled': after.length == 0}">
            <a class="page-link" href="#" @click.prevent="next()">
                <i :title="$t('[[[Następna strona]]]')" class="far fa-angle-right"></i>
            </a>
        </li>
        <li class="page-item" :class="{'disabled': after.length == 0}">
            <a class="page-link" href="#" @click.prevent="last()">
                <i :title="$t('[[[Ostatnia strona]]]')" class="far fa-angle-double-right"></i>
            </a>
        </li>
    </ul>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Emit, Prop } from '@/helpers/Decorators';
import Pager from '@/helpers/Pager';

@Options({
    name: 'pagination'
})
export default class Pagination extends Vue
{
    @Prop()
    public pager!: Pager;

    public get limit(): number
    {
        return this.phone ? 1 : 5;
    }

    public get current(): number
    {
        return this.pager.pageIndex;
    }

    public get before(): number[]
    {
        if (this.pager.totalRows == Number.MAX_SAFE_INTEGER) return [];

        const index = this.current - this.limit;

        return Array(this.limit).fill(undefined).map((p, i) => index + i).filter(p => p > 0);
    }

    public get after(): number[]
    {
        if (this.pager.totalRows == Number.MAX_SAFE_INTEGER) return [];

        const index = this.current + 1;

        return Array(this.limit).fill(undefined).map((p, i) => index + i).filter(p => p < this.pager.totalPages + 1);
    }

    public first(): void
    {
        this.onPaging(1);
    }

    public prev(): void
    {
        this.onPaging(this.pager.pageIndex - 1);
    }

    public page(index: number): void
    {
        this.onPaging(index);
    }

    public next(): void
    {
        this.onPaging(this.pager.pageIndex + 1);
    }

    public last(): void
    {
        this.onPaging(this.pager.totalPages);
    }

    @Emit('change')
    public onPaging(pageIndex: number): number
    {
        this.pager.setPageIndex(pageIndex);

        return pageIndex;
    }
}
</script>
