<template>
    <div>
        <Bar :chart-data="dataChart" :options="options" :key="reloadKey" :style="customStyles" />
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import DashboardService, { LoginsModel } from '../../services/DashboardService';
import {
    Chart as ChartJSBar,
    BarController,
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    Title,
    Tooltip
} from 'chart.js';
import { BarChart } from 'vue-chart-3';
import { useThemeStore } from '@/store/theme';

ChartJSBar.register(
    BarController,
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    Title,
    Tooltip
);

@Options({
    name: 'chart-logins',
    components: {
        Bar: BarChart
    },
})
export default class ChartLogins extends Vue
{
    public loginCounter: LoginsModel[] = [];
    public reloadKey: number = 0;

    public get theme() : string
    {
        return useThemeStore().theme;
    }

    public customStyles = {
        height: '290px',
        width: '100%',
        position: 'relative'
    };

    public options = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
            }
        },
        scales: {
            x: {
                grid: {
                    color: '#ebedef',
                    borderDash: [2, 4],
                    tickColor: '#ebedef',
                },
                ticks: {
                    stepSize: 1,
                    color: this.theme == 'dark' ? '#adb5bd' : '#6f6f6f'
                },
                title: {
                    display: true
                },
            },
            y: {
                grid: {
                    color: '#ebedef',
                    borderDash: [2, 4],
                    tickColor: '#ebedef',
                },
                gridLines: {
                    borderDash: [2, 4],
                    color: "#348632",
                },
                ticks: {
                    stepSize: 1,
                    color: this.theme == 'dark' ? '#adb5bd' : '#6f6f6f'
                },
                title: {
                    display: true
                }
            }
        },
        elements: {
            point: {
                radius: 0
            }
        }
    };

    public dataChart = {
        labels: [] as string[],
        datasets: [
            {
                backgroundColor: '#bbbdbf',
                data: [] as number[],
            }
        ]
    };

    public async created(): Promise<void>
    {
        await this.loadData();
    }

    public async loadData(): Promise<void>
    {
        try
        {
            this.loginCounter = await DashboardService.getLoginsCount();

            this.dataChart.labels = this.loginCounter.map(element => this.$filters.date(element.date)).reverse();
            this.dataChart.datasets[0].data = this.loginCounter.map((i) => i.count).reverse();
            this.reloadKey++;
        }
        catch (ex)
        {
            this.handleException(ex, {
                400: (ex: any) => this.$alert.warning(ex.message)
            });
        }
    }
}
</script>
