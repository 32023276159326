<template>
    <ideo-form-group :label="$t('[[[Opis]]]')" :invalid-feedback="form.$errors.first('content.value.description')" :state="form.$errors.state('content.value.description')">
        <editor :id="'description-' + form.id" v-model="form.content.value.description" data-cy="description"></editor>
    </ideo-form-group>
    <ideo-form-group :invalid-feedback="form.$errors.first('content.value.questions.answer')" :state="form.$errors.state('content.value.questions.answer')">
        <div class="row">
            <div class="col-md-5">{{ $t('[[[Element]]]') }}</div>
            <div class="col-md-5">{{ $t('[[[Dopasowanie]]]') }}</div>
        </div>
        <div v-for="(question, i) in form.content.value.questions" :key="i">
            <div :class="['row p-2 mb-3', { 'bg-light' : i % 2 === 0 }]">
                <div class="col-md-5">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text bg-primary border-primary text-white">{{ i + 1 }}</span>
                        </div>
                        <ideo-form-textarea v-model="question.question" :rows="2" :name="`content.value.questions[${i}].question`" :data-cy="`question-${i}`"></ideo-form-textarea>
                    </div>
                    <small class="form-text text-danger" v-if="!form.$errors.state(`content.value.questions[${i}].question`)">{{ form.$errors.first(`content.value.questions[${i}].question`) }}</small>
                </div>
                <div class="col-md-5">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text bg-primary border-primary text-white">{{ i + 1 }}</span>
                        </div>
                        <ideo-form-textarea v-model="question.answer" :rows="2" :name="`content.value.questions[${i}].answer`" :data-cy="`answer-${i}`"></ideo-form-textarea>
                    </div>
                    <small class="form-text text-danger" v-if="!form.$errors.state(`content.value.questions[${i}].answer`)">{{ form.$errors.first(`content.value.questions[${i}].answer`) }}</small>
                </div>
                <div class="col-md-2 d-flex align-items-start">
                    <action-button variant="danger" icon="far fa-trash" class="ms-auto" :title="$t('[[[Usuń pytanie]]]')" :confirm="$t('[[[Potwierdzenie usunięcia]]]')" @click="removeQuestion(i)" :disabled="form.content.value.questions.length < 2" />
                    <action-button variant="secondary" icon="far fa-arrow-up" :title="$t('[[[Przenieś wyżej]]]')" @click="questionUp(i)" :disabled="i == 0" />
                    <action-button variant="secondary" icon="far fa-arrow-down" :title="$t('[[[Przenieś niżej]]]')" @click="questionDown(i)" :disabled="i == form.content.value.questions.length - 1" />
                </div>
            </div>
            <hr>
        </div>
        <div>
            <action-button variant="outline-dark" class="w-100" icon="far fa-plus" :text="$t('[[[Nowe połączenie]]]')" @click="addQuestion()" data-cy="addQuestion" />
        </div>
    </ideo-form-group>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Emit, Prop, Watch } from '@/helpers/Decorators';
import merge from 'lodash/merge';
import { FormType } from '@/helpers/Form';

interface FormMatchingPairs
{
    content: {
        value: {
            description: string,
            questions: Pair[]
        }
    }
}

interface Pair
{
    id: number,
    question: string,
    answer: string
}

@Options({
    name: 'type-matchingpairs-settings'
})
export default class MatchingPairsSettings extends Vue
{
    @Prop() public modelValue!: any;

    public defaults: any = {
        description: '',
        questions: [] as Pair[]
    };

    public get form(): FormType<FormMatchingPairs>
    {
        return this.modelValue;
    }

    public created(): void
    {
        this.form.content.value = merge(this.defaults, this.form.content.value);
        this.form.file = null;

        if (!this.form.content.value.questions.length)
        {
            this.form.content.value.questions = [{
                id: 1,
                question: '',
                answer: ''
            }];
        }
    }

    public addQuestion(): void
    {
        const ids = this.form.content.value.questions.map((x: { id: any; }) => x.id);

        const maxId = this.form.content.value.questions.length > 0 ? Math.max(...ids) : 1;

        this.form.content.value.questions.push({
            id: maxId + 1,
            question: '',
            answer: ''
        });
    }

    public removeQuestion(index: number): void
    {
        const items = this.form.content.value.questions.slice();

        items.splice(index, 1);
        this.form.content.value.questions = items;

        this.form.$errors.clear();
    }

    public questionUp(index: number): void
    {
        if (index > 0)
        {
            const questions = this.form.content.value.questions.splice(index, 1);

            this.form.content.value.questions.splice(index - 1, 0, questions[0]);

            this.form.$errors.clear();
        }
    }

    public questionDown(index: number): void
    {
        if (index < this.form.content.value.questions.length - 1)
        {
            const questions = this.form.content.value.questions.splice(index, 1);

            this.form.content.value.questions.splice(index + 1, 0, questions[0]);

            this.form.$errors.clear();
        }
    }

    @Watch('form.content.value', { deep: true })
    public onContentChanged(value: any): void
    {
        this.triggetModel();
    }

    @Emit('update:modelValue')
    public triggetModel(): any
    {
        return this.form;
    }
}
</script>
