<template>
    <ideo-form-group :label="$t('[[[Wybierz formularz]]]')">
        <div class="d-flex flex-column flex-md-row">
            <div class="flex-grow-1">
                <ideo-autocomplete
                    v-model="form.settings.dynamicFormOriginId"
                    name="dynamicFormOriginId"
                    track-by="originId"
                    :placeholder="$t('[[[Wyszukaj formularz...]]]')"
                    :fetch="(id: number) => fetchForm(id)"
                    :search="(query: string, limit: number) => searchForm(query, limit)"
                >
                    <template #selected="{ option }">
                        <div class="text-wrap">
                            {{ option.title }}
                        </div>
                    </template>
                    <template #option="{ option }">
                        <div class="text-wrap">
                            {{ option.title }}
                        </div>
                    </template>
                </ideo-autocomplete>
            </div>
        </div>
    </ideo-form-group>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Emit, Prop, Watch } from '@/helpers/Decorators';
import merge from 'lodash/merge';
import DynamicFormService, { ListItemModel } from '@/modules/cms/dynamic-forms/services/DynamicFormService';

@Options({
    name: 'module-dynamic-forms-settings'
})
export default class DynamicFormSettings extends Vue
{
    @Prop() public modelValue!: any;

    public defaults: any = {
        dynamicFormOriginId: null
    };

    public get form(): any
    {
        return this.modelValue;
    }

    public created(): void
    {
        this.form.settings = merge(this.defaults, this.form.settings);
    }

    public async fetchForm(id: number): Promise<ListItemModel>
    {
        const response = await DynamicFormService.fetchAutocompleteModel(id, this.$i18n.locale());

        return response || null;
    }

    public async searchForm(query: string, limit: number): Promise<ListItemModel[]>
    {
        return await DynamicFormService.getAutocompleteList(query, limit, this.$i18n.locale());
    }

    @Watch('form.settings', { deep: true })
    public onSettingsChanged(value: any): void
    {
        this.triggetModel();
    }

    @Emit('update:modelValue')
    public triggetModel(): any
    {
        return this.form;
    }
}
</script>
