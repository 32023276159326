<template>
    <hr>

    <ideo-form-group :label="$t('[[[Strona docelowa dla \'Czytaj więcej\']]]')" :invalid-feedback="form.$errors.first('settings.morePage')" :state="form.$errors.state('settings.morePage')">
        <edito-page-selector v-model="form.settings.morePage" :module="module" label="" :website="false" :preview="false" class="mb-0" />
    </ideo-form-group>

    <hr>
    
    <ideo-form-group :label="$t('[[[Wybierz artykuł]]]')">
        <div class="d-flex flex-column flex-md-row">
            <div class="flex-grow-1">
                <ideo-autocomplete
                    v-model="articleId"
                    name="articleId"
                    track-by="id"
                    :placeholder="$t('[[[zacznij pisać aby wyszukać artykuł...]]]')"
                    :fetch="(id: number) => fetchArticle(id)"
                    :search="(query: string, limit: number) => searchArticles(query, limit)"
                >
                    <template #selected="{ option }">
                        <div class="text-wrap">
                            {{ option.title }}
                        </div>
                    </template>
                    <template #option="{ option }">
                        <div class="text-wrap">
                            {{ option.title }}
                        </div>
                    </template>
                </ideo-autocomplete>
            </div>
            <div class="d-flex align-items-stretch">
                <button class="btn btn-secondary text-nowrap mt-2 mt-md-0 ms-md-2" @click.prevent="addRelated()">
                    <i class="fas fa-plus"></i>
                </button>
            </div>
        </div>
    </ideo-form-group>

    <ideo-form-group :label="$t('[[[Powiązane artykuły]]]')">
        <div class="list-group">
            <div v-for="item in this.related" :key="item.id" class="list-group-item list-group-item-action p-2 d-flex">
                <span class="me-2">
                    <i class="fad fa-fw fa-browser text-primary"></i>
                </span>
                <span class="me-auto">
                    <span class="d-block" :title="$t('[[[Powiązany artykuł]]]')">
                        <b>{{ item.title }}</b>
                    </span>
                </span>
                <span class="d-flex align-items-center">
                    <ideo-button variant="danger" size="sm" pill :id="`btn-delete-related-${item.originId}`" :title="$t('[[[Usuń powiązanie]]]')">
                        <i class="fas fa-trash-alt"></i>
                    </ideo-button>
                    <confirmation :message="$t('[[[Potwierdzenie usunięcia]]]')" :target="`btn-delete-related-${item.originId}`" :value="item.id" @confirm="removeRelated(item.originId)" />
                </span>
            </div>
            <div class="list-group-item list-group-item-light text-center" v-if="this.related.length == 0">
                {{ $t('[[[Brak powiązań]]]') }}
            </div>
        </div>
    </ideo-form-group>
    <hr>

    <ideo-form-group :label="$t('[[[Treść]]]')" :invalid-feedback="form.$errors.first('settings.content')" :state="form.$errors.state('settings.content')">
        <editor id="content" v-model="form.settings.content"></editor>
    </ideo-form-group>

    <ideo-form-group :label="$t('[[[Zdjęcie w tle]]]')" :invalid-feedback="form.$errors.first(`settings.backgroundImage`)" :state="form.$errors.state(`settings.backgroundImage`)">
        <filepicker id="backgroundImage" v-model="form.settings.backgroundImage" media="images" />
    </ideo-form-group>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Emit, Prop, Watch } from '@/helpers/Decorators';
import { ValidatableComponent } from '@/components/cms';
import ArticlesService, { ListItemModel } from '@/modules/cms/articles/services/ArticlesService';
import merge from 'lodash/merge';

@Options({
    name: 'widget-content-with-articles-settings'
})
export default class ContentWithArticlesSettings extends Vue implements ValidatableComponent
{
    @Prop() public modelValue!: any;

    public module: string = 'articles';
    public defaults: any = {
        morePage: null,
        content: '',
        backgroundImage: null,
        relatedArticles: [],
    };
    

    public articleId: number|null = null;
    public related: ListItemModel[] = [];

    public get form(): any
    {
        return this.modelValue;
    }

    public async created(): Promise<void>
    {
        this.form.settings = merge(this.defaults, this.form.settings);
        this.loadRelated(this.form.id)
    }

    @Watch('form.settings', { deep: true })
    public onSettingsChanged(value: any): void
    {
        this.triggetModel();
    }

    @Emit('update:modelValue')
    public triggetModel(): any
    {
        return this.form;
    }

    public async validate(): Promise<void>
    {
    }

    public async fetchArticle(id: number): Promise<ListItemModel>
    {
        const response = await ArticlesService.fetchAutocompleteModel(id);

        return response || null;
    }

    public async searchArticles(query: string, limit: number): Promise<ListItemModel[]>
    {
        return await ArticlesService.getAutocompleteList(query, limit);
    }

    public async addRelated():Promise<void>
    {
        if (this.articleId == null)
            return;

        const response = await ArticlesService.fetchAutocompleteModel(this.articleId);

        if (this.related.find(x => x.originId === response.originId) === undefined &&
            this.form.settings.relatedArticles.find(x => x === response.originId) === undefined)
        {
            this.related.push(response);
            this.form.settings.relatedArticles.push(response.originId);
        }

        this.articleId = null;
    }

    public removeRelated(originId: number): void
    {
        this.related = this.related.filter(x => x.originId != originId);
        this.form.settings.relatedArticles = this.form.settings.relatedArticles.filter(x => x != originId);
    }

    public async loadRelated(id: number): Promise<boolean>
    {
        if (id === 0)
            return true;

        try
        {
            this.related = await ArticlesService.getRelatedFromWidget(id);
        }
        catch (ex)
        {
            return false;
        }

        return true;
    }
}
</script>
