<template>
    <ideo-form-group :label="$t('[[[Pytanie]]]')" :invalid-feedback="form.$errors.first('content.value.question')" :state="form.$errors.state('content.value.question')" required>
        <editor :id="seconds('question-' + form.id)" v-model="form.content.value.question" data-cy="question"></editor>
    </ideo-form-group>
    <ideo-form-group :label="$t('[[[Odpowiedzi]]]')" :invalid-feedback="form.$errors.first('content.value.answers')" :state="form.$errors.state('content.value.answers')">
        <div v-for="(answer, i) in form.content.value.answers" :key="answer.id" class="mb-3 g-0 row">
            <div class="col-md-11 col-sm-10">
                <div class="input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text bg-primary border-primary text-white">{{ i + 1 }}</span>
                    </div>
                    <ideo-form-textarea v-model="answer.text" :name="`content.value.answers[${i}]`" :rows="2" :placeholder="$t('[[[Odpowiedź]]]')" :data-cy="`answer-${i}`"></ideo-form-textarea>
                    <div class="input-group-append">
                        <action-button :variant="answer.isCorrect ? 'success' : 'secondary'" icon="far fa-check" :title="$t('[[[Poprawna odpowiedź]]]')" @click="isCorrectChanged(answer.id)" data-cy="isCorrect" />
                        <action-button variant="danger" icon="far fa-trash" :title="$t('[[[Usuń odpowiedź]]]')" :confirm="$t('[[[Potwierdzenie usunięcia]]]')" @click="removeAnswer(i)" :disabled="form.content.value.answers.length < 2" />
                    </div>
                </div>
            </div>
            <div class="col-md-1 col-sm-2 d-flex align-items-start">
                <action-button variant="secondary" icon="far fa-arrow-up" class="ms-auto" :title="$t('[[[Wyżej]]]') " @click="answerUp(i)" :disabled="i == 0" />
                <action-button variant="secondary" icon="far fa-arrow-down" :title="$t('[[[Niżej]]]')" @click="answerDown(i)" :disabled="i == form.content.value.answers.length - 1" />
            </div>
            <small class="form-text text-danger" v-if="!form.$errors.state(`content.value.answers[${i}]`)">{{ form.$errors.first(`content.value.answers[${i}]`) }}</small>
        </div>
        <div>
            <action-button variant="outline-dark" class="w-100" icon="far fa-plus" :text="$t('[[[Dodaj odpowiedź]]]')" @click="addAnswer()" data-cy="addAnswer" />
        </div>
    </ideo-form-group>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Emit, Prop, Watch } from '@/helpers/Decorators';
import merge from 'lodash/merge';
import { FormType } from '@/helpers/Form';
import { seconds } from '@/helpers/Utils';

interface FormSingleSelect
{
    content: {
        value: {
            question: string,
            answers: Answer[]
        }
    }
}

interface Answer {
    id: number,
    text: string,
    isCorrect: boolean
}

@Options({
    name: 'type-singleselect-settings'
})
export default class SingleSelectSettings extends Vue
{
    @Prop() public modelValue!: any;

    // rozwiązanie problemu inicjalizowania edytora, z powodu dwóch tych samych id-ków
    public seconds = seconds;

    public defaults: any = {
        question: '',
        answers: []
    };

    public get form(): FormType<FormSingleSelect>
    {
        return this.modelValue;
    }

    public created(): void
    {
        this.form.content.value = merge(this.defaults, this.form.content.value);
        this.form.file = null;

        if (!this.form.content.value.answers.length)
        {
            this.form.content.value.answers = [{
                id: 1,
                text: '',
                isCorrect: true
            }];
        }
    }

    public addAnswer(): void
    {
        const ids = this.form.content.value.answers.map((x: { id: any; }) => x.id);

        const maxId = this.form.content.value.answers.length > 0 ? Math.max(...ids) : 1;

        this.form.content.value.answers.push({
            id: maxId + 1,
            text: '',
            isCorrect: false
        });
    }

    public removeAnswer(index: number): void
    {
        const items = this.form.content.value.answers.slice();

        items.splice(index, 1);
        this.form.content.value.answers = items;

        this.form.$errors.clear(`content.value.answers[${index}]`);
    }

    public answerUp(index: number): void
    {
        if (index > 0)
        {
            const answers = this.form.content.value.answers.splice(index, 1);

            this.form.content.value.answers.splice(index - 1, 0, answers[0]);

            this.form.$errors.clear();
        }
    }

    public answerDown(index: number): void
    {
        if (index < this.form.content.value.answers.length - 1)
        {
            const answers = this.form.content.value.answers.splice(index, 1);

            this.form.content.value.answers.splice(index + 1, 0, answers[0]);

            this.form.$errors.clear();
        }
    }

    public isCorrectChanged(id: number) : void
    {
        const clickedAnswer = this.form.content.value.answers.find((x: { id: number; }) => x.id == id);
        const clickedAnswerValue = clickedAnswer.isCorrect;

        const count = this.form.content.value.answers.filter(item =>
        {
            return item.isCorrect;
        }).length;

        if (count == 1)
        {
            const correctAnswer = this.form.content.value.answers.find(x => x.isCorrect);

            if (correctAnswer.id == id)
                return;
        }

        this.form.content.value.answers.find((x: { id: number; }) => x.id == id).isCorrect = !clickedAnswerValue;

        for (const answer of this.form.content.value.answers)
        {
            if (answer.id != id)
            {
                answer.isCorrect = false;
            }
        }

        this.form.$errors.clear('content.value.answers');
    }

    @Watch('form.content.value', { deep: true })
    public onContentChanged(value: any): void
    {
        this.triggetModel();
    }

    @Emit('update:modelValue')
    public triggetModel(): any
    {
        this.form.$errors.clear('content.value.question');

        return this.form;
    }
}
</script>
