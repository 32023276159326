import { useScriptTag } from '@vueuse/core';

interface Recaptcha
{
    active: boolean;
    siteKey: string;
    $install(siteKey: string, active: boolean): Promise<void>;
    $execute(action: string, resolve: Function, reject?: Function, onFinnaly?: Function): void;
}

export const useRecaptcha = (): { $recaptcha: Recaptcha } =>
{
    return {
        $recaptcha: new RecaptchaHelper(),
    };
};

export class RecaptchaHelper implements Recaptcha
{
    public active: boolean;
    public siteKey: string;
    private unload: any;

    private async $loadScript(siteKey: string): Promise<void>
    {
        if (this.unload)
            await this.unload();

        const { load, unload } = useScriptTag(
            `https://www.google.com/recaptcha/api.js?render=${siteKey}`,
            () => {},
            { manual: true }
        );

        this.unload = unload;

        await load();
    }

    public async $install(siteKey: string, active: boolean): Promise<void>
    {
        this.active = active;

        if (siteKey !== this.siteKey && this.active)
            await this.$loadScript(siteKey);

        this.siteKey = siteKey;
    }

    public async $execute(action: string, resolve: Function, reject?: Function, onFinnaly?: Function): Promise<void>
    {
        await (window as any).grecaptcha.ready(async () =>
        {
            try
            {
                const captchaResult = await (window as any).grecaptcha.execute(this.siteKey, {action});

                resolve(captchaResult);
            }
            catch (ex)
            {
                this.unload?.();

                reject?.(ex);
            }
            finally
            {
                onFinnaly?.();
            }
        });
    }
}
