<script lang="ts" setup>
import { ref, Ref, computed, watch } from 'vue';
import properties from '@/components/forms/properties';
import { RootEntry } from '@/components/builder/form/entries/RootEntry';
import { instanceOfLikertEntry, LikertType, LikertEntry, LikertTypes } from '.';
import { Option } from '@/helpers/Interfaces';
import { useLocalization } from '@/plugins/localization';
import { FormBuilderContract } from '@/components/builder/form';
import { AggregateBlueprint } from '@/components/builder/base/blueprints/AggregateBlueprint';
import { ChoiceOption } from '@/components/builder/form/types/ChoiceOption';

const { $t } = useLocalization();

defineOptions({
    name: 'likert-blueprint',
    components: {
        ...properties
    }
});

const props = defineProps({
  "blueprint": null,
  "entry": null,
  "form": null,
  "parent": null,
  "index": null
});
const blueprint = computed(() => props.blueprint);

const entryData = ref(new LikertEntry()) as Ref<LikertEntry>;

entryData.value = props.form.document.initEntry(props.blueprint, entryData.value, instanceOfLikertEntry, props.index);

const value = computed({
    get(): Record<number, number>
    {
        return entryData.value.data;
    },
    set(values: Record<number, number>)
    {
        entryData.value.data = values;
    }
});

const questions = computed({
    get(): ChoiceOption[]
    {
        return props.blueprint.questions.map((value, i) =>
        {
            return {
                value: i.toString(),
                text: value,
                selected: false
            };
        });
    },
    set(value: ChoiceOption[])
    {
        blueprint.value.questions = value.map(p => p.text);
    }
});

const choices = computed({
    get(): ChoiceOption[]
    {
        return props.blueprint.choices.map((value, i) =>
        {
            return {
                value: i.toString(),
                text: value,
                selected: false
            };
        });
    },
    set(value: ChoiceOption[])
    {
        blueprint.value.choices = value.map(p => p.text);
    }
});

const options = computed<Option<number|null>[]>(() =>
{
    const options = props.blueprint.choices.map((value, i) =>
    {
        return {
            value: i+1,
            text: props.form.localization.translate(value)
        };
    });

    if (props.blueprint.allowNoChoice)
    {
        options.push({
            value: 0,
            text: props.form.localization.translate(props.blueprint.noChoiceLabel)
        });
    }

    return options;
});

const allowNoChoice = computed(() => props.blueprint.allowNoChoice);
const defaultOptions = computed<Option<number|null>[]>(() => ([{ value: null, text: '' }, ...options.value]));

const fieldTypeOptions: Option<LikertTypes>[] = [
    { value: LikertTypes.Satisfaction, text: '[[[Satysfakcja]]]' },
    { value: LikertTypes.Agreement, text: '[[[Zgoda]]]' },
    { value: LikertTypes.Importance, text: '[[[Ważność]]]' },
    { value: LikertTypes.Comparison, text: '[[[Porównanie]]]' },
    { value: LikertTypes.Statement, text: '[[[Stwierdzenie]]]' },
    { value: LikertTypes.Scale, text: '[[[Skala 1-5]]]' },
    { value: LikertTypes.Custom, text: '[[[Dowolny]]]' }
];
const fieldType = computed(() => LikertTypes);

const isCustom = computed(() => props.blueprint.fieldType == LikertTypes.Custom);

const required = computed(() => props.form.expressions.required(props.blueprint));

const questionText = (value: Record<string, string>) => props.form.localization.translate(value);

const checkedValue = (question: ChoiceOption): number =>
{
    if (value.value !== null && question.value in value.value)
    {
        return value.value[Number(question.value)];
    }

    return props.blueprint.defaultValue || null;
};

const change = (question: ChoiceOption, answer: string): void =>
{
    const values: any = { ...value.value };

    values[question.value] = answer;
    update(values);
};

const update = (val: Record<number, number>): void =>
{
    value.value = val;
};

watch(fieldType, (value: typeof LikertTypes) =>
{
    props.blueprint.defaultChoices(value as any);
});

watch(allowNoChoice, (): void =>
{
    if (props.blueprint.defaultValue == -1)
    {
        blueprint.value.defaultValue = null;
    }
});
</script>

<template>
    <form-component-wrapper class="likert-component" :form="form" :parent="parent" :blueprint="blueprint">
        <template #default>
            <div class="form-group">
                <form-label :form="form" :blueprint="blueprint" :required="required" :entry="entryData" />
                <responsive v-if="!phone">
                    <table class="table table-bordered w-100 mb-0">
                        <thead>
                            <tr>
                                <th class="question"></th>
                                <th v-for="option in options" :key="option.value">
                                    {{ option.text }}
                                </th>
                            </tr>
                        </thead>
                        <tbody v-if="questions.length > 0">
                            <tr v-for="question in questions" :key="question.value">
                                <td class="question">
                                    {{ questionText(question.text) }}
                                </td>
                                <td v-for="option in options" :key="option.value">
                                    <ideo-form-radio :name="`${blueprint.name}_${question.value}`" :value="option.value" :checked="checkedValue(question)" @change="change(question, $event)"></ideo-form-radio>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </responsive>
                <div v-if="phone">
                    <div v-for="(question, i) in questions" :key="question.value" class="mb-2">
                        <h6>{{ i + 1 }}. {{ question.text }}</h6>
                        <ideo-form-radio-group :name="`${blueprint.name}_${question.value}`" :options="options" :checked="checkedValue(question)" @change="change(question, $event)" stacked />
                    </div>
                </div>
                <form-error-message :entry="entryData" name="value" />
                <form-error-message :entry="entryData" name="custom" />
                <form-help :form="form" :blueprint="blueprint" />
            </div>
        </template>
        <template #properties>
            <field-name :form="form" :blueprint="blueprint" v-model="blueprint.name" />
            <ideo-form-localize v-slot="{ locale }">
                <field-text v-model="blueprint.label[locale]" :label="$t('[[[Etykieta]]]')" />
            </ideo-form-localize>
            <field-checkbox v-model="blueprint.showLabel" :label="$t('[[[Pokaż etykietę]]]')" />
            <field-options v-model="questions" :can-select="false" :has-value="false" :label="$t('[[[Pytania]]]')" :invalid-feedback="() => form.schema.errorMessage(blueprint, 'questions')" />
            <field-select v-model="blueprint.fieldType" :options="fieldTypeOptions" :label="$t('[[[Rodzaj odpowiedzi]]]')" />
            <field-options v-model="choices" :can-select="false" :has-value="false" :label="$t('[[[Odpowiedzi]]]')" :invalid-feedback="() => form.schema.errorMessage(blueprint, 'choices')" v-if="isCustom" />
            <field-checkbox v-model="blueprint.allowNoChoice" :label="$t('[[[Dopuść brak odpowiedzi]]]')" />
            <ideo-form-localize v-if="blueprint.allowNoChoice" v-slot="{ locale }">
                <field-text v-model="blueprint.noChoiceLabel[locale]" :label="$t('[[[Brak odpowiedzi]]]')" />
            </ideo-form-localize>
            <field-select v-model="blueprint.defaultValue" :options="defaultOptions" :label="$t('[[[Wartość domyślna]]]')" />
            <ideo-form-localize v-slot="{ locale }">
                <field-textarea v-model="blueprint.help[locale]" :label="$t('[[[Pomoc]]]')" />
            </ideo-form-localize>
            <field-visible :form="form" :blueprint="blueprint" />
            <field-readonly :form="form" :blueprint="blueprint" />
            <field-required :form="form" :blueprint="blueprint" />
            <field-error :form="form" :blueprint="blueprint" />
            <field-filter :form="form" :blueprint="blueprint" />
        </template>
    </form-component-wrapper>
</template>

<style lang="scss" scoped>
.table {
    table-layout: fixed;
    word-break: break-word;

    th,
    td {
        // white-space: nowrap;
        // text-overflow: ellipsis;
        // overflow: hidden;
        // width: 1%;
        white-space: break-spaces;
        vertical-align: middle;
        padding: 8px;
        font-weight: normal;
        font-size: 0.85em;
        text-align: center;
    }

    th {
        background-color: var(--bs-gray-100);
    }

    .question {
        text-align: left;
        white-space: normal;
    }

    .custom-radio {
        display: inline-block !important;
        margin-left: 8px;
    }
}
</style>
