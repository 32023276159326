<template>
    <ideo-form-group :label="$t('[[[Opis]]]')" :invalid-feedback="form.$errors.first('content.value.description')" :state="form.$errors.state('content.value.description')">
        <editor :id="seconds('description-' + form.id)" v-model="form.content.value.description" data-cy="description"></editor>
    </ideo-form-group>
    <ideo-form-group>
        <ideo-form-radio-group stacked>
            <ideo-form-radio v-model="form.content.value.orientation" value="horizontal">{{ $t('[[[Poziomo]]]') }}</ideo-form-radio>
            <ideo-form-radio v-model="form.content.value.orientation" value="vertical">{{ $t('[[[Pionowo]]]') }}</ideo-form-radio>
        </ideo-form-radio-group>
    </ideo-form-group>
    <ideo-form-group :label="$t('[[[Pytanie i odpowiedzi]]]')" :invalid-feedback="form.$errors.first('content.value.questions')" :state="form.$errors.state('content.value.questions')" required>
        <div v-for="(answer, j) in form.content.value.answers" :key="j" class="row g-0 mb-3">
            <div class="col-md-11 col-sm-10">
                <div class="input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text bg-primary border-primary text-white">{{ 1 }}.{{ j + 1 }}</span>
                    </div>
                    <ideo-form-textarea v-model="answer.text" :name="`content.value.answers[${j}]`" :rows="2" :placeholder="$t('[[[Odpowiedź]]]')" :data-cy="`answer-${j}`"></ideo-form-textarea>
                    <div class="input-group-append mx-1">
                        <action-button variant="danger" icon="far fa-trash" :title="$t('[[[Usuń odpowiedź]]]')" :confirm="$t('[[[Potwierdzenie usunięcia]]]')" @click="removeAnswer(j)" :disabled="form.content.value.answers.length < 2" />
                    </div>
                </div>
                <small class="form-text text-danger" v-if="!form.$errors.state(`content.value.answers[${j}]`)">{{ form.$errors.first(`content.value.answers[${j}]`) }}</small>
            </div>
            <div class="col-md-1 col-sm-2 d-flex align-items-start">
                <Move :items="form.content.value.answers" :index="j" />
            </div>
        </div>
        <small class="form-text text-danger" v-if="!form.$errors.state(`content.value.answers`)">{{ form.$errors.first(`content.value.answers`) }}</small>
        <div>
            <action-button variant="outline-dark" class="w-100" icon="far fa-plus" :text="$t('[[[Dodaj odpowiedź]]]')" @click="addAnswer()" data-cy="addAnswer" />
        </div>
    </ideo-form-group>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Emit, Prop, Watch } from '@/helpers/Decorators';
import merge from 'lodash/merge';
import { FormType } from '@/helpers/Form';
import { seconds } from '@/helpers/Utils';
import Move from '@/components/common/Move.vue';

interface FormSetInOrder
{
    content: {
        value: {
            description: string,
            orientation: boolean,
            question: string,
            answers: Answer[]
        }
    }
}

interface Answer {
    id: number,
    text: string
}

@Options({
    name: 'type-setinorder-settings',
    components: { Move }
})
export default class SetInOrderSettings extends Vue
{
    @Prop() public modelValue!: any;

    // rozwiązanie problemu inicjalizowania edytora, z powodu dwóch tych samych id-ków
    public seconds = seconds;

    public defaults: any = {
        description: '',
        orientation: 'horizontal',
        question: '',
        answers: [] as Answer[]
    };

    public get form(): FormType<FormSetInOrder>
    {
        return this.modelValue;
    }

    public created(): void
    {
        this.form.content.value = merge(this.defaults, this.form.content.value);
        this.form.file = null;

        if (!this.form.content.value.answers.length)
        {
            this.form.content.value.answers = [{
                id: 1,
                text: ''
            }];
        }
    }

    public addAnswer(): void
    {
        const ids = this.form.content.value.answers.map((x: { id: any; }) => x.id);

        const maxId = this.form.content.value.answers.length > 0 ? Math.max(...ids) : 1;

        this.form.content.value.answers.push({
            id: maxId + 1,
            text: ''
        });
    }

    public removeAnswer(index: number): void
    {
        const items = this.form.content.value.answers.slice();

        items.splice(index, 1);
        this.form.content.value.answers = items;

        this.form.$errors.clear(`content.value.answers[${index}]`);
    }

    @Watch('form.content.value', { deep: true })
    public onContentChanged(value: any): void
    {
        this.triggetModel();
    }

    @Emit('update:modelValue')
    public triggetModel(): any
    {
        return this.form;
    }
}
</script>
