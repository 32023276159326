<template>
    <ideo-form-group :label="$t('[[[Wideo]]]')" :invalid-feedback="form.$errors.first('file')" :state="form.$errors.state('file')" required>
        <filepicker v-model="form.file" media="videos" data-cy="file" />
    </ideo-form-group>
    <ideo-form-group :label="$t('[[[Treść]]]')" :invalid-feedback="form.$errors.first('content.value.text')" :state="form.$errors.state('content.value.text')">
        <editor :id="seconds('text-' + form.id)" v-model="form.content.value.text" data-cy="description"></editor>
    </ideo-form-group>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Emit, Prop, Watch } from '@/helpers/Decorators';
import { Resource as File } from '@/modules/core/files/services/StorageService';
import { FormType } from '@/helpers/Form';
import merge from 'lodash/merge';
import { seconds } from '@/helpers/Utils';

interface FormVideoMaterial
{
    file: File;
    content: {
        value: {
            text: string
        }
    }
}

@Options({
    name: 'type-video-settings'
})
export default class VideoMaterialSettings extends Vue
{
    @Prop() public modelValue!: any;

    // rozwiązanie problemu inicjalizowania edytora, z powodu dwóch tych samych id-ków
    public seconds = seconds;

    public defaults: any = {
        text: ''
    };

    public get form(): FormType<FormVideoMaterial>
    {
        return this.modelValue;
    }

    public created(): void
    {
        this.form.content.value = merge(this.defaults, this.form.content.value);
    }

    @Watch('form.content.value', { deep: true })
    public onContentChanged(value: any): void
    {
        this.triggetModel();
    }

    @Emit('update:modelValue')
    public triggetModel(): any
    {
        this.form.$errors.clear('content.value.text');

        return this.form;
    }
}
</script>
