export default {
    "Project-Id-Version": "",
    "POT-Creation-Date": "2023-12-14T13:07:56.519Z",
    "MIME-Version": "1.0",
    "Content-Type": "text/plain; charset=utf-8",
    "Content-Transfer-Encoding": "8bit",
    "X-Generator": "Edito",
    "PO-Revision-Date": "2023-12-14T13:07:56.725Z",
    "Language": "pl-PL",
    "Messages": {
        "3": "",
        "5": "",
        "10": "",
        " dodanych w ": "",
        " Elementy do uzupełnienia w tekście zamieszczamy pomiędzy zdefiniowanymi znacznikami %$dowolna_nazwa$%.": "",
        " opublikowanych": "",
        "...": "",
        "...nie znalazłeś bloku?": "",
        "{Potwierdzenie przywrócenia": "",
        "%$ – znacznik otwierający tag": "",
        "%0": "",
        "%0 znaków": "",
        "<Brak nazwy>": "",
        "${ex.message}": "",
        "$% - znacznik zamykający tag": "",
        "1 godzina": "",
        "10 minut": "",
        "12 godzin": "",
        "15 minut": "",
        "24 godziny": "",
        "3 godziny": "",
        "30 minut": "",
        "5 minut": "",
        "6 godzin": "",
        "Aby dodawać członków zespołu musisz najpierw zapisać formularz.": "",
        "Aby dodawać użytkowników do szkolenia musisz najpierw zapisać formularz.": "",
        "Aby dodawać użytkowników do ścieżki szkoleniowej musisz najpierw zapisać formularz.": "",
        "Aby dodawać użytkowników do wydarzenia musisz najpierw zapisać formularz.": "",
        "Aby dodawać zespół do szkolenia musisz najpierw zapisać formularz.": "",
        "Aby dodawać zespół do ścieżki szkoleniowej musisz najpierw zapisać formularz.": "",
        "Aby móc przetestować poprawność kluczy, najpierw zapisz formularz.": "",
        "Aby móc zapisać element szkolenia, powinien on posiadać przynajmniej jeden opublikowany slajd.": "",
        "Aby połączenie z API OpenAI zostało nawiązane, należy podać klucz wygenerowany na stronie producenta i wybrać wersję modelu. Połączenie z API jest sprawdzane przy zapisie formularza.": "",
        "Aby recaptcha została aktywowana, należy zapisać formularz z podanymi kluczami wraz z zaznaczonym oknem \"Recaptcha\". Następnie przetestować poprawność podanych kluczy klikając przycisk \"Przetestuj poprawność zapisanych kluczy\".": "",
        "Aby zobaczyć aktualny podgląd certyfikatu kliknij Zapisz.": "",
        "Administracja": "",
        "Administracja - Kraje": "",
        "Administracja - Miasta": "",
        "Administracja - Role": "",
        "Administracja - Stanowiska pracy": "",
        "Administracja - Użytkownicy": "",
        "Administracja - Województwa": "",
        "Administracja - Zespoły": "",
        "Administratorzy": "",
        "Adres": "",
        "Adres aktywnej strony": "",
        "Adres domenowy": "",
        "Adres e-mail potwierdzony": "",
        "Adres email": "",
        "Adres email nadawcy": "",
        "Adres IP": "",
        "Adres IP: %0": "",
        "Adres kanoniczny": "",
        "Adres serwera": "",
        "Adres strony": "",
        "Adres url": "",
        "Adres URL": "",
        "Adres url do pliku wideo": "",
        "Adres URL profilu autora.": "",
        "Akcent": "",
        "Akceptacja": "",
        "Akceptacja komentarzy": "",
        "Akceptuj": "",
        "Akcja": "",
        "Akcje": "",
        "Aktualizacja wyników szkolenia użytkowników została dodana do kolejki zadań.": "",
        "Aktualizacja: {0}": "",
        "Aktualizuj": "",
        "Aktualna sesja": "",
        "Aktualne hasło": "",
        "Aktualne urządzenie": "",
        "Aktualności": "",
        "Aktualności ze strony": "",
        "Aktywna": "",
        "Aktywne": "",
        "Aktywne kanały komunikacji": "",
        "Aktywne sesje": "",
        "Aktywni": "",
        "Aktywny": "",
        "Alias": "",
        "Alias został przepięty.": "",
        "Angielski": "",
        "Ankieta": "",
        "Ankieta po szkoleniu jest już nieaktualna.": "",
        "Ankieta przed i po szkoleniu": "",
        "Ankieta przed szkoleniem jest już nieaktualna.": "",
        "Ankiety": "",
        "Anuluj": "",
        "Anuluj edycję": "",
        "Anuluj wszystkie wprowadzone zmiany": "",
        "Apple iPad": "",
        "Apple iPhone 5": "",
        "Apple iPhone 6": "",
        "Aranżacja": "",
        "Aranżacja utowrzona przez": "",
        "Aranżacja została opublikowana.": "",
        "Aranżacja została zachowana.": "",
        "Aranżacje": "",
        "Archiwalne elementy szkolenia": "",
        "Archiwizuj": "",
        "Archiwizuj materiał lekcji": "",
        "Archiwizuj materiał testu": "",
        "Archiwizuj stronę": "",
        "Archiwum": "",
        "Artykuł": "",
        "Artykuły": "",
        "Automatyczna akceptacja": "",
        "Automatyczna akceptacja komentarzy zalogowanych użytkowników": "",
        "Automatyczna akceptacja wszystkich komentarzy": "",
        "Automatyczna publikacja galerii": "",
        "Automatyczne akceptacja wersji po osiągnięciu daty publikacji. Wybrana wersja musi mieć ustawiony status na \"Ukończony\"": "",
        "Automatyczny": "",
        "Autor": "",
        "Autor szkolenia": "",
        "Autor został dodany.": "",
        "Autor został usunięty.": "",
        "Autor został zaktualizowany.": "",
        "Autor/adres IP": "",
        "Autorzy": "",
        "Autorzy spoza bazy użytkowników": "",
        "Azure AD": "",
        "Bardzo dobrze": "",
        "Bardzo niezadowolony": "",
        "Bardzo zadowolony": "",
        "Bardzo źle": "",
        "Barwa tekstu": "",
        "Barwa tła": "",
        "Baza danych": "",
        "Baza danych tego klienta jest nieaktualna. Uruchom aktualizację za pomocą przycisku na dole formularza.": "",
        "Baza dokumentów": "",
        "Bazy danych": "",
        "Bez \"http(s)://\" i \"/\" na końcu.": "",
        "Bezpieczeństwo": "",
        "Bezpieczne połączenie SSL": "",
        "Biały": "",
        "Biblioteka": "",
        "Blok aktywny": "",
        "Blok został usunięty.": "",
        "Blokada konta": "",
        "Bloki": "",
        "Blokowanie użytkowników": "",
        "Blokowanie użytkowników szkoleń": "",
        "Błąd 404. Nie znaleziono żądanego zasobu.": "",
        "Błędne": "",
        "Błędnie określona liczba, sprawdź liczbę opublikowanych materiałów.": "",
        "Brak": "",
        "Brak aktywnego filtru": "",
        "Brak dodanych plików": "",
        "Brak dostępu do szkoleń": "",
        "Brak działu nadrzędnego": "",
        "Brak kategorii": "",
        "Brak kategorii nadrzędnej": "",
        "Brak kluczy": "",
        "Brak kolekcji": "",
        "Brak modułu": "",
        "Brak nowych powiadomień.": "",
        "Brak odpowiedzi": "",
        "Brak ograniczenia": "",
        "Brak plików do dodania": "",
        "Brak powiązania ze szkoleniem": "",
        "Brak powiązań": "",
        "Brak stron": "",
        "Brak strony nadrzędnej": "",
        "Brak tematu": "",
        "Brak typu zadania": "",
        "Brak wersji": "",
        "Brak wyboru": "",
        "brak wydziału": "",
        "Brak wyników": "",
        "Brak zapisanych filtrów.": "",
        "Brak zdefiniowanych ról.": "",
        "Brak zdefiniowanych zespołów.": "",
        "Brakujące tytuły kafelków.": "",
        "Bramka SMS": "",
        "Certyfikat jest opublikowany": "",
        "Certyfikat nie jest opublikowany": "",
        "Certyfikat przygotowany jest do formatu A4. Wyświetlany podgląd stanowi jedynie widok roboczy. Docelowy wygląd certyfikatu będzie powiązany z właściwymi danymi szkolenia oraz użytkownika.": "",
        "Certyfikat został dodany.": "",
        "Certyfikat został usunięty.": "",
        "Certyfikat został zaktualizowany.": "",
        "Certyfikaty": "",
        "Chat": "",
        "Chcesz przywrócić temat wraz z elementami?": "",
        "Chcesz przywrócić ten element?": "",
        "Checkbox": "",
        "Choices": "",
        "Ciemny": "",
        "co": "",
        "Co 10 dni": "",
        "Co 10 minut": "",
        "Co 12 godzin": "",
        "Co 15 minut": "",
        "Co 2 godziny": "",
        "Co 2 minuty": "",
        "Co 2 tygodnie": "",
        "Co 20 minut": "",
        "Co 3 godziny": "",
        "co 3 miesiące": "",
        "Co 3 miesiące": "",
        "co 30 dni": "",
        "Co 30 minut": "",
        "Co 4 miesiące": "",
        "Co 5 dni": "",
        "Co 5 minut": "",
        "Co 6 godzin": "",
        "co 6 miesięcy": "",
        "Co drugi dzień": "",
        "Co drugi miesiąc": "",
        "Co godzinę": "",
        "Co miesiąc": "",
        "Co minutę": "",
        "Co pół roku": "",
        "co rok": "",
        "Co rok": "",
        "Co tydzień": "",
        "Codziennie": "",
        "Coś poszło nie tak": "",
        "Created": "",
        "Cron": "",
        "Cyfry": "",
        "Cytat": "",
        "Cytaty": "",
        "Czarny": "",
        "Czas": "",
        "Czas (minuty)": "",
        "Czas na slajd": "",
        "Czas pomiędzy": "",
        "Czas rozpoczęcia": "",
        "Czas trwania blokady": "",
        "Czas uruchomienia": "",
        "Czas zakończenia": "",
        "czerwcu": "",
        "Czerwiec": "",
        "Czerwony": "",
        "Częstotliwość odświeżania": "",
        "Członkowie zespołu": "",
        "Cztery kolumny": "",
        "Czwartek": "",
        "Czy na pewno chcesz ukryć wiadomość?": "",
        "Czy na pewno chcesz wyczyścić pamięć podręczną?": "",
        "Czy na pewno chcesz zablokować użytkownika?": "",
        "Czy na pewno chcesz zkopiować szkolenie?": "",
        "Czy opublikowany": "",
        "Czy zablokować użytkownika i jego wiadomość?": "",
        "Czytać mogą tylko zalogowani użytkownicy": "",
        "Dalej": "",
        "Dane": "",
        "Dane formularza są przechowywane w obiekcie Entry.": "",
        "Dane osobowe": "",
        "Dashboard": "",
        "Dashboard szkolenia": "",
        "Data": "",
        "Data akceptacji": "",
        "Data automatycznej akceptacji": "",
        "Data dezaktywacji": "",
        "Data do": "",
        "Data dodania": "",
        "Data i czas": "",
        "Data informująca o terminie wejścia w życie tego regulaminu.": "",
        "Data końcowa": "",
        "Data logowania": "",
        "Data modyfikacji": "",
        "Data modyfikacji:": "",
        "Data od": "",
        "Data odrzucenia": "",
        "Data odrzucenia:": "",
        "Data ogłoszenia do": "",
        "Data ogłoszenia od": "",
        "Data opuszczenia zespołu": "",
        "Data publikacji": "",
        "Data publikacji:": "",
        "Data rejestracji": "",
        "Data rozpoczęcia": "",
        "Data uruchomienia": "",
        "Data usunięcia": "",
        "Data utworzenia": "",
        "Data utworzenia aranżacji": "",
        "Data utworzenia wersji": "",
        "Data użycia": "",
        "Data wydania": "",
        "Data wygaśnięcia": "",
        "Data wylogowania": "",
        "Data zakończenia": "",
        "Data zakończenia blokady konta": "",
        "Data zalogowania": "",
        "Data zdarzenia": "",
        "Data zgłoszenia": "",
        "Daty dostępów dla użytkowników ze ścieżki szkoleniowej są nadrzędne, daty dostępów dodane w szkoleniu nie będą brane pod uwagę.": "",
        "Daty publikacji zostały zaktualizowane.": "",
        "Dedykowane": "",
        "Definicja": "",
        "Definicja formularza znajduje się w obiekcie Form.": "",
        "Definicja szablonu": "",
        "Definicje formularzy": "",
        "Designer": "",
        "Designer Studio": "",
        "Długi teskt": "",
        "Długość": "",
        "Długość treści": "",
        "Długość trwania sesji [min": "",
        "Dni": "",
        "do": "",
        "Do": "",
        "Do akceptacji": "",
        "do czasu ręcznego odblokowania": "",
        "Do poziomu": "",
        "Do szkolenia nie przypisano jeszcze żadnych użytkowników.": "",
        "Do szkolenia nie przypisano jeszcze żadnych zespołów.": "",
        "Do ścieżki szkoleniowej nie przypisano jeszcze żadnych użytkowników.": "",
        "Do ścieżki szkoleniowej nie przypisano jeszcze żadnych zespołów.": "",
        "Do testu nie można dodawać materiałów opisowych": "",
        "Dobrze": "",
        "Docelowa strona": "",
        "Docs": "",
        "Dodaj": "",
        "Dodaj autora": "",
        "Dodaj certyfikat": "",
        "Dodaj dni": "",
        "Dodaj do zespołu": "",
        "Dodaj do zestawu": "",
        "Dodaj dokument": "",
        "Dodaj dział": "",
        "Dodaj element": "",
        "Dodaj etykietę": "",
        "Dodaj formularz": "",
        "Dodaj galerię": "",
        "Dodaj jeden lub więcej widgetów, aby uzyskać wgląd w postępy swojego zespołu.": "",
        "Dodaj kafelek": "",
        "Dodaj katalog": "",
        "Dodaj kategorie": "",
        "Dodaj kategorię": "",
        "Dodaj klienta": "",
        "Dodaj kolejne słowo": "",
        "Dodaj kolejny powód": "",
        "Dodaj komponent": "",
        "Dodaj lekcję": "",
        "Dodaj lekcję SCORM": "",
        "Dodaj lekcję Wideo": "",
        "Dodaj link": "",
        "Dodaj nową opinie": "",
        "Dodaj nową witrynę aby rozpocząć zarządzanie stronami.": "",
        "Dodaj nowy": "",
        "Dodaj nowy katalog aby rozpocząć zarządzanie stronami.": "",
        "Dodaj nowy tag": "",
        "Dodaj obrazek": "",
        "Dodaj odpowiedź": "",
        "Dodaj paczkę SCORM": "",
        "Dodaj plik": "",
        "Dodaj pytanie": "",
        "Dodaj rolę": "",
        "Dodaj serwer": "",
        "Dodaj shortcode": "",
        "Dodaj slajd": "",
        "Dodaj slajder": "",
        "Dodaj słowo": "",
        "Dodaj stronę": "",
        "Dodaj stwierdzenie": "",
        "Dodaj synonimy": "",
        "Dodaj szablon": "",
        "Dodaj szkolenie": "",
        "Dodaj ścieżkę": "",
        "Dodaj temat": "",
        "Dodaj temat/link": "",
        "Dodaj test": "",
        "Dodaj token": "",
        "Dodaj użytkownika": "",
        "Dodaj widget": "",
        "Dodaj wiersz": "",
        "Dodaj witrynę": "",
        "Dodaj wpis": "",
        "Dodaj wszystkie z kategorii": "",
        "Dodaj zastępstwo": "",
        "Dodaj zespół": "",
        "Dodaj zestaw": "",
        "Dodaj źródło": "",
        "dodane i edytowane przeze mnie": "",
        "Dodane pliki z sortowaniem": "",
        "Dodane przez": "",
        "Dodane przez moje zespoły": "",
        "Dodane przeze mnie": "",
        "Dodanie": "",
        "Dodanie aktualności": "",
        "Dodanie ankiety": "",
        "Dodanie artykułu": "",
        "Dodanie autora": "",
        "Dodanie certyfikatu": "",
        "Dodanie dokumentu": "",
        "Dodanie działu": "",
        "Dodanie etykiety": "",
        "Dodanie formularza": "",
        "Dodanie galerii": "",
        "Dodanie kategorii": "",
        "Dodanie klienta": "",
        "Dodanie komponentu": "",
        "Dodanie lekcji": "",
        "Dodanie linku": "",
        "Dodanie paczki SCORM": "",
        "Dodanie poradnika": "",
        "Dodanie regulaminu": "",
        "Dodanie roli": "",
        "Dodanie serwera": "",
        "Dodanie slajdera": "",
        "Dodanie slajdów": "",
        "Dodanie strony": "",
        "Dodanie szablonu": "",
        "Dodanie szkolenia": "",
        "Dodanie ścieżki szkoleniowej": "",
        "Dodanie testu": "",
        "Dodanie użytkownika": "",
        "Dodanie witryny": "",
        "Dodanie zastępstwa": "",
        "Dodanie zespołu": "",
        "Dodanie zestawu regulaminów": "",
        "Dodanie źródła": "",
        "Dodanie źródła danych": "",
        "Dodanych plików %0 (limit wynosi %1).": "",
        "Dodatki do e-learningu": "",
        "Dodatkowe uwagi": "",
        "Dodawanie": "",
        "Dokument": "",
        "Dokument %0": "",
        "Dokument nie jest jeszcze nigdzie opublikowany.": "",
        "Dokument nie ma ustawionej daty publikacji.": "",
        "Dokument został dodany.": "",
        "Dokument został usunięty.": "",
        "Dokument został zaktualizowany.": "",
        "Dokumenty": "",
        "Dołącz stronę do pliku sitemap.xml": "",
        "Domena": "",
        "Domyślna": "",
        "Domyślna wartość": "",
        "Domyślna witryna": "",
        "Domyślne ustawienie dla witryny": "",
        "Domyślnie ukryte": "",
        "Domyślnie widoczne": "",
        "Domyślny": "",
        "Domyślny tytuł aktywnej strony": "",
        "Dopasowanie": "",
        "Dopasuj do kategorii": "",
        "Dopasuj do obrazka": "",
        "Dopuść brak odpowiedzi": "",
        "Dostęp do": "",
        "Dostęp niezdefiniowany": "",
        "Dostęp od": "",
        "Dostęp przyznany": "",
        "Dostęp przyznany przez zespół": "",
        "Dostęp przyznany ręcznie": "",
        "Dostęp zabroniony": "",
        "Dostępna jest nowa aktualizacja. Proszę zapisać postęp pracy i klinąć przycisk \"Aktualizuj\". Możesz również zaakceptować aktualizację poprzez odświeżenie okna przeglądarki. Brak aktualizacji może spowodować błędy.": "",
        "Dostępne bloki": "",
        "Dostępne dla": "",
        "Dostępne języki": "",
        "Dostępne moduły": "",
        "Dostępne wersje językowe.": "",
        "Dostępny w sekcjach": "",
        "Dostępy": "",
        "Dowolny": "",
        "Dozwolone rozszerzenia": "",
        "Dozwolone rozszerzenia: %0.": "",
        "Dozwolone typy plików: %0.": "",
        "Drugie imię": "",
        "Drugorzędny": "",
        "Dwie kolumny": "",
        "Dział aktywny": "",
        "Dział nadrzędny": "",
        "Dział został dodany.": "",
        "Dział został zaktualizowany.": "",
        "Działania masowe": "",
        "Dzielenie": "",
        "Dzienny": "",
        "dzień": "",
        "Dzień": "",
        "dzień (dni)": "",
        "Dzień miesiąca": "",
        "E-learning": "",
        "E-mail": "",
        "Edycja": "",
        "Edycja aktualności": "",
        "Edycja ankiety": "",
        "Edycja aranżacji": "",
        "Edycja artykułu": "",
        "Edycja autora": "",
        "Edycja certyfikatu": "",
        "Edycja dokumentu": "",
        "Edycja działu": "",
        "Edycja etykiety": "",
        "Edycja formularza": "",
        "Edycja galerii": "",
        "Edycja kategorii": "",
        "Edycja klienta": "",
        "Edycja komentarza": "",
        "Edycja komponentu": "",
        "Edycja lekcji": "",
        "Edycja linku": "",
        "Edycja paczek SCORM": "",
        "Edycja poradnika": "",
        "Edycja regulaminu": "",
        "Edycja roli": "",
        "Edycja serwera": "",
        "Edycja slajdera": "",
        "Edycja slajdów": "",
        "Edycja strony": "",
        "Edycja szablonu": "",
        "Edycja szkolenia": "",
        "Edycja ścieżki szkoleniowej": "",
        "Edycja testu": "",
        "Edycja ustawień": "",
        "Edycja użytkownika": "",
        "Edycja witryny": "",
        "Edycja zastępstwa": "",
        "Edycja zespołu": "",
        "Edycja zestawu regulaminów": "",
        "Edycja źródła": "",
        "Edycja źródła danych": "",
        "Edytor": "",
        "Edytowana wersja": "",
        "Edytowane mogą być tylko szablony włączonych i obsługiwanych kanałów.": "",
        "Edytuj": "",
        "Edytuj aranżację": "",
        "Edytuj blok": "",
        "Edytuj członka grupy": "",
        "Edytuj datę dostępu": "",
        "Edytuj katalog": "",
        "Edytuj kategorie": "",
        "Edytuj kategorię": "",
        "Edytuj komentarz": "",
        "Edytuj lekcje": "",
        "Edytuj lekcje SCORM": "",
        "Edytuj lekcje wideo": "",
        "Edytuj slajder": "",
        "Edytuj stronę": "",
        "Edytuj szablon": "",
        "Edytuj tag": "",
        "Edytuj temat": "",
        "Edytuj test": "",
        "Edytuj uprawnienia": "",
        "Edytuj wpis": "",
        "Edytuj zestaw": "",
        "Eksport użytkowników": "",
        "Element": "",
        "Element grupujący": "",
        "Element nadrzędny": "",
        "element niewymagany": "",
        "Element opublikowany": "",
        "Element szkolenia rozpoczęty przez użytkowników": "",
        "Element szkolenia ukończony przez użytkowników": "",
        "element wymagany": "",
        "Element wymagany": "",
        "Element został dodany.": "",
        "Element został przywrócony.": "",
        "Element został usunięty.": "",
        "Element został zaktualizowany.": "",
        "Elementy": "",
        "Email": "",
        "Etykieta": "",
        "Etykiety": "",
        "Exportuj do Excela": "",
        "Facebook Admins": "",
        "Facebook Application ID": "",
        "FavIcona": "",
        "Film": "",
        "Filtr dla synchronizacji działów": "",
        "Filtr dla synchronizacji użytkowników": "",
        "Filtr globalny": "",
        "Filtr o podanej nazwie już istnieje.": "",
        "Filtr prywatny": "",
        "Filtr został usunięty": "",
        "Filtr został zapisany": "",
        "Filtrowanie": "",
        "Filtruj": "",
        "Filtry": "",
        "Filtry globalne": "",
        "Filtry systemowe": "",
        "Folder został usunięty.": "",
        "Formularz": "",
        "Formularz bazuje na nieaktualnej wersji definicji.": "",
        "Formularz nie został wypełniony poprawnie.": "",
        "Formularz zarchiwizowany": "",
        "Formularz został dodany.": "",
        "Formularz został zaktualizowany.": "",
        "Galeria": "",
        "Galeria nie jest jeszcze nigdzie opublikowana.": "",
        "Galeria nie ma ustawionej daty publikacji.": "",
        "Galeria zdjęć": "",
        "Galeria została dodana.": "",
        "Galeria została usunięta.": "",
        "Galeria została zaktualizowana.": "",
        "Generuj treść slajdu": "",
        "Godziny": "",
        "Google Nexus 10": "",
        "Google Nexus 5": "",
        "Google Nexus 7": "",
        "grudniu": "",
        "Grudzień": "",
        "Grupa": "",
        "Harmonogram zadań": "",
        "Harmonogram został zmieniony": "",
        "Hasło": "",
        "Hasło (Klucz ukryty)": "",
        "Hasło musi zawierać co najmniej jeden znak specjalny np.: ~ @ # $ % ^ & *": "",
        "Hasło użytkownika serwisowego": "",
        "Hasło zostało zmienione. Zaloguj się ponownie": "",
        "Health": "",
        "Hierarchia": "",
        "Historia": "",
        "Historia logowania": "",
        "Historia wygenerowanych treści.": "",
        "Historia zadania": "",
        "Host": "",
        "Host dodatkowy": "",
        "Host główny": "",
        "i posiadających wymagane uprawnienia": "",
        "Id": "",
        "ID": "",
        "ID lub typ zadania": "",
        "Id modelu: %0": "",
        "ID obiektu": "",
        "Id sesji": "",
        "Id slajdu": "",
        "ID zadania": "",
        "Id zdarzenia": "",
        "Identity": "",
        "Identyfikator odpowiedzi.": "",
        "Identyfikator slajdu": "",
        "Identyfikator zadania": "",
        "Identyfikator zapytania": "",
        "Ikona": "",
        "Ikona wyświetlana jest w postaci kwadratu.": "",
        "Liczba dostępnych bloków": "",
        "Liczba elementów": "",
        "Liczba elementów do uzupełnienia": "",
        "Liczba kategorii": "",
        "Liczba odpowiedzi": "",
        "Liczba pytań": "",
        "Liczba wierszy": "",
        "Liczba wyników do odrzucenia": "",
        "Liczba wyników do pobrania": "",
        "Liczba wyświetlanych aktualności": "",
        "Liczba znaków": "",
        "Imię": "",
        "Imię i Nazwisko": "",
        "Imię i nazwisko/nazwa autora": "",
        "Import slajdów z PDF": "",
        "Import użytkowników": "",
        "Importuj": "",
        "Importuj slajdy": "",
        "Indeks": "",
        "Indeks został usunięty.": "",
        "Indeksowanie strony": "",
        "Indywidualne tokeny": "",
        "Informacja": "",
        "Informacja o błędzie": "",
        "Informacja o ciasteczkach": "",
        "Inspekcja": "",
        "Instalacja": "",
        "ISBN": "",
        "Jasny": "",
        "Jasny niebieski": "",
        "Jedna kolumna": "",
        "Jednokrotnego wyboru": "",
        "Jeszcze nie ma kategorii.": "",
        "Jeśli pole jest puste to wstawiana automatycznie.": "",
        "Jeśli pole jest puste, wówczas używana jest wartość domyślna. W przeciwnym wypadku wartość domyślna jest nadpisywana.": "",
        "Język": "",
        "Język domyślny": "",
        "Kalendarz": "",
        "Kalendarz - Kategorie zasobów": "",
        "Kanał jest domyślnie wymagany. Nie da się wyłączyć wysyłania powiadomienia tym kanałem. Możliwa jest tylko zmiana tytułu i treści komunikatu.": "",
        "kanał wymagany": "",
        "Kanały komunikacji": "",
        "Kanały obsługiwane przez wybrany typ powiadmienia": "",
        "Kanały włączone w ustawieniach aplikacji": "",
        "Katalog": "",
        "Katalog główny": "",
        "Katalog linków": "",
        "Katalog został utworzony.": "",
        "Kategoria": "",
        "Kategoria jest wymagana": "",
        "Kategoria linku": "",
        "Kategoria materiałów": "",
        "Kategoria materiału": "",
        "Kategoria nadrzędna": "",
        "Kategoria slajdu": "",
        "Kategoria szkolenia": "",
        "Kategoria wydarzenia": "",
        "Kategoria wyszukiwanego materiału": "",
        "Kategoria została dodana.": "",
        "Kategoria została usunięta.": "",
        "Kategoria została zaktualizowana.": "",
        "Kategorie": "",
        "Kategorie linków": "",
        "Kategorie materiałów": "",
        "Kategorie szkoleń": "",
        "Kategorie wydarzeń": "",
        "Kategorię można opublikować tylko na jednej stronie.": "",
        "Klienci": "",
        "Klient aktywny": "",
        "Klient został dodany.": "",
        "Klient został usunięty.": "",
        "Klient został zaktualizowany.": "",
        "Klucz": "",
        "Klucz API": "",
        "Klucz prywatny": "",
        "Klucz publiczny": "",
        "Klucze": "",
        "Klucze są nie prawidłowe": "",
        "Klucze są prawidłowe.": "",
        "Kobieta": "",
        "Kod bannera (tutaj wklej kod HTML i JS)": "",
        "Kod Bing Webmaster": "",
        "Kod Google Webmaster": "",
        "Kod HTML": "",
        "Kod pocztowy": "",
        "Kod wertfikacyjny": "",
        "Kod weryfikacyjny jest nieprawidłowy.": "",
        "Kod źródłowy": "",
        "Kolejka": "",
        "Kolejne pozycje z lewej strony": "",
        "Kolejne pozycje z prawej strony": "",
        "Kolekcje dokumentów": "",
        "Kolor": "",
        "Kolor drugorzędny: %0": "",
        "Kolor icon na panelu z aplikacjami: %0": "",
        "Kolor podstawowy: %0": "",
        "Kolor tekstu aktywnej pozycji menu: %0": "",
        "Kolor tła": "",
        "Kolor tła panelu z aplikacjami: %0": "",
        "Kolory zostały przywrócone.": "",
        "Kolory zostały zapisane.": "",
        "Komentarz %0": "",
        "Komentarze": "",
        "Komponent został dodany.": "",
        "Komponent został usunięty.": "",
        "Komponent został zaktualizowany.": "",
        "Komponenty": "",
        "Komunikat": "",
        "Komunikat błędu": "",
        "Komunikat mailowy": "",
        "Komunikat walidacji": "",
        "Konfiguracja": "",
        "Konfiguracja aranżacji jest niedostępna ponieważ nie podano definicji szablonu.": "",
        "Konfiguracja bloków jest niedostępna ponieważ nie podano definicji szablonu.": "",
        "Konfiguracja bloku": "",
        "Konfiguracja bloku została zapisana.": "",
        "Konfiguracja domyślna": "",
        "Konfiguracja harmonogramu": "",
        "Konfiguracja sekcji jest niedostępna ponieważ nie podano definicji szablonu.": "",
        "Konfiguruj stronę": "",
        "Koniec po": "",
        "Konta użytkowników": "",
        "Konta użytkowników zostały ${message}": "",
        "Konta użytkowników zostały przypisane do zespołu": "",
        "Konta użytkowników zostały usunięte z systemu": "",
        "Kontakt": "",
        "Kontakty - Typ działania": "",
        "Kontener plików": "",
        "Konto aktywne": "",
        "Konto pozostanie nieaktywne dopóki adres e-mail nie zostanie potwierdzony": "",
        "Konto publiczne": "",
        "Kontrolka wyboru (pojedynczy wybór)": "",
        "Kontrolka wyboru (wybór wielokrotny)": "",
        "Koordynator": "",
        "Koordynatorów": "",
        "Koordynowane szkolenia:": "",
        "Kopiuj": "",
        "Kopiuj filtr": "",
        "Kosz": "",
        "Kraj": "",
        "Kroki": "",
        "Krótki opis": "",
        "Krótki tekst": "",
        "Krótki tytuł": "",
        "Książka": "",
        "Kwiecień": "",
        "kwietniu": "",
        "Laptop 15,6\"": "",
        "Laptop 15\"": "",
        "Laptop 17\"": "",
        "LDAPS (LDAP over SSL)": "",
        "Legenda": "",
        "Lekcja": "",
        "Lekcja może zawierać tylko materiały opisowe lub materiały video": "",
        "Lekcja SCORM ukryta": "",
        "Lekcja SCORM została dodana.": "",
        "Lekcja SCORM została zaktualizowana.": "",
        "Lekcja ukryta": "",
        "Lekcja wideo została dodana.": "",
        "Lekcja wideo została zaktualizowana.": "",
        "Lekcja została dodana.": "",
        "Lekcja została zaktualizowana.": "",
        "Lewy panel": "",
        "Liczba": "",
        "Liczba całkowita": "",
        "Liczba dni co ile wysyłane jest powiadomienie": "",
        "Liczba dni do zakończenia szkolenia przy której zostanie wysłane powiadomienie": "",
        "Liczba dni od jakiej użytkownik otrzyma powiadomienie": "",
        "Liczba dziesiętna": "",
        "Liczba koordynowanych szkoleń: %0": "",
        "Liczba opublikowanych elementów (zakładka Zawartość) musi być większa lub równa ustawionej liczbie wyświetlanych elementów (zakładka Ustawienia)": "",
        "Liczba podejść": "",
        "Liczba slajdów widoczna dla użytkownika nie może być większa od liczby slajdów dodanych w panelu.": "",
        "Liczba szkoleń": "",
        "Liczba szkoleń w ścieżce": "",
        "Liczba wydarzeń": "",
        "Liczba wyników na stronie": "",
        "Liczba wyświetlanych elementów": "",
        "Liczba wyświetlanych najnowszych elementów": "",
        "Liczba wyświetlanych slajdów": "",
        "Liczba zgłoszeń:": "",
        "Lider": "",
        "Like do komentarzy mogą dodawać tylko zalogowani użytkownicy": "",
        "Limit czasu (minuty)": "",
        "Limit zgłoszeń": "",
        "Limit znaków dla niezalogowanych": "",
        "Limit znaków dla zalogowanych": "",
        "Limity": "",
        "Linia": "",
        "linie": "",
        "Link": "",
        "Link do strony": "",
        "Link do wybranej strony serwisu": "",
        "Link do zewnętrznej strony": "",
        "Link przycisku": "",
        "Link został dodany.": "",
        "Link został usunięty.": "",
        "Link został zaktualizowany.": "",
        "lipcu": "",
        "Lipiec": "",
        "Lista": "",
        "Lista artykułów": "",
        "Lista certyfikatów": "",
        "Lista dostępnych zmiennych, które można wykorzystać we wszystkich polach tekstowych:": "",
        "Lista efektywnych uprawnień użytkownika wynikających z uprawnień indywidualnych oraz ról.": "",
        "Lista indeksów": "",
        "Lista jest pusta": "",
        "Lista komentarzy": "",
        "Lista koordynatorów": "",
        "Lista linków": "",
        "Lista możliwości zakończenia czatu. W obecnym stanie model zwraca jedną możliwą odpowiedź.": "",
        "Lista paczek SCORM": "",
        "Lista plików": "",
        "Lista regulaminów": "",
        "Lista rozwijana": "",
        "Lista szkoleń": "",
        "Lista ścieżek szkoleniowych": "",
        "Lista wiadomości (prompt) składających się na dotychczasową konwersację z modelem.": "",
        "Lista wyboru": "",
        "Lista wydarzeń": "",
        "Lista zespołów": "",
        "Listopad": "",
        "listopadzie": "",
        "Litery": "",
        "Litery i cyfry": "",
        "Log błędu": "",
        "Log z aktywności": "",
        "Logi aktywności zostały wyczyszczone": "",
        "Login": "",
        "Logo": "",
        "Logowanie": "",
        "Logowanie zakończyło się niepowodzeniem.": "",
        "Losowa kolejność elementów": "",
        "Luty": "",
        "lutym": "",
        "Łączenie treści": "",
        "Maj": "",
        "maju": "",
        "Maksimum": "",
        "Maksymalna liczba plików": "",
        "Maksymalna liczba tokenów": "",
        "Maksymalny rozmiar pliku w MB": "",
        "Maksymalny rozmiar pliku: %0MB.": "",
        "Małe znaki": "",
        "Mappingi": "",
        "marcu": "",
        "Marketing": "",
        "Marzec": "",
        "Masz niezapisane zmiany w materiałach. Zapisz je lub usuń aby móc zapisać element szkolenia.": "",
        "Materiał opisowy": "",
        "Materiał wideo": "",
        "Materiały szkoleniowe": "",
        "Max tokens": "",
        "Messages": "",
        "Metadane": "",
        "Mężczyzna": "",
        "Miejsca po przecinku": "",
        "Miejscowość": "",
        "mies.": "",
        "miesiąc": "",
        "Miesiąc": "",
        "Miesiące": "",
        "Miesięczny": "",
        "Migracje": "",
        "Miniaturka wideo": "",
        "Miniaturki": "",
        "Minimalna długość hasła": "",
        "Minimum": "",
        "Minuty": "",
        "Mnożenie": "",
        "Model": "",
        "Model generuje odpowiedź na podstawie podanych przez użytkownika parametrów.": "",
        "Moduł": "",
        "Moduły": "",
        "Modyfikacja": "",
        "Moje dokumenty": "",
        "Moje filtry": "",
        "Moje formularze": "",
        "Moje konto": "",
        "Monitor aktywności": "",
        "Monitor kolejek": "",
        "Monitoring": "",
        "Możliwość modyfikacji tego działu jest ograniczona. Nieaktywne pola są sychronizowane z zewnętrzną bazą danych %0.": "",
        "Możliwość modyfikacji tego konta jest ograniczona. Nieaktywne pola są sychronizowane z zewnętrzną bazą danych %0.": "",
        "Możliwość modyfikacji tego konta jest ograniczona. Nieaktywne pola są synchronizowane z zewnętrzną bazą danych %0.": "",
        "Możliwość wielokrotnego wyboru": "",
        "Mój tytuł": "",
        "Multimedia": "",
        "Na pewno usunąć wybrane elementy?": "",
        "Naciśnij enter aby usunąć": "",
        "Naciśnij enter aby wybrać": "",
        "Nadawca": "",
        "Nadpisz wersję": "",
        "Nadpisz wersję slajdu": "",
        "Nagłówek": "",
        "Nagłówek dokumentu": "",
        "Najniższy": "",
        "Najnowsze": "",
        "Najwyższy": "",
        "Narzędzia": "",
        "Następna strona": "",
        "Nazwa": "",
        "Nazwa aktywnej strony": "",
        "Nazwa aliasu": "",
        "Nazwa aranżacji": "",
        "Nazwa bazy danych": "",
        "Nazwa bloku": "",
        "Nazwa certyfikatu": "",
        "Nazwa działu": "",
        "Nazwa elementu": "",
        "Nazwa filtra": "",
        "Nazwa formularza": "",
        "Nazwa grupy": "",
        "Nazwa katalogu": "",
        "Nazwa katalogu została zmieniona.": "",
        "Nazwa kategorii": "",
        "Nazwa Kategorii": "",
        "Nazwa klienta": "",
        "Nazwa kolumny z opisem": "",
        "Nazwa kolumny z wartością": "",
        "Nazwa komponentu": "",
        "Nazwa konta (Klucz dostępu)": "",
        "Nazwa koordynatora": "",
        "Nazwa lekcji": "",
        "Nazwa linku": "",
        "Nazwa nadawcy": "",
        "Nazwa obiektu": "",
        "Nazwa paczki": "",
        "Nazwa paczki SCORM": "",
        "Nazwa pliku": "",
        "Nazwa pliku została zmieniona.": "",
        "Nazwa płyty": "",
        "Nazwa pokoju": "",
        "Nazwa pola": "",
        "Nazwa powiadomienia": "",
        "Nazwa przycisku": "",
        "Nazwa przycisku ``Dalej``": "",
        "Nazwa przycisku ``Wstecz``": "",
        "Nazwa przycisku ``Wyślij``": "",
        "Nazwa roli": "",
        "Nazwa sekcji": "",
        "Nazwa serwera": "",
        "Nazwa slajdera": "",
        "Nazwa slajdu": "",
        "Nazwa strony": "",
        "Nazwa szablonu": "",
        "Nazwa szkolenia": "",
        "Nazwa ścieżki": "",
        "Nazwa testu": "",
        "Nazwa tytułu nie może być dłuższa niż 240 znaków ani krótsza niż 3 znaki.": "",
        "Nazwa urządzenia": "",
        "Nazwa użytkownika": "",
        "Nazwa użytkownika serwisowego": "",
        "Nazwa w panelu": "",
        "Nazwa witryny": "",
        "Nazwa wyświetlana": "",
        "Nazwa wyświetlana:": "",
        "Nazwa zadania": "",
        "Nazwa zespołu": "",
        "Nazwa zestawu": "",
        "Nazwa źródła": "",
        "Nazwa źródła danych": "",
        "Nazwa:": "",
        "Nazwisko": "",
        "Nazwy wartości": "",
        "Neutralne": "",
        "Nie": "",
        "Nie ma jeszcze żadnych kategorii.": "",
        "Nie mam zdania": "",
        "Nie masz uprawnień do modyfikacji aranżacji.": "",
        "Nie masz uprawnień do panelu administracyjnego.": "",
        "Nie masz uprawnień do publikacji aranżacji.": "",
        "Nie masz uprawnień do tego widoku. Stan widoku mógł ulec zmianie, odśwież i sprawdź lub spróbuj ponownie później.": "",
        "Nie możesz dodać tego samego widgetu dwa razy": "",
        "Nie możesz modyfikować uprawnień": "",
        "Nie można usunąć kategorii do ktorej są przypisane elementy.": "",
        "Nie można usunąć swojego konta.": "",
        "Nie podano": "",
        "Nie podano nazwy poprzedniego elementu.": "",
        "Nie podano nazwy poprzedniej kategorii.": "",
        "Nie podano nazwy tytułu.": "",
        "Nie podano odpowiedzi dla ostatniego elementu.": "",
        "Nie podano opisu.": "",
        "Nie podano wymaganej ilości znaków: %0.": "",
        "Nie pokazuj krótkiego opisu strony w wynikach wyszukiwania (NoSnippet)": "",
        "Nie pokazuj linku do archiwum strony (NoArchive)": "",
        "Nie pokazuj strony jako źródła grafiki w wynikach wyszukiwania (NoImageIndex)": "",
        "Nie udało się pobrać listy aranżacji": "",
        "Nie udało się pobrać listy bloków": "",
        "Nie udało się pobrać listy działów": "",
        "Nie udało się pobrać listy filtrów": "",
        "Nie udało się pobrać listy kanałów": "",
        "Nie udało się pobrać listy kategorii": "",
        "Nie udało się pobrać listy kolejek": "",
        "Nie udało się pobrać listy modułów": "",
        "Nie udało się pobrać listy powiadomień": "",
        "Nie udało się pobrać listy ról": "",
        "Nie udało się pobrać listy serwerów baz danych": "",
        "Nie udało się pobrać listy serwerów plików": "",
        "Nie udało się pobrać listy stron": "",
        "Nie udało się pobrać listy szablonów": "",
        "Nie udało się pobrać listy typów materiałów": "",
        "Nie udało się pobrać listy typów modeli": "",
        "Nie udało się pobrać listy wydziałów": "",
        "Nie udało się pobrać listy zespołów": "",
        "Nie udało się pobrać powiadomień.": "",
        "Nie udało się pobrać ustawień kont użytkowników": "",
        "Nie udało się pobrać widgetu/ów": "",
        "Nie udało się usunąć szablonu": "",
        "Nie udało się wczytać podglądu strony.": "",
        "Nie udało się wysłać pliku": "",
        "Nie udało się zapisać szablonu": "",
        "Nie wiem": "",
        "Nie wszystkie pola zostały wypelnione prawidłowo.": "",
        "Nie wszystkie wybrane elementy zostały przeniesione. Nie posiadasz wymaganych uprawnień lub, w przypadku przenoszenia katalogów, katalog docelowy jest podkatalogiem katalogu źródłowego. Katalogi i pliki których nie udało się przenieść pozostały nadal w zakładce Wybrane.": "",
        "Nie wybrano ankiety": "",
        "Nie wybrano obrazu.": "",
        "Nie wybrano paczki.": "",
        "Nie wybrano strony": "",
        "Nie wybrano żadnego użytkownika.": "",
        "Nie wybrano żadnej kategorii.": "",
        "Nie wybrano żadnej strony.": "",
        "Nie wymaga uwierzytelniania": "",
        "nie wymuszaj zmiany hasła": "",
        "Nie wypełniono poprzedniego wiersza.": "",
        "Nie zdefiniowano żadnych ról.": "",
        "Nie zdefiniowano żadnych sekcji.": "",
        "Nie znaleziono dostępnych widgetów": "",
        "Nie znaleziono filtrów.": "",
        "Nie znaleziono kolumn.": "",
        "Nie znaleziono pasującego widgetu": "",
        "Nie znaleziono zapisanych filtrów.": "",
        "Nie znaleziono żadnych plików": "",
        "Nie znaleziono żadnych użytkowników.": "",
        "Nie znaleziono żadnych wyników.": "",
        "Nieaktywne": "",
        "Nieaktywni": "",
        "Niebieski": "",
        "niedozwolone rozszerzenie pliku (%0)": "",
        "Niedozwolone słowa": "",
        "niedozwolony typ pliku (%0)": "",
        "Niedziela": "",
        "Nieopublikowane": "",
        "Nieopublikowany": "",
        "Niepoprawne wyrażenie regularne": "",
        "Niepotwierdzeni": "",
        "Nieprawidłowy adres email": "",
        "Nieprawidłowy adres URL": "",
        "Niestandardowy adres": "",
        "Niestandardowy adres strony": "",
        "Niestandardowy błąd": "",
        "Nieudane": "",
        "Nieudane próby logowania": "",
        "Nieważne": "",
        "Niewidoczne": "",
        "Niezadowolony": "",
        "Nigdy": "",
        "Nikomu nie przydzielono uprawnień": "",
        "Niski": "",
        "Niżej": "",
        "Nowa data": "",
        "Nowe hasło": "",
        "Nowe połączenie": "",
        "Nowy materiał": "",
        "np. (&(objectClass=organizationalUnit))": "",
        "np. (&(objectClass=user)": "",
        "np. pl-PL": "",
        "Number": "",
        "Numer": "",
        "Numer aktywnej strony": "",
        "Numer domu": "",
        "Numer mieszkania": "",
        "Numer sesji": "",
        "Numer telefonu": "",
        "Object": "",
        "Obojętny": "",
        "Obok siebie": "",
        "Obraz": "",
        "Obraz został wycięty.": "",
        "Obrazek": "",
        "Obrazek wyróżniający": "",
        "Oczekujący na akceptację moderatora": "",
        "Oczekuje na wysłanie": "",
        "od": "",
        "Od": "",
        "od %0": "",
        "Od poziomu": "",
        "Odblokuj": "",
        "Odejmowanie": "",
        "Odnośnik URL": "",
        "Odpowiedzi": "",
        "Odpowiedź": "",
        "Odpowiedź 1": "",
        "Odpowiedź 2": "",
        "Odpowiedź 3": "",
        "Odpowiedź 4": "",
        "Odpowiedź 5": "",
        "Odrzucone": "",
        "Odrzucone zgody": "",
        "Odrzucony po zgłoszeniach": "",
        "Odrzuć": "",
        "Odstęp": "",
        "Odśwież": "",
        "Odznacz wszystkie": "",
        "Ogólne": "",
        "Ograniczna wygenerowaną odpowiedź do podanej liczby tokenów.": "",
        "Ok": "",
        "Okładka": "",
        "Określa z której strony do ścieżki mają być dodawane kolejne pozycje.": "",
        "Opcja pozwala na przypisywanie do tej kategorii wydarzeń dodanych przez wszystkich użytkowników": "",
        "Opcje": "",
        "Open Graph": "",
        "OpenAI": "",
        "Opiekun ścieżki": "",
        "Opis": "",
        "Opis kategorii": "",
        "Opis obrazka": "",
        "Opis sekcji": "",
        "Opis szkolenia": "",
        "Opis witryny": "",
        "Opis zespołu": "",
        "Opis źródła": "",
        "Opisy": "",
        "Opublikowana": "",
        "Opublikowane": "",
        "Opublikowane języki": "",
        "Opublikowane przez:": "",
        "Opublikowane wersje językowe.": "",
        "Opublikowany": "",
        "Opublikowany na stronach": "",
        "Opublikuj": "",
        "Opublikuj autora": "",
        "Opublikuj materiał": "",
        "Opublikuj ścieżkę szkoleniową aby móc dodać szkolenia": "",
        "Oraz hasło": "",
        "Oraz nowe hasło": "",
        "Organizacja": "",
        "Osoba odpowiedzialna": "",
        "Osoba zastępowana": "",
        "Osoba zastępująca": "",
        "Ostatnia aktywność": "",
        "Ostatnia strona": "",
        "Ostatnio oglądane": "",
        "Ostrzeżenie": "",
        "Otwieraj w nowym oknie": "",
        "Otwórz stronę w nowym oknie": "",
        "Otwórz w nowym oknie": "",
        "Paczka SCORM została dodana.": "",
        "Paczka SCORM została zaktualizowana.": "",
        "Paczka została usunięta.": "",
        "Paczki SCORM": "",
        "Paleta kolorów": "",
        "Pamięć podręczna": "",
        "Pamięć podręczna została wyczyszczona.": "",
        "Parameters": "",
        "Parametry": "",
        "Parametry, wymagane do wykonania zapytania:": "",
        "Pasek": "",
        "Pasek postępu": "",
        "Październik": "",
        "październiku": "",
        "Pełna treść:": "",
        "Pełny ekran": "",
        "Piątek": "",
        "Pierwsza strona": "",
        "Pigułki": "",
        "Pionowo": "",
        "Piosenka": "",
        "Pisać mogą tylko zalogowani użytkownicy": "",
        "Plik": "",
        "Plik graficzny": "",
        "Plik jest wysyłany": "",
        "Plik PDF": "",
        "Plik wideo": "",
        "Plik został wysłany": "",
        "Pliki": "",
        "Pliki do dodania": "",
        "Pliki do wysłania: %0": "",
        "Pliki prywatne": "",
        "Pliki publiczne": "",
        "Płeć": "",
        "Płyta z muzyką": "",
        "po %0 nieudanych próbach logowania": "",
        "Po dodaniu szkoleń nie można zmienić publikacji.": "",
        "Po jednym synonimie w wierszu": "",
        "Po ustawieniu dat dostępów dla użytkowników w ścieżce będą one nadrzędne, daty dostępów dodane w szkoleniu nie będą brane pod uwagę.": "",
        "Po ustawieniu indywidualnych dat dostępów dla użytkowników będą one nadrzędne, daty dostępów z zespołów nie będą brane pod uwagę.": "",
        "Po wybraniu tej opcji konta użytkowników zostaną usunięte z systemu. Czy chcesz kontynuować?": "",
        "Po zaznaczeniu tej opcji uprawnienia indywidualne i pochodzące z ról nie są brane pod uwagę.": "",
        "Po zmianie krótkiego tytułu zmieni się również adres URL.": "",
        "Pobierz": "",
        "Pochodzenie": "",
        "Poczta": "",
        "Podaj adres url...": "",
        "Podaj identyfikatory użytkowników Facebooka, oddzielone przecinkami, aby mogli uzyskać dostęp do statystyk (Facebook Insights).": "",
        "Podaj kod weryfikacyjny": "",
        "Podaj swój adres email": "",
        "Podaj uwagi do treści, która ma zostać wygenerowana. Przykład: \"Używaj symboli matematycznych\".": "",
        "Podana nazwa jest zajęta.": "",
        "Podana wartość jest nieprawidłowa.": "",
        "Podanie nazwy jest wymagane.": "",
        "Podano adres": "",
        "Podano nieprawidłowe dane": "",
        "Podano nieprawidłowe dane.": "",
        "Podążanie za linkami": "",
        "Podczas pierszego uruchomienia wymagane jest utworzenie konta głównego administratora.": "",
        "Podgląd": "",
        "Podkreślenie": "",
        "Podlega ocenie": "",
        "Podpis": "",
        "Podstawowe": "",
        "Podstawowe operacje na datach": "",
        "Podstawowe operacje na liczbach": "",
        "Podstawowe operacje tekstowe": "",
        "Podstawowe operacje warunkowe": "",
        "Podstawowy": "",
        "Podtytuł": "",
        "Podziedziałek - Piątek": "",
        "Pojedynczy artykuł (strona opisowa)": "",
        "Pojedynczy wybór": "",
        "Pokaż dla wybranych grup lub użytkowników": "",
        "Pokaż etykietę": "",
        "Pokaż filtry": "",
        "Pokaż historię": "",
        "Pokaż numery stron": "",
        "Pokaż przycisk ``Wyślij``": "",
        "Pokaż szczegóły": "",
        "Pokaż tylko liderów": "",
        "Pokaż tylko moje dokumenty": "",
        "Pokaż tytuł formularza": "",
        "Pokaż tytuł sekcji": "",
        "Pokaż tytuł strony": "",
        "Pokaż tytuły stron": "",
        "Pokaż w koncie użytkownika": "",
        "Pokaż więcej": "",
        "pokaż więcej...": "",
        "Pokaż wszystkich użytkowników": "",
        "Pola takie jak \"Nazwa slajdu\", \"Kategoria materiału\", \"Typ materiału\" są uwzględniane przy automatycznym generowaniu treści, dlatego konieczne jest ich wypełnienie.": "",
        "Pole \"%0\" jest wymagane.": "",
        "Pole \"%0\" nie zawiera prawidłowego adresu email.": "",
        "Pole \"%0\" nie zawiera prawidłowego adresu URL.": "",
        "Pole \"Drugie imię\" jest wymagane.": "",
        "Pole \"Imię\" jest wymagane.": "",
        "Pole \"Nazwisko\" jest wymagane.": "",
        "Pole \"Tytuł\" jest wymagane.": "",
        "Pole może zawierać jedynie cyfry.": "",
        "Pole może zawierać jedynie litery i cyfry.": "",
        "Pole może zawierać jedynie litery.": "",
        "Pole nie może zawierać spacji": "",
        "Pole typu \"%0\" jest niedozwolone w tym formularzu.": "",
        "Pole wymagane": "",
        "Pole zawiera": "",
        "Polski": "",
        "Połącz w pary": "",
        "Połączenie z API OpenAI jest aktywne.": "",
        "Połączenie z API OpenAI jest nieaktywne.": "",
        "Pomoc": "",
        "Poniedziałek": "",
        "Poprawna odpowiedź": "",
        "Poprawne": "",
        "Poprzednia strona": "",
        "Porównaj %0": "",
        "Porównaj z edytowaną wersją": "",
        "Porównania": "",
        "Porównanie": "",
        "Porównywana wersja": "",
        "Port": "",
        "Posiada załączniki": "",
        "Posiadasz niezapisane materiały": "",
        "Potwierdzenie akceptacji": "",
        "Potwierdzenie archiwizacji": "",
        "Potwierdzenie archiwizacji. Archiwizacja tematu spowoduje zarchiwizowanie przypisanych do niego lekcji": "",
        "Potwierdzenie przywrócenia": "",
        "Potwierdzenie przywrócenia szkolenia": "",
        "Potwierdzenie publikacji": "",
        "Potwierdzenie ukrycia": "",
        "Potwierdzenie usunięcia": "",
        "Potwierdzenie usunięcia.": "",
        "Potwierdzenie wylogowania użytkownika": "",
        "Potwierdzenie zatwierdzenia": "",
        "Potwierdzenie zmiany statusu": "",
        "Potwierdź": "",
        "Potwierdź akceptację": "",
        "Potwierdź publikację wersji": "",
        "Potwierdź usunięcie": "",
        "Potwierdź usunięcie aranżacji": "",
        "Potwierdź wyczyszczenie logów": "",
        "Potwierdź wyłączenie publikacji": "",
        "Potwierdź zapis do aktualnej wersji": "",
        "Potwierdź zapis do nowej wersji": "",
        "Powiadomienia": "",
        "Powiadomienia zostały skonfigurowane.": "",
        "Powiadomienie dla administratora chatu o nowych wiadomościach": "",
        "Powiadomienie dla użytkownika o nowych wiadomościach na chacie": "",
        "Powiadomienie jest domyślnie wymagane. Nie da się wyłączyć wysyłania tego powiadomienia.": "",
        "Powiadomienie o oczekującym szkoleniu": "",
        "Powiadomienie o zbliżającym się zakończeniu szkolenia": "",
        "Powiadomienie testowe zostało wysłane.": "",
        "Powiązane artykuły": "",
        "Powiązany artykuł": "",
        "Powiększ": "",
        "Powód": "",
        "powrót": "",
        "Powrót": "",
        "Powtarzaj co": "",
        "Powtórz hasło": "",
        "Powtórzone": "",
        "Poziomo": "",
        "Pozostałe filtry": "",
        "Pozostałe ustawienia": "",
        "Pozostało %0 znaków.": "",
        "Pozwól kontynuować na obecnej wersji": "",
        "Pozycja": "",
        "Prawda-Fałsz": "",
        "Prawy panel": "",
        "Predefiniowane tła panelu z aplikacjami": "",
        "Predefiniowane zestawy kolorów": "",
        "Premiera": "",
        "Priorytet": "",
        "Procent": "",
        "Profil koordynatora": "",
        "Profil użytkownika": "",
        "Profil został zaktualizowany.": "",
        "Program": "",
        "Program szkolenia": "",
        "Projekt": "",
        "Próg zaliczenia (%)": "",
        "Prywatne": "",
        "Przebudowanie indeksów": "",
        "Przebudowanie indeksównnOperacja może trwać od kilku sekund do kilku godzin w zależności od rozmiaru indeksów.nnNależy używać tylko w przypadku konieczności zaaplikowania zmian dokonanych w słownikach synonimów lub słów wykluczonych.": "",
        "Przeciągnij do innej sekcji": "",
        "Przeciągnij i upuść aby posortować pliki": "",
        "Przedrostek": "",
        "Przeglądanie logów aktywności": "",
        "Przeglądarka z której korzystasz nie wspiera powiadomień PUSH": "",
        "Przeglądnij logi": "",
        "Przeindeksowanie danych": "",
        "Przeindeksowanie danychnnOperacja może trwać od kilku sekund do kilku godzin w zależności od ilości danych w bazie.nnNależy używać tylko w przypadku zauważenia braku danych w indeksach.": "",
        "Przekroczono dozwoloną liczbę znaków: %0.": "",
        "przekroczony dozwolony rozmiar pliku (%0 MB)": "",
        "Przełącznik": "",
        "Przeniesienie wybranych elementów": "",
        "Przenieś": "",
        "Przenieś do sekcji": "",
        "Przenieś niżej": "",
        "Przenieś wyżej": "",
        "Przepnij alias": "",
        "Przesuń do góry": "",
        "Przesuń na dół": "",
        "Przetestuj poprawność zapisanych kluczy": "",
        "Przetestuj ustawienia poczty": "",
        "Przetestuj ustawienia Sms": "",
        "Przetwarzane": "",
        "Przybornik": "",
        "Przykład: domena\\użytkownik": "",
        "Przykład: użytkownik@domena": "",
        "Przypisz do zespołu": "",
        "Przypisz role": "",
        "Przypomnienie": "",
        "Przyrostek": "",
        "Przywrócić użytkownika i jego wiadomości?": "",
        "Przywrócić wiadomość?": "",
        "Przywrócono domyślną aranżacje.": "",
        "Przywróć": "",
        "Przywróć domyślną aranżacje": "",
        "Przywróć domyślne": "",
        "Przywróć użytkownika": "",
        "Przyznaj dostęp": "",
        "Pseudonim": "",
        "Publiczne": "",
        "Publiczne ID:": "",
        "Publikacja": "",
        "Publikacja aranżacji została wyłączona.": "",
        "Publikacja galerii przez użytkownika": "",
        "Publikacja i przypisane szkolenia zostały usunięte.": "",
        "Publikacja priorytetowa": "",
        "Publikacja zarchiwizowana": "",
        "Publikacja została dodana.": "",
        "Publikacja została usunięta.": "",
        "Publikuj do": "",
        "Publikuj na stronie": "",
        "Publikuj od": "",
        "Publikuj szkolenie": "",
        "Publikuj ścieżkę szkoleniową": "",
        "Punkty": "",
        "Pytania": "",
        "Pytania i odpowiedzi": "",
        "Pytanie": "",
        "Pytanie 1": "",
        "Pytanie 2": "",
        "Pytanie 3": "",
        "Pytanie i odpowiedzi": "",
        "Raczej się nie zgadzam": "",
        "Raczej się zgadzam": "",
        "Radio": "",
        "Ranga": "",
        "Raporty": "",
        "ReCaptcha": "",
        "Recaptcha nie jest aktywna, klucze muszą przejść pozytywny proces weryfikacji.": "",
        "Recaptcha została aktywowana, klucze zostały zweryfikowane.": "",
        "Region": "",
        "Regulamin wymagany": "",
        "Regulamin został dodany.": "",
        "Regulamin został zaktualizowany.": "",
        "Regulaminy": "",
        "Rejestr zmian": "",
        "Rejestracja konta": "",
        "Relacyjne": "",
        "Repozytorium plików": "",
        "Resetowanie podejść": "",
        "Resetowanie podejść użytkowników": "",
        "Resetuj": "",
        "Resetuj zmiany": "",
        "Reżyseria": "",
        "Ręczna akceptacja wszystkich komentarzy": "",
        "Roczny": "",
        "Rodzaj adresu kanonicznego": "",
        "Rodzaj odpowiedzi": "",
        "Rodzaj wydarzenia": "",
        "rok": "",
        "Rok": "",
        "Rola": "",
        "Rola aktywna": "",
        "Rola domyślna": "",
        "Rola została dodana.": "",
        "Rola została usunięta.": "",
        "Rola została zaktualizowana.": "",
        "Role i uprawnienia": "",
        "Role użytkownika": "",
        "Role zostały przypisane": "",
        "Root": "",
        "Rozmiar": "",
        "Rozmiar zdjęcia został zmieniony.": "",
        "Rozmieść równomiernie": "",
        "Rozwiń do": "",
        "Rozwiń/Zwiń": "",
        "Równość": "",
        "RSS": "",
        "Satysfakcja": "",
        "Scenariusz": "",
        "Scorm": "",
        "Sekcja": "",
        "Sekcja %0": "",
        "Sekcje": "",
        "Sekcje dla gości": "",
        "Sekcje dla zalogowanych": "",
        "Sekcje i bloki": "",
        "Sekcje wspólne": "",
        "Separator elementów w tytule": "",
        "Serial telewizyjny": "",
        "Serwer bazy danych": "",
        "Serwer dostępny": "",
        "Serwer plików": "",
        "Serwer został dodany.": "",
        "Serwer został usunięty.": "",
        "Serwer został zaktualizowany.": "",
        "Serwery plików": "",
        "Sesja": "",
        "Sesja nr %0": "",
        "Sesja użytkownika": "",
        "Sierpień": "",
        "sierpniu": "",
        "Skala 1-5": "",
        "Skopiowano": "",
        "Skopiuj szkolenie": "",
        "Skrócony opis": "",
        "Slajd z materiałów szkoleniowych": "",
        "Slajd został dodany.": "",
        "Slajd został usunięty.": "",
        "Slajd został wygenerowany.": "",
        "Slajd został zaktualizowany.": "",
        "Slajder": "",
        "Slajder został dodany.": "",
        "Slajder został usunięty.": "",
        "Slajder został zaktualizowany.": "",
        "Slajdery": "",
        "Slajdy": "",
        "Slajdy zostały zaimportowane.": "",
        "słowa": "",
        "Słowa kluczowe": "",
        "Słowa oddzielone przecinkami.": "",
        "Słowa wykluczone": "",
        "Słownik": "",
        "Słownik dowolny": "",
        "Słownik systemowy": "",
        "Słowo": "",
        "Słowo bazowe": "",
        "Słowo wykluczone": "",
        "Słowo zostało dodane.": "",
        "Słowo zostało usunięte.": "",
        "Słowo zostało zaktualizowane.": "",
        "Sobota": "",
        "Sortowanie autorów": "",
        "Sortowanie malejąco": "",
        "Sortowanie rosnąco": "",
        "Sortuj autorów": "",
        "Specjalizowane": "",
        "Sposób prezentacji zakładek": "",
        "Sprawdzenie czy pusty": "",
        "Stała wartość": "",
        "Stan": "",
        "Stan powiadomienia": "",
        "Standard": "",
        "Standard 1.2": "",
        "Standard 2004": "",
        "Standard nieznany": "",
        "Standardowa strona": "",
        "Status": "",
        "Status dokumentu został zmieniony.": "",
        "Status edytowanej wersji dokumentu": "",
        "Status formularza został zmieniony.": "",
        "Status galerii został zmieniony.": "",
        "Status linku został zmieniony.": "",
        "Status paczki został zmieniony.": "",
        "Status regulaminu został zmieniony.": "",
        "Status:": "",
        "Statyczny": "",
        "Statystyki wykorzystania tokenów.": "",
        "Stopka": "",
        "Strona": "",
        "Strona %0": "",
        "Strona błędu": "",
        "Strona docelowa dla 'Czytaj więcej'": "",
        "Strona dostępna dla wszystkich": "",
        "Strona dostępna tylko dla zalogowanych użytkowników": "",
        "Strona główna": "",
        "Strona lekcji opublikowana": "",
        "Strona lekcji SCORM opublikowana": "",
        "Strona logowania": "",
        "Strona nadrzędna": "",
        "Strona opublikowana": "",
        "Strona szkolenia ukryta": "",
        "Strona testu opublikowana": "",
        "Strona ukryta": "",
        "Strona z modułu konto użytkownika": "",
        "Strona z modułu wyszukiwarki": "",
        "Strona została dodana.": "",
        "Strona została usunięta.": "",
        "Strona została zaktualizowana.": "",
        "Strona została zarchiwizowana.": "",
        "Strony": "",
        "Struktura serwisu": "",
        "Stwierdzenia": "",
        "Stwierdzenie": "",
        "Styczeń": "",
        "styczniu": "",
        "Subskrypcja": "",
        "Sukces": "",
        "Suma zużytych tokenów": "",
        "Symbol": "",
        "Symbol autora": "",
        "Symbol klienta": "",
        "Symbol lekcji": "",
        "Symbol może składać się tylko z małych liter, cyfr oraz znaku - ": "",
        "Symbol strony": "",
        "Symbol strony szkolenia": "",
        "Symbol testu": "",
        "Synchronizuj": "",
        "Synonimy": "",
        "Synonimy zostały dodane.": "",
        "Synonimy zostały usunięte.": "",
        "Synonimy zostały zaktualizowane.": "",
        "Szablon graficzny": "",
        "Szablon został dodany.": "",
        "Szablon został usunięty.": "",
        "Szablon został zaktualizowany.": "",
        "Szablony": "",
        "Szary": "",
        "Szczegóły": "",
        "Szczegóły zadania": "",
        "Szczegóły zdarzenia": "",
        "Szczegóły zdarzenia nr %0": "",
        "Szerokość": "",
        "Szkic": "",
        "szkolenia": "",
        "Szkolenia": "",
        "szkolenie": "",
        "Szkolenie": "",
        "Szkolenie jest opublikowane": "",
        "Szkolenie jest puste": "",
        "Szkolenie może występować tylko w jednej ścieżce. To szkolenie zostało już dodane do ścieżki: {0}": "",
        "Szkolenie nie jest opublikowane": "",
        "Szkolenie zostało dodane.": "",
        "Szkolenie zostało przywrócone.": "",
        "Szkolenie zostało skopiowane": "",
        "Szkolenie zostało zaktualizowane.": "",
        "Szkolenie zostało zarchiwizowane.": "",
        "Szkolenie zostało zarchiwizowane. Aby je wyświetlić, należy przywrócić szkolenie z archiwum.": "",
        "szkoleń": "",
        "Szkoleń": "",
        "Szukaj": "",
        "Szukaj we wszystkich wersjach": "",
        "Szukana fraza": "",
        "Szukane słowo": "",
        "Ścieżka": "",
        "Ścieżka aktywnej strony": "",
        "Ścieżka już jest opublikowana.": "",
        "Ścieżka nawigacyjna": "",
        "Ścieżka szkoleniowa": "",
        "Ścieżka szkoleniowa została dodana.": "",
        "Ścieżka szkoleniowa została usunięta.": "",
        "Ścieżka szkoleniowa została zaktualizowana.": "",
        "Ścieżka/strona:": "",
        "Średni teskt": "",
        "Średnio": "",
        "Środa": "",
        "Tagi": "",
        "Tak": "",
        "Tak/Nie": "",
        "Tekst": "",
        "Tekst alternatywny": "",
        "Tekst do uzupełnienia": "",
        "Tekst zastępczy": "",
        "Tekstowy / HTML / JS": "",
        "Telefon": "",
        "Temat": "",
        "Ten dashboard nie zawiera jeszcze żadnych widgetów": "",
        "Ten element nie zawiera menu kontekstowego.": "",
        "Ten kanał jest wyłączony.": "",
        "Ten kanał ma możliwość wysyłania tekstu sformatowanego.": "",
        "Ten kanał nie ma możliwości wysyłania tekstu sformatowanego.": "",
        "Ten kanał nie zawiera żadnych powiadomień.": "",
        "Teraz": "",
        "Termin cykliczny": "",
        "Termin jednorazowy": "",
        "Terminy": "",
        "Terminy do słownika będzie można dodawać dopiero po zapisaniu źródła danych.": "",
        "Test": "",
        "Test ukryty": "",
        "Test został dodany.": "",
        "Test został zaktualizowany.": "",
        "Testowy adres email": "",
        "Testowy numer telefonu": "",
        "Testuj": "",
        "Tło certyfikatu": "",
        "Tło przycisku": "",
        "Tło strona 2": "",
        "To pole jest wymagane": "",
        "To szkolenie znajduje się już w ścieżce szkoleń": "",
        "Token": "",
        "Token został usunięty.": "",
        "Tokeny": "",
        "Treści odpowiedzi są wymagane": "",
        "Treści pytań są wymagane": "",
        "Treść": "",
        "Treść certyfikatu": "",
        "Treść komentarza": "",
        "Tryb konserwacji systemu": "",
        "Tryb monitorowania aktywności": "",
        "Tryb monitorowania aktywności włączony.": "",
        "Tryb monitorowania aktywności wyłączony.": "",
        "Tryb wyświetlania": "",
        "Trzy kolumny": "",
        "Twoje hasło wygasło. Zmień je na nowe.": "",
        "Twój token": "",
        "Tydzień": "",
        "tydzień (tygodnie) w następującym dniu": "",
        "Tygodniowy": "",
        "Tylko aktywne źródła": "",
        "Tylko do odczytu": "",
        "tylko dokumenty wersjonowane": "",
        "Tylko małe litery, bez znaków specjalnych, cyfr i spacji.": "",
        "Tylko priorytetowe": "",
        "Tylko serwer deweloperski. Nie używać w trybie produkcyjnym. ": "",
        "Tylko zalogowani użytkownicy mogą zgłaszać komentarze": "",
        "Typ": "",
        "Typ autora": "",
        "Typ bannera": "",
        "Typ elementu": "",
        "Typ konta": "",
        "Typ linku": "",
        "Typ logowania": "",
        "Typ materiału": "",
        "Typ mime": "",
        "Typ obiektu": "",
        "Typ obiektu:": "",
        "Typ obiektu.": "",
        "Typ pola": "",
        "Typ powiadomienia": "",
        "Typ serwera": "",
        "Typ slajdu": "",
        "Typ słownika": "",
        "Typ strony": "",
        "Typ zestawu": "",
        "Typ źródła": "",
        "Typ źródła danych": "",
        "Typy materiałów": "",
        "Tytuł": "",
        "Tytuł aktywnej witryny": "",
        "Tytuł formularza jest wymagany": "",
        "Tytuł formularza nie może być dłuższy niż %0 znaków": "",
        "Tytuł kafelka": "",
        "Tytuł sekcji": "",
        "Tytuł strony": "",
        "Tytuł witryny": "",
        "Udało się zapisać ustawienia widgetów": "",
        "Układ": "",
        "Ukryj": "",
        "Ukryj materiał": "",
        "Ulica": "",
        "Umożliwienie sprawdzania odpowiedzi": "",
        "Uniksowy znacznik czasu utworzenia zakończenia czatu.": "",
        "Upload": "",
        "Uprawnienia": "",
        "Uprawnienia użytkownika": "",
        "URL": "",
        "Uruchom zadanie": "",
        "Urządzenie zostało usunięte.": "",
        "Usage": "",
        "Usługi": "",
        "Ustaw kategorię": "",
        "Ustaw szerokości proporcjonalnie": "",
        "Ustaw w kolejności": "",
        "Ustawienia": "",
        "Ustawienia domyślne": "",
        "Ustawienia dostępności": "",
        "Ustawienia konta": "",
        "Ustawienia ogólne": "",
        "Ustawienia podstawowe": "",
        "Ustawienia powiadomień zostały zaktualizowane.": "",
        "Ustawienia sekcji \"%0\"": "",
        "Ustawienia sesji": "",
        "Ustawienia slajdera": "",
        "Ustawienia strony": "",
        "ustawienia widgetu": "",
        "Ustawienia witryny": "",
        "Ustawienia zaawansowane": "",
        "Ustawienia zostały zaktualizowane.": "",
        "Ustawienia zostały zapisane.": "",
        "Usunął": "",
        "Usunięcie": "",
        "Usunięcie indeksunnOperacja jest nieodwracalna. Czy na pewno chcesz usunąć wybrany indeks?": "",
        "Usunięcie kategorii spowoduje również usunięcie wszystkich powiązanych z nią formularzy.nnCzy na pewno kontynuować?": "",
        "Usunięcie wybranych elementów": "",
        "Usunięte": "",
        "Usuń": "",
        "Usuń blok z sekcji": "",
        "Usuń datę": "",
        "Usuń element": "",
        "Usuń filtr": "",
        "Usuń indeks": "",
        "Usuń kafelek": "",
        "Usuń katalog": "",
        "Usuń kategorię": "",
        "Usuń nie zapisany materiał z lekcji": "",
        "Usuń nie zapisany materiał z testu": "",
        "Usuń obrazek": "",
        "Usuń odpowiedź": "",
        "Usuń plik": "",
        "Usuń plik z listy": "",
        "Usuń poprzednie wersje certyfikatu": "",
        "Usuń powiązanie": "",
        "Usuń powód": "",
        "Usuń publikację": "",
        "Usuń pytanie": "",
        "Usuń regulamin": "",
        "Usuń slajd": "",
        "Usuń słowo": "",
        "Usuń spacje": "",
        "Usuń stronę": "",
        "Usuń szkolenie z ścieżki szkoleń": "",
        "Usuń tag": "",
        "Usuń urządzenie": "",
        "Usuń urządzenie aby odwołać zapamiętane automatyczne logowanie na swoje konto": "",
        "Usuń użytkowników": "",
        "Usuń wiersz": "",
        "Usuń z zespołu": "",
        "Usuń ze szkolenia": "",
        "Usuń zestaw": "",
        "Utworzył": "",
        "Utwórz grupy": "",
        "Utwórz katalog": "",
        "Utwórz nowy": "",
        "Utwórz raport wyników": "",
        "Uwzględnij wydziały podrzędne": "",
        "Uzupełnij dane dotyczące szkolenia": "",
        "Uzupełnij luki": "",
        "Uzupełnij wymagane pola.": "",
        "Użytkownicy": "",
        "Użytkownicy mogą zgłaszać komentarze do moderacji": "",
        "Użytkownicy w tej roli": "",
        "Użytkownik": "",
        "Użytkownik i jego wiadomości zostały przywrócone.": "",
        "Użytkownik jest liderem zespołu": "",
        "Użytkownik nie może się zalogować dopóki konto nie jest aktywne.": "",
        "Użytkownik posiada pełne uprawnienia": "",
        "Użytkownik z bazy": "",
        "Użytkownik zalogowany": "",
        "Użytkownik został dodany do zespołu.": "",
        "Użytkownik został dodany.": "",
        "Użytkownik został przypisany do szkolenia.": "",
        "Użytkownik został przypisany do ścieżki szkoleniowej.": "",
        "Użytkownik został usunięty.": "",
        "Użytkownik został wylogowany.": "",
        "Użytkownik został zablokowany, a jego wiadomośći ukryte.": "",
        "Użytkownik został zaktualizowany.": "",
        "Użytkownikcy z bazy": "",
        "Użytkowników": "",
        "Video": "",
        "W ciągu ostatniego tygodnia nie dodano nowych użytkowników": "",
        "W każdy dzień tygodnia": "",
        "W następującym dniu:": "",
        "W organizacji nie ma jeszcze żadnych działów.": "",
        "W przyszłości": "",
        "w tym ": "",
        "W tym katalogu nie ma jeszcze żadnych stron.": "",
        "W zespole od %0": "",
        "W zespole od %0 do %1": "",
        "Walidacja": "",
        "Walidacja certyfikatu SSL": "",
        "Wartość": "",
        "Wartość 0 oznacza brak limitu": "",
        "Wartość domyślna": "",
        "Wartość domyślna: (&(objectClass=organizationalUnit))": "",
        "Wartość domyślna: (&(objectClass=user)": "",
        "Wartość musi być liczbą całkowitą": "",
        "Wartość musi być liczbą dodatnią": "",
        "Wartość musi być liczbą ujemną": "",
        "Warunki logiczne": "",
        "Warunkowo": "",
        "Ważne": "",
        "Ważne informacje": "",
        "Ważność": "",
        "Wczytaj zapisany filtr": "",
        "Weekend": "",
        "Wersja językowa": "",
        "Wersja modelu": "",
        "Wersja modelu GPT.": "",
        "Wersja nieopublikowana": "",
        "Wersja opublikowana": "",
        "Wersja utowrzona przez": "",
        "Wersje": "",
        "Wersje aranżacji": "",
        "Wersje dokumentu": "",
        "Wersjonowanie wpisów": "",
        "Wewnętrznie": "",
        "Wiadomości": "",
        "Wiadomość": "",
        "Wiadomość testowa została wysłana.": "",
        "Wiadomość została przywrócona.": "",
        "Wiadomość została ukryta.": "",
        "Wideo": "",
        "Wideo z biblioteki": "",
        "Wideo zewnętrzne (URL)": "",
        "Widgety": "",
        "Widoczna dla wszystkich użytkowników": "",
        "Widoczność": "",
        "Widoczny obszar": "",
        "Wielkość kroku": "",
        "Wielokrotnego wyboru": "",
        "Wielokrotny wybór": "",
        "Wiersz": "",
        "Więcej": "",
        "więcej...": "",
        "Więcej...": "",
        "Witryna": "",
        "Witryna / Wersja językowa": "",
        "Witryna aktywna": "",
        "Witryna domyślna": "",
        "Witryna i język": "",
        "Witryna internetowa": "",
        "Witryna tylko dla zalogowanych użytkowników": "",
        "Witryna została dodana.": "",
        "Witryna została usunięta.": "",
        "Witryna została zaktualizowana.": "",
        "Witryny": "",
        "Wklej komponent": "",
        "Właściwości": "",
        "Właściwość ``%0`` aktywnego wpisu": "",
        "Włącz ankietę": "",
        "Włącz chat": "",
        "Włącz monitorowanie aktywności": "",
        "Włącz przynajmniej jedną wersję językową dla witryny aby rozpocząć zarządzanie stronami.": "",
        "Włącz social media": "",
        "Włącz system komentarzy": "",
        "Włącz system powiadomień": "",
        "Włączenie generowania pliku sitemap.xml dla całej witryny": "",
        "Włączenie obsługi cacheu dla witryny": "",
        "Włączenie protokołu SSL dla tej witryny": "",
        "Włączone": "",
        "Włączone (Follow)": "",
        "Włączone (Index)": "",
        "Województwo": "",
        "Wpis domyślny": "",
        "Wpis został dodany.": "",
        "Wpis został usunięty.": "",
        "Wpis został zaakceptowany.": "",
        "Wpis został zaktualizowany.": "",
        "Wprowadzenie zmian w polu \"Wybierz paczkę SCORM\" powoduje, że nadpisanie dotychczasowej wersji elementu szkolenia jest niedostępne.": "",
        "Wprowadzenie zmian w polu \"Źródło\" powoduje, że nadpisanie dotychczasowej wersji elementu szkolenia jest niedostępne.": "",
        "Wprowadzenie zmian w zakładce \"ZAWARTOŚĆ\" powoduje, że nadpisanie dotychczasowej wersji elementu szkolenia jest niedostępne.": "",
        "Wprowadzona wartość jest nieprawidłowa": "",
        "Wprowadzona wartość jest za długa": "",
        "Wprowadzona wartość jest za krótka": "",
        "Wprowadzona wartość jest za niska": "",
        "Wprowadzona wartość jest za wysoka": "",
        "Wprowadzona wartość nie jest poprawna": "",
        "Wróć do logowania": "",
        "Wróć do strony głównej": "",
        "Wrzesień": "",
        "wrześniu": "",
        "Wskazuje robotom wyszukiwarek czy mają indeksować stronę.": "",
        "Wskazuje robotom wyszukiwarek czy mają podążać za linkami.": "",
        "Wstaw cztery kolumny 1/4": "",
        "Wstaw dwie kolumny 1/3 oraz 2/3": "",
        "Wstaw dwie kolumny 2/3 oraz 1/3": "",
        "Wstaw dwie równe kolumny": "",
        "Wstaw kontrolkę": "",
        "Wstaw stronę": "",
        "Wstaw szablon informacyjny ze zdjęciem": "",
        "Wstaw treść widoczną po rozwinięciu": "",
        "Wstaw trzy kolumny 1/3": "",
        "Wstaw z dołu": "",
        "Wstaw z góry": "",
        "Wstaw z lewej strony": "",
        "Wstaw z prawej strony": "",
        "Wstecz": "",
        "Wstęp": "",
        "Wszyscy": "",
        "Wszystkie": "",
        "Wszystkie formularze": "",
        "Wszystkie kategorie": "",
        "Wszystkie niezapisane zmiany zostaną utracone.": "",
        "Wszystkie próby": "",
        "Wszystkie typy": "",
        "Wtorek": "",
        "wybierz": "",
        "Wybierz": "",
        "Wybierz adres kanoniczny z listy lub podaj ręcznie.": "",
        "Wybierz ankietę do wypełnienia po szkoleniu": "",
        "Wybierz ankietę do wypełnienia przed szkoleniem": "",
        "Wybierz artykuł": "",
        "Wybierz autora": "",
        "Wybierz blok": "",
        "Wybierz certyfikat": "",
        "wybierz długość": "",
        "Wybierz długość generowanej treści.": "",
        "Wybierz formularz": "",
        "Wybierz formularz zawierający dodatkowe pola dla kont użytkownków": "",
        "Wybierz istniejącego użytkownika": "",
        "wybierz katalog": "",
        "Wybierz katalog": "",
        "Wybierz katalog do którego chcesz przenieść wybrane elementy.": "",
        "Wybierz kategorię": "",
        "Wybierz paczkę": "",
        "Wybierz paczkę SCORM": "",
        "Wybierz plik": "",
        "Wybierz plik lub katalog": "",
        "Wybierz pliki": "",
        "Wybierz pliki (do {0})": "",
        "Wybierz regulamin": "",
        "Wybierz sekcję": "",
        "Wybierz serwer": "",
        "Wybierz shortcode": "",
        "Wybierz stronę": "",
        "Wybierz strony": "",
        "wybierz szablon": "",
        "Wybierz szkolenie": "",
        "Wybierz temat": "",
        "Wybierz typ powiadomienia aby zobaczyć dostępne klucze.": "",
        "Wybierz użytkownika": "",
        "Wybierz użytkowników dla których chcesz wykonać akcje": "",
        "wybierz wersję": "",
        "Wybierz wersję": "",
        "Wybierz zaznaczone": "",
        "wybierz...": "",
        "Wybierz...": "",
        "Wybór": "",
        "Wybór języka": "",
        "Wybór kategorii": "",
        "Wybór kategorii wydarzeń": "",
        "Wybór koloru jest wymagany": "",
        "Wybór listy stron": "",
        "Wybrana data jest mniejsza niż dozwolona %0.": "",
        "Wybrana data jest większa niż dozwolona %0.": "",
        "Wybrana ikona powinna być kwadratem": "",
        "Wybrana liczba jest mniejsza niż dozwolona %0.": "",
        "Wybrana liczba jest większa niż dozwolona %0.": "",
        "Wybrana wersja slajdu została wygenerowana w nieprawidłowym formacie. Nie można jej wyświetlić.": "",
        "Wybrane": "",
        "Wybrane %0 na %1": "",
        "Wybrane elementy zostały przeniesione.": "",
        "Wybrane elementy zostały usunięte.": "",
        "Wybrane kategorie": "",
        "Wybrane pliki": "",
        "Wybrane pliki: {0}": "",
        "Wybrane regulaminy:": "",
        "Wybrane regulaminy: ": "",
        "Wybranie ankiety jest wymagane": "",
        "Wybranie katalogu jest wymagane": "",
        "Wybranie strony jest wymagane": "",
        "Wybrano": "",
        "Wybrano %0 użytkowników.": "",
        "Wybrano jednego użytkownika.": "",
        "Wybrany certyfikat jest już nieaktualny.": "",
        "Wybrany język": "",
        "Wybrany materiał został już dodany do lekcji": "",
        "Wybrany szablon": "",
        "Wybrany znak zastąpi znacznik separatora | wstawiony w tytule.": "",
        "Wyczyszczenie pamięci podręcznej może spowodować usunięcie niezapisanych postępów czasu spędzonego na platformie.": "",
        "Wyczyść": "",
        "Wyczyść aranżację": "",
        "Wyczyść pamięć podręczną": "",
        "Wydział": "",
        "Wykonaj": "",
        "Wykonawca": "",
        "Wyloguj się": "",
        "Wyloguj zalogowanego obecnie użytkownika": "",
        "Wyłączone (NoFollow)": "",
        "Wyłączone (NoIndex)": "",
        "Wymagaj kolejności zaliczania elementów": "",
        "Wymagaj odpowiedzi, aby przejść dalej": "",
        "Wymagaj ponownej akceptacji po zmianach": "",
        "Wymagaj znaków specjalnych w hasłach": "",
        "Wymagane": "",
        "Wymagane jest ustawienie minimalnej długości hasła": "",
        "Wymagane jest wybranie formularza": "",
        "Wymagane jest wybranie kategorii": "",
        "Wymagane jest wybranie sekcji": "",
        "Wymagane jest wybranie źródła danych": "",
        "wymagany": "",
        "Wymiary": "",
        "Wymuś nowe podejście - reset wyniku": "",
        "Wymuś zmianę hasła": "",
        "Wyniki": "",
        "Wyrażenia warunkowe": "",
        "Wyrażenie regularne": "",
        "Wyrażenie w postaci \"/pattern/flag\" nie jest obsługiwane, użyj same wartości pattern": "",
        "Wyrażone zgody": "",
        "Wysłane": "",
        "Wysoki": "",
        "Wysokość": "",
        "wystąpieniach": "",
        "Wystąpił błąd podczas próby exportu użytkowników.": "",
        "Wystąpił błąd podczas próby importu użytkowników.": "",
        "Wystąpił błąd połączenia. Upewnij się że masz włączony internet i spróbuj ponownie.": "",
        "Wystąpił błąd serwera podczas obsługiwania wybranej operacji. Spróbuj ponownie.": "",
        "Wystąpił błąd. Spróbuj ponownie później.": "",
        "Wystąpiły błędy w formularzu. Sprawdź wszystkie pola formularza.": "",
        "Wysyłaj tym kanałem": "",
        "Wyszukaj": "",
        "Wyszukaj (Ctrl+Q)": "",
        "Wyszukaj albo dodaj nowy tag": "",
        "Wyszukaj autora...": "",
        "Wyszukaj blok": "",
        "Wyszukaj formularz...": "",
        "Wyszukaj regulamin...": "",
        "Wyszukaj użytkownika": "",
        "wyszukaj użytkownika...": "",
        "Wyszukaj wszędzie": "",
        "Wyszukaj zespół": "",
        "wyszukaj...": "",
        "Wyszukaj...": "",
        "Wyszukiwany ciąg znaków": "",
        "Wyszukiwarka": "",
        "Wyślij": "",
        "Wyślij kod": "",
        "Wyświetl materiały": "",
        "Wyświetl popup z kontynuacją lekcji": "",
        "Wyświetl również zarchiwizowane formularze": "",
        "Wyświetlaj nazwę bloczka": "",
        "Wyświetlaj tylko treść": "",
        "Wyświetlam": "",
        "Wyświetlanie": "",
        "Wytnij": "",
        "Wyzeruj licznik": "",
        "Wyżej": "",
        "Wzorzec": "",
        "Wzorzec cyklu": "",
        "Zaakceptowane": "",
        "Zaakceptowany": "",
        "Zaawansowane": "",
        "Zaawansowane polecenia": "",
        "Zabierz dostęp": "",
        "Zablokowany": "",
        "Zablokuj": "",
        "Zablokuj / Odblokuj konta": "",
        "Zablokuj użytkownika": "",
        "zacznij pisać aby wyszukać ankietę...": "",
        "zacznij pisać aby wyszukać artykuł...": "",
        "zacznij pisać aby wyszukać autora...": "",
        "zacznij pisać aby wyszukać certyfikat...": "",
        "zacznij pisać aby wyszukać kategorię...": "",
        "zacznij pisać aby wyszukać materiał...": "",
        "zacznij pisać aby wyszukać paczkę SCORM...": "",
        "zacznij pisać aby wyszukać slajder...": "",
        "zacznij pisać aby wyszukać szkolenie...": "",
        "zacznij pisać aby wyszukać użytkownika...": "",
        "zacznij pisać aby wyszukać zespół...": "",
        "Zacznik pisać Form lub Entry aby uruchomić podpowiadanie.": "",
        "Zaczyna sie od": "",
        "Zadanie przebudowania indeksów zostało rozpoczęte.": "",
        "Zadanie przeindeksowania danych zostało rozpoczęte.": "",
        "Zadanie zostało uruchomione.": "",
        "Zadanie zostało włączone": "",
        "Zadanie zostało wyłączone": "",
        "Zadowolony": "",
        "Zagrożenie": "",
        "Zaimportowano listę użytkowników.": "",
        "Zajawka": "",
        "Zakładki": "",
        "Zakolejkowane": "",
        "Zakończ sesję": "",
        "Zakończ sesję aby wylogować użytkownika": "",
        "Zakończone": "",
        "Zakres cyklu": "",
        "Zakres czasu": "",
        "Zaktualizowano.": "",
        "Zaktualizuj formularz": "",
        "Zależna od kontrolki": "",
        "Zaloguj się": "",
        "Zaloguj się na swoje konto": "",
        "Załączniki": "",
        "Zamiany zostały zapisane.": "",
        "Zamknij": "",
        "Zaokrąglenie": "",
        "Zapamiętaj logowanie": "",
        "Zapamiętane urządzenia": "",
        "Zapis nowej wersji elementu szkolenia": "",
        "Zapis został usunięty": "",
        "Zapisz": "",
        "Zapisz filtr": "",
        "Zapisz filtry": "",
        "Zapisz i wróć": "",
        "Zapisz jako": "",
        "Zapisz nową wersję": "",
        "Zapisz nową wersję slajdu": "",
        "Zapisz slajd": "",
        "Zapisz ścieżkę aby móc ją opublikować.": "",
        "Zapisz ustawienia widgetów": "",
        "Zapisz wersję": "",
        "Zapisz zmiany": "",
        "Zaplanowane": "",
        "Zapomniałeś hasła?": "",
        "Zapytanie": "",
        "Zapytanie SQL": "",
        "Zarchiwizowane": "",
        "Zarchiwizowany": "",
        "Zarządzaj zespołami": "",
        "Zarządzanie witrynami": "",
        "Zarządzanie zespołami": "",
        "Zasięg": "",
        "Zastępstwa": "",
        "Zastępstwo": "",
        "Zastępstwo wygasło.": "",
        "Zastępstwo wygaśnie za 10 minut.": "",
        "Zastępstwo wygaśnie za 5 minut.": "",
        "Zastępstwo wygaśnie za minutę.": "",
        "Zastępstwo zostało dodane.": "",
        "Zastępstwo zostało usunięte.": "",
        "Zastępstwo zostało zaktualizowane.": "",
        "Zastępuje osobę": "",
        "Zatwierdź": "",
        "Zawartość": "",
        "Zawiera": "",
        "Zawiera sekcje": "",
        "Zawierające jeden z wybranych tagów": "",
        "Zawsze": "",
        "Zaznacz wszystkie": "",
        "Zaznacz/odznacz wszystko": "",
        "Zbliżająca się data zakończenia": "",
        "Zdarzenie": "",
        "Zdecydowanie nie": "",
        "Zdecydowanie nieważne": "",
        "Zdecydowanie się nie zgadzam": "",
        "Zdecydowanie się zgadzam": "",
        "Zdecydowanie tak": "",
        "Zdecydowanie ważne": "",
        "Zdjęcie": "",
        "Zdjęcie autora": "",
        "Zdjęcie dla tabletów": "",
        "Zdjęcie dla urządzeń mobilnych": "",
        "Zdjęcie główne": "",
        "Zdjęcie użytkownika": "",
        "Zdjęcie w tle": "",
        "Zespoły": "",
        "Zespół": "",
        "Zespół aktywny": "",
        "Zespół został dodany.": "",
        "Zespół został przypisany do szkolenia.": "",
        "Zespół został przypisany do ścieżki szkoleniowej.": "",
        "Zespół został usunięty.": "",
        "Zespół został zaktualizowany.": "",
        "Zestaw niestandardowy": "",
        "Zestaw został dodany.": "",
        "Zestaw został zaktualizowany.": "",
        "Zewnętrzny link jest wymagany": "",
        "Zewnętrzny serwis": "",
        "Zgłaszanie komentarzy": "",
        "Zgłoszenia": "",
        "Zgoda": "",
        "Zielony": "",
        "Zmiana hasła": "",
        "Zmiana rozmiaru obrazu": "",
        "Zmiana wersji elementu nie dotyczy użytkowników z zakończonym szkoleniem": "",
        "Zmiany zaczną obowiązywać dopiero po przebudowaniu indeksów.": "",
        "Zmiany zostały zapisane.": "",
        "Zmieną %HEAD_TITLE% można wykorzystać w innych polach tekstowych.": "",
        "Zmieniono ustawienia widgetu": "",
        "Zmień daty publikacji": "",
        "Zmień hasło": "",
        "Zmień nazwę": "",
        "Zmień nazwę katalogu": "",
        "Zmień nazwę pliku": "",
        "Zmień rozmiar": "",
        "Zmień status edytowanej wersji": "",
        "Zmień widok": "",
        "Zmniejsz": "",
        "Zmodyfikował": "",
        "znaki": "",
        "Zobacz przed opublikowaniem": "",
        "Zobacz sesję użytkownika": "",
        "Zobacz szczegóły": "",
        "Zobacz szczegóły sesji użytkownika": "",
        "Zobacz szczegóły zdarzenia": "",
        "Zobacz wszystkie": "",
        "Został przywrócony oryginalny harmonogram": "",
        "Zresetuj hasło": "",
        "Zużyte tokeny": "",
        "Zwrócony": "",
        "Źle": "",
        "Źródła danych": "",
        "Źródła LDAP": "",
        "Źródło": "",
        "Źródło aktywne": "",
        "Źródło danych": "",
        "źródło danych zostało dodane.": "",
        "Źródło danych zostało zaktualizowane.": "",
        "Źródło LDAP zostało dodane.": "",
        "Źródło LDAP zostało zaktualizowane.": "",
        "Źródło URL": "",
        "źródło zostało usunięte.": "",
        "Żaden kanał komunikacji nie został włączony w ustawieniach powiadomień.": "",
        "Żółty": "",
        "Żródło danych": ""
    }
};
