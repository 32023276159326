<script lang="ts" setup>
import { computed, provide, useAttrs } from 'vue';
import { useMedia } from '@/plugins/media';
import Pager from '@/helpers/Pager';
import CardLayout from './layouts/CardLayout.vue';
import FlexLayout from './layouts/FlexLayout.vue';
import TableLayout from './layouts/TableLayout.vue';
import Dropdown from './settings/Dropdown.vue';
import { Header } from './helpers';

export interface Props
{
    loaded?: boolean;
    layout?: 'auto'|'table'|'flex'|'card';
    showHeader?: boolean;
    headers?: Header[];
    items: any[];
    columns?: {
        visible: Record<string, boolean>,
        positions: Record<string, number>
    };
    pager: Pager;
    emptyLabel?: string;
    rowClick?: (item: any, i: number) => void;
    rowClass?: (item: any, i: number) => Record<string, boolean> | string[] | string;
}

const props = defineProps({
  "loaded": { type: Boolean, default: false },
  "layout": { default: 'auto' },
  "showHeader": { type: Boolean, default: true },
  "headers": { default: () => [] as any[] },
  "items": null,
  "columns": { default: () => ({visible: {}, positions: {}}) },
  "pager": { default: () => new Pager(1, 20) },
  "emptyLabel": { default: '[[[Brak wyników]]]' },
  "rowClick": { type: Function, default: (item: any, i: number) => {} },
  "rowClass": { type: Function, default: (item: any, i: number) => ({}) }
});
const emit = defineEmits(["change", "update:loaded", "check"]);
const $attrs = useAttrs();

defineOptions({
    name: 'list-view-dynamic',
    inheritAttrs: false,
    components: {
        'list-view-card-layout': CardLayout,
        'list-view-flex-layout': FlexLayout,
        'list-view-table-layout': TableLayout
    }
});

const media = useMedia();
const layout = computed(() =>
{
    if (props.layout == 'auto')
    {
        return media.mobile() ? 'flex' : 'table';
    }

    return props.layout;
});
const pager = computed(() => props.pager);
const layoutName = computed(() => `list-view-${layout.value}-layout`);
const loaded = computed({
    get: () => props.loaded,
    set: (value) => emit('update:loaded', value)
});
const headers = computed(() =>
{
    return props.headers.map((p, index) =>
    {
        const header = {
            ...p,
            key: p.name || `col-${index}`,
            visible: p.visible === undefined ? true : p.visible,
            checkbox: p.type == 'checkbox',
            position: p.position !== undefined ? p.position :
                ((type, index) =>
                {
                    switch (type)
                    {
                        case 'checkbox':
                            return 0;
                        case 'buttons':
                            return Number.MAX_SAFE_INTEGER;
                        default:
                            return index;
                    }
                })(p.type, index)
        };

        header.visible = getVisibility(header);
        header.position = getPosition(header);

        return header;
    }) as Header[];
});

const columns = computed(() => props.columns);
const visible = computed({
    get: () => columns.value.visible,
    set: (value) => { columns.value.visible = value; }
});
const getVisibility = (header: Header): boolean =>
{
    return header.key in visible.value ? visible.value[header.key] : header.visible;
};

const positions = computed({
    get: () => columns.value.positions,
    set: (value) => { columns.value.positions = value; }
});
const getPosition = (header: Header): number =>
{
    return header.key in positions.value ? positions.value[header.key] : header.position;
};

provide('loaded', loaded);
provide('layout', layout);
provide('empty-label', props.emptyLabel);
provide('row-click', props.rowClick);
provide('row-class', props.rowClass);

const onSorting = (header: any): void =>
{
    const sort = header.sort || '';

    if (pager.value.sorting === sort)
    {
        pager.value.order = pager.value.order === 'ASC' ? 'DESC' : 'ASC';
    }
    else
    {
        pager.value.sort(header.sort || '');
    }

    emit('change');
};

const checkItems = (value: boolean): void =>
{
    emit('check', value);
};
</script>

<template>
    <Dropdown :headers="headers" v-model:visible="visible" v-model:positions="positions" v-if="showHeader" />
    <component :is="layoutName" v-bind="$attrs" :pager="pager" :headers="headers" :rows="items" :show-header="showHeader" @change="onSorting" @check="checkItems">
        <template #row="{item, index}: any">
            <slot name="row" :item="item" :index="index"></slot>
        </template>
    </component>
</template>
