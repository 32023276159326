<template>
    <ideo-form-group :label="$t('[[[Wybór strony]]]')" :invalid-feedback="form.$errors.first('settings.pageId')" :state="form.$errors.state('settings.pageId')" required>
        <ideo-form-select v-model="form.settings.pageId" :options="selectedPageOptions" data-cy="page">
            <template #first>
                <option :value="null">
                    {{ $t('[[[Wybierz stronę]]]') }}
                </option>
            </template>
        </ideo-form-select>
    </ideo-form-group>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Emit, Prop, Watch } from '@/helpers/Decorators';
import { CurrentWebsite, Option } from '@/helpers/Interfaces';
import { FormType } from '@/helpers/Form';
import merge from 'lodash/merge';
import { $t } from '@/plugins/localization';
import { wait } from '@/helpers/Utils';
import { useWebsitesStore } from '@/modules/cms/websites/store';
import PagesService, { PageListItemModel } from '@/modules/cms/pages/services/PagesService';
import Multiselect from 'vue-multiselect/src/Multiselect.vue';

interface FormSettings
{
    settings: any;
}

@Options({
    name: 'widget-gallery-settings',
    components: {
        'multiselect': Multiselect
    }
})
export default class GallerySettings extends Vue
{
    @Prop() public modelValue!: any;

    public get form(): FormType<FormSettings>
    {
        return this.modelValue;
    }

    public module: string = 'gallery';
    public defaults: any = {
        pageId: null,
    };
    public pageOptions: PageListItemModel[] = [];

    public get current(): CurrentWebsite
    {
        return useWebsitesStore().current;
    }

    public async created(): Promise<void>
    {
        await wait(() => this.current.website);
        await this.loadPages();
        this.form.settings = merge(this.defaults, this.form.settings);
    }

    public get selectedPageOptions(): Option[]
    {
        return this.pageOptions.map(p =>
        {
            return { value: p.id, text: p.option };
        });
    }

    @Watch('form.settings', { deep: true })
    public onSettingsChanged(value: any): void
    {
        this.triggetModel();
    }

    @Emit('update:modelValue')
    public triggetModel(): any
    {
        return this.form;
    }

    public async validate(): Promise<void>
    {
        this.form.validate(schema => ({
            'settings.pageId': schema.string().nullable().required(this.$t("[[[Wybranie strony jest wymagane]]]"))
        }));
    }

    public async loadPages(): Promise<void>
    {
        try
        {
            this.pageOptions = await PagesService.getPagesByModule(this.current.website, this.current.locale, this.module);
            this.pageOptions = this.pageOptions.filter(x => x.id != 0);
        }
        catch (ex)
        {
            this.pageOptions = [];
            this.$log.debug(ex);
        }
    }
}
</script>
