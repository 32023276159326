<template>
    <!-- <ideo-form-group :label="$t('[[[Wybierz autora]]]')">
        <div class="d-flex flex-column flex-md-row">
            <div class="flex-grow-1">
                <ideo-autocomplete
                    v-model="form.settings.authorId"
                    name="author-autocomplete"
                    track-by="id"
                    :placeholder="$t('[[[Wyszukaj autora...]]]')"
                    :fetch="(id: number) => fetchAuthor(id)"
                    :search="(query: string, limit: number) => searchAuthor(query, limit)"
                >
                    <template #selected="{ option }">
                        <div class="d-flex flex-nowrap align-items-center">
                            <ideo-img :src="$filters.image(option.facePictureUrl, 'ico')" rounded="circle" :blank="!option.facePictureUrl" width="18" height="18" blank-color="#777" />
                            <b class="ms-2">{{ option.userName }}</b>
                            <i class="ms-auto">{{ option.websiteName }}</i>
                        </div>
                    </template>
                    <template #option="{ option }">
                        <div class="d-flex flex-nowrap align-items-center">
                            <ideo-img :src="$filters.image(option.facePictureUrl, 'ico')" rounded="circle" :blank="!option.facePictureUrl" width="36" height="36" blank-color="#777" />
                            <b class="ms-2">{{ option.userName }}</b>
                            <i class="ms-auto">{{ option.websiteName }}</i>
                        </div>
                    </template>
                </ideo-autocomplete>
            </div>
        </div>
    </ideo-form-group> -->
    <ideo-form-group :label="$t('[[[Imię i nazwisko/nazwa]]]')" :invalid-feedback="form.$errors.first('settings.authorName')" :state="form.$errors.state('settings.authorName')">
        <ideo-form-input type="text" v-model="form.settings.authorName" name="authorName"></ideo-form-input>
    </ideo-form-group>
    <ideo-form-group :label="$t('[[[Zdjęcie]]]')" :invalid-feedback="form.$errors.first(`settings.authorPicture`)" :state="form.$errors.state(`settings.authorPicture`)">
        <filepicker id="authorPicture" v-model="form.settings.authorPicture" media="images" />
    </ideo-form-group>
    <ideo-form-group :label="$t('[[[Treść]]]')" :invalid-feedback="form.$errors.first('settings.content')" :state="form.$errors.state('settings.content')">
        <editor id="content" v-model="form.settings.content"></editor>
    </ideo-form-group>
    <ideo-form-group :label="$t('[[[Data obowiązywania od]]]')">
        <ideo-datetime v-model="form.settings.datePublication" name="datePublication" type="datetime" />
    </ideo-form-group>
    <ideo-form-group :label="$t('[[[Data obowiązywania do]]]]')">
        <ideo-datetime v-model="form.settings.dateExpiration" name="dateExpiration" type="datetime" />
    </ideo-form-group>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Emit, Prop, Watch } from '@/helpers/Decorators';
import { FormType } from '@/helpers/Form';
import { ValidatableComponent } from '@/components/cms';
import AuthorsService, { ListItemModel as AuthorListItemModel } from '@/modules/cms/authors/services/AuthorsService';
import merge from 'lodash/merge';

interface FormSettings
{
    settings: any;
}

@Options({
    name: 'widget-opinion-slider-settings'
})
export default class OpinionSliderSettings extends Vue implements ValidatableComponent
{
    @Prop() public modelValue!: any;

    public defaults: any = {
        content: '',
        authorId: null,
        authorName: '',
        authorPicture: null,
        datePublication: null,
        dateExpiration: null
    };

    public get form(): FormType<FormSettings>
    {
        return this.modelValue;
    }

    public async created(): Promise<void>
    {
        this.form.settings = merge(this.defaults, this.form.settings);
    }

    @Watch('form.settings', { deep: true })
    public onSettingsChanged(value: any): void
    {
        this.triggetModel();
    }

    @Emit('update:modelValue')
    public triggetModel(): any
    {
        return this.form;
    }

    public async validate(): Promise<void>
    {
    }

    public async fetchAuthor(id: number): Promise<AuthorListItemModel>
    {
        const response = await AuthorsService.fetchAutocompleteModel(id);

        return response || null;
    }

    public async searchAuthor(query: string, limit: number): Promise<AuthorListItemModel[]>
    {
        return await AuthorsService.getAutocompleteList(query, limit);
    }
}
</script>
