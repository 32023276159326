export default {
    "Project-Id-Version": "",
    "POT-Creation-Date": "2023-12-14T13:07:56.519Z",
    "MIME-Version": "1.0",
    "Content-Type": "text/plain; charset=utf-8",
    "Content-Transfer-Encoding": "8bit",
    "X-Generator": "Edito",
    "PO-Revision-Date": "2023-12-14T13:07:56.780Z",
    "Last-Translator": "",
    "Language-Team": "",
    "Language": "en-US",
    "Messages": {
        "3": "3",
        "5": "5",
        "10": "10",
        " dodanych w ": " added in ",
        " Elementy do uzupełnienia w tekście zamieszczamy pomiędzy zdefiniowanymi znacznikami %$dowolna_nazwa$%.": " Elements to be completed are placed between the defined tags %$dowolna_name$%.",
        " opublikowanych": " published",
        "...": "",
        "...nie znalazłeś bloku?": "... have you not found the block?",
        "{Potwierdzenie przywrócenia": "{Confirmation of restoration",
        "%$ – znacznik otwierający tag": "%$ – tag to start a tag",
        "%0": "%0",
        "%0 znaków": "%0 characters",
        "<Brak nazwy>": "<No name>",
        "${ex.message}": "${ex.message}",
        "$% - znacznik zamykający tag": "$% - tag to close a tag",
        "1 godzina": "1 hour",
        "10 minut": "10 minutes",
        "12 godzin": "12 hours",
        "15 minut": "15 minutes",
        "24 godziny": "24 hours",
        "3 godziny": "3 hours",
        "30 minut": "30 minutes",
        "5 minut": "5 minutes",
        "6 godzin": "6 hours",
        "Aby dodawać członków zespołu musisz najpierw zapisać formularz.": "To add team members, you have to save the form.",
        "Aby dodawać użytkowników do szkolenia musisz najpierw zapisać formularz.": "To add users to the course, you have to save the form.",
        "Aby dodawać użytkowników do ścieżki szkoleniowej musisz najpierw zapisać formularz.": "To add users to the learning path, you have to save the form.",
        "Aby dodawać użytkowników do wydarzenia musisz najpierw zapisać formularz.": "To add users to an event, you must first save the form.",
        "Aby dodawać zespół do szkolenia musisz najpierw zapisać formularz.": "To add a team to the course, you have to save the form.",
        "Aby dodawać zespół do ścieżki szkoleniowej musisz najpierw zapisać formularz.": "To add a team to the learning path, you have to save the form.",
        "Aby móc przetestować poprawność kluczy, najpierw zapisz formularz.": "To test the validity of the keys, first save the form.",
        "Aby móc zapisać element szkolenia, powinien on posiadać przynajmniej jeden opublikowany slajd.": "To save a course component, it should have at least one published slide.",
        "Aby połączenie z API OpenAI zostało nawiązane, należy podać klucz wygenerowany na stronie producenta i wybrać wersję modelu. Połączenie z API jest sprawdzane przy zapisie formularza.": "To connect to the OpenAI API, it is necessary to provide the key generated on the provider's website and select the model version. The connection to the API is checked when the form is saved.",
        "Aby recaptcha została aktywowana, należy zapisać formularz z podanymi kluczami wraz z zaznaczonym oknem \"Recaptcha\". Następnie przetestować poprawność podanych kluczy klikając przycisk \"Przetestuj poprawność zapisanych kluczy\".": "In order for the recaptcha to be activated, you need to save the form with the keys and the \"Recaptcha\" space selected. Then test the correctness of the given keys by clicking the \"Test the correctness of saved keys\" button.",
        "Aby zobaczyć aktualny podgląd certyfikatu kliknij Zapisz.": "To preview the certificate, click Save.",
        "Administracja": "Administration",
        "Administracja - Kraje": "Administration - Countries",
        "Administracja - Miasta": "Administration - Cities",
        "Administracja - Role": "Administration - Roles",
        "Administracja - Stanowiska pracy": "Administration - Positions",
        "Administracja - Użytkownicy": "Administration - Users",
        "Administracja - Województwa": "Administration - Voivodships",
        "Administracja - Zespoły": "Administration - Teams",
        "Administratorzy": "Administrators",
        "Adres": "Address",
        "Adres aktywnej strony": "Address of the active page",
        "Adres domenowy": "Domain address",
        "Adres e-mail potwierdzony": "The email address has been confirmed",
        "Adres email": "Email address",
        "Adres email nadawcy": "Sender's email address",
        "Adres IP": "IP address",
        "Adres IP: %0": "IP address: %0",
        "Adres kanoniczny": "Canonical address",
        "Adres serwera": "Server address",
        "Adres strony": "Website address",
        "Adres url": "Url",
        "Adres URL": "URL",
        "Adres url do pliku wideo": "Url to video file",
        "Adres URL profilu autora.": "URL of the author profile.",
        "Akcent": "",
        "Akceptacja": "Acceptance",
        "Akceptacja komentarzy": "Accept comments",
        "Akceptuj": "Accept",
        "Akcja": "Action",
        "Akcje": "Actions",
        "Aktualizacja wyników szkolenia użytkowników została dodana do kolejki zadań.": "The user course results update has been added to the task queue.",
        "Aktualizacja: {0}": "Update: {0}",
        "Aktualizuj": "Update",
        "Aktualna sesja": "Current session",
        "Aktualne hasło": "Current password",
        "Aktualne urządzenie": "Current device",
        "Aktualności": "News",
        "Aktualności ze strony": "Website news",
        "Aktywna": "Active",
        "Aktywne": "Active",
        "Aktywne kanały komunikacji": "Active communication channels",
        "Aktywne sesje": "Current sessions",
        "Aktywni": "Active",
        "Aktywny": "Active",
        "Alias": "Alias",
        "Alias został przepięty.": "Alias has been switched.",
        "Angielski": "English",
        "Ankieta": "Survey",
        "Ankieta po szkoleniu jest już nieaktualna.": "The post-course survey is no longer current.",
        "Ankieta przed i po szkoleniu": "Survey before and after the course",
        "Ankieta przed szkoleniem jest już nieaktualna.": "The pre-course survey is no longer current.",
        "Ankiety": "Surveys",
        "Anuluj": "Cancel",
        "Anuluj edycję": "Undo",
        "Anuluj wszystkie wprowadzone zmiany": "Cancel all the changes made",
        "Apple iPad": "Apple iPad",
        "Apple iPhone 5": "Apple iPhone 5",
        "Apple iPhone 6": "Apple iPhone 6",
        "Aranżacja": "Arrangement",
        "Aranżacja utowrzona przez": "Arranged by",
        "Aranżacja została opublikowana.": "The arrangement has been published.",
        "Aranżacja została zachowana.": "The arrangement has been saved.",
        "Aranżacje": "Arrangements",
        "Archiwalne elementy szkolenia": "Archived course elements",
        "Archiwizuj": "Archive",
        "Archiwizuj materiał lekcji": "Archive lesson materials",
        "Archiwizuj materiał testu": "Archive test material",
        "Archiwizuj stronę": "Archive site",
        "Archiwum": "Archive",
        "Artykuł": "Article",
        "Artykuły": "Articles",
        "Automatyczna akceptacja": "Auto acceptance",
        "Automatyczna akceptacja komentarzy zalogowanych użytkowników": "Automatic acceptance of comments from logged-in users",
        "Automatyczna akceptacja wszystkich komentarzy": "Automatic acceptance of all comments",
        "Automatyczna publikacja galerii": "",
        "Automatyczne akceptacja wersji po osiągnięciu daty publikacji. Wybrana wersja musi mieć ustawiony status na \"Ukończony\"": "Automatic version acceptance on the publication date. The selected version must be given status \"Completed\"",
        "Automatyczny": "Automatic",
        "Autor": "Author",
        "Autor szkolenia": "Course author",
        "Autor został dodany.": "",
        "Autor został usunięty.": "",
        "Autor został zaktualizowany.": "",
        "Autor/adres IP": "Author/IP address",
        "Autorzy": "",
        "Autorzy spoza bazy użytkowników": "",
        "Azure AD": "Azure AD",
        "Bardzo dobrze": "Very good",
        "Bardzo niezadowolony": "Very unsatisfied",
        "Bardzo zadowolony": "Very satisfied",
        "Bardzo źle": "Very bad",
        "Barwa tekstu": "",
        "Barwa tła": "",
        "Baza danych": "Database",
        "Baza danych tego klienta jest nieaktualna. Uruchom aktualizację za pomocą przycisku na dole formularza.": "This customer's database is out of date. Install an update with the button at the bottom of the form.",
        "Baza dokumentów": "Document database",
        "Bazy danych": "Database",
        "Bez \"http(s)://\" i \"/\" na końcu.": "No \"http(s)://\" and \"/\" at the end.",
        "Bezpieczeństwo": "Safety",
        "Bezpieczne połączenie SSL": "Secure SSL connection",
        "Biały": "White",
        "Biblioteka": "Library",
        "Blok aktywny": "Active block",
        "Blok został usunięty.": "The block has been removed.",
        "Blokada konta": "Account blocked",
        "Bloki": "Blocks",
        "Blokowanie użytkowników": "Block users",
        "Blokowanie użytkowników szkoleń": "Block course users",
        "Błąd 404. Nie znaleziono żądanego zasobu.": "Error 404. The requested resource was not found.",
        "Błędne": "Incorrect",
        "Błędnie określona liczba, sprawdź liczbę opublikowanych materiałów.": "Incorrect number, check the number of published materials.",
        "Brak": "Lack",
        "Brak aktywnego filtru": "No active filter",
        "Brak dodanych plików": "No files added",
        "Brak dostępu do szkoleń": "No access to the course",
        "Brak działu nadrzędnego": "No parent section",
        "Brak kategorii": "No categories",
        "Brak kategorii nadrzędnej": "No parent category",
        "Brak kluczy": "No keys",
        "Brak kolekcji": "No collection",
        "Brak modułu": "No module",
        "Brak nowych powiadomień.": "No new notifications.",
        "Brak odpowiedzi": "No response",
        "Brak ograniczenia": "No limit",
        "Brak plików do dodania": "No files to add",
        "Brak powiązania ze szkoleniem": "No link with course",
        "Brak powiązań": "No connection",
        "Brak stron": "No pages",
        "Brak strony nadrzędnej": "No parent page",
        "Brak tematu": "No topic",
        "Brak typu zadania": "No task type",
        "Brak wersji": "No version",
        "Brak wyboru": "No choice",
        "brak wydziału": "no department",
        "Brak wyników": "No results",
        "Brak zapisanych filtrów.": "No saved filters.",
        "Brak zdefiniowanych ról.": "No defined roles.",
        "Brak zdefiniowanych zespołów.": "No defined teams.",
        "Brakujące tytuły kafelków.": "Missing tile titles.",
        "Bramka SMS": "SMS gateway",
        "Certyfikat jest opublikowany": "The certificate is published",
        "Certyfikat nie jest opublikowany": "Certificate is not published",
        "Certyfikat przygotowany jest do formatu A4. Wyświetlany podgląd stanowi jedynie widok roboczy. Docelowy wygląd certyfikatu będzie powiązany z właściwymi danymi szkolenia oraz użytkownika.": "The certificate is prepared in A4 format. The preview displayed is only a draft. The desired appearance of the certificate will be associated with the relevant course and user data.",
        "Certyfikat został dodany.": "The certificate has been added.",
        "Certyfikat został usunięty.": "The certificate has been removed.",
        "Certyfikat został zaktualizowany.": "The certificate has been updated.",
        "Certyfikaty": "Certificates",
        "Chat": "Chat",
        "Chcesz przywrócić temat wraz z elementami?": "Want to restore the theme with components?",
        "Chcesz przywrócić ten element?": "Want to restore this component?",
        "Checkbox": "Checkbox",
        "Choices": "Choices",
        "Ciemny": "",
        "co": "every",
        "Co 10 dni": "Every 10 days",
        "Co 10 minut": "Every 10 minutes",
        "Co 12 godzin": "Every 12 hours",
        "Co 15 minut": "Every 15 minutes",
        "Co 2 godziny": "Every 2 hours",
        "Co 2 minuty": "Every 2 minutes",
        "Co 2 tygodnie": "Every 2 weeks",
        "Co 20 minut": "Every 20 minutes",
        "Co 3 godziny": "Every 3 hours",
        "co 3 miesiące": "every 3 months",
        "Co 3 miesiące": "Every 3 months",
        "co 30 dni": "every 30 days",
        "Co 30 minut": "Every 30 minutes",
        "Co 4 miesiące": "Every 4 months",
        "Co 5 dni": "Every 5 days",
        "Co 5 minut": "Every 5 minutes",
        "Co 6 godzin": "Every 6 hours",
        "co 6 miesięcy": "every 6 months",
        "Co drugi dzień": "Every other day",
        "Co drugi miesiąc": "Every other month",
        "Co godzinę": "Every hour",
        "Co miesiąc": "Every month",
        "Co minutę": "Every minute",
        "Co pół roku": "Every half year",
        "co rok": "every year",
        "Co rok": "Every year",
        "Co tydzień": "Every week",
        "Codziennie": "Every day",
        "Coś poszło nie tak": "Something went wrong",
        "Created": "Created",
        "Cron": "Cron",
        "Cyfry": "Digits",
        "Cytat": "Quote",
        "Cytaty": "Quotes",
        "Czarny": "Black",
        "Czas": "Time",
        "Czas (minuty)": "Time (minutes)",
        "Czas na slajd": "Time per slide",
        "Czas pomiędzy": "Time between",
        "Czas rozpoczęcia": "Start time",
        "Czas trwania blokady": "Account blocking time",
        "Czas uruchomienia": "Start time",
        "Czas zakończenia": "End time",
        "czerwcu": "June",
        "Czerwiec": "June",
        "Czerwony": "Red",
        "Częstotliwość odświeżania": "Refresh rate",
        "Członkowie zespołu": "Team members",
        "Cztery kolumny": "Four columns",
        "Czwartek": "Thursday",
        "Czy na pewno chcesz ukryć wiadomość?": "Are you sure you want to hide the message?",
        "Czy na pewno chcesz wyczyścić pamięć podręczną?": "Are you sure you want to clear the cache?",
        "Czy na pewno chcesz zablokować użytkownika?": "Are you sure you want to block a user?",
        "Czy na pewno chcesz zkopiować szkolenie?": "Are you sure you want to copy the course?",
        "Czy opublikowany": "Whether published",
        "Czy zablokować użytkownika i jego wiadomość?": "Do you want to block the user and messages?",
        "Czytać mogą tylko zalogowani użytkownicy": "Only logged-in users can read it",
        "Dalej": "Next",
        "Dane": "Data",
        "Dane formularza są przechowywane w obiekcie Entry.": "The form data is stored in the Entry object.",
        "Dane osobowe": "Personal data",
        "Dashboard": "Dashboard",
        "Dashboard szkolenia": "Course dashboard",
        "Data": "Date",
        "Data akceptacji": "Acceptance date",
        "Data automatycznej akceptacji": "Auto acceptance date",
        "Data dezaktywacji": "Deactivation date",
        "Data do": "Date to",
        "Data dodania": "Adding date",
        "Data i czas": "Date and time",
        "Data informująca o terminie wejścia w życie tego regulaminu.": "The date informing about date of entry into force of these regulations.",
        "Data końcowa": "End date",
        "Data logowania": "Login date",
        "Data modyfikacji": "Modification date",
        "Data modyfikacji:": "Modification date:",
        "Data od": "Date from",
        "Data odrzucenia": "Rejection date",
        "Data odrzucenia:": "Rejection date:",
        "Data ogłoszenia do": "",
        "Data ogłoszenia od": "",
        "Data opuszczenia zespołu": "Date of leaving the team",
        "Data publikacji": "Publication date",
        "Data publikacji:": "Publication date:",
        "Data rejestracji": "Registration date",
        "Data rozpoczęcia": "Start date",
        "Data uruchomienia": "Start time",
        "Data usunięcia": "Deletion date",
        "Data utworzenia": "Creation date",
        "Data utworzenia aranżacji": "Arrangement creation date",
        "Data utworzenia wersji": "Version creation date",
        "Data użycia": "Use date",
        "Data wydania": "Release date",
        "Data wygaśnięcia": "Expiration date",
        "Data wylogowania": "Logout date",
        "Data zakończenia": "End date",
        "Data zakończenia blokady konta": "Account block end date",
        "Data zalogowania": "Login date",
        "Data zdarzenia": "Event date",
        "Data zgłoszenia": "Notification date",
        "Daty dostępów dla użytkowników ze ścieżki szkoleniowej są nadrzędne, daty dostępów dodane w szkoleniu nie będą brane pod uwagę.": "Access dates for users in the learning path are parent, access dates added in the course will not be taken into account.",
        "Daty publikacji zostały zaktualizowane.": "The publication dates have been updated.",
        "Dedykowane": "",
        "Definicja": "Definition",
        "Definicja formularza znajduje się w obiekcie Form.": "The form definition is in the Form object.",
        "Definicja szablonu": "Template definition",
        "Definicje formularzy": "Form definitions",
        "Designer": "Designer",
        "Designer Studio": "",
        "Długi teskt": "Long text",
        "Długość": "Length",
        "Długość treści": "Content length",
        "Długość trwania sesji [min": "Session duration [min",
        "Dni": "Days",
        "do": "to",
        "Do": "To",
        "Do akceptacji": "To be accepted",
        "do czasu ręcznego odblokowania": "until manually unlocked",
        "Do poziomu": "To level",
        "Do szkolenia nie przypisano jeszcze żadnych użytkowników.": "No assigned users to the course.",
        "Do szkolenia nie przypisano jeszcze żadnych zespołów.": "No assigned teams to the course.",
        "Do ścieżki szkoleniowej nie przypisano jeszcze żadnych użytkowników.": "No assigned users to the learning path.",
        "Do ścieżki szkoleniowej nie przypisano jeszcze żadnych zespołów.": "No assigned teams to the learning path.",
        "Do testu nie można dodawać materiałów opisowych": "It is not possible to enhance the test with descriptive materials",
        "Dobrze": "Good",
        "Docelowa strona": "Target page",
        "Docs": "Docs",
        "Dodaj": "Add",
        "Dodaj autora": "",
        "Dodaj certyfikat": "Add certificate",
        "Dodaj dni": "Add days",
        "Dodaj do zespołu": "Add to team",
        "Dodaj do zestawu": "Add to set",
        "Dodaj dokument": "Add document",
        "Dodaj dział": "Add section",
        "Dodaj element": "Add element",
        "Dodaj etykietę": "Add tag",
        "Dodaj formularz": "Add form",
        "Dodaj galerię": "",
        "Dodaj jeden lub więcej widgetów, aby uzyskać wgląd w postępy swojego zespołu.": "Add one or more widgets to keep track of your team's progress.",
        "Dodaj kafelek": "Add tile",
        "Dodaj katalog": "Add directory",
        "Dodaj kategorie": "Add category",
        "Dodaj kategorię": "Add category",
        "Dodaj klienta": "Add client",
        "Dodaj kolejne słowo": "Add another word",
        "Dodaj kolejny powód": "Add another reason",
        "Dodaj komponent": "",
        "Dodaj lekcję": "Add lesson",
        "Dodaj lekcję SCORM": "Add SCORM lesson",
        "Dodaj lekcję Wideo": "Add Video lesson",
        "Dodaj link": "Add link",
        "Dodaj nową opinie": "Add new review",
        "Dodaj nową witrynę aby rozpocząć zarządzanie stronami.": "Add a new website to start managing your pages.",
        "Dodaj nowy": "Add new",
        "Dodaj nowy katalog aby rozpocząć zarządzanie stronami.": "Add a new directory to start managing your pages.",
        "Dodaj nowy tag": "Add new tag",
        "Dodaj obrazek": "Add image",
        "Dodaj odpowiedź": "Add answer",
        "Dodaj paczkę SCORM": "Add SCORM package",
        "Dodaj plik": "Add file",
        "Dodaj pytanie": "Add question",
        "Dodaj rolę": "Add role",
        "Dodaj serwer": "Add server",
        "Dodaj shortcode": "Add shortcode",
        "Dodaj slajd": "Add slide",
        "Dodaj slajder": "Add slider",
        "Dodaj słowo": "Add word",
        "Dodaj stronę": "Add page",
        "Dodaj stwierdzenie": "Add statement",
        "Dodaj synonimy": "Add synonyms",
        "Dodaj szablon": "Add template",
        "Dodaj szkolenie": "Add course",
        "Dodaj ścieżkę": "Add path",
        "Dodaj temat": "Add topic",
        "Dodaj temat/link": "Add topic/link",
        "Dodaj test": "Add test",
        "Dodaj token": "Add token",
        "Dodaj użytkownika": "Add user",
        "Dodaj widget": "Add widget",
        "Dodaj wiersz": "Add row",
        "Dodaj witrynę": "Add website",
        "Dodaj wpis": "Add entry",
        "Dodaj wszystkie z kategorii": "Add all categories",
        "Dodaj zastępstwo": "Add replacement",
        "Dodaj zespół": "Add team",
        "Dodaj zestaw": "Add set",
        "Dodaj źródło": "Add source",
        "dodane i edytowane przeze mnie": "added and edited by me",
        "Dodane pliki z sortowaniem": "Added files with sorting",
        "Dodane przez": "Added by",
        "Dodane przez moje zespoły": "Added by my teams",
        "Dodane przeze mnie": "Added by me",
        "Dodanie": "Adding",
        "Dodanie aktualności": "Add news",
        "Dodanie ankiety": "Add surveys",
        "Dodanie artykułu": "Add article",
        "Dodanie autora": "",
        "Dodanie certyfikatu": "Add certificate",
        "Dodanie dokumentu": "Add document",
        "Dodanie działu": "Add section",
        "Dodanie etykiety": "Add tag",
        "Dodanie formularza": "Add form",
        "Dodanie galerii": "",
        "Dodanie kategorii": "Add category",
        "Dodanie klienta": "Add client",
        "Dodanie komponentu": "",
        "Dodanie lekcji": "Add lesson",
        "Dodanie linku": "Add link",
        "Dodanie paczki SCORM": "Add SCORM package",
        "Dodanie poradnika": "Add guide",
        "Dodanie regulaminu": "Add regulations",
        "Dodanie roli": "Add role",
        "Dodanie serwera": "Add server",
        "Dodanie slajdera": "Add slider",
        "Dodanie slajdów": "Add slides",
        "Dodanie strony": "Add page",
        "Dodanie szablonu": "Add template",
        "Dodanie szkolenia": "Add course",
        "Dodanie ścieżki szkoleniowej": "Add learning path",
        "Dodanie testu": "Add test",
        "Dodanie użytkownika": "Add user",
        "Dodanie witryny": "Add website",
        "Dodanie zastępstwa": "Add replacement",
        "Dodanie zespołu": "Add team",
        "Dodanie zestawu regulaminów": "Add set of regulations",
        "Dodanie źródła": "Add source",
        "Dodanie źródła danych": "Add data source",
        "Dodanych plików %0 (limit wynosi %1).": "Added files %0 (limit is %1).",
        "Dodatki do e-learningu": "E-learning extras",
        "Dodatkowe uwagi": "Additional notes",
        "Dodawanie": "Adding",
        "Dokument": "Document",
        "Dokument %0": "Document %0",
        "Dokument nie jest jeszcze nigdzie opublikowany.": "The document has yet not been published anywhere.",
        "Dokument nie ma ustawionej daty publikacji.": "The document does not have a publication date set.",
        "Dokument został dodany.": "Document has been added.",
        "Dokument został usunięty.": "The document has been deleted.",
        "Dokument został zaktualizowany.": "The document has been updated.",
        "Dokumenty": "Documents",
        "Dołącz stronę do pliku sitemap.xml": "Add the page to the sitemap file.xml",
        "Domena": "Domain",
        "Domyślna": "Default",
        "Domyślna wartość": "Default value",
        "Domyślna witryna": "Default site",
        "Domyślne ustawienie dla witryny": "Default site setting",
        "Domyślnie ukryte": "Hidden by default",
        "Domyślnie widoczne": "Visible by default",
        "Domyślny": "Default",
        "Domyślny tytuł aktywnej strony": "Default title of the active page",
        "Dopasowanie": "Match",
        "Dopasuj do kategorii": "Match to category",
        "Dopasuj do obrazka": "Match image",
        "Dopuść brak odpowiedzi": "Allow lack of response",
        "Dostęp do": "Access to",
        "Dostęp niezdefiniowany": "Undefined access",
        "Dostęp od": "Access from",
        "Dostęp przyznany": "Access granted",
        "Dostęp przyznany przez zespół": "Team-granted access",
        "Dostęp przyznany ręcznie": "Manually-granted access",
        "Dostęp zabroniony": "Access denied",
        "Dostępna jest nowa aktualizacja. Proszę zapisać postęp pracy i klinąć przycisk \"Aktualizuj\". Możesz również zaakceptować aktualizację poprzez odświeżenie okna przeglądarki. Brak aktualizacji może spowodować błędy.": "A new update is available. Please save the work progress and click the \"Update\" button. You can also accept the update by refreshing the browser. Noo update may result in errors.",
        "Dostępne bloki": "Available blocks",
        "Dostępne dla": "Available for",
        "Dostępne języki": "Available languages",
        "Dostępne moduły": "Available modules",
        "Dostępne wersje językowe.": "Available language versions.",
        "Dostępny w sekcjach": "Available in sections",
        "Dostępy": "Available",
        "Dowolny": "Any",
        "Dozwolone rozszerzenia": "Allowed extensions",
        "Dozwolone rozszerzenia: %0.": "Allowed extensions: %0.",
        "Dozwolone typy plików: %0.": "Allowed file types: %0.",
        "Drugie imię": "Middle name",
        "Drugorzędny": "",
        "Dwie kolumny": "Two columns",
        "Dział aktywny": "Active section",
        "Dział nadrzędny": "Parent section",
        "Dział został dodany.": "The section has been added.",
        "Dział został zaktualizowany.": "The section has been updated.",
        "Działania masowe": "Mass activities",
        "Dzielenie": "Division",
        "Dzienny": "Daily",
        "dzień": "day",
        "Dzień": "Day",
        "dzień (dni)": "day(s)",
        "Dzień miesiąca": "Day of the month",
        "E-learning": "E-Learning",
        "E-mail": "E-mail",
        "Edycja": "Edit",
        "Edycja aktualności": "Edit news",
        "Edycja ankiety": "Edit survey",
        "Edycja aranżacji": "Edit arrangement",
        "Edycja artykułu": "Edit article",
        "Edycja autora": "",
        "Edycja certyfikatu": "Edit certificate",
        "Edycja dokumentu": "Edit document",
        "Edycja działu": "Edit section",
        "Edycja etykiety": "Edit label",
        "Edycja formularza": "Edit form",
        "Edycja galerii": "",
        "Edycja kategorii": "Edit category",
        "Edycja klienta": "Edit client",
        "Edycja komentarza": "Edit comment",
        "Edycja komponentu": "",
        "Edycja lekcji": "Editing lesson",
        "Edycja linku": "Edit link",
        "Edycja paczek SCORM": "Edit SCORM packages",
        "Edycja poradnika": "Edit guide",
        "Edycja regulaminu": "Edit regulations",
        "Edycja roli": "Edit role",
        "Edycja serwera": "Edit server",
        "Edycja slajdera": "Edit slider",
        "Edycja slajdów": "Edit slides",
        "Edycja strony": "Edit page",
        "Edycja szablonu": "Edit template",
        "Edycja szkolenia": "Edit course",
        "Edycja ścieżki szkoleniowej": "Edit learning path",
        "Edycja testu": "Edit test",
        "Edycja ustawień": "Edit settings",
        "Edycja użytkownika": "Edit user",
        "Edycja witryny": "Edit site",
        "Edycja zastępstwa": "Edit substitution",
        "Edycja zespołu": "Edit team",
        "Edycja zestawu regulaminów": "Editi set of regulations",
        "Edycja źródła": "Edit source",
        "Edycja źródła danych": "Edit data source",
        "Edytor": "Editor",
        "Edytowana wersja": "Edited version",
        "Edytowane mogą być tylko szablony włączonych i obsługiwanych kanałów.": "Only templates for enabled and supported channels can be edited.",
        "Edytuj": "Edit",
        "Edytuj aranżację": "Edit arrangement",
        "Edytuj blok": "Edit block",
        "Edytuj członka grupy": "Edit group member",
        "Edytuj datę dostępu": "Edit access date",
        "Edytuj katalog": "Edit directory",
        "Edytuj kategorie": "Edit category",
        "Edytuj kategorię": "Edit category",
        "Edytuj komentarz": "Edit comment",
        "Edytuj lekcje": "Edit lessons",
        "Edytuj lekcje SCORM": "Edit SCORM lessons",
        "Edytuj lekcje wideo": "Edit video lesson",
        "Edytuj slajder": "Edit slider",
        "Edytuj stronę": "Edit page",
        "Edytuj szablon": "Edit template",
        "Edytuj tag": "Edit tag",
        "Edytuj temat": "Edit topic",
        "Edytuj test": "Edit test",
        "Edytuj uprawnienia": "Edit permissions",
        "Edytuj wpis": "Edit post",
        "Edytuj zestaw": "Edit set",
        "Eksport użytkowników": "User export",
        "Element": "Element",
        "Element grupujący": "Grouping element",
        "Element nadrzędny": "Parent component",
        "element niewymagany": "component not required",
        "Element opublikowany": "Published element",
        "Element szkolenia rozpoczęty przez użytkowników": "Course component started by users",
        "Element szkolenia ukończony przez użytkowników": "Course component completed by users",
        "element wymagany": "component required",
        "Element wymagany": "Component required",
        "Element został dodany.": "The element has been added.",
        "Element został przywrócony.": "The element has been restored.",
        "Element został usunięty.": "The element has been deleted.",
        "Element został zaktualizowany.": "The element has been updated.",
        "Elementy": "Elements",
        "Email": "E-mail",
        "Etykieta": "Tag",
        "Etykiety": "Tags",
        "Exportuj do Excela": "Export to Excel",
        "Facebook Admins": "Facebook Admins",
        "Facebook Application ID": "Facebook Application ID",
        "FavIcona": "Favicon",
        "Film": "Film",
        "Filtr dla synchronizacji działów": "Filter for synchronising departments",
        "Filtr dla synchronizacji użytkowników": "Filter for synchronising users",
        "Filtr globalny": "Global filter",
        "Filtr o podanej nazwie już istnieje.": "The filter with a given name already exists.",
        "Filtr prywatny": "Private filter",
        "Filtr został usunięty": "Filter has been removed",
        "Filtr został zapisany": "Filter has been saved",
        "Filtrowanie": "Filtering",
        "Filtruj": "Filter",
        "Filtry": "Filters",
        "Filtry globalne": "Global filters",
        "Filtry systemowe": "System filters",
        "Folder został usunięty.": "The folder has been deleted.",
        "Formularz": "Modules / Contact form",
        "Formularz bazuje na nieaktualnej wersji definicji.": "An outdated version of the definition is used in the form.",
        "Formularz nie został wypełniony poprawnie.": "",
        "Formularz zarchiwizowany": "Archived form",
        "Formularz został dodany.": "The form has been added.",
        "Formularz został zaktualizowany.": "The form has been updated.",
        "Galeria": "",
        "Galeria nie jest jeszcze nigdzie opublikowana.": "",
        "Galeria nie ma ustawionej daty publikacji.": "",
        "Galeria zdjęć": "Photo gallery",
        "Galeria została dodana.": "",
        "Galeria została usunięta.": "",
        "Galeria została zaktualizowana.": "",
        "Generuj treść slajdu": "Generate slide content",
        "Godziny": "Hours",
        "Google Nexus 10": "Google Nexus 10",
        "Google Nexus 5": "Google Nexus 5",
        "Google Nexus 7": "Google Nexus 7",
        "grudniu": "December",
        "Grudzień": "December",
        "Grupa": "Group",
        "Harmonogram zadań": "Task scheduler",
        "Harmonogram został zmieniony": "Schedule has been changed",
        "Hasło": "Password",
        "Hasło (Klucz ukryty)": "Password (Hidden Key)",
        "Hasło musi zawierać co najmniej jeden znak specjalny np.: ~ @ # $ % ^ & *": "The password must contain at least one special character, e.g.: ~ @ $ % ^ & *",
        "Hasło użytkownika serwisowego": "Maintenace user password",
        "Hasło zostało zmienione. Zaloguj się ponownie": "The password has been changed. Sign in again",
        "Health": "Health",
        "Hierarchia": "Hierarchy",
        "Historia": "History",
        "Historia logowania": "Login history",
        "Historia wygenerowanych treści.": "Generated content hiistory.",
        "Historia zadania": "Task history",
        "Host": "Host",
        "Host dodatkowy": "Secondary host",
        "Host główny": "Main host",
        "i posiadających wymagane uprawnienia": "and having the required permissions",
        "Id": "Id",
        "ID": "ID",
        "ID lub typ zadania": "Task ID or type",
        "Id modelu: %0": "Model Id: %0",
        "ID obiektu": "Object ID",
        "Id sesji": "Session Id",
        "Id slajdu": "Slide id",
        "ID zadania": "Task ID",
        "Id zdarzenia": "Event Id",
        "Identity": "Identity",
        "Identyfikator odpowiedzi.": "Answer ID.",
        "Identyfikator slajdu": "Slide ID",
        "Identyfikator zadania": "Task identificator",
        "Identyfikator zapytania": "Inquiry ID",
        "Ikona": "Icon",
        "Ikona wyświetlana jest w postaci kwadratu.": "The icon is displayed in the form of a square.",
        "Liczba dostępnych bloków": "Number of available blocks",
        "Liczba elementów": "Number of components",
        "Liczba elementów do uzupełnienia": "Number of components to be completed",
        "Liczba kategorii": "Number of categories",
        "Liczba odpowiedzi": "Number of answers",
        "Liczba pytań": "Number of questions",
        "Liczba wierszy": "Number of rows",
        "Liczba wyników do odrzucenia": "Number of results to be rejected",
        "Liczba wyników do pobrania": "Number of results to download",
        "Liczba wyświetlanych aktualności": "Number of news updates displayed",
        "Liczba znaków": "Number of characters",
        "Imię": "First name",
        "Imię i Nazwisko": "First name and last name",
        "Imię i nazwisko/nazwa autora": "",
        "Import slajdów z PDF": "PDF slides import",
        "Import użytkowników": "User import",
        "Importuj": "Import",
        "Importuj slajdy": "Import slides",
        "Indeks": "Index",
        "Indeks został usunięty.": "The index has been deleted.",
        "Indeksowanie strony": "Page indexing",
        "Indywidualne tokeny": "Individual tokens",
        "Informacja": "",
        "Informacja o błędzie": "Error information",
        "Informacja o ciasteczkach": "Information about cookies",
        "Inspekcja": "Inspection",
        "Instalacja": "Installation",
        "ISBN": "ISBN",
        "Jasny": "",
        "Jasny niebieski": "Light blue",
        "Jedna kolumna": "One column",
        "Jednokrotnego wyboru": "Single choice",
        "Jeszcze nie ma kategorii.": "There are no categories yet.",
        "Jeśli pole jest puste to wstawiana automatycznie.": "If the field is empty, the value is inserted automatically.",
        "Jeśli pole jest puste, wówczas używana jest wartość domyślna. W przeciwnym wypadku wartość domyślna jest nadpisywana.": "If the field is left blank, then the default value will be used. If not, the default value will be added.",
        "Język": "Language",
        "Język domyślny": "Default language",
        "Kalendarz": "Calendar",
        "Kalendarz - Kategorie zasobów": "Calendar - Resource Categories",
        "Kanał jest domyślnie wymagany. Nie da się wyłączyć wysyłania powiadomienia tym kanałem. Możliwa jest tylko zmiana tytułu i treści komunikatu.": "The channel is required by default. It is not possible to stop sending a notification through this channel. It is only possible to change the title and content of the message.",
        "kanał wymagany": "required channel",
        "Kanały komunikacji": "Communication channels",
        "Kanały obsługiwane przez wybrany typ powiadmienia": "Channels supported by the selected notification type",
        "Kanały włączone w ustawieniach aplikacji": "Channels enabled in app settings",
        "Katalog": "Directory",
        "Katalog główny": "Main directory",
        "Katalog linków": "Links directory",
        "Katalog został utworzony.": "The directory has been created.",
        "Kategoria": "Category",
        "Kategoria jest wymagana": "Category is required",
        "Kategoria linku": "Link category",
        "Kategoria materiałów": "Material category",
        "Kategoria materiału": "Material category",
        "Kategoria nadrzędna": "Parent category",
        "Kategoria slajdu": "Slide category",
        "Kategoria szkolenia": "Course category",
        "Kategoria wydarzenia": "Event category",
        "Kategoria wyszukiwanego materiału": "Category of the searched material",
        "Kategoria została dodana.": "The category has been added.",
        "Kategoria została usunięta.": "The category has been removed.",
        "Kategoria została zaktualizowana.": "The category has been updated.",
        "Kategorie": "Categories",
        "Kategorie linków": "Link categories",
        "Kategorie materiałów": "Material categories",
        "Kategorie szkoleń": "Course categories",
        "Kategorie wydarzeń": "Event categories",
        "Kategorię można opublikować tylko na jednej stronie.": "A category may only be published on one page.",
        "Klienci": "Clients",
        "Klient aktywny": "Active client",
        "Klient został dodany.": "The client has been added.",
        "Klient został usunięty.": "The client has been deleted.",
        "Klient został zaktualizowany.": "The client has been updated.",
        "Klucz": "",
        "Klucz API": "API key",
        "Klucz prywatny": "Private key",
        "Klucz publiczny": "Public key",
        "Klucze": "Keys",
        "Klucze są nie prawidłowe": "Keys are not valid",
        "Klucze są prawidłowe.": "Keys are valid.",
        "Kobieta": "Woman",
        "Kod bannera (tutaj wklej kod HTML i JS)": "",
        "Kod Bing Webmaster": "Bing Webmaster Code",
        "Kod Google Webmaster": "Google Webmaster Code",
        "Kod HTML": "HTML code",
        "Kod pocztowy": "Postal code",
        "Kod wertfikacyjny": "Verification code",
        "Kod weryfikacyjny jest nieprawidłowy.": "The verification code is invalid.",
        "Kod źródłowy": "",
        "Kolejka": "Queue",
        "Kolejne pozycje z lewej strony": "Next items on the left",
        "Kolejne pozycje z prawej strony": "Next items on the right",
        "Kolekcje dokumentów": "Document collections",
        "Kolor": "Colour",
        "Kolor drugorzędny: %0": "Secondary colour: %0",
        "Kolor icon na panelu z aplikacjami: %0": "",
        "Kolor podstawowy: %0": "Primary colour: %0",
        "Kolor tekstu aktywnej pozycji menu: %0": "The text color of the active menu component: %0",
        "Kolor tła": "Background color",
        "Kolor tła panelu z aplikacjami: %0": "",
        "Kolory zostały przywrócone.": "Colors have been restored.",
        "Kolory zostały zapisane.": "Colors have been saved.",
        "Komentarz %0": "Comment %0",
        "Komentarze": "Comments",
        "Komponent został dodany.": "",
        "Komponent został usunięty.": "",
        "Komponent został zaktualizowany.": "",
        "Komponenty": "",
        "Komunikat": "Message",
        "Komunikat błędu": "Error message",
        "Komunikat mailowy": "E-mail message",
        "Komunikat walidacji": "Validation message",
        "Konfiguracja": "Settings",
        "Konfiguracja aranżacji jest niedostępna ponieważ nie podano definicji szablonu.": "The arrangement configuration is not available because no template definition is provided.",
        "Konfiguracja bloków jest niedostępna ponieważ nie podano definicji szablonu.": "Block configuration is not available because no template definition is provided.",
        "Konfiguracja bloku": "Block configuration",
        "Konfiguracja bloku została zapisana.": "The block configuration has been saved.",
        "Konfiguracja domyślna": "Default configuration",
        "Konfiguracja harmonogramu": "Schedule configuration",
        "Konfiguracja sekcji jest niedostępna ponieważ nie podano definicji szablonu.": "Section configuration is not available because no template definition is provided.",
        "Konfiguruj stronę": "Configure page",
        "Koniec po": "End after",
        "Konta użytkowników": "User accounts",
        "Konta użytkowników zostały ${message}": "User accounts have been ${message}",
        "Konta użytkowników zostały przypisane do zespołu": "User accounts have been assigned to the team",
        "Konta użytkowników zostały usunięte z systemu": "User accounts have been deleted from the system",
        "Kontakt": "Contact",
        "Kontakty - Typ działania": "Contacts - Activity Type",
        "Kontener plików": "File container",
        "Konto aktywne": "Active account",
        "Konto pozostanie nieaktywne dopóki adres e-mail nie zostanie potwierdzony": "The account will remain inactive until the email address is confirmed",
        "Konto publiczne": "Public account",
        "Kontrolka wyboru (pojedynczy wybór)": "Choice control (single choice)",
        "Kontrolka wyboru (wybór wielokrotny)": "Choice control (multiple choice)",
        "Koordynator": "Coordinator",
        "Koordynatorów": "Coordinators",
        "Koordynowane szkolenia:": "Coordinated courses:",
        "Kopiuj": "Copy",
        "Kopiuj filtr": "Copy filter",
        "Kosz": "Recycle bin",
        "Kraj": "Country",
        "Kroki": "Steps",
        "Krótki opis": "Short description",
        "Krótki tekst": "Short text",
        "Krótki tytuł": "Short title",
        "Książka": "Book",
        "Kwiecień": "April",
        "kwietniu": "April",
        "Laptop 15,6\"": "Laptop 15.6\"",
        "Laptop 15\"": "15\" laptop",
        "Laptop 17\"": "17\" laptop",
        "LDAPS (LDAP over SSL)": "LDAPS (LDAP over SSL)",
        "Legenda": "Key",
        "Lekcja": "Lesson",
        "Lekcja może zawierać tylko materiały opisowe lub materiały video": "The lesson can contain only descriptive or video materials",
        "Lekcja SCORM ukryta": "SCORM Lesson Hidden",
        "Lekcja SCORM została dodana.": "The SCORM lesson has been added.",
        "Lekcja SCORM została zaktualizowana.": "The SCORM lesson has been updated.",
        "Lekcja ukryta": "Hidden lesson",
        "Lekcja wideo została dodana.": "The video lesson has been added.",
        "Lekcja wideo została zaktualizowana.": "The video lesson has been updated.",
        "Lekcja została dodana.": "The lesson has been added.",
        "Lekcja została zaktualizowana.": "The lesson has been updated.",
        "Lewy panel": "Left panel",
        "Liczba": "Number",
        "Liczba całkowita": "Integer",
        "Liczba dni co ile wysyłane jest powiadomienie": "Number of days of how often a notification is sent",
        "Liczba dni do zakończenia szkolenia przy której zostanie wysłane powiadomienie": "Number of days until the end of the course at which the notification will be sent",
        "Liczba dni od jakiej użytkownik otrzyma powiadomienie": "Number of days from which the user will receive a notification",
        "Liczba dziesiętna": "Decimal",
        "Liczba koordynowanych szkoleń: %0": "Number of coordinated courses: %0",
        "Liczba opublikowanych elementów (zakładka Zawartość) musi być większa lub równa ustawionej liczbie wyświetlanych elementów (zakładka Ustawienia)": "Number of published components (Content tab) must be bigger than or equal to the set number of components displayed (Settings tab)",
        "Liczba podejść": "Number of approaches",
        "Liczba slajdów widoczna dla użytkownika nie może być większa od liczby slajdów dodanych w panelu.": "The number of slides visible to the user cannot be greater than the number of slides added in the panel.",
        "Liczba szkoleń": "Number of courses",
        "Liczba szkoleń w ścieżce": "Number of courses in the path",
        "Liczba wydarzeń": "Number of events",
        "Liczba wyników na stronie": "Number of results on the page",
        "Liczba wyświetlanych elementów": "Number of elements displayed",
        "Liczba wyświetlanych najnowszych elementów": "Number of recent elements displayed",
        "Liczba wyświetlanych slajdów": "Number of slides displayed",
        "Liczba zgłoszeń:": "Number of requests:",
        "Lider": "Leader",
        "Like do komentarzy mogą dodawać tylko zalogowani użytkownicy": "Like to comments can only be added by logged-in users",
        "Limit czasu (minuty)": "Time limit (minutes)",
        "Limit zgłoszeń": "Request limit",
        "Limit znaków dla niezalogowanych": "Character limit for not logged in users is required",
        "Limit znaków dla zalogowanych": "Character limit for logged-in users is required",
        "Limity": "Limits",
        "Linia": "Line",
        "linie": "lines",
        "Link": "Link",
        "Link do strony": "Website link",
        "Link do wybranej strony serwisu": "Link to the selected page of the website",
        "Link do zewnętrznej strony": "Link to external site",
        "Link przycisku": "Button link",
        "Link został dodany.": "Link has been added.",
        "Link został usunięty.": "The link has been removed.",
        "Link został zaktualizowany.": "The link has been updated.",
        "lipcu": "July",
        "Lipiec": "July",
        "Lista": "List ",
        "Lista artykułów": "List of articles",
        "Lista certyfikatów": "List of certificates",
        "Lista dostępnych zmiennych, które można wykorzystać we wszystkich polach tekstowych:": "List of available variables that can be used in all text fields:",
        "Lista efektywnych uprawnień użytkownika wynikających z uprawnień indywidualnych oraz ról.": "List of effective user permissions resulting from individual permissions and roles.",
        "Lista indeksów": "Index list",
        "Lista jest pusta": "The list is empty",
        "Lista komentarzy": "Comments list",
        "Lista koordynatorów": "Coordinators list",
        "Lista linków": "Links list",
        "Lista możliwości zakończenia czatu. W obecnym stanie model zwraca jedną możliwą odpowiedź.": "A list of ways to end the chat. In its current status, the model returns one possible answer.",
        "Lista paczek SCORM": "SCORM Packages List",
        "Lista plików": "File list",
        "Lista regulaminów": "Regulation list",
        "Lista rozwijana": "Drop-down list",
        "Lista szkoleń": "Course list",
        "Lista ścieżek szkoleniowych": "Learning path list",
        "Lista wiadomości (prompt) składających się na dotychczasową konwersację z modelem.": "A list of the messages (prompts) that have made up the conversation with the model so far.",
        "Lista wyboru": "Selection list",
        "Lista wydarzeń": "Events list",
        "Lista zespołów": "Teams list",
        "Listopad": "November",
        "listopadzie": "November",
        "Litery": "Letters",
        "Litery i cyfry": "Letters and numbers",
        "Log błędu": "Error log",
        "Log z aktywności": "Activity log",
        "Logi aktywności zostały wyczyszczone": "Activity logs cleared",
        "Login": "Login",
        "Logo": "Logo",
        "Logowanie": "Log in",
        "Logowanie zakończyło się niepowodzeniem.": "Login failed.",
        "Losowa kolejność elementów": "Random order of elements",
        "Luty": "February",
        "lutym": "February",
        "Łączenie treści": "Combining content",
        "Maj": "May",
        "maju": "May",
        "Maksimum": "Maximum",
        "Maksymalna liczba plików": "Maximum number of files",
        "Maksymalna liczba tokenów": "Maximum number of tokens",
        "Maksymalny rozmiar pliku w MB": "Maximum file size in MB",
        "Maksymalny rozmiar pliku: %0MB.": "Maximum file size: %0MB.",
        "Małe znaki": "Small characters",
        "Mappingi": "Mappings",
        "marcu": "March",
        "Marketing": "Marketing",
        "Marzec": "March",
        "Masz niezapisane zmiany w materiałach. Zapisz je lub usuń aby móc zapisać element szkolenia.": "You have made changes to the materials that are unsaved. Save or delete them to save the course component.",
        "Materiał opisowy": "Descriptive material",
        "Materiał wideo": "Video",
        "Materiały szkoleniowe": "Course materials",
        "Max tokens": "Max tokens",
        "Messages": "Messages",
        "Metadane": "Metadata",
        "Mężczyzna": "Man",
        "Miejsca po przecinku": "Decimal points",
        "Miejscowość": "Town",
        "mies.": "month",
        "miesiąc": "month",
        "Miesiąc": "Month",
        "Miesiące": "Months",
        "Miesięczny": "Monthly",
        "Migracje": "Migrations",
        "Miniaturka wideo": "Video thumbnail",
        "Miniaturki": "Thumbnails",
        "Minimalna długość hasła": "Minimum password length",
        "Minimum": "Minimum",
        "Minuty": "Minutes",
        "Mnożenie": "Multiplication",
        "Model": "Model",
        "Model generuje odpowiedź na podstawie podanych przez użytkownika parametrów.": "The model generates a response based on the parameters specified by the user.",
        "Moduł": "Module",
        "Moduły": "Modules",
        "Modyfikacja": "Modification",
        "Moje dokumenty": "My documents",
        "Moje filtry": "My filters",
        "Moje formularze": "My forms",
        "Moje konto": "My account",
        "Monitor aktywności": "Activity monitor",
        "Monitor kolejek": "Queue monitor",
        "Monitoring": "Monitoring",
        "Możliwość modyfikacji tego działu jest ograniczona. Nieaktywne pola są sychronizowane z zewnętrzną bazą danych %0.": "The possibility of modifying this section is limited. Inactive fields are synchronized with the external database %0.",
        "Możliwość modyfikacji tego konta jest ograniczona. Nieaktywne pola są sychronizowane z zewnętrzną bazą danych %0.": "The possibility to modify this account is limited. Inactive fields are synchronized with the external database %0.",
        "Możliwość modyfikacji tego konta jest ograniczona. Nieaktywne pola są synchronizowane z zewnętrzną bazą danych %0.": "This account has limited modifiability. Inactive fields are synchronized to an external database %0.",
        "Możliwość wielokrotnego wyboru": "Multiple choice",
        "Mój tytuł": "My title",
        "Multimedia": "Multimedia",
        "Na pewno usunąć wybrane elementy?": "Are you sure to delete the selected elements?",
        "Naciśnij enter aby usunąć": "Press enter to delete",
        "Naciśnij enter aby wybrać": "Press enter to select",
        "Nadawca": "Sender",
        "Nadpisz wersję": "Overwrite version",
        "Nadpisz wersję slajdu": "Overwrite slide version",
        "Nagłówek": "Header",
        "Nagłówek dokumentu": "Document header",
        "Najniższy": "Lowest",
        "Najnowsze": "Recent",
        "Najwyższy": "Highest",
        "Narzędzia": "Tools",
        "Następna strona": "Next page",
        "Nazwa": "Name",
        "Nazwa aktywnej strony": "Active page name",
        "Nazwa aliasu": "Alias name",
        "Nazwa aranżacji": "Arrangement name",
        "Nazwa bazy danych": "Database name",
        "Nazwa bloku": "Block name",
        "Nazwa certyfikatu": "Certificate name",
        "Nazwa działu": "Department name",
        "Nazwa elementu": "Item name",
        "Nazwa filtra": "Filter name",
        "Nazwa formularza": "Form name",
        "Nazwa grupy": "Group name",
        "Nazwa katalogu": "Directory name",
        "Nazwa katalogu została zmieniona.": "The directory has been renamed.",
        "Nazwa kategorii": "Category name",
        "Nazwa Kategorii": "Category Name",
        "Nazwa klienta": "Client name",
        "Nazwa kolumny z opisem": "Description column name",
        "Nazwa kolumny z wartością": "Column name with value",
        "Nazwa komponentu": "",
        "Nazwa konta (Klucz dostępu)": "Account Name (Access Key)",
        "Nazwa koordynatora": "Coordinator name",
        "Nazwa lekcji": "Lesson name",
        "Nazwa linku": "Link name",
        "Nazwa nadawcy": "Sender name",
        "Nazwa obiektu": "Object name",
        "Nazwa paczki": "Package name",
        "Nazwa paczki SCORM": "SCORM package name",
        "Nazwa pliku": "File name",
        "Nazwa pliku została zmieniona.": "The file has been renamed.",
        "Nazwa płyty": "Disc name",
        "Nazwa pokoju": "Room name",
        "Nazwa pola": "Field name",
        "Nazwa powiadomienia": "Notification name",
        "Nazwa przycisku": "Button name",
        "Nazwa przycisku ``Dalej``": "Button name ''Next''",
        "Nazwa przycisku ``Wstecz``": "Button name ''Back''",
        "Nazwa przycisku ``Wyślij``": "Button name ''Send''",
        "Nazwa roli": "Role name",
        "Nazwa sekcji": "Section name",
        "Nazwa serwera": "Server name",
        "Nazwa slajdera": "Slider name",
        "Nazwa slajdu": "Slide name",
        "Nazwa strony": "Page name",
        "Nazwa szablonu": "Template name",
        "Nazwa szkolenia": "Course name",
        "Nazwa ścieżki": "Path name",
        "Nazwa testu": "Test name",
        "Nazwa tytułu nie może być dłuższa niż 240 znaków ani krótsza niż 3 znaki.": "The title cannot contain neither more than 240 characters nor less than 3 characters.",
        "Nazwa urządzenia": "Device name",
        "Nazwa użytkownika": "User name",
        "Nazwa użytkownika serwisowego": "Maintenance user name",
        "Nazwa w panelu": "Name in panel",
        "Nazwa witryny": "Site name",
        "Nazwa wyświetlana": "Name displayed",
        "Nazwa wyświetlana:": "Name displayed:",
        "Nazwa zadania": "Task name",
        "Nazwa zespołu": "Team name",
        "Nazwa zestawu": "Set name",
        "Nazwa źródła": "Source name",
        "Nazwa źródła danych": "Data source name",
        "Nazwa:": "Name:",
        "Nazwisko": "Last name",
        "Nazwy wartości": "Value names",
        "Neutralne": "Neutral",
        "Nie": "No",
        "Nie ma jeszcze żadnych kategorii.": "No categories yet.",
        "Nie mam zdania": "I have no opinion",
        "Nie masz uprawnień do modyfikacji aranżacji.": "You do not have permission to modify the arrangement.",
        "Nie masz uprawnień do panelu administracyjnego.": "You do not have permission to access the administration panel.",
        "Nie masz uprawnień do publikacji aranżacji.": "You don't have permission to publish the arrangement.",
        "Nie masz uprawnień do tego widoku. Stan widoku mógł ulec zmianie, odśwież i sprawdź lub spróbuj ponownie później.": "",
        "Nie możesz dodać tego samego widgetu dwa razy": "You cannot add the same widget twice",
        "Nie możesz modyfikować uprawnień": "You cannot modify permissions",
        "Nie można usunąć kategorii do ktorej są przypisane elementy.": "You cannot delete a category to which the elements are assigned.",
        "Nie można usunąć swojego konta.": "You can't delete your account.",
        "Nie podano": "Not specified",
        "Nie podano nazwy poprzedniego elementu.": "No name is given for the previous element.",
        "Nie podano nazwy poprzedniej kategorii.": "No name is given for the previous category.",
        "Nie podano nazwy tytułu.": "No title name is given.",
        "Nie podano odpowiedzi dla ostatniego elementu.": "No response was given for the last element.",
        "Nie podano opisu.": "No description is given.",
        "Nie podano wymaganej ilości znaków: %0.": "The required number of characters was not given: %0.",
        "Nie pokazuj krótkiego opisu strony w wynikach wyszukiwania (NoSnippet)": "Do not show a short description of the page in search results (NoSnippet)",
        "Nie pokazuj linku do archiwum strony (NoArchive)": "Do not show a link to the archive of the page (NoArchive)",
        "Nie pokazuj strony jako źródła grafiki w wynikach wyszukiwania (NoImageIndex)": "Do not show the page as an image source in search results (NoImageIndex)",
        "Nie udało się pobrać listy aranżacji": "Failed to download the arrangement list",
        "Nie udało się pobrać listy bloków": "Failed to download block list",
        "Nie udało się pobrać listy działów": "Failed to download the sections list",
        "Nie udało się pobrać listy filtrów": "Failed to download filter list",
        "Nie udało się pobrać listy kanałów": "Failed to download channel list",
        "Nie udało się pobrać listy kategorii": "Failed to download category list",
        "Nie udało się pobrać listy kolejek": "Failed to download queue list",
        "Nie udało się pobrać listy modułów": "Failed to download module list",
        "Nie udało się pobrać listy powiadomień": "Failed to download notification list",
        "Nie udało się pobrać listy ról": "Failed to download role list",
        "Nie udało się pobrać listy serwerów baz danych": "Failed to retrieve the database server list",
        "Nie udało się pobrać listy serwerów plików": "Failed to download the file server list",
        "Nie udało się pobrać listy stron": "Failed to download the pages list",
        "Nie udało się pobrać listy szablonów": "Failed to download template list",
        "Nie udało się pobrać listy typów materiałów": "Failed to download material type list",
        "Nie udało się pobrać listy typów modeli": "Failed to download the list of model types",
        "Nie udało się pobrać listy wydziałów": "Failed to download the list of sections",
        "Nie udało się pobrać listy zespołów": "Failed to download team list",
        "Nie udało się pobrać powiadomień.": "Failed to download notifications.",
        "Nie udało się pobrać ustawień kont użytkowników": "Failed to download user account settings",
        "Nie udało się pobrać widgetu/ów": "Failed to download widget(s)",
        "Nie udało się usunąć szablonu": "Failed to delete template",
        "Nie udało się wczytać podglądu strony.": "Failed to load the website preview.",
        "Nie udało się wysłać pliku": "Failed to send file",
        "Nie udało się zapisać szablonu": "Failed to save template",
        "Nie wiem": "I don't know",
        "Nie wszystkie pola zostały wypelnione prawidłowo.": "Not all fields have been filled in correctly.",
        "Nie wszystkie wybrane elementy zostały przeniesione. Nie posiadasz wymaganych uprawnień lub, w przypadku przenoszenia katalogów, katalog docelowy jest podkatalogiem katalogu źródłowego. Katalogi i pliki których nie udało się przenieść pozostały nadal w zakładce Wybrane.": "Not all selected items have been transfered. You do not have the required permissions or, if you are moving directories, the destination directory is a subdirectory of the source directory. Directories and files that could not have been transfered remain in the Selected tab.",
        "Nie wybrano ankiety": "Survey not selected",
        "Nie wybrano obrazu.": "No image has been selected.",
        "Nie wybrano paczki.": "No package has been selected.",
        "Nie wybrano strony": "No page has been selected",
        "Nie wybrano żadnego użytkownika.": "No user has been selected.",
        "Nie wybrano żadnej kategorii.": "No category has been selected.",
        "Nie wybrano żadnej strony.": "No page has been selected.",
        "Nie wymaga uwierzytelniania": "No authentication required",
        "nie wymuszaj zmiany hasła": "do not force password change",
        "Nie wypełniono poprzedniego wiersza.": "The previous line was not filled in.",
        "Nie zdefiniowano żadnych ról.": "No roles are defined.",
        "Nie zdefiniowano żadnych sekcji.": "No sections are defined.",
        "Nie znaleziono dostępnych widgetów": "No available widgets found",
        "Nie znaleziono filtrów.": "No filters found.",
        "Nie znaleziono kolumn.": "No columns found.",
        "Nie znaleziono pasującego widgetu": "No matching widget found",
        "Nie znaleziono zapisanych filtrów.": "No saved filters found.",
        "Nie znaleziono żadnych plików": "No files found",
        "Nie znaleziono żadnych użytkowników.": "No users found.",
        "Nie znaleziono żadnych wyników.": "No results found.",
        "Nieaktywne": "Inactive",
        "Nieaktywni": "Inactive",
        "Niebieski": "Blue",
        "niedozwolone rozszerzenie pliku (%0)": "file extension not allowed (%0)",
        "Niedozwolone słowa": "Unpermitted words",
        "niedozwolony typ pliku (%0)": "file type not allowed (%0)",
        "Niedziela": "Sunday",
        "Nieopublikowane": "Unpublished",
        "Nieopublikowany": "Unpublished",
        "Niepoprawne wyrażenie regularne": "Incorrect regular expression",
        "Niepotwierdzeni": "Unconfirmed",
        "Nieprawidłowy adres email": "Invalid email address",
        "Nieprawidłowy adres URL": "Invalid URL",
        "Niestandardowy adres": "Non-standard address",
        "Niestandardowy adres strony": "Non-standard page address",
        "Niestandardowy błąd": "Non-standard error",
        "Nieudane": "Failed",
        "Nieudane próby logowania": "Failed login attempts",
        "Nieważne": "Not important",
        "Niewidoczne": "Not visible",
        "Niezadowolony": "Unsatisfied",
        "Nigdy": "Never",
        "Nikomu nie przydzielono uprawnień": "No one has been granted permissions",
        "Niski": "Low",
        "Niżej": "Below",
        "Nowa data": "New date",
        "Nowe hasło": "New password",
        "Nowe połączenie": "New connection",
        "Nowy materiał": "New material",
        "np. (&(objectClass=organizationalUnit))": "e.g. (&(objectClass=organizationalUnit))",
        "np. (&(objectClass=user)": "e.g. (&(objectClass=user)",
        "np. pl-PL": "e.g. pl-PL",
        "Number": "Number",
        "Numer": "Number",
        "Numer aktywnej strony": "Active page number",
        "Numer domu": "House no",
        "Numer mieszkania": "Flat no",
        "Numer sesji": "Session number",
        "Numer telefonu": "Phone number",
        "Object": "Object",
        "Obojętny": "Indifferent",
        "Obok siebie": "Next to each other",
        "Obraz": "",
        "Obraz został wycięty.": "The image has been cut out.",
        "Obrazek": "Image",
        "Obrazek wyróżniający": "Featured image",
        "Oczekujący na akceptację moderatora": "Awaiting moderator approval",
        "Oczekuje na wysłanie": "To be shipped",
        "od": "from",
        "Od": "From",
        "od %0": "from %0",
        "Od poziomu": "From level",
        "Odblokuj": "Unblock",
        "Odejmowanie": "Subtraction",
        "Odnośnik URL": "",
        "Odpowiedzi": "Responses",
        "Odpowiedź": "Answer",
        "Odpowiedź 1": "Answer 1",
        "Odpowiedź 2": "Answer 2",
        "Odpowiedź 3": "Answer 3",
        "Odpowiedź 4": "Answer 4",
        "Odpowiedź 5": "Answer 5",
        "Odrzucone": "Rejected",
        "Odrzucone zgody": "Rejected consents",
        "Odrzucony po zgłoszeniach": "Rejected after requests",
        "Odrzuć": "Reject",
        "Odstęp": "Space",
        "Odśwież": "Refresh",
        "Odznacz wszystkie": "Unselect all",
        "Ogólne": "General",
        "Ograniczna wygenerowaną odpowiedź do podanej liczby tokenów.": "Limit the generated response to the specified number of tokens.",
        "Ok": "Ok",
        "Okładka": "Cover",
        "Określa z której strony do ścieżki mają być dodawane kolejne pozycje.": "Specifies where you want to add additional items to the path.",
        "Opcja pozwala na przypisywanie do tej kategorii wydarzeń dodanych przez wszystkich użytkowników": "This option allows you to assign events added by all users to this category",
        "Opcje": "Options",
        "Open Graph": "Open Graph",
        "OpenAI": "OpenAI",
        "Opiekun ścieżki": "Path supervisor",
        "Opis": "Description",
        "Opis kategorii": "Category description",
        "Opis obrazka": "Image description",
        "Opis sekcji": "Section description",
        "Opis szkolenia": "Course description",
        "Opis witryny": "Site description",
        "Opis zespołu": "Team description",
        "Opis źródła": "Source description",
        "Opisy": "Descriptions",
        "Opublikowana": "Published",
        "Opublikowane": "Published",
        "Opublikowane języki": "Published languages",
        "Opublikowane przez:": "Posted by:",
        "Opublikowane wersje językowe.": "Published language versions.",
        "Opublikowany": "Published",
        "Opublikowany na stronach": "Published on pages",
        "Opublikuj": "Publish",
        "Opublikuj autora": "",
        "Opublikuj materiał": "Publish material",
        "Opublikuj ścieżkę szkoleniową aby móc dodać szkolenia": "Publish a course path to be able to add training courses",
        "Oraz hasło": "And a password",
        "Oraz nowe hasło": "And a new password",
        "Organizacja": "Organization",
        "Osoba odpowiedzialna": "Responsible person",
        "Osoba zastępowana": "Person being substituted",
        "Osoba zastępująca": "Substitute",
        "Ostatnia aktywność": "Recent activity",
        "Ostatnia strona": "Last page",
        "Ostatnio oglądane": "Recently viewed",
        "Ostrzeżenie": "",
        "Otwieraj w nowym oknie": "",
        "Otwórz stronę w nowym oknie": "Open the page in a new window",
        "Otwórz w nowym oknie": "Open in a new window",
        "Paczka SCORM została dodana.": "The SCORM package has been added.",
        "Paczka SCORM została zaktualizowana.": "The SCORM package has been updated.",
        "Paczka została usunięta.": "The package has been removed.",
        "Paczki SCORM": "SCORM packages",
        "Paleta kolorów": "Color palette",
        "Pamięć podręczna": "Cache",
        "Pamięć podręczna została wyczyszczona.": "The cache has been cleared.",
        "Parameters": "Parameters",
        "Parametry": "Parameters",
        "Parametry, wymagane do wykonania zapytania:": "Parameters required to execute the request:",
        "Pasek": "Bar",
        "Pasek postępu": "Progress bar",
        "Październik": "October",
        "październiku": "October",
        "Pełna treść:": "Full content:",
        "Pełny ekran": "Full screen",
        "Piątek": "Friday",
        "Pierwsza strona": "First page",
        "Pigułki": "Knowledge summaries",
        "Pionowo": "Vertically",
        "Piosenka": "Song",
        "Pisać mogą tylko zalogowani użytkownicy": "Only logged-in users can write",
        "Plik": "File",
        "Plik graficzny": "",
        "Plik jest wysyłany": "The file is being sent",
        "Plik PDF": "PDF file",
        "Plik wideo": "Video file",
        "Plik został wysłany": "The file has been sent",
        "Pliki": "Files",
        "Pliki do dodania": "Files to add",
        "Pliki do wysłania: %0": "Files to send: %0",
        "Pliki prywatne": "Private files",
        "Pliki publiczne": "Public files",
        "Płeć": "Gender",
        "Płyta z muzyką": "Music CD",
        "po %0 nieudanych próbach logowania": "after %0 unsuccessful login attempts",
        "Po dodaniu szkoleń nie można zmienić publikacji.": "Once a course is added, publications cannot be changed.",
        "Po jednym synonimie w wierszu": "One synonym per line",
        "Po ustawieniu dat dostępów dla użytkowników w ścieżce będą one nadrzędne, daty dostępów dodane w szkoleniu nie będą brane pod uwagę.": "Once access dates are set for users in the path, they will be parent, access dates added in the course will not be taken into account.",
        "Po ustawieniu indywidualnych dat dostępów dla użytkowników będą one nadrzędne, daty dostępów z zespołów nie będą brane pod uwagę.": "Once individual access dates are set for users, they will be parent, access dates from teams will not be taken into account.",
        "Po wybraniu tej opcji konta użytkowników zostaną usunięte z systemu. Czy chcesz kontynuować?": "When you select this option, user accounts are deleted from the system. Do you want to continue?",
        "Po zaznaczeniu tej opcji uprawnienia indywidualne i pochodzące z ról nie są brane pod uwagę.": "When you select this option, individual and role permissions will not be considered.",
        "Po zmianie krótkiego tytułu zmieni się również adres URL.": "When you change the short title, the URL will also be changed.",
        "Pobierz": "Download",
        "Pochodzenie": "Origin",
        "Poczta": "Mail",
        "Podaj adres url...": "Enter the url...",
        "Podaj identyfikatory użytkowników Facebooka, oddzielone przecinkami, aby mogli uzyskać dostęp do statystyk (Facebook Insights).": "Provide Facebook user IDs, separated by commas, so they can access to their statistics (Facebook Insights).",
        "Podaj kod weryfikacyjny": "Enter verification code",
        "Podaj swój adres email": "Enter your email address",
        "Podaj uwagi do treści, która ma zostać wygenerowana. Przykład: \"Używaj symboli matematycznych\".": "Provide comments about the content that you want to generate. For example: \"Use mathematical symbols.\"",
        "Podana nazwa jest zajęta.": "The name you entered is already taken.",
        "Podana wartość jest nieprawidłowa.": "The provided value is not valid.",
        "Podanie nazwy jest wymagane.": "Name is required.",
        "Podano adres": "Address given",
        "Podano nieprawidłowe dane": "Invalid data provided",
        "Podano nieprawidłowe dane.": "Invalid data provided.",
        "Podążanie za linkami": "Links following",
        "Podczas pierszego uruchomienia wymagane jest utworzenie konta głównego administratora.": "On initial startup, you will need to create a main administrator account.",
        "Podgląd": "Preview",
        "Podkreślenie": "Highlight",
        "Podlega ocenie": "To be graded",
        "Podpis": "Signature",
        "Podstawowe": "Basic",
        "Podstawowe operacje na datach": "Basic date operations",
        "Podstawowe operacje na liczbach": "Basic digits operations",
        "Podstawowe operacje tekstowe": "Basic text operations",
        "Podstawowe operacje warunkowe": "Basic conditional operations",
        "Podstawowy": "Basic",
        "Podtytuł": "Subtitle",
        "Podziedziałek - Piątek": "Monday- Friday",
        "Pojedynczy artykuł (strona opisowa)": "Single article (descriptive page)",
        "Pojedynczy wybór": "Single choice",
        "Pokaż dla wybranych grup lub użytkowników": "Show for selected groups or users",
        "Pokaż etykietę": "Show tag",
        "Pokaż filtry": "Show filters",
        "Pokaż historię": "Show history",
        "Pokaż numery stron": "Show page numbers",
        "Pokaż przycisk ``Wyślij``": "Show ''Send'' button",
        "Pokaż szczegóły": "Show details",
        "Pokaż tylko liderów": "Show leaders only",
        "Pokaż tylko moje dokumenty": "Show my documents only",
        "Pokaż tytuł formularza": "Show form title",
        "Pokaż tytuł sekcji": "Show section title",
        "Pokaż tytuł strony": "Show page title",
        "Pokaż tytuły stron": "Show page titles",
        "Pokaż w koncie użytkownika": "Show in user account",
        "Pokaż więcej": "Show more",
        "pokaż więcej...": "show more...",
        "Pokaż wszystkich użytkowników": "Show all users",
        "Pola takie jak \"Nazwa slajdu\", \"Kategoria materiału\", \"Typ materiału\" są uwzględniane przy automatycznym generowaniu treści, dlatego konieczne jest ich wypełnienie.": "Fields such as \"Slide Name\", \"Material Category\", \"Material Type\" are included in the automatic generation of content, so it is necessary to fill them in.",
        "Pole \"%0\" jest wymagane.": "Field ''%0'' is required.",
        "Pole \"%0\" nie zawiera prawidłowego adresu email.": "Field ''%0'' does not contain a valid email address.",
        "Pole \"%0\" nie zawiera prawidłowego adresu URL.": "Field ''%0'' does not contain a valid URL address.",
        "Pole \"Drugie imię\" jest wymagane.": "''Middle name'' is required.",
        "Pole \"Imię\" jest wymagane.": "''Name'' is required.",
        "Pole \"Nazwisko\" jest wymagane.": "''Surname'' is required.",
        "Pole \"Tytuł\" jest wymagane.": "''Title'' is required.",
        "Pole może zawierać jedynie cyfry.": "Field can only contain numbers.",
        "Pole może zawierać jedynie litery i cyfry.": "This field can contain only letters and numbers.",
        "Pole może zawierać jedynie litery.": "This field can only contain letters.",
        "Pole nie może zawierać spacji": "",
        "Pole typu \"%0\" jest niedozwolone w tym formularzu.": "Field type \"%0\" is not allowed in this form.",
        "Pole wymagane": "Field required",
        "Pole zawiera": "Field contains",
        "Polski": "Polish",
        "Połącz w pary": "Pair up",
        "Połączenie z API OpenAI jest aktywne.": "Connection to the OpenAI API is active.",
        "Połączenie z API OpenAI jest nieaktywne.": "The connection to the OpenAI API is inactive.",
        "Pomoc": "Help",
        "Poniedziałek": "Monday",
        "Poprawna odpowiedź": "Correct answer",
        "Poprawne": "Correct",
        "Poprzednia strona": "Previous page",
        "Porównaj %0": "Compare %0",
        "Porównaj z edytowaną wersją": "Compare with edited version",
        "Porównania": "Comparison",
        "Porównanie": "Comparison",
        "Porównywana wersja": "Compared version",
        "Port": "Port",
        "Posiada załączniki": "Containing attachments",
        "Posiadasz niezapisane materiały": "You have unsaved materials",
        "Potwierdzenie akceptacji": "Confirmation of acceptance",
        "Potwierdzenie archiwizacji": "Archiving confirmation",
        "Potwierdzenie archiwizacji. Archiwizacja tematu spowoduje zarchiwizowanie przypisanych do niego lekcji": "Archiving confirmation. When a topic is archived, the lessons assigned to it are also archived",
        "Potwierdzenie przywrócenia": "Confirmation of restoration",
        "Potwierdzenie przywrócenia szkolenia": "Confirmation of course restoration",
        "Potwierdzenie publikacji": "Publication confirmation",
        "Potwierdzenie ukrycia": "Hiding confirmation",
        "Potwierdzenie usunięcia": "Deletion confirmation",
        "Potwierdzenie usunięcia.": "Deletion confirmation.",
        "Potwierdzenie wylogowania użytkownika": "User logout confirmation",
        "Potwierdzenie zatwierdzenia": "Approval confirmation",
        "Potwierdzenie zmiany statusu": "Confirmation of status change",
        "Potwierdź": "Confirm",
        "Potwierdź akceptację": "Confirm acceptance",
        "Potwierdź publikację wersji": "Confirm version publication",
        "Potwierdź usunięcie": "Confirm deletion",
        "Potwierdź usunięcie aranżacji": "Confirm layout removal",
        "Potwierdź wyczyszczenie logów": "Confirm clearing logs",
        "Potwierdź wyłączenie publikacji": "Confirm that you want to disable publication",
        "Potwierdź zapis do aktualnej wersji": "Confirm saving to the current version",
        "Potwierdź zapis do nowej wersji": "Confirm subscription to new version",
        "Powiadomienia": "Notifications",
        "Powiadomienia zostały skonfigurowane.": "Notifications have been configured.",
        "Powiadomienie dla administratora chatu o nowych wiadomościach": "Notification to the chat administrator about new messages",
        "Powiadomienie dla użytkownika o nowych wiadomościach na chacie": "Notification to the user about new messages in the chat",
        "Powiadomienie jest domyślnie wymagane. Nie da się wyłączyć wysyłania tego powiadomienia.": "Notification is required by default. You cannot stop this notification to appear.",
        "Powiadomienie o oczekującym szkoleniu": "Pending course notification",
        "Powiadomienie o zbliżającym się zakończeniu szkolenia": "Notification of the upcoming end of the course",
        "Powiadomienie testowe zostało wysłane.": "Test notification has been sent.",
        "Powiązane artykuły": "Related articles",
        "Powiązany artykuł": "Related article",
        "Powiększ": "Zoom in",
        "Powód": "Reason",
        "powrót": "return",
        "Powrót": "Back",
        "Powtarzaj co": "Repeat every",
        "Powtórz hasło": "Repeat password",
        "Powtórzone": "Repeated",
        "Poziomo": "Horizontally",
        "Pozostałe filtry": "Other filters",
        "Pozostałe ustawienia": "Other settings",
        "Pozostało %0 znaków.": "There are %0 characters left.",
        "Pozwól kontynuować na obecnej wersji": "Allow to continue on current version",
        "Pozycja": "Position",
        "Prawda-Fałsz": "True-False",
        "Prawy panel": "Right panel",
        "Predefiniowane tła panelu z aplikacjami": "",
        "Predefiniowane zestawy kolorów": "Predefined color sets",
        "Premiera": "Premiere",
        "Priorytet": "Priority",
        "Procent": "Percentage",
        "Profil koordynatora": "Coordinator profile",
        "Profil użytkownika": "Profile",
        "Profil został zaktualizowany.": "The profile has been updated.",
        "Program": "Program",
        "Program szkolenia": "Course program",
        "Projekt": "Project",
        "Próg zaliczenia (%)": "Pass marker (%)",
        "Prywatne": "Private",
        "Przebudowanie indeksów": "Rebuilding indices",
        "Przebudowanie indeksównnOperacja może trwać od kilku sekund do kilku godzin w zależności od rozmiaru indeksów.nnNależy używać tylko w przypadku konieczności zaaplikowania zmian dokonanych w słownikach synonimów lub słów wykluczonych.": "Rebuilding indices. This can take from a few seconds to several hours, depending on the size of the indices. Use only if you need to apply changes made to the synonyms or excluded words dictionaries.",
        "Przeciągnij do innej sekcji": "Drag to another section",
        "Przeciągnij i upuść aby posortować pliki": "Drag and drop to sort files",
        "Przedrostek": "Prefix",
        "Przeglądanie logów aktywności": "View activity logs",
        "Przeglądarka z której korzystasz nie wspiera powiadomień PUSH": "The browser you are using does not support PUSH notifications",
        "Przeglądnij logi": "View logs",
        "Przeindeksowanie danych": "Data re-indexing",
        "Przeindeksowanie danychnnOperacja może trwać od kilku sekund do kilku godzin w zależności od ilości danych w bazie.nnNależy używać tylko w przypadku zauważenia braku danych w indeksach.": "Re-indexing datannOperation can take from a few seconds to a few hours depending on the amount of data in the database.nnUse only if you notice missing data in the indexes.",
        "Przekroczono dozwoloną liczbę znaków: %0.": "Allowed number of characters exceeded: %0.",
        "przekroczony dozwolony rozmiar pliku (%0 MB)": "allowed file size exceeded (%0 MB)",
        "Przełącznik": "Switcher",
        "Przeniesienie wybranych elementów": "Transfer selected items",
        "Przenieś": "Move",
        "Przenieś do sekcji": "Move to section",
        "Przenieś niżej": "Move lower",
        "Przenieś wyżej": "Move higher",
        "Przepnij alias": "Switch alias",
        "Przesuń do góry": "Move up",
        "Przesuń na dół": "Move down",
        "Przetestuj poprawność zapisanych kluczy": "Test the validity of the saved keys",
        "Przetestuj ustawienia poczty": "Test your mail settings",
        "Przetestuj ustawienia Sms": "Test your SMS settings",
        "Przetwarzane": "Processing",
        "Przybornik": "Kit",
        "Przykład: domena\\użytkownik": "Example: domain\\user",
        "Przykład: użytkownik@domena": "Example: user@domain",
        "Przypisz do zespołu": "Assign to team",
        "Przypisz role": "Assign roles",
        "Przypomnienie": "Reminder",
        "Przyrostek": "Suffix",
        "Przywrócić użytkownika i jego wiadomości?": "Restore the user and their messages?",
        "Przywrócić wiadomość?": "Restore a message?",
        "Przywrócono domyślną aranżacje.": "Default layout restored.",
        "Przywróć": "Reload",
        "Przywróć domyślną aranżacje": "Restore default layout",
        "Przywróć domyślne": "Restore defaults",
        "Przywróć użytkownika": "Restore user",
        "Przyznaj dostęp": "Grant access",
        "Pseudonim": "Pseudonym",
        "Publiczne": "Public",
        "Publiczne ID:": "Public ID:",
        "Publikacja": "Publication",
        "Publikacja aranżacji została wyłączona.": "The publication of the arrangement has been disabled.",
        "Publikacja galerii przez użytkownika": "",
        "Publikacja i przypisane szkolenia zostały usunięte.": "The publication and assigned courses have been deleted.",
        "Publikacja priorytetowa": "Priority publication",
        "Publikacja zarchiwizowana": "Archived publication",
        "Publikacja została dodana.": "The publication has been added.",
        "Publikacja została usunięta.": "The publication has been deleted.",
        "Publikuj do": "Publish to",
        "Publikuj na stronie": "Publish to page",
        "Publikuj od": "Publish from",
        "Publikuj szkolenie": "Publish course",
        "Publikuj ścieżkę szkoleniową": "Publish learning path",
        "Punkty": "Points",
        "Pytania": "Questions",
        "Pytania i odpowiedzi": "Questions & Answers",
        "Pytanie": "Question",
        "Pytanie 1": "Question 1",
        "Pytanie 2": "Question 2",
        "Pytanie 3": "Question 3",
        "Pytanie i odpowiedzi": "Question and answers",
        "Raczej się nie zgadzam": "I rather disagree",
        "Raczej się zgadzam": "I rather agree",
        "Radio": "Radio",
        "Ranga": "Rank",
        "Raporty": "Reports",
        "ReCaptcha": "ReCaptcha",
        "Recaptcha nie jest aktywna, klucze muszą przejść pozytywny proces weryfikacji.": "Recaptcha is not active, the keys must pass the verification process.",
        "Recaptcha została aktywowana, klucze zostały zweryfikowane.": "Recaptcha has been activated, the keys have been verified.",
        "Region": "Region",
        "Regulamin wymagany": "Regulations required",
        "Regulamin został dodany.": "Regulations have been added.",
        "Regulamin został zaktualizowany.": "Ragulations have been updated.",
        "Regulaminy": "Regulations",
        "Rejestr zmian": "Changelog",
        "Rejestracja konta": "Sign-up",
        "Relacyjne": "",
        "Repozytorium plików": "File repository",
        "Resetowanie podejść": "Reset approaches",
        "Resetowanie podejść użytkowników": "Reset user approaches",
        "Resetuj": "Reset",
        "Resetuj zmiany": "Reset changes",
        "Reżyseria": "Direction",
        "Ręczna akceptacja wszystkich komentarzy": "Manually accept all comments",
        "Roczny": "Annual",
        "Rodzaj adresu kanonicznego": "Type of canonical address",
        "Rodzaj odpowiedzi": "Type of response",
        "Rodzaj wydarzenia": "Event type",
        "rok": "year",
        "Rok": "Year",
        "Rola": "Role",
        "Rola aktywna": "Active role",
        "Rola domyślna": "Default role",
        "Rola została dodana.": "The role has been added.",
        "Rola została usunięta.": "The role has been removed.",
        "Rola została zaktualizowana.": "The role has been updated.",
        "Role i uprawnienia": "Roles and permissions",
        "Role użytkownika": "User roles",
        "Role zostały przypisane": "Roles have been assigned",
        "Root": "Root",
        "Rozmiar": "Size",
        "Rozmiar zdjęcia został zmieniony.": "The image has been resized.",
        "Rozmieść równomiernie": "Spread evenly",
        "Rozwiń do": "Expand to",
        "Rozwiń/Zwiń": "Expand/Collapse",
        "Równość": "Equality",
        "RSS": "RSS",
        "Satysfakcja": "Satisfaction",
        "Scenariusz": "Scenario",
        "Scorm": "Scorm",
        "Sekcja": "Section",
        "Sekcja %0": "Section %0",
        "Sekcje": "Sections",
        "Sekcje dla gości": "Guest sections",
        "Sekcje dla zalogowanych": "Sections for logged in",
        "Sekcje i bloki": "Sections and blocks",
        "Sekcje wspólne": "Common sections",
        "Separator elementów w tytule": "Element separator in title",
        "Serial telewizyjny": "Television series",
        "Serwer bazy danych": "Database server",
        "Serwer dostępny": "Server available",
        "Serwer plików": "File server",
        "Serwer został dodany.": "Server has been added.",
        "Serwer został usunięty.": "Server has been removed.",
        "Serwer został zaktualizowany.": "Server has been updated.",
        "Serwery plików": "File servers",
        "Sesja": "Session",
        "Sesja nr %0": "Session No . %0",
        "Sesja użytkownika": "User's session has expired",
        "Sierpień": "August",
        "sierpniu": "August",
        "Skala 1-5": "Scale 1-5",
        "Skopiowano": "Copied",
        "Skopiuj szkolenie": "Copy course",
        "Skrócony opis": "Short description",
        "Slajd z materiałów szkoleniowych": "Slide from course materials",
        "Slajd został dodany.": "The slide has been added.",
        "Slajd został usunięty.": "The slide has been deleted.",
        "Slajd został wygenerowany.": "The slide has been generated.",
        "Slajd został zaktualizowany.": "The slide has been updated.",
        "Slajder": "Slider",
        "Slajder został dodany.": "The slider has been added.",
        "Slajder został usunięty.": "The slider has been removed.",
        "Slajder został zaktualizowany.": "The slider has been updated.",
        "Slajdery": "Sliders",
        "Slajdy": "Slides",
        "Slajdy zostały zaimportowane.": "The slides have been imported.",
        "słowa": "words",
        "Słowa kluczowe": "Keywords",
        "Słowa oddzielone przecinkami.": "Words separated by commas.",
        "Słowa wykluczone": "Excluded words",
        "Słownik": "Dictionary",
        "Słownik dowolny": "Any dictionary",
        "Słownik systemowy": "System dictionary",
        "Słowo": "Word",
        "Słowo bazowe": "Base word",
        "Słowo wykluczone": "Excluded word",
        "Słowo zostało dodane.": "Word has been added.",
        "Słowo zostało usunięte.": "Word has been deleted.",
        "Słowo zostało zaktualizowane.": "Word has been updated.",
        "Sobota": "Saturday",
        "Sortowanie autorów": "",
        "Sortowanie malejąco": "Sort descending",
        "Sortowanie rosnąco": "Sort ascending",
        "Sortuj autorów": "",
        "Specjalizowane": "",
        "Sposób prezentacji zakładek": "Methodof displaying tabs",
        "Sprawdzenie czy pusty": "Check if empty",
        "Stała wartość": "Constant value",
        "Stan": "Satus",
        "Stan powiadomienia": "Notification status",
        "Standard": "Standard",
        "Standard 1.2": "Standard 1.2",
        "Standard 2004": "Standard 2004",
        "Standard nieznany": "Standard unknown",
        "Standardowa strona": "Standard page",
        "Status": "Status",
        "Status dokumentu został zmieniony.": "The status of the document has been changed.",
        "Status edytowanej wersji dokumentu": "Status of the edited version of the document",
        "Status formularza został zmieniony.": "The status of the form has been changed.",
        "Status galerii został zmieniony.": "",
        "Status linku został zmieniony.": "The status of the link has been changed.",
        "Status paczki został zmieniony.": "The status of the package has been changed.",
        "Status regulaminu został zmieniony.": "The status of the regulations has been changed.",
        "Status:": "Status:",
        "Statyczny": "Static",
        "Statystyki wykorzystania tokenów.": "Token usage statistics.",
        "Stopka": "Footer",
        "Strona": "Page",
        "Strona %0": "Page %0",
        "Strona błędu": "Error page",
        "Strona docelowa dla 'Czytaj więcej'": "",
        "Strona dostępna dla wszystkich": "Website available to everyone",
        "Strona dostępna tylko dla zalogowanych użytkowników": "Page available only to logged in users",
        "Strona główna": "Home",
        "Strona lekcji opublikowana": "Lesson page published",
        "Strona lekcji SCORM opublikowana": "SCORM lesson page published",
        "Strona logowania": "Login page",
        "Strona nadrzędna": "Parent page",
        "Strona opublikowana": "Published page",
        "Strona szkolenia ukryta": "Course page hidden",
        "Strona testu opublikowana": "Test page published",
        "Strona ukryta": "Hidden page",
        "Strona z modułu konto użytkownika": "Page from the user account module",
        "Strona z modułu wyszukiwarki": "Page from the search engine module",
        "Strona została dodana.": "The page has been added.",
        "Strona została usunięta.": "The page has been deleted.",
        "Strona została zaktualizowana.": "The page has been updated.",
        "Strona została zarchiwizowana.": "The page has been archived.",
        "Strony": "Page",
        "Struktura serwisu": "Site structure",
        "Stwierdzenia": "Statement",
        "Stwierdzenie": "Statement",
        "Styczeń": "January",
        "styczniu": "January",
        "Subskrypcja": "Subscription",
        "Sukces": "",
        "Suma zużytych tokenów": "Total tokens used",
        "Symbol": "Symbol",
        "Symbol autora": "",
        "Symbol klienta": "Customer symbol",
        "Symbol lekcji": "Lesson symbol",
        "Symbol może składać się tylko z małych liter, cyfr oraz znaku - ": "A symbol can only consist of lowercase letters, numbers, and a character - ",
        "Symbol strony": "Page symbol",
        "Symbol strony szkolenia": "Course page symbol",
        "Symbol testu": "Test symbol",
        "Synchronizuj": "Sync",
        "Synonimy": "Synonyms",
        "Synonimy zostały dodane.": "Synonyms have been added.",
        "Synonimy zostały usunięte.": "Synonyms have been deleted.",
        "Synonimy zostały zaktualizowane.": "Synonyms have been updated.",
        "Szablon graficzny": "Graphic template",
        "Szablon został dodany.": "The template has been added.",
        "Szablon został usunięty.": "The template has been removed.",
        "Szablon został zaktualizowany.": "The template has been updated.",
        "Szablony": "Templates",
        "Szary": "Grey",
        "Szczegóły": "Details",
        "Szczegóły zadania": "Task details",
        "Szczegóły zdarzenia": "Event details",
        "Szczegóły zdarzenia nr %0": "Details of event number %0",
        "Szerokość": "Width",
        "Szkic": "Draft",
        "szkolenia": "courses",
        "Szkolenia": "Courses",
        "szkolenie": "course",
        "Szkolenie": "Course",
        "Szkolenie jest opublikowane": "The course has been published",
        "Szkolenie jest puste": "Course is empty",
        "Szkolenie może występować tylko w jednej ścieżce. To szkolenie zostało już dodane do ścieżki: {0}": "The course can only occur in one path. This course has already been added to the path: {0}",
        "Szkolenie nie jest opublikowane": "Course has not been published",
        "Szkolenie zostało dodane.": "Course has been added.",
        "Szkolenie zostało przywrócone.": "Course has been restored.",
        "Szkolenie zostało skopiowane": "Course has been copied",
        "Szkolenie zostało zaktualizowane.": "The course has been updated.",
        "Szkolenie zostało zarchiwizowane.": "Course has been archived.",
        "Szkolenie zostało zarchiwizowane. Aby je wyświetlić, należy przywrócić szkolenie z archiwum.": "The course has been archived. In order to view it, you must restore the course from the archive.",
        "szkoleń": "courses",
        "Szkoleń": "Courses",
        "Szukaj": "Search",
        "Szukaj we wszystkich wersjach": "Search in all versions",
        "Szukana fraza": "Search term",
        "Szukane słowo": "Search term",
        "Ścieżka": "Path",
        "Ścieżka aktywnej strony": "Path of the active page",
        "Ścieżka już jest opublikowana.": "The path is already published.",
        "Ścieżka nawigacyjna": "Navigation path",
        "Ścieżka szkoleniowa": "Learning path",
        "Ścieżka szkoleniowa została dodana.": "The learning path has been added.",
        "Ścieżka szkoleniowa została usunięta.": "The learning path has been removed.",
        "Ścieżka szkoleniowa została zaktualizowana.": "The learning path has been updated.",
        "Ścieżka/strona:": "Path/Page:",
        "Średni teskt": "Medium-lenght text",
        "Średnio": "Moderately",
        "Środa": "Wednesday",
        "Tagi": "Tags",
        "Tak": "Yes",
        "Tak/Nie": "Yes/No",
        "Tekst": "Text",
        "Tekst alternatywny": "Alt text",
        "Tekst do uzupełnienia": "Text to be completed",
        "Tekst zastępczy": "Alternative text",
        "Tekstowy / HTML / JS": "",
        "Telefon": "Phone",
        "Temat": "Topic",
        "Ten dashboard nie zawiera jeszcze żadnych widgetów": "This dashboard does not yet contain any widgets",
        "Ten element nie zawiera menu kontekstowego.": "This item does not contain a context menu.",
        "Ten kanał jest wyłączony.": "This channel is disabled.",
        "Ten kanał ma możliwość wysyłania tekstu sformatowanego.": "This channel provides a possibility to send formatted text.",
        "Ten kanał nie ma możliwości wysyłania tekstu sformatowanego.": "This channel does not provide a possibility to send formatted text.",
        "Ten kanał nie zawiera żadnych powiadomień.": "This channel does not contain any notifications.",
        "Teraz": "Now",
        "Termin cykliczny": "Recurring term",
        "Termin jednorazowy": "One-time term",
        "Terminy": "Terms",
        "Terminy do słownika będzie można dodawać dopiero po zapisaniu źródła danych.": "You won't be able to add terms to the dictionary until you save the data source.",
        "Test": "Test",
        "Test ukryty": "Hidden test",
        "Test został dodany.": "The test has been added.",
        "Test został zaktualizowany.": "The test has been updated.",
        "Testowy adres email": "Tested email address",
        "Testowy numer telefonu": "Test phone number",
        "Testuj": "Test",
        "Tło certyfikatu": "Certificate background",
        "Tło przycisku": "Button background",
        "Tło strona 2": "Background page 2",
        "To pole jest wymagane": "This field is required",
        "To szkolenie znajduje się już w ścieżce szkoleń": "This course is already in the course path",
        "Token": "Token",
        "Token został usunięty.": "Token has been removed.",
        "Tokeny": "Tokens",
        "Treści odpowiedzi są wymagane": "Answers are required",
        "Treści pytań są wymagane": "Questions are required",
        "Treść": "Content",
        "Treść certyfikatu": "Certificate content",
        "Treść komentarza": "Comment content",
        "Tryb konserwacji systemu": "System maintenancemMode",
        "Tryb monitorowania aktywności": "Activity monitoring mode",
        "Tryb monitorowania aktywności włączony.": "Activity monitoring mode enabled.",
        "Tryb monitorowania aktywności wyłączony.": "Activity monitoring mode disabled.",
        "Tryb wyświetlania": "Display mode",
        "Trzy kolumny": "Three columns",
        "Twoje hasło wygasło. Zmień je na nowe.": "Your password has expired. Change it to new one.",
        "Twój token": "Your token",
        "Tydzień": "Week",
        "tydzień (tygodnie) w następującym dniu": "week(s) on the following day",
        "Tygodniowy": "Weekly",
        "Tylko aktywne źródła": "Active sources only",
        "Tylko do odczytu": "Read-only",
        "tylko dokumenty wersjonowane": "versioned documents only",
        "Tylko małe litery, bez znaków specjalnych, cyfr i spacji.": "Lowercase letters only, no special characters, numbers, and spaces.",
        "Tylko priorytetowe": "Priority only",
        "Tylko serwer deweloperski. Nie używać w trybie produkcyjnym. ": "Development server only. Do not use in production mode. ",
        "Tylko zalogowani użytkownicy mogą zgłaszać komentarze": "Only logged-in users can report comments",
        "Typ": "Type",
        "Typ autora": "",
        "Typ bannera": "",
        "Typ elementu": "Element type",
        "Typ konta": "Account type",
        "Typ linku": "Link type",
        "Typ logowania": "Login type",
        "Typ materiału": "Material type",
        "Typ mime": "Mime type",
        "Typ obiektu": "Object type",
        "Typ obiektu:": "Object type:",
        "Typ obiektu.": "Object type.",
        "Typ pola": "Field type",
        "Typ powiadomienia": "Notification type",
        "Typ serwera": "Server type",
        "Typ slajdu": "Slide type",
        "Typ słownika": "Dictionary type",
        "Typ strony": "Page type",
        "Typ zestawu": "Set type",
        "Typ źródła": "Source type",
        "Typ źródła danych": "Data source type",
        "Typy materiałów": "Material types",
        "Tytuł": "Title",
        "Tytuł aktywnej witryny": "Title of the active site",
        "Tytuł formularza jest wymagany": "Form title is required",
        "Tytuł formularza nie może być dłuższy niż %0 znaków": "The title of the form cannot be longer than %0 znaków",
        "Tytuł kafelka": "Tile title",
        "Tytuł sekcji": "Section title",
        "Tytuł strony": "Page title",
        "Tytuł witryny": "Site title",
        "Udało się zapisać ustawienia widgetów": "Successfully saved widget settings",
        "Układ": "Arrangement",
        "Ukryj": "Hide",
        "Ukryj materiał": "Hide material",
        "Ulica": "Street",
        "Umożliwienie sprawdzania odpowiedzi": "Enable checking of responses",
        "Uniksowy znacznik czasu utworzenia zakończenia czatu.": "Unix timestamp of the creation of the end of the chat session.",
        "Upload": "Upload",
        "Uprawnienia": "Permissions",
        "Uprawnienia użytkownika": "User permissions",
        "URL": "URL",
        "Uruchom zadanie": "Run the task",
        "Urządzenie zostało usunięte.": "The device has been removed.",
        "Usage": "Usage",
        "Usługi": "Services",
        "Ustaw kategorię": "Set category",
        "Ustaw szerokości proporcjonalnie": "Set widths proportionally",
        "Ustaw w kolejności": "Set in order",
        "Ustawienia": "Settings",
        "Ustawienia domyślne": "Default settings",
        "Ustawienia dostępności": "Accessibility settings",
        "Ustawienia konta": "Account settings",
        "Ustawienia ogólne": "General settings",
        "Ustawienia podstawowe": "Basic settings",
        "Ustawienia powiadomień zostały zaktualizowane.": "Your notification settings have been updated.",
        "Ustawienia sekcji \"%0\"": "Settings of the \"%0\" section",
        "Ustawienia sesji": "Session settings",
        "Ustawienia slajdera": "Slider settings",
        "Ustawienia strony": "Page settings",
        "ustawienia widgetu": "widget settings",
        "Ustawienia witryny": "Site settings",
        "Ustawienia zaawansowane": "Advanced settings",
        "Ustawienia zostały zaktualizowane.": "The settings have been updated.",
        "Ustawienia zostały zapisane.": "Settings have been saved.",
        "Usunął": "Deleted",
        "Usunięcie": "Deletion",
        "Usunięcie indeksunnOperacja jest nieodwracalna. Czy na pewno chcesz usunąć wybrany indeks?": "Index deletionnnDeleting an index is irreversible. Are you sure you want to delete the selected index?",
        "Usunięcie kategorii spowoduje również usunięcie wszystkich powiązanych z nią formularzy.nnCzy na pewno kontynuować?": "Deleting a category will also delete all forms related to it.nnAre you sure you want to continue?",
        "Usunięcie wybranych elementów": "Delete selected items",
        "Usunięte": "Deleted",
        "Usuń": "Delete",
        "Usuń blok z sekcji": "Delete a block from a section",
        "Usuń datę": "Delete date",
        "Usuń element": "Delete item",
        "Usuń filtr": "Delete filter",
        "Usuń indeks": "Delete index",
        "Usuń kafelek": "Delete tile",
        "Usuń katalog": "Delete directory",
        "Usuń kategorię": "Delete category",
        "Usuń nie zapisany materiał z lekcji": "Delete unsaved material from the lesson",
        "Usuń nie zapisany materiał z testu": "Delete unsaved material from the test",
        "Usuń obrazek": "Delete image",
        "Usuń odpowiedź": "Delete answer",
        "Usuń plik": "Delete file",
        "Usuń plik z listy": "Remove the file from the list",
        "Usuń poprzednie wersje certyfikatu": "Delete previous versions of the certificate",
        "Usuń powiązanie": "Unlink",
        "Usuń powód": "Delete reason",
        "Usuń publikację": "Delete a publication",
        "Usuń pytanie": "Delete question",
        "Usuń regulamin": "Delete regulations",
        "Usuń slajd": "Delete slide",
        "Usuń słowo": "Delete word",
        "Usuń spacje": "Delete spaces",
        "Usuń stronę": "Delete page",
        "Usuń szkolenie z ścieżki szkoleń": "Delete course from the course path",
        "Usuń tag": "Delete tag",
        "Usuń urządzenie": "Delete device",
        "Usuń urządzenie aby odwołać zapamiętane automatyczne logowanie na swoje konto": "Delete a device to cancel automatic sign-in to your account",
        "Usuń użytkowników": "Delete users",
        "Usuń wiersz": "Delete row",
        "Usuń z zespołu": "Remove from team",
        "Usuń ze szkolenia": "Remove from the course",
        "Usuń zestaw": "Delete set",
        "Utworzył": "Author",
        "Utwórz grupy": "Create groups",
        "Utwórz katalog": "Create a directory",
        "Utwórz nowy": "Create new",
        "Utwórz raport wyników": "Create a results report",
        "Uwzględnij wydziały podrzędne": "Include sub-departments",
        "Uzupełnij dane dotyczące szkolenia": "Fill in the course data",
        "Uzupełnij luki": "Fill in the gaps",
        "Uzupełnij wymagane pola.": "Fill in the required fields.",
        "Użytkownicy": "Users",
        "Użytkownicy mogą zgłaszać komentarze do moderacji": "Users can submit comments for moderation",
        "Użytkownicy w tej roli": "Users in this role",
        "Użytkownik": "User",
        "Użytkownik i jego wiadomości zostały przywrócone.": "The user and their messages have been restored.",
        "Użytkownik jest liderem zespołu": "User is a team leader",
        "Użytkownik nie może się zalogować dopóki konto nie jest aktywne.": "The user cannot log in until the account is active.",
        "Użytkownik posiada pełne uprawnienia": "The user has full permissions",
        "Użytkownik z bazy": "",
        "Użytkownik zalogowany": "Logged in user",
        "Użytkownik został dodany do zespołu.": "The user has been added to the team.",
        "Użytkownik został dodany.": "The user has been added.",
        "Użytkownik został przypisany do szkolenia.": "The user has been assigned to the course.",
        "Użytkownik został przypisany do ścieżki szkoleniowej.": "The user has been assigned to a learning path.",
        "Użytkownik został usunięty.": "User has been deleted.",
        "Użytkownik został wylogowany.": "The user has been logged out.",
        "Użytkownik został zablokowany, a jego wiadomośći ukryte.": "The user has been blocked and their messages have been hidden.",
        "Użytkownik został zaktualizowany.": "The user has been updated.",
        "Użytkownikcy z bazy": "",
        "Użytkowników": "Users",
        "Video": "Video",
        "W ciągu ostatniego tygodnia nie dodano nowych użytkowników": "No new users were added in the last week",
        "W każdy dzień tygodnia": "Every day of the week",
        "W następującym dniu:": "On the following day:",
        "W organizacji nie ma jeszcze żadnych działów.": "There are no sections in the organization yet.",
        "W przyszłości": "In the future",
        "w tym ": "including ",
        "W tym katalogu nie ma jeszcze żadnych stron.": "There are no pages in this directory yet.",
        "W zespole od %0": "In the team from %0",
        "W zespole od %0 do %1": "In the team from %0 to %1",
        "Walidacja": "Validation",
        "Walidacja certyfikatu SSL": "SSL certificate validation",
        "Wartość": "Value",
        "Wartość 0 oznacza brak limitu": "A value of 0 indicates no limit",
        "Wartość domyślna": "Default value",
        "Wartość domyślna: (&(objectClass=organizationalUnit))": "Default value: (&(objectClass=organizationalUnit))",
        "Wartość domyślna: (&(objectClass=user)": "Default value: (&(objectClass=user)",
        "Wartość musi być liczbą całkowitą": "The value must be an integer",
        "Wartość musi być liczbą dodatnią": "The value must be a positive number",
        "Wartość musi być liczbą ujemną": "The value must be a negative number",
        "Warunki logiczne": "Logical conditions",
        "Warunkowo": "Conditionally",
        "Ważne": "Important",
        "Ważne informacje": "Important information",
        "Ważność": "Validity",
        "Wczytaj zapisany filtr": "Load saved filter",
        "Weekend": "Weekend",
        "Wersja językowa": "Language version",
        "Wersja modelu": "Model version",
        "Wersja modelu GPT.": "GPT model version.",
        "Wersja nieopublikowana": "Unpublished version",
        "Wersja opublikowana": "Published version",
        "Wersja utowrzona przez": "Version created by",
        "Wersje": "Versions",
        "Wersje aranżacji": "Arrangement versions",
        "Wersje dokumentu": "Document versions",
        "Wersjonowanie wpisów": "",
        "Wewnętrznie": "Internally",
        "Wiadomości": "Messages",
        "Wiadomość": "Message",
        "Wiadomość testowa została wysłana.": "A test message has been sent.",
        "Wiadomość została przywrócona.": "The message has been restored.",
        "Wiadomość została ukryta.": "The message has been hidden.",
        "Wideo": "Video",
        "Wideo z biblioteki": "Video from library",
        "Wideo zewnętrzne (URL)": "External video (URL)",
        "Widgety": "Widgets",
        "Widoczna dla wszystkich użytkowników": "Visible to all users",
        "Widoczność": "Visibility",
        "Widoczny obszar": "Visible area",
        "Wielkość kroku": "Step size",
        "Wielokrotnego wyboru": "Multiple choice",
        "Wielokrotny wybór": "Multiple choice",
        "Wiersz": "Row",
        "Więcej": "More",
        "więcej...": "more...",
        "Więcej...": "More…",
        "Witryna": "Website",
        "Witryna / Wersja językowa": "Website / Language version",
        "Witryna aktywna": "Active site",
        "Witryna domyślna": "Default site",
        "Witryna i język": "Site and language",
        "Witryna internetowa": "Website",
        "Witryna tylko dla zalogowanych użytkowników": "Site for logged-in users only",
        "Witryna została dodana.": "The site has been added.",
        "Witryna została usunięta.": "The site has been removed.",
        "Witryna została zaktualizowana.": "The site has been updated.",
        "Witryny": "Sites",
        "Wklej komponent": "Paste component",
        "Właściwości": "Characteristics",
        "Właściwość ``%0`` aktywnego wpisu": "The ''%0'' feature of the active entry",
        "Włącz ankietę": "Enable survey",
        "Włącz chat": "Enable chat",
        "Włącz monitorowanie aktywności": "Turn on activity monitoring",
        "Włącz przynajmniej jedną wersję językową dla witryny aby rozpocząć zarządzanie stronami.": "Enable at least one language version for your site to start managing your pages.",
        "Włącz social media": "Enable social media",
        "Włącz system komentarzy": "Enable the comment system",
        "Włącz system powiadomień": "Enable the notification system",
        "Włączenie generowania pliku sitemap.xml dla całej witryny": "Enable sitemap.xml file generation for the website",
        "Włączenie obsługi cacheu dla witryny": "Enable cache support for the website",
        "Włączenie protokołu SSL dla tej witryny": "Enable SSL for this site",
        "Włączone": "Enabled",
        "Włączone (Follow)": "Enabled (Follow)",
        "Włączone (Index)": "Enabled (Index)",
        "Województwo": "Province",
        "Wpis domyślny": "Default entry",
        "Wpis został dodany.": "The post has been added.",
        "Wpis został usunięty.": "The post has been deleted.",
        "Wpis został zaakceptowany.": "The post has been accepted.",
        "Wpis został zaktualizowany.": "The post has been updated.",
        "Wprowadzenie zmian w polu \"Wybierz paczkę SCORM\" powoduje, że nadpisanie dotychczasowej wersji elementu szkolenia jest niedostępne.": "If you change the 'Select SCORM package' field, the existing version of the course component cannot be overwritten.",
        "Wprowadzenie zmian w polu \"Źródło\" powoduje, że nadpisanie dotychczasowej wersji elementu szkolenia jest niedostępne.": "If you change the 'Source' field, the existing version of the course component cannot be overwritten.",
        "Wprowadzenie zmian w zakładce \"ZAWARTOŚĆ\" powoduje, że nadpisanie dotychczasowej wersji elementu szkolenia jest niedostępne.": "If you make changes in the \"CONTENT\" field, the existing version of the course component cannot be overwritten.",
        "Wprowadzona wartość jest nieprawidłowa": "The value you entered is invalid",
        "Wprowadzona wartość jest za długa": "The value you entered is too long",
        "Wprowadzona wartość jest za krótka": "The value you entered is too short",
        "Wprowadzona wartość jest za niska": "The value you entered is too low",
        "Wprowadzona wartość jest za wysoka": "The value you entered is too high",
        "Wprowadzona wartość nie jest poprawna": "The value you entered is not valid",
        "Wróć do logowania": "Back to login",
        "Wróć do strony głównej": "Back to Home Page",
        "Wrzesień": "September",
        "wrześniu": "September",
        "Wskazuje robotom wyszukiwarek czy mają indeksować stronę.": "It indicates to search engine robots whether to index the page.",
        "Wskazuje robotom wyszukiwarek czy mają podążać za linkami.": "It indicates to search engine robots whether to follow the links.",
        "Wstaw cztery kolumny 1/4": "Insert four columns 1/4",
        "Wstaw dwie kolumny 1/3 oraz 2/3": "Insert two columns 1/3 and 2/3",
        "Wstaw dwie kolumny 2/3 oraz 1/3": "Insert two columns 2/3 and 1/3",
        "Wstaw dwie równe kolumny": "Insert two equal columns",
        "Wstaw kontrolkę": "Insert control",
        "Wstaw stronę": "Insert page",
        "Wstaw szablon informacyjny ze zdjęciem": "Insert information template with photo",
        "Wstaw treść widoczną po rozwinięciu": "Insert content visible when expanded",
        "Wstaw trzy kolumny 1/3": "Insert three columns 1/3",
        "Wstaw z dołu": "Insert from below",
        "Wstaw z góry": "Insert from above",
        "Wstaw z lewej strony": "Insert left",
        "Wstaw z prawej strony": "Insert right",
        "Wstecz": "Back",
        "Wstęp": "Introduction",
        "Wszyscy": "Everyone",
        "Wszystkie": "All",
        "Wszystkie formularze": "All forms",
        "Wszystkie kategorie": "All categories",
        "Wszystkie niezapisane zmiany zostaną utracone.": "Any unsaved changes will be lost.",
        "Wszystkie próby": "All attempts",
        "Wszystkie typy": "All types",
        "Wtorek": "Tuesday",
        "wybierz": "select",
        "Wybierz": "Select",
        "Wybierz adres kanoniczny z listy lub podaj ręcznie.": "Select a canonical address from the list or enter it manually.",
        "Wybierz ankietę do wypełnienia po szkoleniu": "Select a survey to be completed after the course",
        "Wybierz ankietę do wypełnienia przed szkoleniem": "Select a survey to be completed before the course",
        "Wybierz artykuł": "Select article",
        "Wybierz autora": "Select author",
        "Wybierz blok": "Select block",
        "Wybierz certyfikat": "Select a certificate",
        "wybierz długość": "select length",
        "Wybierz długość generowanej treści.": "Select the length of the content to be generated.",
        "Wybierz formularz": "Select form",
        "Wybierz formularz zawierający dodatkowe pola dla kont użytkownków": "Select a form with additional user account fields",
        "Wybierz istniejącego użytkownika": "",
        "wybierz katalog": "select directory",
        "Wybierz katalog": "Select a catalog",
        "Wybierz katalog do którego chcesz przenieść wybrane elementy.": "Select the directory to which you want to move the selected components.",
        "Wybierz kategorię": "Select a category",
        "Wybierz paczkę": "Select a package",
        "Wybierz paczkę SCORM": "Select the SCORM package",
        "Wybierz plik": "Select file",
        "Wybierz plik lub katalog": "Select file or directory",
        "Wybierz pliki": "Select files",
        "Wybierz pliki (do {0})": "Select files (to {0})",
        "Wybierz regulamin": "Select regulations",
        "Wybierz sekcję": "Select section",
        "Wybierz serwer": "Select server",
        "Wybierz shortcode": "Select shortcode",
        "Wybierz stronę": "Select page",
        "Wybierz strony": "Select pages",
        "wybierz szablon": "select template",
        "Wybierz szkolenie": "Select course",
        "Wybierz temat": "Select topic",
        "Wybierz typ powiadomienia aby zobaczyć dostępne klucze.": "Select the notification type to see the available keys.",
        "Wybierz użytkownika": "Select user",
        "Wybierz użytkowników dla których chcesz wykonać akcje": "Select the users for whom you want to perform actions",
        "wybierz wersję": "select version",
        "Wybierz wersję": "Select version",
        "Wybierz zaznaczone": "Choose selected",
        "wybierz...": "select...",
        "Wybierz...": "Select...",
        "Wybór": "Choice",
        "Wybór języka": "Select language",
        "Wybór kategorii": "Category selection",
        "Wybór kategorii wydarzeń": "Event category selection",
        "Wybór koloru jest wymagany": "Colour choice is required",
        "Wybór listy stron": "Selection of the list of pages",
        "Wybrana data jest mniejsza niż dozwolona %0.": "Selected date is before the permitted one %0.",
        "Wybrana data jest większa niż dozwolona %0.": "Selected date is above the permitted one %0.",
        "Wybrana ikona powinna być kwadratem": "The selected icon should be a square",
        "Wybrana liczba jest mniejsza niż dozwolona %0.": "Selected number is lesser than allowed %0.",
        "Wybrana liczba jest większa niż dozwolona %0.": "Selected number is greater then allowed %0.",
        "Wybrana wersja slajdu została wygenerowana w nieprawidłowym formacie. Nie można jej wyświetlić.": "The selected version of the slide was created in an invalid format. It can not be displayed.",
        "Wybrane": "Selected",
        "Wybrane %0 na %1": "Selected %0 out of %1",
        "Wybrane elementy zostały przeniesione.": "The selected items have been moved.",
        "Wybrane elementy zostały usunięte.": "The selected items have been removed.",
        "Wybrane kategorie": "Selected categories",
        "Wybrane pliki": "Selected files",
        "Wybrane pliki: {0}": "Selected files: {0}",
        "Wybrane regulaminy:": "Selected regulations:",
        "Wybrane regulaminy: ": "Selected regulations: ",
        "Wybranie ankiety jest wymagane": "Selecting a survey is required",
        "Wybranie katalogu jest wymagane": "Selecting a directory is required",
        "Wybranie strony jest wymagane": "Selecting a page is required",
        "Wybrano": "Selected",
        "Wybrano %0 użytkowników.": "%0 users have been selected.",
        "Wybrano jednego użytkownika.": "One user has been selected.",
        "Wybrany certyfikat jest już nieaktualny.": "The selected certificate is no longer valid.",
        "Wybrany język": "Selected language",
        "Wybrany materiał został już dodany do lekcji": "The selected material has already been added to the lesson",
        "Wybrany szablon": "Selected template",
        "Wybrany znak zastąpi znacznik separatora | wstawiony w tytule.": "The selected character will replace the separator marker | inserted in the title.",
        "Wyczyszczenie pamięci podręcznej może spowodować usunięcie niezapisanych postępów czasu spędzonego na platformie.": "Clearing the cache may delete unsaved progress of time spent on the platform.",
        "Wyczyść": "Clear",
        "Wyczyść aranżację": "Clear arrangement",
        "Wyczyść pamięć podręczną": "Clear cache",
        "Wydział": "Department",
        "Wykonaj": "Do",
        "Wykonawca": "Author",
        "Wyloguj się": "Log out",
        "Wyloguj zalogowanego obecnie użytkownika": "Log off the currently logged in user",
        "Wyłączone (NoFollow)": "Off (NoFollow)",
        "Wyłączone (NoIndex)": "Disabled (NoIndex)",
        "Wymagaj kolejności zaliczania elementów": "Require element passing order",
        "Wymagaj odpowiedzi, aby przejść dalej": "Require a response to move on",
        "Wymagaj ponownej akceptacji po zmianach": "Require re-approval after changes",
        "Wymagaj znaków specjalnych w hasłach": "Require special characters in passwords",
        "Wymagane": "Required",
        "Wymagane jest ustawienie minimalnej długości hasła": "Minimum password length is required",
        "Wymagane jest wybranie formularza": "Form must be selected",
        "Wymagane jest wybranie kategorii": "Category must be selected",
        "Wymagane jest wybranie sekcji": "A section must be selected",
        "Wymagane jest wybranie źródła danych": "Data source is required",
        "wymagany": "required",
        "Wymiary": "Dimensions",
        "Wymuś nowe podejście - reset wyniku": "Force another approach - score reset",
        "Wymuś zmianę hasła": "Force password change",
        "Wyniki": "Results",
        "Wyrażenia warunkowe": "Conditional expressions",
        "Wyrażenie regularne": "Regular expression",
        "Wyrażenie w postaci \"/pattern/flag\" nie jest obsługiwane, użyj same wartości pattern": "Expressions of the form \"/pattern/flag\" are not supported, use pattern values only",
        "Wyrażone zgody": "Consents given",
        "Wysłane": "Sent",
        "Wysoki": "High",
        "Wysokość": "Height",
        "wystąpieniach": "occurrences",
        "Wystąpił błąd podczas próby exportu użytkowników.": "An error occurred while trying to export users.",
        "Wystąpił błąd podczas próby importu użytkowników.": "An error occurred while trying to import users.",
        "Wystąpił błąd połączenia. Upewnij się że masz włączony internet i spróbuj ponownie.": "A connection error has occurred. Make sure you are online and try again.",
        "Wystąpił błąd serwera podczas obsługiwania wybranej operacji. Spróbuj ponownie.": "A server error occurred while handling the selected operation. Please try again.",
        "Wystąpił błąd. Spróbuj ponownie później.": "An error has occurred. Please try again later.",
        "Wystąpiły błędy w formularzu. Sprawdź wszystkie pola formularza.": "",
        "Wysyłaj tym kanałem": "Send through this channel",
        "Wyszukaj": "Search",
        "Wyszukaj (Ctrl+Q)": "Search (Ctrl+Q)",
        "Wyszukaj albo dodaj nowy tag": "Search for or add a new tag",
        "Wyszukaj autora...": "Search authors...",
        "Wyszukaj blok": "Search blocks",
        "Wyszukaj formularz...": "Search forms...",
        "Wyszukaj regulamin...": "Search regulations...",
        "Wyszukaj użytkownika": "Search users",
        "wyszukaj użytkownika...": "search users...",
        "Wyszukaj wszędzie": "Search everywhere",
        "Wyszukaj zespół": "Search teams",
        "wyszukaj...": "search...",
        "Wyszukaj...": "Search...",
        "Wyszukiwany ciąg znaków": "Search set of characters",
        "Wyszukiwarka": "Search",
        "Wyślij": "Send",
        "Wyślij kod": "Send the code",
        "Wyświetl materiały": "View materials",
        "Wyświetl popup z kontynuacją lekcji": "View the popup with the continuation of the lesson",
        "Wyświetl również zarchiwizowane formularze": "Display also archived forms",
        "Wyświetlaj nazwę bloczka": "Display block name",
        "Wyświetlaj tylko treść": "Display content only",
        "Wyświetlam": "Displaying",
        "Wyświetlanie": "Displaying",
        "Wytnij": "Cut",
        "Wyzeruj licznik": "Reset counter",
        "Wyżej": "Above",
        "Wzorzec": "Pattern",
        "Wzorzec cyklu": "Cycle pattern",
        "Zaakceptowane": "Accepted",
        "Zaakceptowany": "Accepted",
        "Zaawansowane": "Advanced",
        "Zaawansowane polecenia": "Advanced commands",
        "Zabierz dostęp": "Take away access",
        "Zablokowany": "Blocked",
        "Zablokuj": "Block",
        "Zablokuj / Odblokuj konta": "Block/Unblock Accounts",
        "Zablokuj użytkownika": "Block user",
        "zacznij pisać aby wyszukać ankietę...": "start typing to search for a survey...",
        "zacznij pisać aby wyszukać artykuł...": "start typing to search for an article...",
        "zacznij pisać aby wyszukać autora...": "start typing to search for an author...",
        "zacznij pisać aby wyszukać certyfikat...": "start typing to search for a certificate...",
        "zacznij pisać aby wyszukać kategorię...": "start typing to search for a category...",
        "zacznij pisać aby wyszukać materiał...": "start typing to search for material...",
        "zacznij pisać aby wyszukać paczkę SCORM...": "start typing to search for a SCORM package...",
        "zacznij pisać aby wyszukać slajder...": "start typing to search for a slider...",
        "zacznij pisać aby wyszukać szkolenie...": "start typing to search for a course...",
        "zacznij pisać aby wyszukać użytkownika...": "start typing to search for a user...",
        "zacznij pisać aby wyszukać zespół...": "start typing to search for a team...",
        "Zacznik pisać Form lub Entry aby uruchomić podpowiadanie.": "Start typing Form or Entry to show the hints.",
        "Zaczyna sie od": "Starts with",
        "Zadanie przebudowania indeksów zostało rozpoczęte.": "Rebuilding the indices has begun.",
        "Zadanie przeindeksowania danych zostało rozpoczęte.": "Reindexing data has begun.",
        "Zadanie zostało uruchomione.": "The task has started.",
        "Zadanie zostało włączone": "Task has been enabled",
        "Zadanie zostało wyłączone": "Task has been disabled",
        "Zadowolony": "Satisfied",
        "Zagrożenie": "",
        "Zaimportowano listę użytkowników.": "The list of users has been imported.",
        "Zajawka": "Sneak peek",
        "Zakładki": "Tabs",
        "Zakolejkowane": "Pending",
        "Zakończ sesję": "End session",
        "Zakończ sesję aby wylogować użytkownika": "End session to log the user out",
        "Zakończone": "Completed",
        "Zakres cyklu": "Cycle scope",
        "Zakres czasu": "Time range",
        "Zaktualizowano.": "Updated.",
        "Zaktualizuj formularz": "Update form",
        "Zależna od kontrolki": "Control dependent",
        "Zaloguj się": "Sign In",
        "Zaloguj się na swoje konto": "Log in to your account",
        "Załączniki": "Attachments",
        "Zamiany zostały zapisane.": "The changes have been saved.",
        "Zamknij": "Close",
        "Zaokrąglenie": "Rounding",
        "Zapamiętaj logowanie": "Remember login",
        "Zapamiętane urządzenia": "Remembered devices",
        "Zapis nowej wersji elementu szkolenia": "Saving new version of the course component",
        "Zapis został usunięty": "The record has been deleted",
        "Zapisz": "Save",
        "Zapisz filtr": "Save filter",
        "Zapisz filtry": "Save filters",
        "Zapisz i wróć": "Save and return",
        "Zapisz jako": "Save as",
        "Zapisz nową wersję": "Save new version",
        "Zapisz nową wersję slajdu": "Save new slide version",
        "Zapisz slajd": "Save slide",
        "Zapisz ścieżkę aby móc ją opublikować.": "Save the path in order to publish it.",
        "Zapisz ustawienia widgetów": "Save widget settings",
        "Zapisz wersję": "Save version",
        "Zapisz zmiany": "Save changes",
        "Zaplanowane": "Scheduled",
        "Zapomniałeś hasła?": "Forgot your password?",
        "Zapytanie": "Query",
        "Zapytanie SQL": "SQL Query",
        "Zarchiwizowane": "Archived",
        "Zarchiwizowany": "Archived",
        "Zarządzaj zespołami": "Manage teams",
        "Zarządzanie witrynami": "Manage sites",
        "Zarządzanie zespołami": "Team management",
        "Zasięg": "Range",
        "Zastępstwa": "Substitutions",
        "Zastępstwo": "Add a substitution",
        "Zastępstwo wygasło.": "The substitution has expired.",
        "Zastępstwo wygaśnie za 10 minut.": "The substitution will expire in 10 minutes.",
        "Zastępstwo wygaśnie za 5 minut.": "The substitution will expire in 5 minutes.",
        "Zastępstwo wygaśnie za minutę.": "The substitution will expire in a minute.",
        "Zastępstwo zostało dodane.": "The substitution has been added.",
        "Zastępstwo zostało usunięte.": "The substitution has been removed.",
        "Zastępstwo zostało zaktualizowane.": "The substitution has been updated.",
        "Zastępuje osobę": "Substituting",
        "Zatwierdź": "Approve",
        "Zawartość": "Content",
        "Zawiera": "Contains",
        "Zawiera sekcje": "Contains sections",
        "Zawierające jeden z wybranych tagów": "Containing one of the selected tags",
        "Zawsze": "Always",
        "Zaznacz wszystkie": "Select all",
        "Zaznacz/odznacz wszystko": "Select/unselect all",
        "Zbliżająca się data zakończenia": "Upcoming end date",
        "Zdarzenie": "Event",
        "Zdecydowanie nie": "Definitely no",
        "Zdecydowanie nieważne": "Definitely not important",
        "Zdecydowanie się nie zgadzam": "I strongly disagree",
        "Zdecydowanie się zgadzam": "I strongly agree",
        "Zdecydowanie tak": "Definitely yes",
        "Zdecydowanie ważne": "Definitely important",
        "Zdjęcie": "Photo",
        "Zdjęcie autora": "",
        "Zdjęcie dla tabletów": "",
        "Zdjęcie dla urządzeń mobilnych": "",
        "Zdjęcie główne": "Main photo",
        "Zdjęcie użytkownika": "User's photo",
        "Zdjęcie w tle": "",
        "Zespoły": "Teams",
        "Zespół": "Team",
        "Zespół aktywny": "Active team",
        "Zespół został dodany.": "The team has been added.",
        "Zespół został przypisany do szkolenia.": "The team was assigned to the course.",
        "Zespół został przypisany do ścieżki szkoleniowej.": "The team has been assigned to a learning path.",
        "Zespół został usunięty.": "The team has been removed.",
        "Zespół został zaktualizowany.": "The team has been updated.",
        "Zestaw niestandardowy": "Custom set",
        "Zestaw został dodany.": "The set has been added.",
        "Zestaw został zaktualizowany.": "The set has been updated.",
        "Zewnętrzny link jest wymagany": "External link is required",
        "Zewnętrzny serwis": "External service",
        "Zgłaszanie komentarzy": "Reporting comments",
        "Zgłoszenia": "Issues",
        "Zgoda": "Consent",
        "Zielony": "Green",
        "Zmiana hasła": "Password change",
        "Zmiana rozmiaru obrazu": "Resize an image",
        "Zmiana wersji elementu nie dotyczy użytkowników z zakończonym szkoleniem": "Component version change does not apply to users who have completed the course",
        "Zmiany zaczną obowiązywać dopiero po przebudowaniu indeksów.": "The changes will not take effect until the indexes have been rebuilt.",
        "Zmiany zostały zapisane.": "",
        "Zmieną %HEAD_TITLE% można wykorzystać w innych polach tekstowych.": "The variable %HEAD_TITLE% can be used in other text boxes.",
        "Zmieniono ustawienia widgetu": "Widget settings changed",
        "Zmień daty publikacji": "Change publication dates",
        "Zmień hasło": "Change password",
        "Zmień nazwę": "Rename",
        "Zmień nazwę katalogu": "Rename a directory",
        "Zmień nazwę pliku": "Rename a file",
        "Zmień rozmiar": "Resize",
        "Zmień status edytowanej wersji": "Change the status of the edited version",
        "Zmień widok": "Change view",
        "Zmniejsz": "Reduce",
        "Zmodyfikował": "Modified by",
        "znaki": "characters",
        "Zobacz przed opublikowaniem": "View before publishing",
        "Zobacz sesję użytkownika": "View user session",
        "Zobacz szczegóły": "See details",
        "Zobacz szczegóły sesji użytkownika": "View user session details",
        "Zobacz szczegóły zdarzenia": "View event details",
        "Zobacz wszystkie": "See all",
        "Został przywrócony oryginalny harmonogram": "Original schedule has been restored",
        "Zresetuj hasło": "Reset password",
        "Zużyte tokeny": "Used tokens",
        "Zwrócony": "Returned",
        "Źle": "Bad",
        "Źródła danych": "Data Sources",
        "Źródła LDAP": "LDAP sources",
        "Źródło": "Source",
        "Źródło aktywne": "Active source",
        "Źródło danych": "Data source",
        "źródło danych zostało dodane.": "the data source has been added.",
        "Źródło danych zostało zaktualizowane.": "The data source has been updated.",
        "Źródło LDAP zostało dodane.": "An LDAP source has been added.",
        "Źródło LDAP zostało zaktualizowane.": "The LDAP source has been updated.",
        "Źródło URL": "URL source",
        "źródło zostało usunięte.": "the source has been removed.",
        "Żaden kanał komunikacji nie został włączony w ustawieniach powiadomień.": "No communication channel has been enabled in the notification settings.",
        "Żółty": "Yellow",
        "Żródło danych": "Data source"
    }
};
