<template>
    <textarea class="form-control" :value="currentValue" @input="updateModel"></textarea>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Emit, Prop, Watch } from '@/helpers/Decorators';

@Options({
    name: 'ideo-form-textarea'
})
export default class IdeoFormTextarea extends Vue
{
    public currentValue: string = null;

    @Prop()
    public value: string;

    @Prop()
    public modelValue: string;

    @Emit('input')
    @Emit('update:modelValue')
    public updateModel(e: Event): string
    {
        const target = e.target as HTMLInputElement;

        this.currentValue = target.value;

        return this.currentValue;
    }

    @Watch('value', { immediate: true })
    @Watch('modelValue', { immediate: true })
    public onModelChanged(value: any[] | any): void
    {
        if (value !== undefined)
        {
            this.currentValue = value;
        }
    }
}
</script>
