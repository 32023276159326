<script lang="ts" setup>
import { ref, reactive, computed, onMounted, type Ref } from 'vue';
import { Form } from '@/helpers/Form';
import { Resource } from '@/helpers/Interfaces';
import Pager from '@/helpers/Pager';
import { persistState, restoreState } from '@/plugins/viewstate';
import { useAlerts } from '@/plugins/alerts';
import { useLogging } from '@/plugins/logging';
import { useLocalization } from '@/plugins/localization';
import { handleException } from '@/helpers/Utils';
import { AllPublicationOptions, PublicationStyle } from '@/helpers/Enums';
import { FormContract, FormEntry } from '@/components/forms/blueprints/form';
import { FluentFormBuilder } from '@/components/forms';
import FormsService from '@/modules/studio/forms/services/FormsService';
import DocumentsService, { FilterModel, ListItemModel, checkPermissions } from '../../services/DocumentsService';
import { FeaturePermissions, SectionItem } from '../../../modules/services/types';

const props = defineProps({
  "symbol": null,
  "section": null,
  "routeKey": null,
  "embedded": { type: Boolean, default: false },
  "preview": { type: Boolean, default: false }
});

const emit = defineEmits(["add", "edit"]);

const { $alert } = useAlerts();
const { $log } = useLogging();
const { $t } = useLocalization();

const loaded = ref(false);
const toolbar = computed(() => props.embedded ? 'header' : 'toolbar');
const section = computed(() => props.section);
const columns = computed(() => section.value.columns);
const filter = reactive(Form.create<FilterModel>({
    formId: null,
    userId: 0,
    status: null,
    title: '',
    dateFromUtc: null,
    dateDueUtc: null,
    onlyMine: false,
    document: new FormEntry(),
    relatedTo: []
}));
const pager = reactive(new Pager(1, 20, 'Id', 'ASC')) as Pager;
const items = ref<Resource<ListItemModel>[]>([]);
const itemsMeta = ref(new Map<number, Record<string, boolean>>());
const permissions = ref<Record<string, boolean>>({});

const blueprint = ref(null) as Ref<FormContract>;
const builder = ref(FluentFormBuilder
    .setup(false, true)
    .blueprint(() => blueprint.value, () => section.value.formId)
    .entry(() => filter.document, () => 0)
    .make());

const sortingOptions = computed(() => ([
    { value: 'Id', text: '[[[Id]]]' },
    { value: 'DateCreatedUtc', text: '[[[Data dodania]]]' }
]));

const routeKey = computed(() => props.routeKey);

onMounted(async () =>
{
    restoreState(routeKey.value, filter, pager, columns.value);

    await loadForm();
    await loadData();
});

function allowed(feature: FeaturePermissions, meta: Record<string, boolean> = null)
{
    return checkPermissions(meta || permissions.value, feature);
}

async function loadForm()
{
    try
    {
        const result = await FormsService.fetchPublished(section.value.formId);

        blueprint.value = result.definition;
        builder.value.update();

        blueprint.value = builder.value.getBlueprint() as FormContract;
        filter.document = builder.value.getEntry() as FormEntry;

        permissions.value = await DocumentsService.indexMeta(props.symbol, props.section.symbol);
    }
    catch (ex)
    {
        handleException($log, ex, {
            400: (ex: any) => $alert.warning(ex.message)
        });
    }
}

async function loadData()
{
    loaded.value = false;
    items.value = [];

    if (!props.symbol || !props.section.formId || !blueprint.value || props.preview)
    {
        pager.setTotalRows(0);
        items.value = [];

        return;
    }

    try
    {
        const response = await DocumentsService.index(props.symbol, props.section.symbol, {...filter.data(), formId: props.section.formId}, pager);

        pager.setTotalRows(response.totalRows);
        items.value = response.items;
    }
    catch (ex)
    {
        pager.setTotalRows(0);
        items.value = [];
        $log.debug(ex);
    }
    finally
    {
        persistState(routeKey.value, filter, pager, columns.value);
        loaded.value = true;
    }
}

function gotoForm(item: Resource<ListItemModel> = null)
{
    if (item != null)
        emit('edit', item.result.id);
    else
        emit('add');
}

async function deleteItem(item: Resource<ListItemModel>)
{
    try
    {
        await DocumentsService.remove(props.symbol, props.section.symbol, item.result.id);
        await loadData();

        $alert.success($t('[[[Dokument został usunięty.]]]'));
    }
    catch (ex)
    {
        handleException($log, ex, {
            400: (ex: any) => $alert.warning(ex.message)
        });
    }
}

async function exportData()
{
    loaded.value = false;

    try
    {
        await DocumentsService.exportToExcel(props.symbol, props.section.symbol, {...filter.data(), formId: props.section.formId});
    }
    catch (ex)
    {
        $log.debug(ex);
    }
    finally
    {
        loaded.value = true;
    }
}

async function loadMeta(item: Resource<ListItemModel>)
{
    try
    {
        if (!itemsMeta.value.has(item.result.id))
        {
            const meta = await DocumentsService.fetchMeta(props.symbol, props.section.symbol, item.result.id);

            itemsMeta.value.set(item.result.id, meta);
        }
    }
    catch (ex)
    {
        itemsMeta.value.set(item.result.id, {});
        $log.debug(ex);
    }
}

async function onEdit(item: Resource<ListItemModel>)
{
    await loadMeta(item);

    if (allowed(section.value.features.update, itemsMeta.value.get(item.result.id)))
    {
        gotoForm(item);
    }
    else
    {
        $alert.warning($t('[[[Nie masz uprawnień do tego dokumentu]]]'));
    }
}

async function reload()
{
    await loadData();
}

defineExpose({
    reload
});
</script>

<template>
    <data-card :sticky-footer="!props.embedded">
        <!-- Header -->
        <template #[toolbar]>
            <ideo-panel :column="false" :stretch="false" :start="desktop">
                <template #start>
                    <action-bar :auto-header="false">
                        <ideo-dropdown
                            variant="primary"
                            :split-class="{'disabled': !allowed(section.features.create)}"
                            :toggle-class="{'opacity-75 toggle': true}"
                            split
                            right
                            @click="gotoForm()"
                            v-if="section.settings.canExport && allowed(section.features.export)"
                        >
                            <template #button-content>
                                <div class="text-nowrap">
                                    <i class="fas fa-plus me-2"></i>
                                    {{ $t('[[[Dodaj dokument]]]') }}
                                </div>
                            </template>
                            <ideo-dropdown-item @click.prevent.stop="exportData()">
                                {{ $t('[[[Eksportuj do Excela]]]') }}
                            </ideo-dropdown-item>
                        </ideo-dropdown>
                        <action-button
                            variant="primary"
                            icon="fas fa-plus"
                            :text="$t('[[[Dodaj dokument]]]')"
                            :disabled="!allowed(section.features.create)"
                            @click="gotoForm()"
                            v-else
                        />
                    </action-bar>
                </template>
                <ideo-inline-filters :filter="filter" :settings="section.filters" :advanced="!props.embedded" @change="loadData()" :route-key="routeKey" v-if="section.formId > 0 && section.settings.enableFilters">
                    <ideo-filter-checkbox v-model="filter.onlyMine" name="onlyMine" :label="$t('[[[Moje dokumenty]]]')" />
                    <ideo-filter name="status" :label="$t('[[[Status]]]')" v-if="section.settings.isVersionable">
                        <ideo-form-select name="status" v-model="filter.status" value-field="id" text-field="status" :size="5">
                            <ideo-form-select-option v-for="(item, index) in AllPublicationOptions()" :key="index" :value="item.value">
                                {{ $t(item.text) }}
                            </ideo-form-select-option>
                        </ideo-form-select>
                    </ideo-filter>
                    <ideo-filter-datetime v-model="filter.dateFromUtc" name="dateFromUtc" :label="$t('[[[Data od]]]')" type="datetime" :visible="false" />
                    <ideo-filter-datetime v-model="filter.dateDueUtc" name="dateDueUtc" :label="$t('[[[Data do]]]')" type="datetime" :visible="false" />
                    <form-filters :builder="builder" />
                </ideo-inline-filters>
                <template #end>
                    <list-view-settings />
                    <data-sorting :pager="pager" :options="sortingOptions" @change="loadData()" class="ms-1" v-if="mobile"></data-sorting>
                </template>
            </ideo-panel>
        </template>
        <!-- Tabela -->
        <template #default>
            <form-list-view
                layout="table"
                :loaded="loaded" :builder="builder" :customisable="section.settings.customisableColumns" :sticky-footer="!props.embedded"
                :items="items" :columns="columns" :pager="pager" @change="loadData()"
                :row-click="(item: Resource<ListItemModel>) => onEdit(item)"
                :row-class="(item: Resource<ListItemModel>) => ({
                    'text-muted': !item.meta.canEdit,
                    ...(section.settings.isVersionable ? PublicationStyle('data-{0}', item.result.status) : {})
                })"
                :column-value="(item: Resource<ListItemModel>, column: string) => item.result.document[column]"
            >
                <template #row-start="{item}: any">
                    <list-view-item name="__Id" :title="$t('[[[Id]]]')" sort="Id" width="50">
                        {{ item.result.id }}
                    </list-view-item>
                    <list-view-item name="__DateCreatedUtc" :title="$t('[[[Data dodania]]]')" sort="DateCreatedUtc" width="120">
                        {{ $filters.datetime(item.result.dateCreatedUtc) }}
                    </list-view-item>
                </template>
                <template #row-end="{item}: any">
                    <list-view-buttons name="__Buttons" width="50" @show="loadMeta">
                        <list-view-button
                            :title="$t('[[[Edytuj]]]')"
                            :disabled="!itemsMeta.has(item.result.id) || !allowed(section.features.update, itemsMeta.get(item.result.id))"
                            @click="onEdit(item)"
                        >
                            <i class="icon far fa-pencil-alt"></i> {{ $t('[[[Edytuj]]]') }}
                        </list-view-button>
                        <list-view-button
                            :title="$t('[[[Usuń]]]')"
                            :disabled="!itemsMeta.has(item.result.id) || !allowed(section.features.delete, itemsMeta.get(item.result.id))"
                            :message="$t('[[[Potwierdzenie usunięcia]]]')"
                            @confirm="deleteItem"
                        >
                            <i class="icon far fa-times"></i> {{ $t('[[[Usuń]]]') }}
                        </list-view-button>
                    </list-view-buttons>
                </template>
            </form-list-view>
        </template>
        <!-- Footer -->
        <template #footer>
            <pagination-bar :pager="pager" @change="loadData()" />
        </template>
    </data-card>
</template>

<style scoped>
:deep(.toggle::before) {
    font-family: var(--fa-font-family);
    content: "\f142";
    margin: 0 2px;
}
:deep(.toggle::after) {
    display: none;
}
</style>
