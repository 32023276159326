<script lang="ts" setup>
import { computed } from 'vue';
import { LOGO, useThemeStore } from '@/store/theme';
import Locales from '@/views/partials/layout/Locales.vue';

const themeStore = useThemeStore();
const logo = computed(() => LOGO[themeStore.theme].default);
const year = computed(() => (new Date()).getFullYear());
const changeTheme = (): void =>
{
    themeStore.changeTheme();
};
</script>

<template>
    <div class="auth-layout d-flex" :class="{'align-items-start': phone}">
        <div class="position-relative d-flex align-items-center justify-content-center bg-body" :class="{'w-100 rounded-bottom-4 py-5': phone, 'rounded-end-4 px-5': !phone}">
            <button type="button" class="navbar-toggler position-absolute top-0 end-0 mt-5 me-5 p-3" @click="changeTheme">
                <i class="far fa-circle-half-stroke"></i>
            </button>
            <div class="position-absolute top-0 start-0 mt-4 ms-2 ms-md-5 p-4">
                <Locales />
            </div>
            <div class="wrapper d-flex flex-column">
                <div class="text-center my-4 pt-5 mt-md-0">
                    <img :src="logo">
                </div>
                <router-view />
                <p class="copy text-center my-4">
                    &copy; {{ year }} - E-learning .NET
                </p>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
.auth-layout {
    min-height: 100vh;
    background-image: url(/static/img/texture1.jpg);
    background-position: left center;
    background-size: cover;

    .wrapper {
        width: 500px;

        .card {
            border-color: transparent;
        }

        .copy {
            color: var(--bs-body-color);
        }
    }
}
</style>
