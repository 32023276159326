<template>
    <div class="chart-login">
        <ChartLogins />
    </div>
</template>
<script lang="ts">
import { Options, Vue } from "vue-class-component";
import ChartLogins from '../charts/ChartLogins.vue';

@Options({
    components: {
        ChartLogins
    }
})
export default class Logins extends Vue
{
}
</script>

<style scoped lang="scss">
.chart-login {
    margin: 32px 0 24px;

    @media only screen and (min-width: 960px) {
        margin: 0 0 24px;
    }
}

</style>
