<template>
    <div>
        <DoughnutChart
            ref="chart"
            :chart-data="chartData"
            :options="chartOptions"
            :plugins="[plugin]"
            :style="customStyles"
        />
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Watch } from '@/helpers/Decorators';
import DashboardService, { CoursesCountModel } from '../../services/DashboardService';
import { Chart as ChartJSDoughnut, DoughnutController, ArcElement, Tooltip, Legend } from 'chart.js';
import { DoughnutChart } from 'vue-chart-3';
import { useThemeStore } from '@/store/theme';
import { $t } from '@/plugins/localization';

ChartJSDoughnut.register(DoughnutController, ArcElement, Tooltip, Legend);

@Options({
    name: 'chart-courses',
    components: {
        DoughnutChart
    }
})
export default class ChartCourses extends Vue
{
    public get theme() : string
    {
        return useThemeStore().theme;
    }

    public countCourses: CoursesCountModel = {
        allCoursesCount: 0,
        allCoursesPublishedCount: 0,
        allCoursesInCurrentMonthCount: 0,
        newCoursesPublishedInCurrentMonthCount: 0,
        newCoursesUnpublishedInCurrentMonthCount: 0
    };
    public dataLabels = ([$t('[[[Opublikowane]]]'), $t('[[[Nieopublikowane]]]')]);

    public customStyles = {
        height: '290px'
    };

    public chartData = {
        labels: this.dataLabels,
        datasets: [
            {
                backgroundColor: ['#4689a7', '#d9d9d9'],
                data: [this.countCourses.newCoursesPublishedInCurrentMonthCount, this.countCourses.newCoursesUnpublishedInCurrentMonthCount],
                hoverBackgroundColor: ['#4689a7', '#d9d9d9'],
                hoverOffset: 10,
                hoverBorderWidth: 0
            }
        ]
    };

    public chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        color: this.theme == 'dark' ? '#adb5bd' : '#6f6f6f',
        onResize: function(chart: any): void
        {
            chart.update();
        },
        layout: {
            padding: {
                top: 10,
                bottom: 10
            }
        },
        plugins: {
            legend: {
                display: true,
                position: 'bottom',
                labels: {
                    boxWidth: 14,
                    font: {
                        size: 12,
                        family: 'Segoe UI'
                    }
                }
            },
            tooltip: {
                enabled: true,
                bodyFont: {
                    size: 12,
                    family: 'Segoe UI'
                },
                boxPadding: 6
            }
        }
    };

    public plugin = {
        total: this.countCourses.allCoursesInCurrentMonthCount,
        id: 'doughnut-chart',
        theme: this.theme,
        beforeDraw: function(chart: any): void
        {
            const width = chart.width;
            const height = chart.height;
            const ctx = chart.ctx;

            ctx.restore();

            let sufix;

            if (this.total == 1)
                sufix = $t('[[[szkolenie]]]');
            else if (this.total < 5 && this.total > 0)
                sufix = $t('[[[szkolenia]]]');
            else
                sufix = $t('[[[szkoleń]]]');

            const text = String(this.total);
            const textX = Math.round((width - ctx.measureText(text).width) / 2);
            let textY = (height / 2.3) - ((height / 15.5) / 2) - 5;
            const sufixX = Math.round((width - ctx.measureText(sufix).width) / 2);
            let sufixY = (height / 2) - ((height / 17.5) / 2) - 5;

            if (window.innerWidth < 1327 && window.innerWidth >= 1294)
            {
                textY = (height / 2.3) - ((height / 15.5) / 2) - 20;
                sufixY = (height / 2) - ((height / 17.5) / 2) - 20;
            }
            else if (window.innerWidth < 1294 && window.innerWidth >= 1200)
            {
                textY = (height / 2.3) - ((height / 15.5) / 2) - 33;
                sufixY = (height / 2) - ((height / 17.5) / 2) - 33;
            }

            ctx.textBaseline = 'middle';
            ctx.fillStyle = this.theme == 'dark' ? '#adb5bd' : 'black';

            ctx.font = 'bold ' + (height / 15.5) + 'px Segoe UI';
            ctx.fillText(text, textX, textY);

            ctx.font = 'normal ' + (height / 17.5) + 'px Segoe UI';
            ctx.fillText(sufix, sufixX, sufixY);

            ctx.save();
        }
    };


    @Watch('countCourses', {
        deep: true
    }) public reloadCountCourses(): void
    {
        this.chartData.datasets[0].data = [this.countCourses.newCoursesPublishedInCurrentMonthCount, this.countCourses.newCoursesUnpublishedInCurrentMonthCount];
    }

    public async created(): Promise<void>
    {
        const course = await this.loadData();

        if (course)
        {
            this.plugin.total = course.allCoursesInCurrentMonthCount;
        }
    }

    public async loadData(): Promise<CoursesCountModel>
    {
        try
        {
            this.countCourses = await DashboardService.getCoursesCounters();

            return this.countCourses;
        }
        catch (ex)
        {
            this.handleException(ex, {
                400: (ex: any) => this.$alert.warning(ex.message)
            });
        }
    }
}
</script>
