import { axios } from '@/plugins/axios';
export default class DynamicFormService
{
    /**
     * @param search string
     * @param limit number
     *
     * @returns Promise<ListItemModel[]>
     */
    public static async getAutocompleteList(search: string, limit: number = 10, languageVersion: string = 'pl-PL'): Promise<ListItemModel[]>
    {
        return (await axios.get<ListItemModel[]>('admin/core/forms/autocomplete', {
            params: {
                search: search,
                limit: limit,
                languageVersion
            }
        })).data;
    }

    /**
            * @param id number
            *
            * @returns Promise<ListItemModel>
            */
    public static async fetchAutocompleteModel(id: number, languageVersion: string = 'pl-PL'): Promise<ListItemModel>
    {
        return (await axios.get<ListItemModel>(`admin/core/forms/autocomplete/${id}`, {params: languageVersion})).data;
    }
}

export interface ListItemModel
{
    id: number;
    title: string;
    originId: number;
}

