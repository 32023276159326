<script lang="ts" setup>
import { ref, Ref, computed, watch } from 'vue';
import properties from '../../properties';
import { FormEntry } from '../form';
import { instanceOfRegulationsEntry, RegulationsType, RegulationsEntry } from '.';
import { FormBuilderContract } from '@/components/builder/form';
import { AggregateBlueprint } from '@/components/builder/base/blueprints/AggregateBlueprint';
import RegulationsSetsService from '@/modules/cms/regulations/services/RegulationsSetsService';
import RegulationsService from '@/modules/cms/regulations/services/RegulationsService';
import { computedAsync } from '@vueuse/core';
import WebsitesService from '@/modules/cms/websites/services/WebsitesService';
import { useLogging } from '@/plugins/logging';
import { useLocalization } from '@/plugins/localization';

defineOptions({
    name: 'regulations-blueprint',
    components: {
        ...properties
    }
});

const { $log } = useLogging();
const { $t, $i18n } = useLocalization();

const props = defineProps({
  "blueprint": null,
  "entry": null,
  "form": null,
  "parent": null
});
const blueprint = computed(() => props.blueprint);

const entryData = ref(new RegulationsEntry()) as Ref<RegulationsEntry>;

entryData.value = props.form.document.initEntry(props.blueprint, entryData.value, instanceOfRegulationsEntry);

const checked = computed({
    get()
    {
        return entryData.value.data.checked || [];
    },
    set(value: number[])
    {
        entryData.value.data.checked = value;
    }
});

const required = computed({
    get()
    {
        return entryData.value.data.required || [];
    },
    set(value: number[])
    {
        entryData.value.data.required = value;
    }
});

const websites = computedAsync(async () =>
{
    try
    {
        return await WebsitesService.getOptions();
    }
    catch (ex)
    {
        $log.debug(ex);
    }
});
const websitesOptions = computed(() => websites.value?.map(p => ({value: p.id, text: p.title})));

const website = computed(() => websites.value?.find(p => p.id == blueprint.value.websiteId));
const locales = computed(() => website.value?.locales.map(code => ({
    value: code,
    text: $i18n.options().find(p => p.value == code)?.text
})) || []);

const regulationsSetsOptions = computedAsync(async () =>
{
    try
    {
        return await RegulationsSetsService.getForWebsite(blueprint.value.websiteId, blueprint.value.locale);
    }
    catch (ex)
    {
        $log.debug(ex);
    }
});

const regulationsList = computedAsync(async () =>
{
    try
    {
        const regulations = await RegulationsService.getListBySetId(blueprint.value.regulationSetId);

        required.value = regulations.filter(x => x.isRequired).map(x => x.id);

        return regulations;
    }
    catch (ex)
    {
        $log.debug(ex);
    }
});

watch(() => blueprint.value.locale, () =>
{
    blueprint.value.regulationSetId = 0;
});

watch(() => blueprint.value.websiteId, () =>
{
    blueprint.value.locale = null;
    blueprint.value.regulationSetId = 0;
});
</script>

<template>
    <form-component-wrapper class="regulations-component" :form="form" :parent="parent" :blueprint="blueprint">
        <template #default>
            <div class="form-group">
                <form-label :blueprint="blueprint" :entry="entryData" />
                <template v-for="regulation in regulationsList" :key="regulation.id">
                    <ideo-form-checkbox v-model="checked" name="sitemap" :value="regulation.id">
                        {{ regulation.title }}
                        <i class="fa-solid fa-fw fa-star-of-life fa-2xs text-danger" v-if="regulation.isRequired"></i>
                    </ideo-form-checkbox>
                </template>
                <form-error-message :entry="entryData" name="value" />
                <form-error-message :entry="entryData" name="custom" />
                <form-help :blueprint="blueprint" />
            </div>
        </template>
        <template #properties>
            <field-name :form="form" :blueprint="blueprint" v-model="blueprint.name" />
            <field-text v-model="blueprint.label" :label="$t('[[[Etykieta]]]')" />
            <field-checkbox v-model="blueprint.showLabel" :label="$t('[[[Pokaż etykietę]]]')" />
            <field-select v-model="blueprint.websiteId" :options="websitesOptions" :label="$t('[[[Witryna]]]')" :invalid-feedback="() => form.schema.errorMessage(blueprint, 'websiteId')" v-if="websitesOptions" />
            <field-select v-model="blueprint.locale" :options="locales" :label="$t('[[[Wersja językowa]]]')" :invalid-feedback="() => form.schema.errorMessage(blueprint, 'locale')" v-if="blueprint.websiteId > 0" />
            <field-select v-model="blueprint.regulationSetId" :options="regulationsSetsOptions" :label="$t('[[[Zestaw]]]')" :invalid-feedback="() => form.schema.errorMessage(blueprint, 'regulationSetId')" v-if="blueprint.locale" />
        </template>
    </form-component-wrapper>
</template>
