import { axios } from '@/plugins/axios';
import merge from 'lodash/merge';
import Pager from '@/helpers/Pager';
import { Resource as File } from '@/modules/core/files/services/StorageService';
import { Pagination, Resource, Statement } from '@/helpers/Interfaces';

/**
 * AthorsService
 */
export default class AuthorsService
{
    /**
     * @param filter FilterModel
     * @param pager Pager
     *
     * @returns Promise<Pagination<Resource<ListItemModel>>>
     */
    public static async getList(filter: FilterModel, pager: Pager): Promise<Pagination<Resource<ListItemModel>>>
    {
        return (await axios.get<Pagination<Resource<ListItemModel>>>('admin/cms/authors', {
            params: merge({}, filter, pager.data())
        })).data;
    }

    /**
     * @param id number
     *
     * @returns Promise<Resource<FormModel>>
     */
    public static async fetch(id: number): Promise<Resource<FormModel>>
    {
        return (await axios.get<Resource<FormModel>>(`admin/cms/authors/${id}`)).data;
    }

    /**
     * @param id number
     *
     * @returns Promise<Resource<FormModel>>
     */
    public static async fetchDefault(): Promise<Resource<FormModel>>
    {
        return (await axios.get<Resource<FormModel>>(`admin/cms/authors/default`)).data;
    }

    /**
     * @param model UpsertModel
     *
     * @returns Promise<Resource<FormModel>>
     */
    public static async create(model: FormModel): Promise<Resource<FormModel>>
    {
        return (await axios.post<Resource<FormModel>>(`admin/cms/authors`, model)).data;
    }

    /**
     * @param id number
     * @param model UpsertModel
     *
     * @returns Promise<Resource<FormModel>>
     */
    public static async update(id: number, model: FormModel): Promise<Resource<FormModel>>
    {
        return (await axios.put<Resource<FormModel>>(`admin/cms/authors/${id}`, model)).data;
    }

    /**
     * @param id number
     *
     * @returns Promise<Statement>
     */
    public static async remove(id: number): Promise<Statement>
    {
        return (await axios.delete(`admin/cms/authors/${id}`)).data as Statement;
    }

    /**
     * @param search string
     * @param limit number
     *
     * @returns Promise<ListItemModel[]>
     */
    public static async getAutocompleteList(search: string, limit: number = 10): Promise<ListItemModel[]>
    {
        return (await axios.get<ListItemModel[]>('admin/cms/authors/autocomplete', {
            params: {
                search: search,
                limit: limit
            }
        })).data;
    }

    /**
        * @param id number
        *
        * @returns Promise<ListItemModel>
        */
    public static async fetchAutocompleteModel(id: number): Promise<ListItemModel>
    {
        return (await axios.get<ListItemModel>(`admin/cms/authors/autocomplete/${id}`)).data;
    }

    /**
     * @param filter FilterModel
     *
     * @returns Promise<Pagination<Resource<ListItemModel>>>
     */
    public static async getAllAuthorsList(filter: FilterModel): Promise<Pagination<Resource<ListItemModel>>>
    {
        return (await axios.get<Pagination<Resource<ListItemModel>>>('admin/cms/authors/sort', {
            params: merge({}, filter)
        })).data;
    }

    /**
     * @param positions PagePosition[]
     *
     * @returns Promise<Statement>
     */
    public static async sortAuthors(positions: PagePosition[]): Promise<Statement>
    {
        return (await axios.put<Statement>(`admin/cms/authors/sort/positions`, positions)).data;
    }
}

export interface ListItemModel
{
    authorType: string;
    facePicture?: File;
    id: number;
    isExistingUser: boolean;
    isPulished: boolean;
    position?: number;
    userName: string;
    websiteName: string;
}

export interface FilterModel
{
    locale: string;
    websiteId: number;
    userName?: string;
    isExistingUser?: boolean;
}

export interface FormModel {
    authorType: string;
    description: string;
    facePicture?: File;
    id: number;
    isExistingUser: boolean;
    isPulished: boolean;
    locale: string;
    shortDescription: string;
    symbol: string;
    userId?: number;
    userName: string;
    websiteId: number;
}

export interface PagePosition
{
    id: number;
    position: number;
}
