<template>
    <ideo-form-group :label="$t('[[[Kategoria szkolenia]]]')" :invalid-feedback="form.$errors.first('settings.categories')" :state="form.$errors.state('settings.categories')" required>
        <ideo-autocomplete-multi-select
            v-model="form.settings.categories"
            name="settings.categories"
            :placeholder="$t('[[[zacznij pisać aby wyszukać kategorię...]]]')"
            :fetch="(id) => fetchCategory(id)"
            :search="(query, limit) => searchCategories(query, limit)"
        >
            <template #selection="{ option }">
                <div class="d-flex flex-nowrap align-items-center">
                    <template v-for="(opt, i) in option" :key="i">
                        <span class="ms-2 multiselect-badges"><b>{{ opt.name }}</b></span>
                    </template>
                </div>
            </template>
            <template #option="{ option }">
                <div class="d-flex flex-nowrap align-items-center">
                    <b class="ms-2">{{ option.name }}</b>
                </div>
            </template>
        </ideo-autocomplete-multi-select>
    </ideo-form-group>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Emit, Prop, Watch } from '@/helpers/Decorators';
import { Option } from '@/helpers/Interfaces';
import { FormType } from '@/helpers/Form';
import merge from 'lodash/merge';
import CourseCategoriesService, { ListItemModel } from '@/modules/elearning/courses/services/CourseCategoriesService';

interface FormSettings
{
    settings: any;
}

@Options({
    name: 'widget-coursecategories-settings'
})
export default class CourseCategoriesSettings extends Vue
{
    @Prop() public modelValue!: any;

    public defaults: any = {
        categories: ''
    };

    public get form(): FormType<FormSettings>
    {
        return this.modelValue;
    }

    public get quantity(): Option<number>[]
    {
        return new Array(10).fill(null).map((value, index) =>
        {
            return { value: index + 1, text: (index + 1).toString() };
        });
    }

    public async created(): Promise<void>
    {
        this.form.settings = merge(this.defaults, this.form.settings);
    }

    @Watch('form.settings', { deep: true })
    public onSettingsChanged(value: any): void
    {
        this.triggetModel();
    }

    @Emit('update:modelValue')
    public triggetModel(): any
    {
        return this.form;
    }

    public async fetchCategory(id: any): Promise<ListItemModel>
    {
        if (id != 0)
        {
            const response = await CourseCategoriesService.fetchAutocompleteModel(id);

            return response || null;
        }

        return null;
    }

    public async searchCategories(query: string, limit: number): Promise<ListItemModel[]>
    {
        if (query.length < 3) return [];

        return await CourseCategoriesService.getAutocompleteList(query, limit);
    }
}
</script>
