<script lang="ts" setup>
import { ref, reactive, computed, watch, type Ref } from 'vue';
import { FeaturePermissions, SectionItem } from '../../../modules/services/types';
import { Form, FormType } from '@/helpers/Form';
import { useAlerts } from '@/plugins/alerts';
import { useLogging } from '@/plugins/logging';
import { useLocalization } from '@/plugins/localization';
import { handleException } from '@/helpers/Utils';
import { PublicationEnum } from '@/helpers/Enums';
import { FormContract, FormEntry } from '@/components/forms/blueprints/form';
import { FluentFormBuilder } from '@/components/forms';
import FormsService from '@/modules/studio/forms/services/FormsService';
import DocumentsService, { FormModel, checkPermissions } from '../../services/DocumentsService';
import ResourcePermissions from '@/components/security/ResourcePermissions.vue';
import FormVersion from '../../../common/views/FormVersion.vue';
import Versions from '../../../common/views/Versions.vue';
import Compare from '../../../common/views/Compare.vue';
import { Blueprint } from '@/components/builder/base/blueprints/Blueprint';
import { Entry } from '@/components/builder/form/entries/Entry';
import { AttachmentsContract, instanceOfAttachmentsEntry } from '@/components/forms/blueprints/attachments';
import Pager from '@/helpers/Pager';
import { Resource } from '@/helpers/Interfaces';

const props = defineProps({
  "symbol": null,
  "section": null,
  "modal": { type: Boolean, default: false },
  "id": { default: 0 }
});

const emit = defineEmits(["change", "exit"]);

const { $alert } = useAlerts();
const { $log } = useLogging();
const { $t } = useLocalization();

const id = computed(() => props.id);
const section = computed(() => props.section);
const toolbar = computed(() => props.modal ? 'header' : 'toolbar');
const isVersionable = computed(() => section.value.settings.isVersionable);
const hasPermissions = computed(() => section.value.settings.hasResourcePermissions && allowed(props.section.features.manage));
const hasSidebar = computed(() => isVersionable.value || hasPermissions.value);
const form = reactive(Form.create<FormModel>({
    id: 0,
    formId: 0,
    cohesionId: null,
    document: null,
    status: PublicationEnum.Draft,
    ownerId: 0,
    resourceId: 0
})) as FormType<FormModel>;
const compare = ref(null) as any as FormType<FormModel>;
const permissions = ref<Record<string, boolean>>({});
const accepted = computed(() => form.status == PublicationEnum.Accepted);

const blueprint = ref(null) as Ref<FormContract>;
const builder = ref(FluentFormBuilder
    .setup(false, true)
    .blueprint(() => blueprint.value, () => section.value.formId)
    .entry(() => form.document, () => form.id)
    .make());

watch(id, async () =>
{
    form.wait(true);
    await loadData();
    await loadForm();
    form.complete();
},
{immediate: true});

function allowed(feature: FeaturePermissions)
{
    return checkPermissions(permissions.value, feature);
}

async function loadData()
{
    if (!id.value) return;

    try
    {
        const response = await DocumentsService.fetch(props.symbol, props.section.symbol, id.value);

        form.withData(response.result);

        permissions.value = await DocumentsService.fetchMeta(props.symbol, props.section.symbol, id.value);
    }
    catch (ex)
    {
        handleException($log, ex, {
            400: (ex: any) => $alert.warning(ex.message)
        });
    }
}

async function loadForm()
{
    try
    {
        const result = !id.value ?
            await FormsService.fetchPublished(section.value.formId) :
            await FormsService.fetchDefinition(form.formId);

        blueprint.value = result.definition;
        builder.value.update();

        blueprint.value = builder.value.getBlueprint() as FormContract;
        form.document = builder.value.getEntry() as FormEntry;
        form.formOrigin = result.resourceId;
        form.formId = result.id;

        if (Object.keys(permissions.value).length == 0)
        {
            permissions.value = await DocumentsService.indexMeta(props.symbol, props.section.symbol);
        }
    }
    catch (ex)
    {
        handleException($log, ex, {
            400: (ex: any) => $alert.warning(ex.message)
        });
    }
}

async function saveForm()
{
    try
    {
        form.wait();

        if (builder.value.validateEntry())
        {
            const entry = await builder.value.collectEntry();
            const { result } = id.value == 0 ?
                await DocumentsService.create(props.symbol, props.section.symbol, form.except(['document']), entry) :
                await DocumentsService.update(props.symbol, props.section.symbol, id.value, form.except(['document']), entry);

            await builder.value.collectEntry(async (blueprint: Blueprint, entry: Entry) =>
            {
                if (instanceOfAttachmentsEntry(entry) && entry.data)
                {
                    await entry.upload(blueprint as AttachmentsContract, async (file) =>
                    {
                        return await DocumentsService.upload(props.symbol, props.section.symbol, blueprint.name, result.id, file.id, file.upload);
                    });
                }
            });

            return result.id;
        }
    }
    catch (ex)
    {
        if (id.value == 0 && form.id > 0)
        {
            await DocumentsService.remove(props.symbol, props.section.symbol, form.id);
        }

        handleException($log, ex, {
            400: (ex: any) => $alert.warning(ex.message),
            403: (ex: any) => $alert.warning(ex.message),
            422: (ex: any) =>
            {
                if ('parentId' in ex.data.errors)
                {
                    $alert.warning(ex.data.errors['parentId'][0]);
                }

                builder.value.setEntryErrors(ex.data.errors);
            }
        });

        return null;
    }
    finally
    {
        form.continue();
    }
}

function onBack()
{
    emit('exit', false);
}

async function onSubmit()
{
    const result = await saveForm();

    if (result)
    {
        $alert.success(id.value == 0 ?
            $t('[[[Wpis został dodany.]]]') :
            $t('[[[Wpis został zaktualizowany.]]]')
        );
        emit('change', result);
    }
}

async function onSubmitAndBack()
{
    const result = await saveForm();

    if (result)
    {
        $alert.success(id.value == 0 ?
            $t('[[[Wpis został dodany.]]]') :
            $t('[[[Wpis został zaktualizowany.]]]')
        );
        emit('exit', true);
    }
}

async function onAccept()
{
    try
    {
        const result = await saveForm();

        if (result)
        {
            emit('change', result);

            await DocumentsService.status(props.symbol, props.section.symbol, id.value, PublicationEnum.Accepted);

            form.status = PublicationEnum.Accepted;

            $alert.success($t('[[[Wpis został zaakceptowany.]]]'));
        }
    }
    catch (ex)
    {
        handleException($log, ex, {
            400: (ex: any) => $alert.warning(ex.message)
        });
    }
}

async function updateVersion(formId: number)
{
    form.wait();
    form.formId = formId;
    await loadForm();
    form.complete();
}

function onCompare(version: Resource<FormModel>)
{
    compare.value = version.result;
}

function closeCompare()
{
    compare.value = null;
}

async function getVersions(id: number, pager: Pager)
{
    return await DocumentsService.getVersions(props.symbol, props.section.symbol, id, pager);
}

async function fetchVersion(id: number)
{
    return await DocumentsService.fetch(props.symbol, props.section.symbol, id);
}

async function changeStatus(id: number, status: PublicationEnum)
{
    await DocumentsService.status(props.symbol, props.section.symbol, id, status);
}

async function selectVersion(id: number)
{
    emit('change', id);
}
</script>

<template>
    <ideo-form @submit.prevent="onSubmit()" @input="form.$errors.clear($event.target.name);" novalidate>
        <placeholder-form :loading="form.loading()">
            <data-card :use-panels="true" :right-panel-name="$t('[[[Właściwości]]]')" :disable-right="!hasSidebar">
                <!-- Toolbar -->
                <template #[toolbar]>
                    <action-bar :toggle-footer="true">
                        <action-button
                            back variant="outline-dark"
                            :icon="modal ? 'far fa-xmark' : 'far fa-arrow-left'"
                            :text="modal ? $t('[[[Zamknij]]]') : $t('[[[Powrót]]]')"
                            @click="onBack()"
                        />
                        <action-button
                            toolbar submit variant="primary"
                            icon="fas fa-check" :text="$t('[[[Zapisz]]]')"
                            :disabled="!form.active() || !allowed(section.features.update)"
                        />
                        <action-button
                            toolbar variant="success"
                            icon="far fa-check-circle" :text="modal ? $t('[[[Zapisz i zamknij]]]') : $t('[[[Zapisz i wróć]]]')"
                            :disabled="!form.active() || !allowed(section.features.update)"
                            @click="onSubmitAndBack()"
                        />
                        <action-button
                            toolbar variant="secondary"
                            icon="far fa-check-square" :text="$t('[[[Akceptuj]]]')"
                            :confirm="$t('[[[Potwierdź akceptację]]]')"
                            :disabled="!form.active()" v-if="isVersionable && id && !accepted && allowed(section.features.accept)"
                            @click="onAccept()"
                        />
                    </action-bar>
                </template>
                <!-- Prawy panel -->
                <template #right>
                    <ideo-tabs justified content-class="mt-3">
                        <ideo-tab :title="$t('[[[Wersje]]]')" v-if="isVersionable">
                            <Versions
                                :form="form"
                                :can-edit="allowed(section.features.update)"
                                :can-accept="allowed(section.features.accept)"
                                :versions="getVersions"
                                :fetch="fetchVersion"
                                :status="changeStatus"
                                @select="selectVersion"
                                @compare="onCompare"
                            />
                            <Compare
                                :current="form.data()"
                                :version="compare"
                                @close="closeCompare"
                            />
                        </ideo-tab>
                        <ideo-tab :title="$t('[[[Uprawnienia]]]')" v-if="hasPermissions">
                            <ResourcePermissions
                                :resource="section.id"
                                :id="form.resourceId"
                            />
                        </ideo-tab>
                    </ideo-tabs>
                </template>
                <!-- Formularz -->
                <template #default>
                    <FormVersion :form-origin="section.formId" :form-id="form.formId" @update="updateVersion" />
                    <form-blueprint :builder="builder" @submit="onSubmit()" v-if="!form.loading()" />
                </template>
            </data-card>
        </placeholder-form>
    </ideo-form>
</template>
