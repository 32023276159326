<template>
    <div v-bind="attrs()">
        <list-view-utils-flex v-for="(item, index) in rows" :key="`row-${index}`" :index="index" :item="item" :headers="props.headers">
            <slot name="row" :item="item" :index="index"></slot>
        </list-view-utils-flex>
        <div v-if="rows.length == 0">
            <div class="text-center p-3 mt-1 mb-3 bg-body-tertiary">{{ $t(emptyLabel) }}</div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { inject, useAttrs } from 'vue';
import Pager from '@/helpers/Pager';
import Flex from '../utils/Flex.vue';
import { only } from '@/helpers/Utils';

export interface Props
{
    showHeader: boolean;
    headers: any[];
    rows: any[];
    pager: Pager;
}

defineOptions({
    name: 'list-view-flex-layout',
    inheritAttrs: false,
    components: {
        'list-view-utils-flex': Flex
    }
});

const props = defineProps({
  "showHeader": { type: Boolean,  },
  "headers": null,
  "rows": null,
  "pager": null
});
const emptyLabel = inject<string>('empty-label');
const $attrs = useAttrs();
const attrs = (): Record<string, any> => only($attrs, 'class', 'style');
</script>
