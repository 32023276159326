import { App, getCurrentInstance, Plugin, reactive } from 'vue';
import { Option } from '@/helpers/Interfaces';

let currentInstance: I18n | null | any = null;

export const $i18n = new Proxy({}, {
    get: (target, property) => currentInstance[property]
}) as I18n;

export const $t = (key: string, ...replacements: any[]): string =>
{
    return currentInstance?.$t(key, ...replacements);
};

interface NuggetOptions
{
    beginToken: string;
    endToken: string;
    delimiterToken: string;
    commentToken: string;
    msgkeyDelimiter: string;
    untranslatable: string;
}

interface I18nOptions
{
    locale?: string;
    defaultLocale?: string;
    languages?: Record<string, string>;
    translations?: Record<string, Record<string, string>>;
    nugget?: NuggetOptions;
    variableToken?: [string, string];
    messageContextEnabled?: boolean;
}

export interface I18n
{
    setLocale(locale: string): void
    locale(): string;
    defaultLocale(): string;
    shortLocale(): string;
    detectLocale(defaultLocale: string): void;
    languages(): Record<string, string>;
    setLanguages(languages: Record<string, string>): void;
    currentTranslation(value: Record<string, string>): string;
    registerTranslations(translations: Record<string, Record<string, string>>): void;
    options(): Option[];
    codes(): string[];
    translate(key: string, ...replacements: any[]): string;
    $t(key: string, ...replacements: any[]): string;
}

class I18nHelper implements I18n
{
    private config: I18nOptions;
    private variableMatcher: RegExp;

    public constructor(app: App<Element>, options: I18nOptions)
    {
        this.config = reactive(Object.assign({
            locale: null,
            languages: {} as any,
            translations: {} as any,
            nugget: {
                beginToken: '[' + '[[', // + is to disable triggering the translate parser in this file
                endToken: ']]' + ']', // + is to disable triggering the translate parser in this file
                delimiterToken: '|||',
                commentToken: '///',
                msgkeyDelimiter: ':£#£#£:',
                untranslatable: '~~~'
            },
            variableToken: ['{', '}'],
            messageContextEnabled: true,
            defaultLocale: 'pl-PL',
        }, options)) as I18nOptions;

        this.variableMatcher = new RegExp('' + this.config.variableToken[0] + '\\w+' + this.config.variableToken[1], 'g');
    }

    public locale(): string
    {
        // @ts-ignore
        return this.config.locale || navigator.language || navigator.userLanguage;
    }

    public defaultLocale(): string
    {
        return this.config.defaultLocale;
    }

    public setLocale(locale: string): void
    {
        this.config.locale = locale;
    }

    private addLocale(locale: string, translations: Record<string, string>): void
    {
        this.config.translations[locale] = translations;
    }

    public shortLocale(): string
    {
        return this.locale().split('-')[0];
    }

    public detectLocale(defaultLocale: string): void
    {
        // @ts-ignore
        const locale = navigator.language || navigator.userLanguage;
        const supportedCultures = Object.keys(this.config.translations);

        this.config.locale = null;

        for (let i = 0; i < supportedCultures.length; i++)
        {
            if (supportedCultures[i] === locale)
            {
                this.config.locale = supportedCultures[i];
                break;
            }
        }

        if (this.config.locale == null)
        {
            for (let i = 0; i < supportedCultures.length; i++)
            {
                if (supportedCultures[i].split('-')[0] === locale)
                {
                    this.config.locale = supportedCultures[i];
                    break;
                }
            }
        }

        if (this.config.locale == null)
        {
            this.config.locale = defaultLocale;
        }
    }

    private localeExists(locale: string): boolean
    {
        return this.config.translations.hasOwnProperty(locale);
    }

    private keyExists(key: string): boolean
    {
        const locale = this.locale();
        const translations = this.config.translations;

        if (translations.hasOwnProperty(locale) === false)
        {
            return false;
        }

        return translations[locale].hasOwnProperty(key);
    }

    private getNugget(key: string): any
    {
        const params = key
            .replace(this.config.nugget.beginToken, '')
            .replace(this.config.nugget.endToken, '')
            .split(this.config.nugget.commentToken);

        const nuget = {
            msgId: params[0].split(this.config.nugget.delimiterToken)[0],
            comment: params.length === 2 ? params[1] : null,
            formatItems: params[0].split(this.config.nugget.delimiterToken).splice(1)
        };

        return nuget;
    }

    private getMsgKey(nugget: any): string
    {
        if (this.config.messageContextEnabled && nugget.comment != null && nugget.comment.length > 0 && !nugget.comment.includes(this.config.nugget.untranslatable))
        {
            return `${nugget.msgId}${this.config.nugget.msgkeyDelimiter}${nugget.comment}`;
        }

        return nugget.msgId;
    }

    public currentTranslation(value: Record<string, string>)
    {
        return value[this.locale()] || value[this.defaultLocale()];
    }

    public registerTranslations(translations: Record<string, Record<string, string>>): void
    {
        Object.entries(translations).forEach(([locale, messages]) =>
        {
            this.config.translations[locale] = {
                ...(this.config.translations[locale] || {}),
                ...messages
            };
        });
    }

    public translate(key: string, replacements? : any, ...args: any[]): string
    {
        const nugget = this.getNugget(key || '');
        const msgkey = this.getMsgKey(nugget);
        let message = '';
        const locale = this.locale();
        const translations = this.config.translations;

        if (translations.hasOwnProperty(locale) && translations[locale].hasOwnProperty(msgkey))
        {
            message = translations[locale][msgkey];
        }

        if (message.length === 0)
        {
            message = nugget.msgId;
        }

        if (arguments.length === 2 && typeof replacements !== 'object' && typeof replacements !== typeof Array)
        {
            replacements = [replacements];
        }
        else if (arguments.length > 2)
        {
            replacements = Array.prototype.slice.call([key || '', replacements, ...args].filter(p => p), 1);
        }

        if (nugget.formatItems.length > 0)
        {
            // Example: this.$t('[[[Pozostało %0 znaków.|||{0}]]]', value)
            for (let i = 0; i < nugget.formatItems.length; i++)
            {
                message = message.replace(`%${i}`, this.translate(nugget.formatItems[i]));
            }

            if (replacements !== null && typeof replacements === 'object')
            {
                message = message.replace(this.variableMatcher, (placeholder) =>
                {
                    const key = placeholder.replace(this.config.variableToken[0], '').replace(this.config.variableToken[1], '');

                    if (replacements[key] !== undefined)
                    {
                        return replacements[key];
                    }

                    return placeholder;
                });
            }
        }

        // Zamiana `` na znak ". Czasami się przydaje.
        return message.replace(/``/g, '"');
    }

    public $t(key: string, replacements? : any, ...args: any[]): string
    {
        return this.translate(key, replacements, ...args);
    }

    public languages(): Record<string, string>
    {
        return this.config.languages;
    }

    public setLanguages(languages: Record<string, string>): void
    {
        this.config.languages = languages;
    }

    public codes(): string[]
    {
        return Object.keys(this.languages());
    }

    public options(): Option[]
    {
        return this.codes().map(p =>
        {
            return { value: p.split('-')[0], text: this.translate(this.config.languages[p]) };
        });
    }
}


export const useLocalization = (): {$t: typeof $t, $i18n: typeof $i18n} =>
{
    const app = getCurrentInstance();

    return {
        $t: app.appContext.config.globalProperties.$t,
        $i18n: app.appContext.config.globalProperties.$i18n
    };
};


interface ITranslation
{
    Language: string;
    Messages: Record<string, string>;
}

const LocalizationPlugin: Plugin =
{
    install(app, options)
    {
        const vue = app.config.globalProperties;
        const messages = {} as Record<string, Record<string, string>>;
        const translations = import.meta.glob(`@/locales/**/messages.ts`, { eager: true });

        Object.values(translations).forEach((result: any) =>
        {
            const translations = result.default as ITranslation;

            messages[translations.Language] = translations.Messages;
        });

        vue.$i18n = currentInstance = new I18nHelper(app, { translations: messages });
        vue.$t = $t;
        vue.$config('i18n').then((config: any) =>
        {
            if (config)
            {
                vue.$i18n.setLanguages(config.languages);
                vue.$i18n.detectLocale(config.locale);
            }
        });
    }
};

export default LocalizationPlugin;

declare module "@vue/runtime-core"
{
    interface ComponentCustomProperties
    {
        $i18n: I18n;
        $t(key: string, replacements? : any, ...args: any[]): string
    }
}
