<template>
    <component :is="tag" class="css-collapse" :class="{'show': modelValue}">
        <slot name="default"></slot>
    </component>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop } from '@/helpers/Decorators';

@Options({
    name: 'ideo-collapse'
})
export default class IdeoCollapse extends Vue
{
    @Prop({ default: false })
    public modelValue: boolean;

    @Prop({ default: 'div' })
    public tag: string;
}
</script>

<style lang="scss">
.css-collapse {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.35s ease;

    &.show {
        max-height: 100vh;
        overflow: visible;
    }
}
</style>
