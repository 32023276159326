<template>
    <ideo-form-group class="widget-logo__picture" :label="$t('[[[Obrazek]]]')" :invalid-feedback="form.$errors.first(`settings.images[${id}].file`)" :state="form.$errors.state(`settings.images[${id}].file`)" required>
        <filepicker v-model="form.settings.images[id].file" media="images" />
    </ideo-form-group>
    <ideo-form-group class="widget-logo__link" :label="$t('[[[Link]]]')" :invalid-feedback="form.$errors.first(`link`)" :state="form.$errors.state(`link`)">
        <ideo-form-input type="text" v-model="form.settings.images[id].link" name="link"></ideo-form-input>
    </ideo-form-group>
    <ideo-form-group class="widget-logo__newTab" :invalid-feedback="form.$errors.first(`link`)" :state="form.$errors.state(`link`)">
        <ideo-form-checkbox v-model="form.settings.images[id].openInNewTab" name="openInNewTab">
            {{ $t('[[[Otwórz w nowym oknie]]]') }}
        </ideo-form-checkbox>
    </ideo-form-group>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Emit, Prop, Watch } from '@/helpers/Decorators';
import { ValidatableComponent } from '@/components/cms';
import merge from 'lodash/merge';
import { FormType } from '@/helpers/Form';

interface FormSettings
{
    file: any;
}

@Options({
    name: 'widget-imageslide'
})
export default class ImageSlide extends Vue implements ValidatableComponent
{
    @Prop() public modelValue!: any;
    @Prop() public id: number;

    public defaults: any = {
        description: '',
        images: [{
            file: null,
            link: ''
        }],
        openInNewTab: false
    };

    public get form(): FormType<FormSettings>
    {
        return this.modelValue;
    }

    public async created(): Promise<void>
    {
        this.form.settings = merge(this.defaults, this.form.settings);
    }

    @Watch('modelValue', { deep: true })
    public onSettingsChanged(value: any): void
    {
        this.triggetModel();
    }

    @Emit('update:modelValue')
    public triggetModel(): any
    {
        return this.form;
    }

    public async validate(): Promise<void>
    {
    }
}
</script>
