<script lang="ts" setup>
import { ref, Ref, computed, watch } from 'vue';
import properties from '@/components/forms/properties';
import { RootEntry } from '@/components/builder/form/entries/RootEntry';
import { instanceOfBooleanEntry, BooleanType, BooleanEntry, BooleanFieldTypes } from '.';
import { useLocalization } from '@/plugins/localization';
import { Option } from '@/helpers/Interfaces';
import { FormBuilderContract } from '@/components/builder/form';
import { AggregateBlueprint } from '@/components/builder/base/blueprints/AggregateBlueprint';

defineOptions({
    name: 'boolean-blueprint',
    components: {
        ...properties
    }
});

const { $t } = useLocalization();

const props = defineProps({
  "blueprint": null,
  "entry": null,
  "form": null,
  "parent": null,
  "index": null
});
const blueprint = computed(() => props.blueprint);

const entryData = ref(new BooleanEntry()) as Ref<BooleanEntry>;

entryData.value = props.form.document.initEntry(props.blueprint, entryData.value, instanceOfBooleanEntry, props.index);

const value = computed({
    get()
    {
        const { data: value } = entryData.value;

        return value === null
            ? props.blueprint.defaultValue
            : value;
    },
    set(value: boolean)
    {
        entryData.value.data = value;
    }
});

const defaultValue = computed({
    get()
    {
        return props.blueprint.defaultValue;
    },
    set(value: any)
    {
        blueprint.value.defaultValue = JSON.parse(value);
    }
});

const trueValueText = computed<string>(() => props.form.localization.translate(props.blueprint.trueValueText) || $t('[[[Tak]]]'));
const falseValueText = computed<string>(() => props.form.localization.translate(props.blueprint.falseValueText) || $t('[[[Nie]]]'));
const placeholderText = computed<string>(() => props.form.localization.translate(props.blueprint.placeholder) || $t('[[[Akceptacja]]]'));

const booleanValueOptions = computed<Option<boolean>[]>(() => ([
    { value: true, text: trueValueText.value },
    { value: false, text: falseValueText.value }
]));

const fieldTypeOptions: Option<BooleanFieldTypes>[] = [
    { value: BooleanFieldTypes.Radio, text: '[[[Radio]]]' },
    { value: BooleanFieldTypes.Checkbox, text: '[[[Checkbox]]]' },
    { value: BooleanFieldTypes.Toggle, text: '[[[Przełącznik]]]' }
];

const isRadioField = computed(() => props.blueprint.fieldType === BooleanFieldTypes.Radio);
const isCheckboxField = computed(() => props.blueprint.fieldType === BooleanFieldTypes.Checkbox);
const isToggleField = computed(() => props.blueprint.fieldType === BooleanFieldTypes.Toggle);

const required = computed(() => props.form.expressions.required(props.blueprint));

watch(defaultValue, (value: boolean, old: boolean) =>
{
    if (value != old)
    {
        entryData.value.data = defaultValue.value;
    }
});
</script>

<template>
    <form-component-wrapper class="boolean-component" :form="form" :parent="parent" :blueprint="blueprint">
        <template #default>
            <div class="form-group">
                <form-label :form="form" :blueprint="blueprint" :required="required" :entry="entryData" />
                <div class="pt-1 ms-2">
                    <ideo-form-radio-group v-model="value" :options="booleanValueOptions" v-if="isRadioField"></ideo-form-radio-group>
                    <ideo-form-checkbox v-model="value" v-if="isCheckboxField">{{ placeholderText }}</ideo-form-checkbox>
                    <ideo-form-checkbox v-model="value" switch v-if="isToggleField">{{ placeholderText }}</ideo-form-checkbox>
                </div>
                <form-error-message :entry="entryData" name="value" />
                <form-error-message :entry="entryData" name="custom" />
                <form-help :form="form" :blueprint="blueprint" />
            </div>
        </template>
        <template #properties>
            <field-name :form="form" :blueprint="blueprint" v-model="blueprint.name" />
            <ideo-form-localize v-slot="{ locale }">
                <field-text v-model="blueprint.label[locale]" :label="$t('[[[Etykieta]]]')" />
            </ideo-form-localize>
            <field-checkbox v-model="blueprint.showLabel" :label="$t('[[[Pokaż etykietę]]]')" />
            <field-choice v-model="blueprint.fieldType" :options="fieldTypeOptions" :label="$t('[[[Typ pola]]]')" />
            <field-select v-model="defaultValue" :options="booleanValueOptions" :label="$t('[[[Domyślna wartość]]]')" />
            <ideo-form-localize v-if="!isRadioField" v-slot="{ locale }">
                <field-text v-model="blueprint.placeholder[locale]" :label="$t('[[[Opis]]]')" :placeholder="$t('[[[Akceptacja]]]')" />
            </ideo-form-localize>
            <ideo-form-localize v-if="isRadioField" v-slot="{ locale }">
                <div class="form-group mb-0" v-if="isRadioField">
                    <label>{{ $t('[[[Nazwy wartości]]]') }}</label>
                    <div class="row">
                        <div class="col">
                            <field-text v-model="blueprint.falseValueText[locale]" :placeholder="$t('[[[Nie]]]')" />
                        </div>
                        <div class="col">
                            <field-text v-model="blueprint.trueValueText[locale]" :placeholder="$t('[[[Tak]]]')" />
                        </div>
                    </div>
                </div>
            </ideo-form-localize>
            <ideo-form-localize v-slot="{ locale }">
                <field-textarea v-model="blueprint.help[locale]" :label="$t('[[[Pomoc]]]')" />
            </ideo-form-localize>
            <field-visible :form="form" :blueprint="blueprint" />
            <field-readonly :form="form" :blueprint="blueprint" />
            <field-required :form="form" :blueprint="blueprint" />
            <field-error :form="form" :blueprint="blueprint" />
            <field-filter :form="form" :blueprint="blueprint" />
        </template>
    </form-component-wrapper>
</template>

<style lang="scss" scoped>
</style>
