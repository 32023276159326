<template>
    <div class="widget-opinions">
        <div class="widget-opinions__box widget-opinions--left">
            <div class="row">
                <ideo-form-group :label="$t('[[[Kolor tła]]]')" :invalid-feedback="form.$errors.first('settings.bgColor')" :state="form.$errors.state('settings.bgColor')" class="col-12 col-md-10">
                    <ideo-form-input v-model="form.settings.bgColor" type="color" name="settings.bgColor"></ideo-form-input>
                </ideo-form-group>
                <div class="col-12 col-md-2 d-flex align-items-center justify-content-end pt-2">
                    <button type="button" class="btn btn-primary" @click="setDefaultBgColor">{{ $t('[[[Resetuj]]]') }}</button>
                </div>
            </div>
            <ideo-form-group :label="$t('[[[Link przycisku]]]')" :invalid-feedback="form.$errors.first('settings.buttonLink')" :state="form.$errors.state('settings.buttonLink')">
                <ideo-form-input v-model="form.settings.buttonLink" type="text" name="buttonLink"></ideo-form-input>
            </ideo-form-group>
            <ideo-form-group :label="$t('[[[Nazwa przycisku]]]')" :invalid-feedback="form.$errors.first('settings.buttonName')" :state="form.$errors.state('settings.buttonName')">
                <ideo-form-input v-model="form.settings.buttonName" type="text" name="buttonName"></ideo-form-input>
            </ideo-form-group>
            <div class="row">
                <ideo-form-group :label="$t('[[[Tło przycisku]]]')" :invalid-feedback="form.$errors.first('settings.buttonBg')" :state="form.$errors.state('settings.buttonBg')" class="col-12 col-md-10">
                    <ideo-form-input v-model="form.settings.buttonBg" type="color" name="buttonBg"></ideo-form-input>
                </ideo-form-group>
                <div class="col-12 col-md-2 d-flex align-items-center justify-content-end pt-2">
                    <button type="button" class="btn btn-primary" @click="setDefaultButtonBg">{{ $t('[[[Resetuj]]]') }}</button>
                </div>
            </div>
            <ideo-form-group class="widget-opinions__box widget-opinions__box--right" :label="$t('[[[Opis]]]')" :invalid-feedback="form.$errors.first('settings.description')" :state="form.$errors.state('settings.description')">
                <editor id="description" v-model="form.settings.description" name="settings.description"></editor>
            </ideo-form-group>
        </div>
        <ideo-form-group class="widget-opinions__box widget-opinions__box--right" :label="$t('[[[Cytaty]]]')" :invalid-feedback="form.$errors.first('settings.quotes')" :state="form.$errors.state('settings.quotes')">
            <div v-for="(quote, i) in form.settings.quotes" :key="quote.id" class="col-12 quotes-item">
                <div class="mb-4">
                    <ideo-form-group :label="$t('[[[Logo]]]')" :invalid-feedback="form.$errors.first(`logo`)" :state="form.$errors.state(`logo`)">
                        <filepicker v-model="form.settings.quotes[i].logo" media="images" />
                    </ideo-form-group>
                    <ideo-form-group :label="$t('[[[Podpis]]]')" :invalid-feedback="form.$errors.first(`signature`)" :state="form.$errors.state(`signature`)">
                        <ideo-form-input type="text" v-model="form.settings.quotes[i].signature" name="signature" :maxlength="50"> </ideo-form-input>
                    </ideo-form-group>
                    <ideo-form-group :label="$t('[[[Stanowisko]]]')" :invalid-feedback="form.$errors.first(`position`)" :state="form.$errors.state(`position`)">
                        <ideo-form-input type="text" v-model="form.settings.quotes[i].position" name="position" :maxlength="100"></ideo-form-input>
                    </ideo-form-group>
                    <ideo-form-group :label="$t('[[[Cytat]]]')" :invalid-feedback="form.$errors.first(`quote`)" :state="form.$errors.state(`quote`)" required>
                        <ideo-form-textarea v-model="form.settings.quotes[i].quote" name="quote"></ideo-form-textarea>
                    </ideo-form-group>
                    <div class="input-group-append">
                        <action-button variant="danger" class="" icon="far fa-trash" :text="$t('[[[Usuń]]]')" :title="$t('[[[Usuń]]]')" :confirm="$t('[[[Potwierdzenie usunięcia]]]')" @click="removeQuote(quote)" />
                    </div>
                </div>
            </div>
            <div class="col-12 mt-3">
                <button
                    type="button"
                    class="btn btn-primary"
                    icon="fas fa-check"
                    :disabled="form.settings.quotes.length >= 10"
                    @click="addQuote()"
                >
                    <i class="fas fa-plus mr-2"></i>
                    {{ $t('[[[Dodaj nową opinie]]]') }}
                </button>
            </div>
        </ideo-form-group>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Emit, Prop, Watch } from '@/helpers/Decorators';
import { ValidatableComponent } from '@/components/cms';
import merge from 'lodash/merge';
import { FormType } from '@/helpers/Form';

interface FormSettings
{
    settings: any;
}

@Options({
    name: 'widget-opinions-settings'
})
export default class OpinionsSettings extends Vue implements ValidatableComponent
{
    @Prop() public modelValue!: any;

    public defaults: any = {
        description: '',
        bgColor: '#ffffff',
        image: null,
        buttonLink: '',
        buttonName: '',
        buttonBg: '#ffffff',
        quotes: []
    };

    public get form(): FormType<FormSettings>
    {
        return this.modelValue;
    }

    public async created(): Promise<void>
    {
        this.form.settings = merge(this.defaults, this.form.settings);

        if (!this.form.settings.quotes.length)
        {
            this.form.settings.quotes = [{
                id: 1,
                quote: ''
            }];
        }
    }

    @Watch('form.settings', { deep: true })
    public onSettingsChanged(value: any): void
    {
        this.triggetModel();
    }

    @Emit('update:modelValue')
    public triggetModel(): any
    {
        return this.form;
    }

    public addQuote(): void
    {
        const ids = this.form.settings.quotes.map((x: { id: any; }) => x.id);

        const maxId = this.form.settings.quotes.length > 0 ? Math.max(...ids) : 1;

        this.form.settings.quotes.push({
            id: maxId + 1,
            quote: ''
        });
    }

    public removeQuote(quote: any): void
    {
        this.form.settings.quotes = this.form.settings.quotes.filter((x: any) => x !== quote);
    }

    public async validate(): Promise<void>
    {
    }

    public setDefaultButtonBg(): void
    {
        this.form.settings.buttonBg = '#ffffff';
    }

    public setDefaultBgColor(): void
    {
        this.form.settings.bgColor = '#ffffff';
    }
}
</script>
<style lang="scss" scoped>
.widget-opinions {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
    &__box {
        &--left {
            order: 1;
        }
        &--right {
            order: 0;
        }
    }
    @media only screen and (min-width: 960px)
    {
        grid-template-columns: 1fr 1fr;

        &__box {
            &--left {
                order: 0;
            }
            &--right {
                order: 1;
            }
        }
    }
}
.quotes-item {
    grid-template-columns: 1fr;
    grid-gap: 16px;
    margin-bottom: 16px;
    background: #fafafb;
    padding-top: 8px;
    border: 1px solid #eaecee;
}
</style>
