<template>
    <button class="dropdown-item" v-bind="{...$props, ...$attrs}" ref="button" @click.stop.prevent>
        <slot name="default"></slot>
        <confirmation :message="props.message" :target="$refs.button" :value="item" @confirm="onConfirm" v-if="loaded && message" />
    </button>
</template>

<script lang="ts" setup>
import { ref, inject, onMounted, type ComputedRef } from 'vue';

export interface Props
{
    message?: string;
}

defineOptions({
    name: 'list-view-button',
    inheritAttrs: false
});

const props = defineProps({
  "message": { default: null }
});
const item = inject<ComputedRef<any>>('item');
const loaded = ref(false);
const emit = defineEmits(["confirm"]);

onMounted(() =>
{
    loaded.value = true;
});

const onConfirm = (item: any): any =>
{
    emit('confirm', item);
};
</script>
