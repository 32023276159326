<template>
    <div v-for="(image,id) in form.settings.images" :key="image.id" class="widget-logo-carousel">
        <widget-imageslide v-model="form" :id="id" />
        <div class="d-flex widget-logo__delete">
            <div class="input-group-append flex-grow-1 me-2">
                <action-button variant="danger" class="w-100" icon="far fa-trash" :text="$t('[[[Usuń obrazek]]]')" :title="$t('[[[Usuń obrazek]]]')" :confirm="$t('[[[Potwierdzenie usunięcia]]]')" @click="removeImage(id)" :disabled="form.settings.images.length < 2" />
            </div>
            <action-button variant="secondary" icon="far fa-arrow-up" :title="$t('[[[Wyżej]]]') " @click="imageUp(id)" :disabled="id == 0" />
            <action-button variant="secondary" icon="far fa-arrow-down" :title="$t('[[[Niżej]]]')" @click="imageDown(id)" :disabled="id == form.settings.images.length - 1" />
        </div>
    </div>
    <div>
        <action-button variant="light" class="w-100" icon="far fa-plus" :text="$t('[[[Dodaj obrazek]]]')" @click="addImage()" />
    </div>
    <ideo-form-group :label="$t('[[[Opis]]]')" :invalid-feedback="form.$errors.first('settings.description')" :state="form.$errors.state('settings.description')">
        <editor id="description" v-model="form.settings.description" name="settings.description"></editor>
    </ideo-form-group>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Emit, Prop, Watch } from '@/helpers/Decorators';
import { ValidatableComponent } from '@/components/cms';
import merge from 'lodash/merge';
import { FormType } from '@/helpers/Form';
import ImageSlide from './ImageSlide.vue';

interface FormSettings
{
    settings: any;
}

@Options({
    name: 'widget-logocarousel-settings',
    components: {
        'widget-imageslide': ImageSlide
    }
})
export default class LogoCarousel extends Vue implements ValidatableComponent
{
    @Prop() public modelValue!: any;

    public defaults: any = {
        description: '',
        images: []
    };

    public get form(): FormType<FormSettings>
    {
        return this.modelValue;
    }

    public async created(): Promise<void>
    {
        this.form.settings = merge(this.defaults, this.form.settings);

        if (!this.form.settings.images.length)
        {
            this.form.settings.images = [{
                id: 1,
                link: '',
                file: null
            }];
        }
    }

    @Watch('form.settings', { deep: true })
    public onSettingsChanged(value: any): void
    {
        this.triggetModel();
    }

    @Emit('update:modelValue')
    public triggetModel(): any
    {
        return this.form;
    }

    public async validate(): Promise<void>
    {
    }

    public addImage(): void
    {
        const ids = this.form.settings.images.map((x: { id: any; }) => x.id);

        const maxId = this.form.settings.images.length > 0 ? Math.max(...ids) : 1;

        this.form.settings.images.push({
            id: maxId + 1,
            link: '',
            file: null
        });
    }

    public removeImage(index: number): void
    {
        const items = this.form.settings.images.slice();

        items.splice(index, 1);
        this.form.settings.images = items;
    }

    public imageUp(index: number): void
    {
        if (index > 0)
        {
            const items = this.form.settings.images.splice(index, 1);

            this.form.settings.images.splice(index - 1, 0, items[0]);

            this.form.$errors.clear();
        }
    }

    public imageDown(index: number): void
    {
        if (index < this.form.settings.images.length - 1)
        {
            const items = this.form.settings.images.splice(index, 1);

            this.form.settings.images.splice(index + 1, 0, items[0]);

            this.form.$errors.clear();
        }
    }
}
</script>
<style lang="scss">
.widget-logo-carousel {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    border-bottom: 1px solid #e4e7ea;
    @media only screen and (min-width: 960px)
    {
        display: grid;
        grid-gap: 20px;
        grid-template-areas:
                        "picture link"
                        "picture newTab"
                        "picture delete";
    }
}
.widget-logo__delete {
    grid-area: delete;
    height: fit-content;
}
.widget-logo__picture {
    grid-area: picture;
}
.widget-logo__link {
    grid-area: link;
}
.widget-logo__newTab {
    grid-area: newTab;
}
</style>
