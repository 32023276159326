<template>
    <ideo-form-group :label="$t('[[[Opis]]]')" :invalid-feedback="form.$errors.first('content')" :state="form.$errors.state('content')">
        <editor :id="seconds('description-' + form.id)" v-model="form.content.value.description" data-cy="description"></editor>
    </ideo-form-group>
    <ideo-form-group :label="$t('[[[Kategorie]]]')" :invalid-feedback="form.$errors.first('content.value.categories')" :state="form.$errors.state('content.value.categories')" required>
        <div v-for="(category, i) in form.content.value.categories" :key="i" class="bg-light p-2 mb-3">
            <div class="input-group">
                <div class="input-group-prepend">
                    <span class="input-group-text bg-primary border-primary text-white">{{ i + 1 }}</span>
                </div>
                <ideo-form-input v-model="category.name" type="text" :name="`content.value.categories[${i}].name`" :placeholder="$t('[[[Nazwa kategorii]]]')" :data-cy="`category-${i}`"></ideo-form-input>
                <div class="input-group-append me-2">
                    <action-button variant="danger" icon="far fa-trash" :title="$t('[[[Usuń kategorię]]]')" :confirm="$t('[[[Potwierdzenie usunięcia]]]')" @click="removeCategory(i)" :disabled="form.content.value.categories.length < 2" />
                </div>
                <Move :items="form.content.value.categories" :index="i" />
            </div>
            <small class="form-text text-danger" v-if="!form.$errors.state(`content.value.categories[${i}].name`)">{{ form.$errors.first(`content.value.categories[${i}].name`) }}</small>
        </div>
        <div class="row">
            <div class="col-12">
                <action-button variant="outline-dark" class="w-100" icon="far fa-plus" :text="$t('[[[Dodaj kategorię]]]')" @click="addCategory()" data-cy="addCategory" />
            </div>
        </div>
    </ideo-form-group>
    <ideo-form-group :label="$t('[[[Elementy]]]')" :invalid-feedback="form.$errors.first('content.value.items')" :state="form.$errors.state('content.value.items')" required>
        <div v-for="(item, i) in form.content.value.items" :key="i" class="bg-light p-2 mb-3">
            <div class="input-group elements-que">
                <div class="input-group-prepend">
                    <span class="input-group-text bg-primary border-primary text-white">{{ i + 1 }}</span>
                </div>
                <ideo-form-input v-model="item.name" type="text" :name="`content.value.items[${i}].name`" :placeholder="$t('[[[Nazwa elementu]]]')" :data-cy="`answer-${i}`"></ideo-form-input>
                <multiselect
                    :name="`content.value.items[${i}].categoryId`"
                    v-model="item.categoryId"
                    :options="Object.keys(categoriesOptions)"
                    :custom-label="opt => categoriesOptions[opt]"
                    :multiple="false"
                    :deselect-label="''"
                    :select-label="''"
                    :selected-label="''"
                    :data-cy="`answer-${i}-category`"
                    :placeholder="$t('[[[Wybierz kategorię]]]')"
                >
                    <template #noOptions>{{ $t('[[[Lista jest pusta]]]') }}</template>
                    <template #noResult>{{ $t('[[[Nie znaleziono żadnych wyników.]]]') }}</template>
                </multiselect>
                <div class="input-group-append">
                    <action-button variant="danger" icon="far fa-trash" :title="$t('[[[Usuń element]]]')" :confirm="$t('[[[Potwierdzenie usunięcia]]]')" @click="removeItem(i)" :disabled="form.content.value.categories.length < 2" />
                </div>
            </div>
            <small class="form-text text-danger" v-if="!form.$errors.state(`content.value.items[${i}].name`)">{{ form.$errors.first(`content.value.items[${i}].name`) }}</small>
            <small class="form-text text-danger" v-if="!form.$errors.state(`content.value.items[${i}].categoryId`)">{{ form.$errors.first(`content.value.items[${i}].categoryId`) }}</small>
        </div>
        <small class="form-text text-danger" v-if="!form.$errors.state(`content.value.categories.count`)">{{ form.$errors.first(`content.value.categories.count`) }}</small>
        <div class="row">
            <div class="col-12">
                <action-button variant="outline-dark" class="w-100" icon="far fa-plus" :text="$t('[[[Dodaj element]]]')" @click="addItem()" data-cy="addAnswer" />
            </div>
        </div>
    </ideo-form-group>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Emit, Prop, Watch } from '@/helpers/Decorators';
import merge from 'lodash/merge';
import { FormType } from '@/helpers/Form';
import { seconds } from '@/helpers/Utils';
import Move from '@/components/common/Move.vue';
import Multiselect from 'vue-multiselect/src/Multiselect.vue';

interface FormCategoriesMatching
{
    content: {
        value: {
            description: string,
            categories: Category[],
            items: Item[]
        }
    }
}

interface Category
{
    id: number,
    name: string
}

interface Item
{
    id: number,
    name: string,
    categoryId: number
}

@Options({
    name: 'type-categoriesmatching-settings',
    components: {
        Move,
        'multiselect': Multiselect
    }
})
export default class CategoriesMatchingSettings extends Vue
{
    @Prop() public modelValue!: any;

    public seconds = seconds;

    public defaults: any = {
        description: '',
        categories: [] as Category[],
        items: [] as Item[]
    };

    public get form(): FormType<FormCategoriesMatching>
    {
        return this.modelValue;
    }

    public get categoriesOptions(): Record<number, string>
    {
        const categories = this.form.content.value.categories.map(p =>
        {
            return {
                [p.id]: p.name
            };
        });

        return categories.reduce((result, item) =>
        {
            const key = Object.keys(item)[0];

            result[key] = item[key];

            return result;
        }, {});
    }

    public created(): void
    {
        this.form.content.value = merge(this.defaults, this.form.content.value);
        this.form.file = null;

        if (!this.form.content.value.categories.length)
        {
            this.form.content.value.categories = [{
                id: 1,
                name: ''
            }];
        }

        if (!this.form.content.value.items.length)
        {
            this.form.content.value.items = [{
                id: 1,
                name: '',
                categoryId: null
            }];
        }
    }

    public addCategory(): void
    {
        const ids = this.form.content.value.categories.map((x: { id: any; }) => x.id);

        const maxId = this.form.content.value.categories.length > 0 ? Math.max(...ids) : 1;

        const lastIndex = this.form.content.value.categories.length - 1;

        if (this.form.content.value.categories[lastIndex].name != '')
        {
            this.form.content.value.categories.push({
                id: maxId + 1,
                name: ''
            });
        }
        else
        {
            this.$alert.warning(this.$t('[[[Nie podano nazwy poprzedniej kategorii.]]]'));
        }
    }

    public removeCategory(index: number): void
    {
        const id = this.form.content.value.categories[index].id;
        let canDelete = true;

        this.form.content.value.items.forEach((item: Item) =>
        {
            if (item.categoryId == id && canDelete)
            {
                this.$alert.warning(this.$t('[[[Nie można usunąć kategorii do ktorej są przypisane elementy.]]]'));
                canDelete = false;
            }
        });

        if (canDelete)
        {
            const items = this.form.content.value.categories.slice();

            items.splice(index, 1);
            this.form.content.value.categories = items;

            this.form.$errors.clear();
        }
    }

    public addItem(): void
    {
        const ids = this.form.content.value.items.map((x: { id: any; }) => x.id);

        const maxId = this.form.content.value.items.length > 0 ? Math.max(...ids) : 1;

        const lastIndex = this.form.content.value.items.length - 1;

        if (this.form.content.value.items[lastIndex].name != '' && this.form.content.value.items[lastIndex].categoryId != null)
        {
            this.form.content.value.items.push({
                id: maxId + 1,
                name: '',
                categoryId: 0
            });

            this.form.$errors.clear();
        }
        else
        {
            this.$alert.warning(this.$t('[[[Nie podano nazwy poprzedniego elementu.]]]'));
        }
    }

    public removeItem(index: number): void
    {
        const items = this.form.content.value.items.slice();

        items.splice(index, 1);
        this.form.content.value.items = items;

        this.form.$errors.clear();
    }

    @Watch('form.content.value.categories', { deep: true })
    public onContentCategoriesChanged(value: any): void
    {
        this.triggetModel();
    }

    @Watch('form.content.value.items', { deep: true })
    public onContentItemsChanged(value: any, oldValue: any): void
    {
        this.form.content.value.items.forEach((element, index) =>
        {
            if (this.form.$errors.has(`content.value.items[${index}].categoryid`) && element.categoryId)
                this.form.$errors.clear(`content.value.items[${index}].categoryid`);
        });

        this.triggetModel();
    }

    @Emit('update:modelValue')
    public triggetModel(): any
    {
        return this.form;
    }
}
</script>
<style lang="scss" scoped>
.elements-que {
    display: grid;
    grid-template-columns: 1fr 1fr;

    @media only screen and (min-width: 960px)
    {
        grid-template-columns: 34px 1fr 1fr 38px;
    }

    :deep(.form-control) {
        width: 100%;
    }

    /* stylelint-disable-next-line selector-class-pattern */
    :deep(.multiselect__tags) {
        border-radius: 0;
    }
}
</style>
