<script lang="ts" setup>
import { ref, Ref, computed } from 'vue';
import properties from '@/components/forms/properties';
import { RootEntry } from '@/components/builder/form/entries/RootEntry';
import { instanceOfNumericEntry, NumericType, NumericEntry, NumericFieldTypes } from '.';
import { Option } from '@/helpers/Interfaces';
import { FormBuilderContract } from '@/components/builder/form';
import { AggregateBlueprint } from '@/components/builder/base/blueprints/AggregateBlueprint';

defineOptions({
    name: 'numeric-blueprint',
    components: {
        ...properties
    }
});

const emit = defineEmits(["update"]);

const props = defineProps({
  "blueprint": null,
  "entry": null,
  "form": null,
  "parent": null,
  "index": null
});
const blueprint = computed(() => props.blueprint);

const entryData = ref(new NumericEntry()) as Ref<NumericEntry>;

entryData.value = props.form.document.initEntry(props.blueprint, entryData.value, instanceOfNumericEntry, props.index);

const value = computed({
    get()
    {
        const { data: value } = entryData.value;

        return value === null
            ? props.form.expressions.executeExpression(props.blueprint.defaultValue)
            : value;
    },
    set(value: number)
    {
        entryData.value.data = value;
    }
});

const display = computed(() => value.value !== null ? value.value.toFixed(precision.value) : null);

const min = computed(() => props.blueprint.range.min);
const max = computed(() => props.blueprint.range.max);
const step = computed(() => props.blueprint.step);
const precision = computed(() => isInteger.value ? 0 : props.blueprint.precision);
const prepend = computed(() => props.blueprint.affix.prepend);
const append = computed(() => isPercent.value ? '%' : props.blueprint.affix.append);

const fieldTypeOptions: Option<NumericFieldTypes>[] = [
    { value: NumericFieldTypes.Integer, text: '[[[Liczba całkowita]]]' },
    { value: NumericFieldTypes.Decimal, text: '[[[Liczba dziesiętna]]]' },
    { value: NumericFieldTypes.Percent, text: '[[[Procent]]]' }
];

const isInteger = computed(() => props.blueprint.fieldType == NumericFieldTypes.Integer);
const isPercent = computed(() => props.blueprint.fieldType == NumericFieldTypes.Percent);

const readonly = computed(() => props.form.expressions.readonly(props.blueprint));
const required = computed(() => props.form.expressions.required(props.blueprint));
const placeholder = computed(() => props.form.localization.translate(props.blueprint.placeholder));

const update = (e: any): void =>
{
    if (e.target.value)
        value.value = parseFloat(e.target.value || 0);
    else
        value.value = null;

    emit('update', value.value);
};
</script>

<template>
    <form-component-wrapper class="numeric-component" :form="form" :parent="parent" :blueprint="blueprint">
        <template #default>
            <div class="form-group">
                <form-label :form="form" :blueprint="blueprint" :required="required" :entry="entryData" />
                <ideo-input-group :prepend="prepend" :append="append">
                    <input type="number" class="form-control" :min="min" :max="max" :step="step" :value="display" @blur="update" :id="blueprint.id" :placeholder="placeholder" :readonly="readonly">
                </ideo-input-group>
                <form-error-message :entry="entryData" name="value" />
                <form-error-message :entry="entryData" name="custom" />
                <form-help :form="form" :blueprint="blueprint" />
            </div>
        </template>
        <template #properties>
            <field-name :form="form" :blueprint="blueprint" v-model="blueprint.name" />
            <ideo-form-localize v-slot="{ locale }">
                <field-text v-model="blueprint.label[locale]" :label="$t('[[[Etykieta]]]')" />
            </ideo-form-localize>
            <field-checkbox v-model="blueprint.showLabel" :label="$t('[[[Pokaż etykietę]]]')" />
            <field-choice v-model="blueprint.fieldType" :options="fieldTypeOptions" :label="$t('[[[Typ pola]]]')" />
            <field-condition v-model="blueprint.defaultValue" :form="form" :label="$t('[[[Wartość domyślna]]]')" type="number" />
            <ideo-form-localize v-slot="{ locale }">
                <field-text v-model="blueprint.placeholder[locale]" :label="$t('[[[Tekst zastępczy]]]')" />
            </ideo-form-localize>
            <field-affix v-model="blueprint.affix" :append="!isPercent" />
            <div class="row">
                <div class="col-6">
                    <field-numeric v-model="blueprint.step" :label="$t('[[[Wielkość kroku]]]')" :precision="precision" :min="0" />
                </div>
                <div class="col-6">
                    <field-numeric v-model="blueprint.precision" :label="$t('[[[Miejsca po przecinku]]]')" :min="0" :max="10" v-if="!isInteger" />
                </div>
            </div>
            <field-minmax v-model="blueprint.range" :label="$t('[[[Zasięg]]]')" :step="step" :precision="precision" />
            <ideo-form-localize v-slot="{ locale }">
                <field-textarea v-model="blueprint.help[locale]" :label="$t('[[[Pomoc]]]')" />
            </ideo-form-localize>
            <field-visible :form="form" :blueprint="blueprint" />
            <field-readonly :form="form" :blueprint="blueprint" />
            <field-required :form="form" :blueprint="blueprint" />
            <field-error :form="form" :blueprint="blueprint" />
            <field-filter :form="form" :blueprint="blueprint" />
        </template>
    </form-component-wrapper>
</template>
