<template>
    <ideo-tree-courses-program-nodes :pages="pages" :archive="archive" :expanded="expanded" :parent="null" :menu="menu" :move="move" :icon-visible="iconVisible" @toggle="onToggle" @click="onClick" @sort="onSort" @restore="onRestore" @resetUnit="resetUnitResult" class="tree" />
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Emit, Prop } from '@/helpers/Decorators';
import { TreeNodeCoursesProgram } from '../utils';

@Options({
    name: 'ideo-tree-courses-program'
})
export default class IdeoTreeCoursesProgram extends Vue
{
    @Prop() public pages!: TreeNodeCoursesProgram[];
    @Prop() public expanded!: number[];
    @Prop() public archive: boolean;
    @Prop() public menu: (node: TreeNodeCoursesProgram) => Promise<any[]>;
    @Prop() public move: (e: any) => boolean;
    @Prop() public iconVisible: (e: any) => boolean;

    @Emit('toggle')
    public onToggle(node: TreeNodeCoursesProgram): TreeNodeCoursesProgram
    {
        return node;
    }

    @Emit('click')
    public onClick(node: TreeNodeCoursesProgram): TreeNodeCoursesProgram
    {
        return node;
    }

    @Emit('sort')
    public onSort(pages: TreeNodeCoursesProgram[]): TreeNodeCoursesProgram[]
    {
        return pages;
    }

    @Emit('restore')
    public onRestore(node: TreeNodeCoursesProgram): TreeNodeCoursesProgram
    {
        return node;
    }

    @Emit('resetUnit')
    public resetUnitResult(node: TreeNodeCoursesProgram): TreeNodeCoursesProgram
    {
        return node;
    }
}
</script>

<style lang="scss">
$icon-color: var(--bs-green);
$link-color: #434E59 !default;
$link-muted: #c0cadd !default;
$icon-size: 16px !default;

.tree {
    padding-left: 0;
    list-style: none;

    ul {
        padding-left: 1.5em;
        list-style: none;
    }
    li {
        position: relative;
        margin: 0.5rem 0;
        padding: 0;

        > span {
            padding: 0.5rem 0;
            a {
                color: var(--bs-link-color);
                padding: 0 0.5em 0 0.5em;
                font-weight: 500;
            }
            .fa-plus-square,
            .fa-minus-square,
            .fa-cog,
            .fa-trash {
                cursor: pointer;
            }
            .fad:after {
                opacity: 1;
            }
        }

        .popover-body {
            min-width: 150px;
            // padding: 0 !important;
        }
        .dropdown-menu {
            position: unset;
            display: block;
            border: none;
        }
    }
}

.tree:not(.tree-nested) {
    li {
        &:hover {
            background-color: var(--color-header);
        }
        ul.tree-nested {
            background-color: #fff;
            li:hover {
                background-color: #e5e5e5;
                .fa-cog {
                    color: #afafaf;
                }
            }
        }
    }
}
</style>
