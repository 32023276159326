import { Blueprint } from '@/components/builder/base/blueprints/Blueprint';
import { ValidatableBlueprint } from '@/components/builder/base/blueprints/ValidatableBlueprint';
import { HasWidth } from '@/components/builder/base/traits/HasWidth';
import { ValidationErrors } from '@/components/builder/base/types/ValidationErrors';
import { FormBuilderContract } from '@/components/builder/form';
import { BlueprintDefinition } from '@/components/builder/form/blueprints/BlueprintDefinition';
import { Entry, entry } from '@/components/builder/form/entries/Entry';
import { ValidEntry } from '@/components/builder/form/entries/ValidEntry';
import { EntryFactory } from '@/components/builder/form/traits/EntryFactory';
import { HasLabel } from '@/components/builder/form/traits/HasLabel';
import { ProcessCallback } from '@/components/builder/form/types/ProcessCallback';

export const Definition: BlueprintDefinition = {
    type: 'regulations',
    name: '[[[Regulaminy]]]',
    icon: 'fa-gavel',
    group: 'special',
    position: 12,
    disabled: true
};

export class RegulationsData
{
    public checked?: number[] = null;
    public required?: number[] = null;
}

export class RegulationsEntry extends ValidEntry<RegulationsData>
{
    public type: string = Definition.type;
    public data: RegulationsData = {};

    public constructor(data: any = null)
    {
        super();

        if (data !== null)
        {
            this.data = data;
        }
    }

    public async collect(blueprint: RegulationsContract, form: FormBuilderContract, preprocess: ProcessCallback): Promise<Entry>
    {
        return entry({
            type: this.type,
            data: this.data
        });
    }

    public validate(blueprint: RegulationsContract, form: FormBuilderContract): boolean
    {
        this.errors = {};

        if (!this.data.required?.every(element => this.data.checked?.includes(element)))
        {
            this.errors.value = [`[[[Pole "%0" jest wymagane.|||${blueprint.label}]]]`];
        }

        return this.valid();
    }
}

export const instanceOfRegulationsEntry = (object: any): object is RegulationsEntry =>
{
    return object && 'type' in object && object.type === Definition.type;
};

export interface RegulationsContract extends Blueprint, HasLabel, HasWidth
{
    regulationSetId: number;
    websiteId: number;
    locale: string;
}

export class RegulationsType implements RegulationsContract, ValidatableBlueprint, EntryFactory<RegulationsEntry>
{
    public id: string;
    public type: string;
    public name: string;
    public label: string;
    public showLabel: boolean;
    public minWidth: number;
    public width: number;
    public errors: ValidationErrors;
    public regulationSetId: number;
    public websiteId: number;
    public locale: string;

    public constructor(id: string, name: string, width: number)
    {
        this.id = id;
        this.type = Definition.type;
        this.name = name;
        this.label = '[[[Regulaminy]]]';
        this.showLabel = true;
        this.minWidth = 1;
        this.width = Math.min(3, Math.max(this.minWidth, width));
        this.errors = {};
        this.regulationSetId = 0;
        this.websiteId = 0;
        this.locale = null;
    }

    public setDefaultWidth(width: number): void
    {
        this.width = Math.min(3, Math.max(this.minWidth, width));
    }

    public createEntry(data: any): RegulationsEntry
    {
        return new RegulationsEntry(data);
    }

    public validate(): Record<string, ValidationErrors>
    {
        this.errors = {};

        if (!this.websiteId)
            this.errors.websiteId = ['[[[Wymagane jest wybranie witryny]]]'];

        if (!this.locale)
            this.errors.locale = ['[[[Wymagane jest wybranie wersji językowej]]]'];

        if (!this.regulationSetId)
            this.errors.regulationSetId = ['[[[Wymagane jest wybranie zestawu regulaminów]]]'];

        return {
            [this.name]: this.errors
        };
    }
}
