<script lang="ts" setup>
import { ref, Ref, computed, watch } from 'vue';
import properties from '@/components/forms/properties';
import { RootEntry } from '@/components/builder/form/entries/RootEntry';
import { Option } from '@/helpers/Interfaces';
import { instanceOfChoiceEntry, ChoiceType, ChoiceEntry, ChoiceFieldTypes } from '.';
import { FormBuilderContract } from '@/components/builder/form';
import { AggregateBlueprint } from '@/components/builder/base/blueprints/AggregateBlueprint';

defineOptions({
    name: 'choice-blueprint',
    components: {
        ...properties
    }
});

const props = defineProps({
  "blueprint": null,
  "entry": null,
  "form": null,
  "parent": null,
  "index": null
});
const blueprint = computed(() => props.blueprint);

const entryData = ref(new ChoiceEntry()) as Ref<ChoiceEntry>;

entryData.value = props.form.document.initEntry(props.blueprint, entryData.value, instanceOfChoiceEntry, props.index);

const defaultValue = computed<string[]>(() =>
{
    if (props.blueprint.defaultValue)
        return [props.form.expressions.executeExpression(props.blueprint.defaultValue)];
    else
        return props.blueprint.options.filter(p => p.selected).map(p => p.value);
});

const values = computed({
    get()
    {
        return entryData.value.data || defaultValue.value;
    },
    set(values: string[])
    {
        entryData.value.data = values;
    }
});

const value = computed({
    get()
    {
        return entryData.value.data?.first() || defaultValue.value.first();
    },
    set(value: string)
    {
        entryData.value.data = [value];
    }
});

const fieldTypeOptions: Option<ChoiceFieldTypes>[] = [
    { value: ChoiceFieldTypes.Dropdown, text: '[[[Lista rozwijana]]]' },
    { value: ChoiceFieldTypes.Radio, text: '[[[Pojedynczy wybór]]]' },
    { value: ChoiceFieldTypes.Checkbox, text: '[[[Wielokrotny wybór]]]' }
];

const layoutOptions: Option<number>[] = [
    { value: 0, text: '[[[Obok siebie]]]' },
    { value: 1, text: '[[[Jedna kolumna]]]' },
    { value: 2, text: '[[[Dwie kolumny]]]' },
    { value: 3, text: '[[[Trzy kolumny]]]' },
    { value: 4, text: '[[[Cztery kolumny]]]' }
];

const isDropdownField = computed(() => props.blueprint.fieldType == ChoiceFieldTypes.Dropdown);
const isRadioList = computed(() => props.blueprint.fieldType == ChoiceFieldTypes.Radio);
const isCheckboxList = computed(() => props.blueprint.fieldType == ChoiceFieldTypes.Checkbox);

const readonly = computed(() => props.form.expressions.readonly(props.blueprint));
const required = computed(() => props.form.expressions.required(props.blueprint));
const placeholder = computed(() => props.form.localization.translate(props.blueprint.placeholder));

const optionText = (value: Record<string, string>) => props.form.localization.translate(value);

watch(defaultValue, () =>
{
    props.blueprint.options.forEach(p => { p.selected = false; });
});
</script>

<template>
    <form-component-wrapper class="choice-component" :form="form" :parent="parent" :blueprint="blueprint">
        <template #default>
            <div class="form-group">
                <form-label :form="form" :blueprint="blueprint" :required="required" :entry="entryData" />
                <select class="form-select" :id="blueprint.id" v-model="value" :readonly="readonly" v-if="isDropdownField">
                    <option :value="null" v-if="defaultValue.length == 0">{{ placeholder }}</option>
                    <option v-for="(option, i) in blueprint.options" :key="i" :value="option.value">{{ optionText(option.text) }}</option>
                </select>
                <div class="pt-1" :class="{[`row row-cols-${blueprint.layout}`]: blueprint.layout > 0}" v-if="!isDropdownField">
                    <div v-for="(option, i) in blueprint.options" :key="i" :class="{'d-inline': blueprint.layout == 0, 'col': blueprint.layout > 0}">
                        <ideo-form-radio :value="option.value" v-model="value" :inline="blueprint.layout == 0" v-if="isRadioList">{{ optionText(option.text) }}</ideo-form-radio>
                        <ideo-form-checkbox :value="option.value" v-model="values" :inline="blueprint.layout == 0" v-if="isCheckboxList">{{ optionText(option.text) }}</ideo-form-checkbox>
                    </div>
                </div>
                <form-error-message :entry="entryData" name="value" />
                <form-error-message :entry="entryData" name="custom" />
                <form-help :form="form" :blueprint="blueprint" />
            </div>
        </template>
        <template #properties>
            <field-name :form="form" :blueprint="blueprint" v-model="blueprint.name" />
            <ideo-form-localize v-slot="{ locale }">
                <field-text v-model="blueprint.label[locale]" :label="$t('[[[Etykieta]]]')" />
            </ideo-form-localize>
            <field-checkbox v-model="blueprint.showLabel" :label="$t('[[[Pokaż etykietę]]]')" />
            <field-choice v-model="blueprint.fieldType" :options="fieldTypeOptions" :label="$t('[[[Typ pola]]]')" />
            <field-options v-model="blueprint.options" :multiple="isCheckboxList" :can-select="!blueprint.defaultValue || isCheckboxList" :label="$t('[[[Opcje]]]')" />
            <field-condition v-model="blueprint.defaultValue" :form="form" :label="$t('[[[Wartość domyślna]]]')" type="text" v-if="!isCheckboxList" />
            <ideo-form-localize v-if="isDropdownField" v-slot="{ locale }">
                <field-text v-model="blueprint.placeholder[locale]" :label="$t('[[[Tekst zastępczy]]]')" />
            </ideo-form-localize>
            <field-radiolist v-model="blueprint.layout" :options="layoutOptions" :label="$t('[[[Układ]]]')" v-if="!isDropdownField" />
            <ideo-form-localize v-slot="{ locale }">
                <field-textarea v-model="blueprint.help[locale]" :label="$t('[[[Pomoc]]]')" />
            </ideo-form-localize>
            <field-visible :form="form" :blueprint="blueprint" />
            <field-readonly :form="form" :blueprint="blueprint" />
            <field-required :form="form" :blueprint="blueprint" />
            <field-error :form="form" :blueprint="blueprint" />
            <field-filter :form="form" :blueprint="blueprint" />
        </template>
    </form-component-wrapper>
</template>

<style lang="scss" scoped>
</style>
