<template>
    <tr :class="getClass()">
        <vnodes :vnodes="cells()" />
    </tr>
</template>

<script lang="ts" setup>
import { computed, inject, provide, useSlots } from 'vue';
import { Header, travel } from '../helpers';

export interface Props
{
    index: number;
    item: any;
    headers: Header[];
}

defineOptions({
    name: 'list-view-utils-row',
    inheritAttrs: false,
    components: {
        'vnodes': (props: Record<string, any>, context: any) => props.vnodes
    }
});

const $slots = useSlots();
const props = defineProps({
  "index": null,
  "item": null,
  "headers": null
});
const item = computed(() => props.item);
const rowClass = inject<(item: any, i: number) => Record<string, boolean> | string[] | string>('row-class');

provide('item', item);

const getClass = (): any =>
{
    if (rowClass)
    {
        return rowClass(props.item, props.index);
    }

    return {};
};

const cells = (): any[] =>
{
    const allowed = [
        'list-view-header',
        'list-view-item',
        'list-view-label',
        'list-view-buttons',
        'list-view-image',
        'list-view-checkbox'
    ];
    const prefix = (new Date()).getTime();

    return travel($slots.default(), allowed)
        .map((p, i) =>
        {
            p.key = `${prefix}-${i}`;
            p.props = p.props || {};
            p.props.index = i;
            p.props.header = props.headers[i];

            return p;
        })
        .orderBy(p => p.props.header.position)
        .toArray();
};
</script>
