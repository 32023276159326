<template>
    <div id="notifications" :class="{'phone': phone}">
        <button type="button" class="navbar-toggler" :class="{'active': visible}" @click="onToggle">
            <span class="position-relative">
                <i class="fas fa-fw fa-bell"></i>
                <span class="badge text-bg-danger position-absolute top-0 start-100 translate-middle ms-2" v-if="total > 0">{{ displayTotal }}</span>
            </span>
        </button>
        <div class="popup border rounded-2 shadow scroll d-flex flex-column" :class="{'d-none': !visible}">
            <div class="text-center p-3" v-if="total == 0">
                {{ $t('[[[Brak nowych powiadomień.]]]') }}
            </div>
            <table class="table table-borderless table-hover mb-0" v-else>
                <tbody>
                    <tr v-for="message in messagesList" :key="message.uid" @click="read(message)">
                        <td class="pointer p-0">
                            <div class="d-flex align-items-center px-3 py-2">
                                <div class="me-3">
                                    <i class="icon fas fa-spinner-third fa-spin text-muted" v-if="message.isRead"></i>
                                    <i class="icon fal fa-check-circle text-success" v-else-if="message.payload.level == 'success'"></i>
                                    <i class="icon fal fa-info-circle text-info" v-else-if="message.payload.level == 'info'"></i>
                                    <i class="icon fal fa-exclamation-circle text-warning" v-else-if="message.payload.level == 'warning'"></i>
                                    <i class="icon fal fa-exclamation-triangle text-danger" v-else-if="message.payload.level == 'error'"></i>
                                </div>
                                <div class="flex-fill">
                                    <div :class="{'text-muted': message.isRead}" v-html="message.payload.data.message"></div>
                                    <div class="text-primary small mt-1">{{ $filters.datetime(message.dateCreatedUtc) }}</div>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="text-center border-top mt-auto p-1">
                <router-link :to="{name: 'core-account-notifications'}">{{ $t('[[[Zobacz wszystkie]]]') }}</router-link>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { DateTime } from 'luxon';
import { FilterModel, MessageModel } from '@/modules/core/common/services/NotificationsService';
import { Pagination, Resource } from '@/helpers/Interfaces';
import { Invoke, Listen } from '@/plugins/signalr';
import Pager from '@/helpers/Pager';

@Options({})
export default class Notifications extends Vue
{
    public timeout: any = null;
    public messages: MessageModel[] = [];
    public total: number = 0;
    public visible: boolean = false;

    public get messagesList(): MessageModel[]
    {
        return this.messages.slice(0, 10);
    }

    public get displayTotal(): string
    {
        return this.total > 99 ? '99+' : this.total.toString();
    }

    public async created(): Promise<void>
    {
        this.loadData();
    }

    public mounted(): void
    {
        window.addEventListener('click', this.hide);
    }

    public unmounted(): void
    {
        window.removeEventListener('click', this.hide);
    }

    public async loadData(): Promise<boolean>
    {
        try
        {
            const result = await this.getMessages({ isRead: false, search: '' }, new Pager(1, 5, 'Id', 'DESC').data());

            this.messages = result.items.map(p => p.result);
            this.total = result.totalRows;
        }
        catch (ex)
        {
            this.handleException(ex, {
                0: (ex: any) => this.$alert.danger(this.$t('[[[Nie udało się pobrać powiadomień.]]]'))
            });

            return false;
        }

        return true;
    }

    public async read(message: MessageModel): Promise<void>
    {
        if (!message.isRead)
        {
            message.isRead = true;

            await this.markAsRead(message.uid);

            if (this.timeout)
                clearTimeout(this.timeout);

            this.timeout = setTimeout(() => this.loadData(), 1000);
        }
    }

    @Invoke('notifications')
    public getMessages: (filter: FilterModel, pager: any) => Promise<Pagination<Resource<MessageModel>>>;

    @Invoke('notifications')
    public markAsRead: (uid: string) => Promise<void>;

    @Listen('notifications')
    public async messageReceived(payload: any): Promise<void>
    {
        this.total++;
        this.messages.unshift({
            id: 0,
            uid: payload.uid,
            dateCreatedUtc: DateTime.utc(),
            payload: payload,
            isRead: false
        });
        this.$alert[payload.level](payload.data.message);

        switch (payload.args.action)
        {
            case 'download': this.forceDownload(payload.args.url, payload.args.filename);
        }
    }

    public forceDownload(url: string, fileName: string): void
    {
        location.href = this.$filters.baseurl(`${url}?dl`);
    }

    public onToggle(): void
    {
        this.visible = !this.visible;
    }

    public hide(e: MouseEvent): void
    {
        if (e.composedPath().where(p => p.id == 'notifications').none())
        {
            this.visible = false;
        }
    }
}
</script>

<style lang="scss" scoped>
#notifications {
    position: relative;

    .popup {
        position: absolute;
        right: 0;
        background-color: var(--bs-body-bg);
        z-index: var(--ideo-dropdown-zindex);
        width: var(--ideo-dropdown-width);
        max-height: var(--ideo-dropdown-height);
    }

    &.mobile {
        .popup {
            position: static;
        }
    }
}
</style>
