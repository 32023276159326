<template>
    <li :id="container" :class="[{'active': isActive}, { 'white' : node.module === 'el_topics' }]" @mouseover.stop="mouseOver" @mouseout.stop="mouseOut">
        <span class="d-flex align-items-center course-item">
            <i class="fa fa-fw spaces" v-if="!hasChildren"></i>
            <span v-if="isCollapsed" @click="onToggle(node)" class="box">
                <i class="fal fa-fw fa-plus"></i>
            </span>
            <span v-if="isExpanded" @click="onToggle(node)" class="box">
                <i class="fal fa-fw fa-minus"></i>
            </span>
            <i :class="icon('fad fa-fw fa-file fa-swap')" v-if="!hasChildren"></i>
            <i :class="icon('fad fa-fw fa-folder')" v-if="isCollapsed"></i>
            <i :class="icon('fad fa-fw fa-folder-open fa-swap-opacity')" v-if="isExpanded"></i>
            <a href="#" @click.stop.prevent="onClick(node)" class="flex-fill course-element" :class="{'text-dark': isActive}">{{ node.name }}</a>
            <span class="d-flex justify-content-end column">
                <span class="d-flex justify-content-center align-items-center" style="width: 140px;">
                    <i v-if="node.module != 'el_topics'" :class="['fad fa-fw fa-circle', node.isRequired ? 'text-success' : 'text-default']" :title="node.isRequired ? $t('[[[element wymagany]]]') : $t('[[[element niewymagany]]]')"></i>
                    <template v-else>
                        {{ $t('[[[wymagany]]]') }}
                    </template>
                </span>
                <span v-if="hasMenu">
                    <template v-for="(item, index) in menuItems" :key="index">
                        <span v-show="item.name" :id="menuItemId(index)" @click="onMenuItemIcon(node, item, !item.confirm)" :title="item.name">
                            <i class="me-2 icon" :class="[item.icon, item.class]"></i>
                            <confirmation v-if="item.confirm" :target="menuItemId(index)" :message="item.confirm" :value="item" @confirm="onMenuItem(node, item, true)" />
                        </span>
                    </template>
                </span>
            </span>
        </span>
        <ideo-tree-courses-program-nodes :pages="pages" :expanded="expanded" :parent="node" :menu="menu" :move="move" @toggle="onToggle" @click="onClick" @sort="onSort" @resetUnit="resetUnitResult(node)" class="tree tree-nested" />
    </li>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Emit, Prop, Ref } from '@/helpers/Decorators';
import { TreeNodeCoursesProgram } from '../utils';
import IdeoPopover from '../../../../components/bootstrap/popover/IdeoPopover.vue';

@Options({
    name: 'ideo-tree-courses-program-node'
})
export default class IdeoTreeCoursesProgramNode extends Vue
{
    public hover: boolean = false;
    public contextMenu: boolean = false;
    public menuItems: any[] = [];

    @Prop()
    public node!: TreeNodeCoursesProgram;

    @Prop()
    public pages!: TreeNodeCoursesProgram[];

    @Prop()
    public expanded!: number[];

    @Prop()
    public menu: (node: TreeNodeCoursesProgram) => Promise<any[]>;

    @Prop()
    public move: (e: any) => boolean;

    @Prop()
    public iconVisible: (e: any) => boolean;

    @Ref('popover')
    public popover: () => IdeoPopover;

    public get hasChildren(): boolean
    {
        return this.node.hasChildren;
    }

    public get isExpanded(): boolean
    {
        return this.node.hasChildren && this.expanded.indexOf(this.node.id) >= 0;
    }

    public get isCollapsed(): boolean
    {
        return this.node.hasChildren && this.expanded.indexOf(this.node.id) === -1;
    }

    public get isActive(): boolean
    {
        return this.$route.params.id === this.node.id.toString();
    }

    public get target(): string
    {
        return `node-menu-${this.node.id}`;
    }

    public get container(): string
    {
        return `node-container-${this.node.id}`;
    }

    public get hasMenu(): boolean
    {
        return this.menu != null;
    }

    public async mounted(): Promise<void>
    {
        this.menuItems = await this.getMenu(this.node);
    }

    public unmounted(): void
    {
        this.$events.$off('tree::popover::hide', this.hidePopover);
    }

    public async getMenu(node: TreeNodeCoursesProgram): Promise<any[]>
    {
        if (this.menu)
            return await this.menu(node);
        else
            return [];
    }

    public menuItemId(index: number): string
    {
        return `dropdown-${this.node.id}-item-${index}`;
    }

    public icon(icon: string): string
    {
        let icons = icon;

        if (this.node.isActive)
        {
            icons += ' text-success';
        }

        return this.node.icon ? this.node.icon : icons;
    }

    public mouseOver(event: MouseEvent): void
    {
        this.hover = true;
    }

    public mouseOut(event: MouseEvent): void
    {
        if (this.contextMenu == false)
        {
            this.hover = false;
        }
    }

    public async togglePopover(): Promise<void>
    {
        this.menuItems = await this.getMenu(this.node);

        if (this.menuItems.length == 0)
        {
            this.$alert.info(this.$t('[[[Ten element nie zawiera menu kontekstowego.]]]'));
            this.contextMenu = false;
        }
    }

    public hidePopover(node: TreeNodeCoursesProgram): void
    {
        if (node === null || node != this.node)
        {
            this.menuItems = [];
            this.contextMenu = false;
            this.hover = false;
        }
    }

    @Emit('toggle')
    public onToggle(node: TreeNodeCoursesProgram): TreeNodeCoursesProgram
    {
        return node;
    }

    @Emit('click')
    public onClick(node: TreeNodeCoursesProgram): TreeNodeCoursesProgram
    {
        return node;
    }

    public onMenuItem(node: TreeNodeCoursesProgram, item: any, confirmed: boolean): void
    {
        if (confirmed)
        {
            this.$events.$emit('tree::popover::hide', node);
            this.togglePopover();
            item.click(node);
        }
    }

    public async onMenuItemIcon(node: TreeNodeCoursesProgram, item: any, confirmed: boolean): Promise<void>
    {
        if (confirmed)
        {
            item.click(node);
        }
    }

    @Emit('delete')
    public onDelete(node: TreeNodeCoursesProgram): TreeNodeCoursesProgram
    {
        this.$events.$emit('tree::popover::hide', node);

        return node;
    }

    @Emit('sort')
    public onSort(pages: TreeNodeCoursesProgram[]): TreeNodeCoursesProgram[]
    {
        return pages;
    }

    @Emit('resetUnit')
    public resetUnitResult(node: TreeNodeCoursesProgram): TreeNodeCoursesProgram
    {
        return node;
    }
}
</script>

<style scoped lang="scss">
.text-default {
    color: #cdd1d9 !important;
}

.box {
    background-color: #E1E2E3;
    border-radius: 4px;
    padding: 4px 5px;
    cursor: pointer;

    & > i {
        font-size: .75rem;
        color: #646464;
        display: block;
    }
}

.spaces {
    padding: 4px 5px;
    display: block;
    min-width: 26px;
}

.course-item {
    gap: 10px;
    padding: 10px 15px;
}

.tree li > span a.course-element {
    font-size: .875rem;
    color: var(--color-text);
    font-weight: 400;
}

.tree:not(.tree-nested) li {
    margin: 0;

    ul.tree-nested {
        padding: 0;

        span.course-item {
            padding-left: 36px;
        }
    }

    .course-item {
        background: var(--course-program);
        border-bottom: 1px solid #ECECEC;
    }
}

.tree:not(.tree-nested) li.white {

    & > .course-item {
        padding: 20px 15px;
        .course-element {
            font-weight: 600;
        }
    }

    .course-item {
        background: var(--color-white);
    }
}

.tree.tree-nested li:hover {
    background: var(--color-header);

    .course-item {
        background: var(--color-header);
    }
}

@media screen and (max-width: 960px) {
    .column {
        flex-direction: column;
        align-items: center;
        gap: 12px;
    }
}
</style>
