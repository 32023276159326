<template>
    <ideo-form-group :invalid-feedback="form.$errors.first('settings.showTitle')" :state="form.$errors.state('settings.showTitle')" :nospace="true">
        <ideo-form-checkbox v-model="form.settings.showTitle">{{ $t('[[[Wyświetlaj tytuł]]]') }}</ideo-form-checkbox>
    </ideo-form-group>
    <ideo-form-group :label="$t('[[[Tytuł]]]')" :invalid-feedback="form.$errors.first('settings.title')" :state="form.$errors.state('settings.title')">
        <ideo-form-input type="text" v-model="form.settings.title" name="title"></ideo-form-input>
    </ideo-form-group>
    <ideo-form-group :label="$t('[[[Link do zdjęcia]]]')" :invalid-feedback="form.$errors.first('settings.imageUrl')" :state="form.$errors.state('settings.imageUrl')">
        <ideo-form-input type="text" v-model="form.settings.imageUrl" name="imageUrl"></ideo-form-input>
    </ideo-form-group>
    <ideo-form-group :label="$t('[[[YouTube (ID)]]]')" :invalid-feedback="form.$errors.first('settings.youtubeId')" :state="form.$errors.state('settings.youtubeId')">
        <ideo-form-input type="text" v-model="form.settings.youtubeId" name="youtubeId"></ideo-form-input>
    </ideo-form-group>
    <ideo-form-group :label="$t('[[[Tekst odnośnika]]]')" :invalid-feedback="form.$errors.first('settings.urlText')" :state="form.$errors.state('settings.urlText')">
        <ideo-form-input type="text" v-model="form.settings.urlText" name="urlText"></ideo-form-input>
    </ideo-form-group>
    <ideo-form-group :label="$t('[[[Link odnośnika]]]')" :invalid-feedback="form.$errors.first('settings.url')" :state="form.$errors.state('settings.url')">
        <ideo-form-input type="text" v-model="form.settings.url" name="url"></ideo-form-input>
    </ideo-form-group>
    <ideo-form-group :invalid-feedback="form.$errors.first('settings.openNewTab')" :state="form.$errors.state('settings.openNewTab')" :nospace="true">
        <ideo-form-checkbox v-model="form.settings.openNewTab" switch>{{ $t('[[[Otwieraj w nowym oknie]]]') }}</ideo-form-checkbox>
    </ideo-form-group>
    <ideo-form-group :label="$t('[[[Zdjęcie (tablet)]]]')" :invalid-feedback="form.$errors.first(`settings.tabletImage`)" :state="form.$errors.state(`settings.tabletImage`)">
        <filepicker id="tabletImage" v-model="form.settings.tabletImage" media="images" />
    </ideo-form-group>
    <ideo-form-group :label="$t('[[[Zdjęcie (mobilne)]]]')" :invalid-feedback="form.$errors.first(`settings.mobileImage`)" :state="form.$errors.state(`settings.mobileImage`)">
        <filepicker id="mobileImage" v-model="form.settings.mobileImage" media="images" />
    </ideo-form-group>
</template>

<script lang="ts">
    import { Options, Vue } from 'vue-class-component';
    import { Emit, Prop, Watch } from '@/helpers/Decorators';
    import { ValidatableComponent } from '@/components/cms';
    import merge from 'lodash/merge';

    @Options({
        name: 'widget-video-settings'
    })
    export default class VideoSettings extends Vue implements ValidatableComponent {
        @Prop() public modelValue!: any;

        public defaults: any = {
            title: '',
            showTitle: false,
            imageUrl: '',
            youtubeId: '',
            urlText: '',
            url: '',
            openNewTab: false,
            tabletImage: null,
            mobileImage: null
        };

        public get form(): any {
            return this.modelValue;
        }

        public async created(): Promise<void> {
            this.form.settings = merge(this.defaults, this.form.settings);
        }

        @Watch('form.settings', { deep: true })
        public onSettingsChanged(value: any): void {
            this.triggetModel();
        }

        @Emit('update:modelValue')
        public triggetModel(): any {
            return this.form;
        }

        public async validate(): Promise<void> {
        }
    }
</script>
