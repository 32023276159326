import { axios } from '@/plugins/axios';
import trimEnd from 'lodash/trimEnd';

/**
 * SettingsService
 */
export default class SettingsService
{
    /**
     * @param name string
     *
     * @returns Promise<PluginsSettings>
     */
    public static async getConfig(name: string = ''): Promise<PluginsSettings>
    {
        return (await axios.get<PluginsSettings>(trimEnd(`admin/cms/settings/${name}`, '/'))).data;
    }

    /**
     * @param name string
     *
     * @returns Promise<ThemeSettings>
     */
    public static async getThemeConfig(name: string): Promise<ThemeSettings>
    {
        return (await axios.get<ThemeSettings>(`admin/cms/settings/theme/${name}`)).data;
    }
}

export interface PluginsSettings
{
    modules: PluginSettings[];
    widgets: PluginSettings[];
}

export interface PluginSettings
{
    id: string;
    name: string;
    isPublishable: boolean;
    isConfigurable: boolean;
    icon?: string;
    settings?: boolean;
}

export interface ThemeSettings
{
    sections: Record<string, string>;
    layouts: Record<string, LayoutSettings>;
    widgets: Record<string, WidgetSettings>;
}

export interface LayoutSettings
{
    name: string;
    sections: string[];
    design: Record<string, DesignSettings>;
}

export interface DesignSettings
{
    row: string;
    column: string;
    class: string;
}
export interface WidgetSettings
{
    sections: string[];
    default: Record<string, string>;
    override: Record<string, Record<string, string>>;
}
