<template>
    <ideo-form-group :label="$t('[[[Opis]]]')" :invalid-feedback="form.$errors.first('content.value.description')" :state="form.$errors.state('content.value.description')">
        <editor :id="seconds('description-' + form.id)" v-model="form.content.value.description" data-cy="description"></editor>
    </ideo-form-group>
    <ideo-form-group :label="$t('[[[Tekst do uzupełnienia]]]')" :invalid-feedback="form.$errors.first('content.value.text')" :state="form.$errors.state('content.value.text')" required>
        <editor id="fillText" v-model="form.content.value.text" data-cy="question"></editor>
        <div class="font-small">
            <div>{{ $t('[[[ Elementy do uzupełnienia w tekście zamieszczamy pomiędzy zdefiniowanymi znacznikami %$dowolna_nazwa$%.]]]') }}</div>
            <div>{{ $t('[[[%$ – znacznik otwierający tag]]]') }}</div>
            <div>{{ $t('[[[$% - znacznik zamykający tag]]]') }}</div>
        </div>
    </ideo-form-group>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Emit, Prop, Watch } from '@/helpers/Decorators';
import merge from 'lodash/merge';
import { FormType } from '@/helpers/Form';
import { seconds } from '@/helpers/Utils';

interface FormFillTheGaps
{
    content: {
        value: {
            description: string,
            text: string
        }
    }
}

@Options({
    name: 'type-fillthegaps-settings'
})
export default class FillTheGapsSettings extends Vue
{
    @Prop() public modelValue!: any;

    // rozwiązanie problemu inicjalizowania edytora, z powodu dwóch tych samych id-ków
    public seconds = seconds;

    public defaults: any = {
        description: '',
        text: ''
    };

    public get form(): FormType<FormFillTheGaps>
    {
        return this.modelValue;
    }

    public created(): void
    {
        this.form.content.value = merge(this.defaults, this.form.content.value);
        this.form.file = null;
    }

    @Watch('form.content.value', { deep: true })
    public onContentChanged(value: any): void
    {
        this.triggetModel();
        this.form.$errors.clear('content.value.text');
    }

    @Emit('update:modelValue')
    public triggetModel(): any
    {
        return this.form;
    }
}

</script>
<style lang="scss" scope>
.font-small {
    font-size: 12px;
}
</style>
