<template>
    <header>
        <Logo v-model:collapsed="collapsed" :hover="hover || mobile"></Logo>
    </header>
    <section class="scroll">
        <ul class="menu" :class="{'collapsed': collapsed, 'hover': hover}">
            <li v-for="(node1, i) in root()" :key="i" :class="{'active': active(node1)}">
                <router-link :to="url(node1)" @click.prevent="toggle(node1)" :aria-label="$t(node1.name)">
                    <i :class="node1.icon"></i>
                    <span>{{ $t(node1.name) }}</span>
                    <b v-if="children(node1).length > 0 && !expanded(node1)"><i class="fas fa-chevron-left"></i></b>
                    <b v-else-if="children(node1).length > 0 && expanded(node1)"><i class="fas fa-chevron-down"></i></b>
                    <b v-else></b>
                </router-link>
                <ul :class="{'expanded': expanded(node1)}" v-if="children(node1).length > 0">
                    <li v-for="(node2, i) in children(node1)" :key="i" :class="{'active': active(node2)}">
                        <router-link :class="{'second-level-item': !children(node2).length}" :to="url(node2)" @click.prevent="toggle(node2)" :aria-label="$t(node2.name)">
                            <i class="fas fa-fw fa-minus"></i>
                            <span>{{ $t(node2.name) }}</span>
                            <b v-if="children(node2).length > 0 && !expanded(node2)"><i class="fas fa-chevron-left"></i></b>
                            <b v-else-if="children(node2).length > 0 && expanded(node2)"><i class="fas fa-chevron-down"></i></b>
                            <b v-else></b>
                        </router-link>
                        <ul :class="{'expanded': expanded(node2)}" v-if="children(node2).length > 0">
                            <li v-for="(node3, i) in children(node2)" :key="i" :class="{'active': active(node3)}">
                                <router-link :to="url(node3)" @click.prevent="toggle(node3)" :aria-label="$t(node3.name)">
                                    <i class="fas fa-fw fa-circle-small fa-2xs mt-1"></i>
                                    <span>{{ $t(node3.name) }}</span>
                                    <b></b>
                                </router-link>
                            </li>
                        </ul>
                    </li>
                </ul>
            </li>
        </ul>
    </section>
    <footer class="d-flex justify-content-center align-items-center">
        Edito {{ version }}
    </footer>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop } from '@/helpers/Decorators';
import { useAuthStore } from '@/store/auth';
import Logo from '../layout/Logo.vue';

@Options({
    name: 'partials-navigation-menu',
    components: {
        Logo
    }
})
export default class Menu extends Vue
{
    @Prop({ default: false })
    public collapsed: boolean;

    @Prop({ default: false })
    public hover: boolean;

    public sitemap: any[] = [];
    public toggled: any[] = [];

    public async mounted(): Promise<void>
    {
        this.sitemap = await this.$sitemap.all();
        this.toggled = await this.$sitemap.crumbs(this.$route);
    }

    public unmounted(): void
    {
        this.$sitemap.purge();
    }

    public active(node: any): boolean
    {
        return this.$sitemap.active(node, this.$route, true);
    }

    public url(node: any): any
    {
        return this.$sitemap.url(node, this.$route);
    }

    public root(): any[]
    {
        return this.sitemap.filter((p: any) => p.visible);
    }

    public children(node: any): any[]
    {
        return node.children.filter((p: any) => p.visible);
    }

    public toggle(node: any): void
    {
        if (this.children(node).length > 0)
        {
            if (this.expanded(node))
            {
                this.toggled = this.toggled.filter(p => p !== node);
            }
            else
            {
                this.toggled = [];
                this.$sitemap.path(node).forEach(p =>
                    this.toggled.push(p)
                );
            }
        }
        else
        {
            this.redirect(this.url(node));
        }
    }

    public expanded(node: any): boolean
    {
        return this.toggled.includes(node);
    }

    public get version()
    {
        return useAuthStore().identity?.systemVersion;
    }
}
</script>

<style lang="scss" scoped>
header {
    height: var(--ideo-header-height);
}
section {
    height: calc(100dvh - var(--ideo-header-height) - var(--ideo-footer-height));
}
footer {
    height: var(--ideo-footer-height);
}
ul.menu {
    --ideo-menu-display: block;
    --ideo-menu-item-space: 4px;
    --ideo-menu-icon-space: 20px;
    --ideo-menu-link-color: var(--ideo-nav-color);
    --ideo-menu-active-color: var(--bs-white);
    --ideo-menu-active-bg: var(--bs-primary);
    --ideo-menu-bg-secondary: rgba(255, 255, 255, 0.05);
    --ideo-menu-radius: var(--bs-border-radius);

    min-height: 100%;
    padding-top: .5rem;
    width: var(--ideo-nav-width-default);

    &.collapsed {
        --ideo-menu-display: none;

        &.hover {
            --ideo-menu-display: block;
        }

        &:not(.hover) .active > a {
            background-color: inherit;

            > span, > i, > b {
                color: inherit;
            }
        }
    }
}

// pierwszy poziom
ul {
    padding: 0;
    margin: 0;
    list-style-type: none;

    li {
        padding: 0;
        margin: 0;

        > a {
            display: flex;
            flex-direction: row;
            align-items: stretch;
            padding: 9px 0;
            margin: calc(var(--ideo-menu-item-space) / 2) var(--ideo-menu-item-space) 0 var(--ideo-menu-item-space);
            border-radius: var(--ideo-menu-radius);
            text-decoration: none;
            font-size: 14px;
            font-weight: 500;
            color: var(--ideo-menu-link-color);

            > i {
                margin: 0 var(--ideo-menu-icon-space) 0 calc(var(--ideo-menu-icon-space) - var(--ideo-menu-item-space));
                min-width: 20px;
                line-height: 1.5em;
            }
            > span {
                display: var(--ideo-menu-display);
                flex-grow: 1;
            }
            > b {
                display: var(--ideo-menu-display);
                margin: 3px 15px 0 10px;
                min-width: 10px;
                text-align: center;
                font-size: 8px;
            }

            &:hover {
                background-color: var(--ideo-menu-active-bg) !important;

                > span, > i, > b {
                    color: var(--ideo-menu-active-color) !important;
                }
            }
        }

        &.active:not(:has(.active)) {
            > a {
                background-color: var(--ideo-menu-active-bg);

                > span, > i, > b {
                    color: var(--ideo-menu-active-color);
                }
            }
        }
    }
}

// drugi poziom
ul ul {
    display: var(--ideo-menu-display);
    overflow: hidden;
    height: 0;
    transition: all 0.5s cubic-bezier(0, 1, 0.5, 1);
    background-color: var(--ideo-menu-bg-secondary);
    border-radius: var(--ideo-menu-radius);

    &.expanded {
        height: auto;
        padding: 0;
    }

    > li {
        > a {
            // padding-left: var(--ideo-nav-width-collapsed);
            font-size: 13px;
        }
        &:first-child {
            margin-top: var(--ideo-menu-item-space);
        }
        &:last-child {
            margin-bottom: var(--ideo-menu-item-space);
        }
    }
}

// trzeci poziom
ul ul ul {
    background-color: transparent;

    > li {
        margin: 0 !important;

        > a {
            padding: 7px 0 7px 16px; //calc(var(--ideo-nav-width-collapsed) + 12px);
            font-size: 12px;
        }
    }
}
</style>
