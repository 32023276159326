<template>
    <div class="dashboard-card">
        <div class="dashboard-card-title">
            <action-bar :auto-header="false">
                <action-button variant="primary ms-auto" icon="fas fa-fw fa-user-friends" :text="$t('[[[Pokaż wszystkich użytkowników]]]')" :to="{name:'core-users'}" />
            </action-bar>
        </div>
        <div v-if="newUsers && newUsers.items.length === 0">
            <div class="text-center p-3 mb-3 bg-light text-muted">{{ $t('[[[W ciągu ostatniego tygodnia nie dodano nowych użytkowników]]]') }}</div>
        </div>
        <div v-else-if="newUsers">
            <list-view
                :items="newUsers.items" :pager="pager" @change="loadData()"
            >
                <template #row="{item}">
                    <list-view-image width="36">
                        <ideo-img :src="$filters.image(item.result.facePictureUrl, 'ico')" rounded="circle" :blank="!item.result.facePictureUrl" width="36" height="36" blank-color="#777" :alt="$t('[[[Zdjęcie użytkownika]]]')" />
                    </list-view-image>
                    <list-view-header :title="$t('[[[Imię i Nazwisko]]]')" sort="Surname">
                        <span>{{ item.result.givenName }} {{ item.result.surname }}</span>
                    </list-view-header>
                    <list-view-item :title="$t('[[[Login]]]')">
                        {{ item.result.userName }}
                    </list-view-item>
                    <list-view-item :title="$t('[[[Data rejestracji]]]')" sort="DateCreatedUtc" :width="150">
                        {{ $filters.datetime(item.result.dateCreatedUtc) }}
                    </list-view-item>
                    <list-view-buttons width="50">
                        <list-view-button @click="$router.push({name:'core-users-edit', params: { id: item.result.id }})">
                            <i class="fas fa-pencil-alt me-1"></i> {{ $t('[[[Szczegóły]]]') }}
                        </list-view-button>
                    </list-view-buttons>
                </template>
            </list-view>
            <div :class="['d-flex mt-4 align-items-center', !mobile ? 'flex-nowrap' : 'flex-wrap', !phone ? 'justify-content-between' : 'justify-content-center']">
                <pagination :pager="pager" @change="loadData()" />
                <div class="d-flex align-items-center">
                    <paginationInfo :pager="pager" class="ms-2 d-none d-sm-block d-md-block d-lg-block" />
                    <data-size :pager="pager" @change="loadData()" class="ms-2"></data-size>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import DashboardService, { UserModel } from '../../services/DashboardService';
import Pager from '@/helpers/Pager';
import Pagination from '@/components/common/Pagination.vue';
import { Resource, Pagination as InterfacePagination } from '@/helpers/Interfaces';
import { useHomeStore } from '../../store/index';

@Options({
    name: "NewUsers",
    components: {
        Pagination
    },
})
export default class NewUsers extends Vue
{
    public store = useHomeStore();
    public newUsers: InterfacePagination<Resource<UserModel>> = null;

    public get pager(): Pager
    {
        return this.store.pager as any;
    }

    public async created(): Promise<void>
    {
        await this.loadData();
    }

    public async loadData(): Promise<void>
    {
        try
        {
            const response = await DashboardService.getNewUsers(this.pager);

            this.pager.setTotalRows(response.totalRows);
            this.newUsers = response;
        }
        catch (ex)
        {
            this.handleException(ex, {
                400: (ex: any) => this.$alert.warning(ex.message)
            });
            this.pager.setTotalRows(0);
        }
    }
}
</script>

<style scoped lang="scss">

.dashboard-card-title {
    font-size: 24px;
    color: rgba(44, 56, 74, 0.95);
    margin-bottom: 24px;
    display: flex;
    justify-content: flex-end;
}
</style>
