<template>
    <ideo-form-group :label="$t('[[[Opis]]]')" :invalid-feedback="form.$errors.first('content.value.description')" :state="form.$errors.state('content.value.description')" required>
        <editor :id="seconds('description-' + form.id)" v-model="form.content.value.description" data-cy="description"></editor>
    </ideo-form-group>
    <ideo-form-group :label="$t('[[[Stwierdzenia]]]')" :invalid-feedback="form.$errors.first('content.questions')" :state="form.$errors.state('content.questions')">
        <div v-for="(question, i) in form.content.value.questions" :key="question.id" class="mb-3 g-0 row">
            <div class="col-md-11 col-sm-10">
                <div class="input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text bg-primary border-primary text-white">{{ i + 1 }}</span>
                    </div>
                    <ideo-form-textarea v-model="question.text" :name="`content.value.questions[${i}]`" :rows="2" :placeholder="$t('[[[Pytanie]]]')" :data-cy="`question-${i}`"></ideo-form-textarea>
                    <div class="input-group-append">
                        <action-button :variant="question.isTrue ? 'success' : 'secondary'" icon="far fa-check" :title="$t('[[[Poprawna odpowiedź]]]')" @click="isTrueChanged(question.id)" data-cy="isTrue" />
                        <action-button variant="danger" icon="far fa-trash" :title="$t('[[[Usuń pytanie]]]')" :confirm="$t('[[[Potwierdzenie usunięcia]]]')" @click="removeQuestion(i)" :disabled="form.content.value.questions.length < 2" />
                    </div>
                </div>
                <small class="form-text text-danger" v-if="!form.$errors.state(`content.value.questions[${i}]`)">{{ form.$errors.first(`content.value.questions[${i}]`) }}</small>
            </div>
            <div class="col-md-1 col-sm-2 d-flex align-items-baseline">
                <action-button variant="secondary" icon="far fa-arrow-up" class="ms-auto" :title="$t('[[[Wyżej]]]') " @click="questionUp(i)" :disabled="i == 0" />
                <action-button variant="secondary" icon="far fa-arrow-down" :title="$t('[[[Niżej]]]')" @click="questionDown(i)" :disabled="i == form.content.value.questions.length - 1" />
            </div>
        </div>
        <div>
            <action-button variant="outline-dark" class="w-100" icon="far fa-plus" :text="$t('[[[Dodaj stwierdzenie]]]')" @click="addQuestion()" data-cy="addQuestion" />
        </div>
    </ideo-form-group>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Emit, Prop, Watch } from '@/helpers/Decorators';
import merge from 'lodash/merge';
import { FormType } from '@/helpers/Form';
import { seconds } from '@/helpers/Utils';

interface FormTrueFalse
{
    content: {
        value: {
            description: string,
            questions: Question[]
        }
    }
}

interface Question {
    id: number,
    text: string,
    isTrue: boolean
}

@Options({
    name: 'type-truefalse-settings'
})
export default class TrueFalseSettings extends Vue
{
    @Prop() public modelValue!: any;

    // rozwiązanie problemu inicjalizowania edytora, z powodu dwóch tych samych id-ków
    public seconds = seconds;

    public defaults: any = {
        description: '',
        questions: []
    };

    public get form(): FormType<FormTrueFalse>
    {
        return this.modelValue;
    }

    public created(): void
    {
        this.form.content.value = merge(this.defaults, this.form.content.value);
        this.form.file = null;

        if (!this.form.content.value.questions.length)
        {
            this.form.content.value.questions = [{
                id: 1,
                text: '',
                isTrue: true
            }];
        }
    }

    public addQuestion(): void
    {
        const ids = this.form.content.value.questions.map((x: { id: any; }) => x.id);

        const maxId = this.form.content.value.questions.length > 0 ? Math.max(...ids) : 1;

        this.form.content.value.questions.push({
            id: maxId + 1,
            text: '',
            isTrue: false
        });
    }

    public removeQuestion(index: number): void
    {
        const items = this.form.content.value.questions.slice();

        items.splice(index, 1);
        this.form.content.value.questions = items;
    }

    public isTrueChanged(id: number) : void
    {
        const clickedQuestionValue = this.form.content.value.questions.find((x: { id: number; }) => x.id == id).isTrue;

        this.form.content.value.questions.find((x: { id: number; }) => x.id == id).isTrue = !clickedQuestionValue;
    }

    public questionUp(index: number): void
    {
        if (index > 0)
        {
            const questions = this.form.content.value.questions.splice(index, 1);

            this.form.content.value.questions.splice(index - 1, 0, questions[0]);

            this.form.$errors.clear();
        }
    }

    public questionDown(index: number): void
    {
        if (index < this.form.content.value.questions.length - 1)
        {
            const questions = this.form.content.value.questions.splice(index, 1);

            this.form.content.value.questions.splice(index + 1, 0, questions[0]);

            this.form.$errors.clear();
        }
    }

    @Watch('form.content.value', { deep: true })
    public onContentChanged(value: any): void
    {
        this.triggetModel();
    }

    @Emit('update:modelValue')
    public triggetModel(): any
    {
        this.form.$errors.clear('content.value.description');

        return this.form;
    }
}
</script>
