<template>
    <div>
        <action-button variant="secondary" icon="far fa-arrow-up" :title="$t('[[[Wyżej]]]') " @click="moveUp(index)" :disabled="index == 0" />
        <action-button variant="secondary" icon="far fa-arrow-down" :title="$t('[[[Niżej]]]')" @click="moveDown(index)" :disabled="index == items.length - 1" />
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Emit, Prop } from '@/helpers/Decorators';

@Options({
    name: 'move'
})
export default class Move extends Vue
{
    @Prop() public items!: Array<any>;
    @Prop() public index!: number;

    public moveUp(index: number): void
    {
        if (index > 0)
        {
            const newItems = this.items.splice(index, 1);

            this.items.splice(index - 1, 0, newItems[0]);

            this.moving(this.items);
        }
    }

    public moveDown(index: number): void
    {
        if (index < this.items.length - 1)
        {
            const newItems = this.items.splice(index, 1);

            this.items.splice(index + 1, 0, newItems[0]);

            this.moving(this.items);
        }
    }

    @Emit('moving')
    public moving(elements: Array<any>): Array<any>
    {
        return elements;
    }
}
</script>
