<template>
    <ideo-form-group :label="$t('[[[Wybór kategorii wydarzeń]]]')" :invalid-feedback="form.$errors.first('settings.categories')" :state="form.$errors.state('settings.categories')">
        <multiselect
            v-model="form.settings.categories"
            label="text"
            track-by="value"
            :options="selectedCategoriesOptions"
            :multiple="true"
            :placeholder="$t('[[[Wybierz kategorię]]]')"
        >
            <template #noOptions>{{ $t('[[[Lista jest pusta]]]') }}</template>
            <template #noResult>{{ $t('[[[Nie znaleziono żadnych wyników.]]]') }}</template>
        </multiselect>
    </ideo-form-group>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Emit, Prop, Watch } from '@/helpers/Decorators';
import { CurrentWebsite, Option } from '@/helpers/Interfaces';
import { FormType } from '@/helpers/Form';
import merge from 'lodash/merge';
import EventCategoriesService, { ListItemModel } from '@/modules/elearning/events/services/EventCategoriesService';
import { $t } from '@/plugins/localization';
import { wait } from '@/helpers/Utils';
import Multiselect from 'vue-multiselect/src/Multiselect.vue';
import { useWebsitesStore } from '@/modules/cms/websites/store';

interface FormSettings
{
    settings: any;
}

@Options({
    name: 'widget-upcomingevents-settings',
    components: {
        'multiselect': Multiselect
    }
})
export default class UpcomingEventsSettings extends Vue
{
    @Prop() public modelValue!: any;

    public get form(): FormType<FormSettings>
    {
        return this.modelValue;
    }

    public module: string = 'el_events';
    public defaults: any = {
        pages: [],
        nameIsVisible: true,
        title: "",
        display: 'Latest',
        categories: [],
        allCategories: true
    };

    public categoriesOptions: ListItemModel[] = [];

    public get current(): CurrentWebsite
    {
        return useWebsitesStore().current;
    }

    public get selectedCategoriesOptions(): Option[]
    {
        return this.categoriesOptions.map(p =>
        {
            return { value: p.id.toString(), text: p.name };
        });
    }

    public async created(): Promise<void>
    {
        await wait(() => this.current.website);
        await this.loadCategories();
        this.form.settings = merge(this.defaults, this.form.settings);
    }

    @Watch('form.settings', { deep: true })
    public onSettingsChanged(value: any): void
    {
        this.triggetModel();
    }

    @Emit('update:modelValue')
    public triggetModel(): any
    {
        return this.form;
    }

    public async loadCategories(): Promise<void>
    {
        try
        {
            const response = await EventCategoriesService.getOptions(this.form.websiteId, this.form.locale);

            this.categoriesOptions = [{
                id: 0,
                name: this.$t('[[[Wszystkie kategorie]]]'),
                color:null,
                dateCreatedUtc:null,
                dateModifiedUtc:null,
                eventsCount: 0,
                locale: ''
            }].concat(response);
        }
        catch (ex)
        {
            this.categoriesOptions = [];
            this.$log.debug(ex);
        }
    }
}
</script>
