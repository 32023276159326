import { Plugin } from 'vue';
import { kebabCase } from 'lodash';
import { defineStore } from 'pinia';
import { reactive } from 'vue';

export const useComponentsStore = defineStore('cms-components', () =>
{
    const modules = reactive<string[]>([]);
    const widgets = reactive<string[]>([]);

    return { modules, widgets };
});
export interface ValidatableComponent
{
    validate(): Promise<void>
}

const ElearningPlugin: Plugin =
{
    async install(app, options)
    {
        const store = useComponentsStore();

        app.component('ElearningWebsiteSelector', (await import('@/components/elearning/WebsiteSelector.vue')).default);
        app.component('ElearningPageSelector', (await import('@/components/elearning/PageSelector.vue')).default);

        // Material types
        const requireTypesComponent = import.meta.glob('@/modules/elearning/common/material-types/*Settings.vue', { eager: true });


        Object.values(requireTypesComponent).forEach((component: any) =>
        {
            store.modules.push(kebabCase(component.default.name));
            app.component(component.default.name, component.default);
        });

        // Module e-learnig settings
        const requireElModuleComponent = import.meta.glob('@/modules/elearning/**/views/*Settings.vue', { eager: true });

        Object.values(requireElModuleComponent).forEach((component: any) =>
        {
            store.modules.push(kebabCase(component.default.name));
            app.component(component.default.name, component.default);
        });

        // Widget settings
        const requireWidgetComponent = import.meta.glob('@/modules/elearning/common/widgets/**/*Settings.vue', { eager: true });

        Object.values(requireWidgetComponent).forEach((component: any) =>
        {
            store.widgets.push(kebabCase(component.default.name));
            app.component(component.default.name, component.default);
        });
    }
};

export default ElearningPlugin;
