<script lang="ts" setup>
import { computed } from 'vue';
import { useLocalization } from '@/plugins/localization';
import { Definition, AddressType, AddressEntry, instanceOfAddressEntry } from '.';
import { FormBuilderContract } from '@/components/builder/form';

export interface Props
{
    builder: FormBuilderContract;
    component: AddressType;
    index: number;
}

defineOptions({
    name: `${Definition.type}-filter`
});

const { $t } = useLocalization();

const props = defineProps({
  "builder": null,
  "component": null,
  "index": null
});
const component = computed(() => props.component);
const entry = computed(() => props.builder.document.initEntry(props.component, new AddressEntry(), instanceOfAddressEntry) as AddressEntry);
const display = computed(() =>
{
    const values = [
        entry.value.data.street,
        entry.value.data.building,
        entry.value.data.apartment,
        entry.value.data.city,
        entry.value.data.zip,
        entry.value.data.province,
        entry.value.data.country,
    ];

    return values.filter(p => p != null).length > 0 ? $t('[[[Podano adres]]]') : null;
});

const reset = (): void =>
{
    entry.value.data.street = null;
    entry.value.data.building = null;
    entry.value.data.apartment = null;
    entry.value.data.city = null;
    entry.value.data.zip = null;
    entry.value.data.province = null;
    entry.value.data.country = null;
};
</script>

<template>
    <ideo-filter :name="component.name" :label="component.label" :visible="component.showFilter" :index="index" :display="display" @clear="reset">
        <div class="mb-n2">
            <div class="row g-0 mb-2 me-n2" v-if="component.hasStreet">
                <div class="col-12 pe-2">
                    <input type="text" class="form-control" v-model="entry.data.street" :placeholder="$t(component.getStreetLabel())">
                </div>
            </div>
            <div class="row g-0 mb-2 me-n2" v-if="component.hasBuilding || component.hasApartment">
                <div class="col pe-2" v-if="component.hasBuilding">
                    <input type="text" class="form-control" v-model="entry.data.building" :placeholder="$t(component.getBuildingLabel())">
                </div>
                <div class="col-5 pe-2" v-if="component.hasApartment">
                    <input type="text" class="form-control" v-model="entry.data.apartment" :placeholder="$t(component.getApartmentLabel())">
                </div>
            </div>
            <div class="row g-0 mb-2 me-n2" v-if="component.hasCity || component.hasZip">
                <div class="col pe-2" v-if="component.hasCity">
                    <input type="text" class="form-control" v-model="entry.data.city" :placeholder="$t(component.getCityLabel())">
                </div>
                <div class="col-5 pe-2" v-if="component.hasZip">
                    <input type="text" class="form-control" v-model="entry.data.zip" :placeholder="$t(component.getZipLabel())">
                </div>
            </div>
            <div class="row g-0 mb-2 me-n2" v-if="component.hasProvince">
                <div class="col pe-2" v-if="component.hasProvince">
                    <input type="text" class="form-control" v-model="entry.data.province" :placeholder="$t(component.getProvinceLabel())">
                </div>
            </div>
            <div class="row g-0 mb-2 me-n2" v-if="component.hasCountry">
                <div class="col pe-2" v-if="component.hasCountry">
                    <input type="text" class="form-control" v-model="entry.data.country" :placeholder="$t(component.getCountryLabel())">
                </div>
            </div>
        </div>
    </ideo-filter>
</template>
