import { axios } from '@/plugins/axios';
import merge from 'lodash/merge';
import Pager from '@/helpers/Pager';
import { Pagination, Resource, Statement, EntityVersion } from '@/helpers/Interfaces';
import { DateTime } from 'luxon';
import { PublicationEnum } from '@/helpers/Enums';

/**
 * RegulationsService
 */
export default class RegulationsService
{
    /**
     * @param filter FilterModel
     * @param pager Pager
     *
     * @returns Promise<Pagination<Resource<ListItemModel>>>
     */
    public static async getList(filter: FilterModel, pager: Pager): Promise<Pagination<Resource<ListItemModel>>>
    {
        return (await axios.get<Pagination<Resource<ListItemModel>>>('admin/cms/regulations', {
            params: merge({}, filter, pager.data())
        })).data;
    }

    /**
     * @param id number
     *
     * @returns Promise<Statement>
     */
    public static async remove(id: number): Promise<Statement>
    {
        return (await axios.delete(`admin/cms/regulations/${id}`)).data as Statement;
    }

    /**
     * @param id number
     *
     * @returns Promise<Resource<ViewModel>>
     */
    public static async fetch(id: number): Promise<Resource<ViewModel>>
    {
        return (await axios.get<Resource<ViewModel>>(`admin/cms/regulations/${id}`)).data;
    }

    /**
      * @param model FormModel
      *
      * @returns Promise<Resource<FormModel>>
      */
    public static async create(model: FormModel): Promise<Resource<FormModel>>
    {
        return (await axios.post<Resource<FormModel>>(`admin/cms/regulations`, model)).data;
    }

    /**
      * @param id number
      * @param model FormModel
      *
      * @returns Promise<Resource<FormModel>>
      */
    public static async update(id: number, model: FormModel): Promise<Resource<FormModel>>
    {
        return (await axios.put<Resource<FormModel>>(`admin/cms/regulations/${id}`, model)).data;
    }

    /**
     * @param id number
     * @param status PublicationEnum
     *
     * @returns Promise<Statement>
     */
    public static async status(id: number, status: PublicationEnum): Promise<Statement>
    {
        return (await axios.patch(`admin/cms/regulations/${id}/status`, {
            status: status
        })).data;
    }

    /**
     * @param id number
     *
     * @returns Promise<Pagination<EntityVersion>>
     */
    public static async getVersions(id: number, pager: Pager): Promise<Pagination<EntityVersion>>
    {
        return (await axios.get<Pagination<EntityVersion>>(`admin/cms/regulations/${id}/versions`, {
            params: pager.data()
        })).data;
    }

    /**
     * @param search string
     * @param limit number
     *
     * @returns Promise<ListItemModel[]>
     */
    public static async getAutocompleteList(search: string, language: string, limit: number = 10): Promise<ListItemModel[]>
    {
        return (await axios.get<ListItemModel[]>('admin/cms/regulations/autocomplete', {
            params: {
                search: search,
                language: language,
                limit: limit
            }
        })).data;
    }

    /**
       * @param id number
       *
       * @returns Promise<ListItemModel>
       */
    public static async fetchAutocompleteModel(id: number): Promise<ListItemModel>
    {
        return (await axios.get<ListItemModel>(`admin/cms/regulations/autocomplete/${id}`)).data;
    }

    /**
     * @param filter UserRegulationsFilterModel
     *
     * @returns Promise<Resource<UserRegulationsListItemModel>[]>
     */
    public static async getUserRegulationsList(filter: UserRegulationsFilterModel): Promise<Resource<UserRegulationsListItemModel>[]>
    {
        return (await axios.get<Resource<UserRegulationsListItemModel>[]>(`admin/cms/regulations/user`, {
            params: filter
        })).data;
    }
}

export interface ListItemModel
{
    id: number;
    ownerId: number;
    dateCreatedUtc: DateTime;
    dateModifiedUtc: DateTime;
    createdBy: string;
    modifiedBy: string;
    title: string;
    status: PublicationEnum;
    language: string;
}

export interface UserRegulationsListItemModel
{
    id: number;
    datePublishedUtc: DateTime;
    dateAcceptedUtc: DateTime;
    dateRejectedUtc: DateTime;
    title: string;
    content: string;
    language: string;
}

export interface UserRegulationsFilterModel
{
    userId: number;
    operationType: string;
}

export interface FilterModel
{
    userId: number;
    status: string;
    language: string;
    title: string;
    dateFromUtc?: DateTime;
    dateDueUtc?: DateTime;
    onlyMine: boolean;
    onlyTeam: boolean;
}

export interface ViewModel extends FormModel
{
    status:string;
}

export interface FormModel
{
    id: number;
    title: string;
    shortDescription:string;
    isAutoAccepted:boolean;
    isRequired:boolean;
    datePublishedUtc:DateTime
    dateAutoAcceptUtc:DateTime
    content:string;
    mailMessage:string;
    language:string;
    status: string;
}
