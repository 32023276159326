<template>
    <div class="widget">
        <div class="widget-title">
            <ideo-form-group :label="$t('[[[Tytuł]]]')" :invalid-feedback="form.$errors.first('settings.title')" :state="form.$errors.state('settings.title')">
                <ideo-form-input v-model="form.settings.title" type="text" name="settings.title"></ideo-form-input>
            </ideo-form-group>
        </div>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Emit, Prop, Watch } from '@/helpers/Decorators';
import { ValidatableComponent } from '@/components/cms';
import merge from 'lodash/merge';
import { FormType } from '@/helpers/Form';

interface FormSettings
{
    settings: any;
}

@Options({
    name: 'widget-lastlearningpath-settings'
})
export default class LastLearningPathSettings extends Vue implements ValidatableComponent
{
    @Prop() public modelValue!: any;

    public defaults: any = {
        title: '',
    };

    public get form(): FormType<FormSettings>
    {
        return this.modelValue;
    }

    public async created(): Promise<void>
    {
        this.form.settings = merge(this.defaults, this.form.settings);
    }

    @Watch('form.settings', { deep: true })
    public onSettingsChanged(value: any): void
    {
        this.triggetModel();
    }

    @Emit('update:modelValue')
    public triggetModel(): any
    {
        return this.form;
    }
}
</script>
