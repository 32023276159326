/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import EmptyLayout from '@/views/layouts/EmptyLayout.vue';

export default [
    {
        path: 'studio',
        redirect: '/error/404',
        component: EmptyLayout,
        children: [
            // Formularze
            {
                path: 'forms',
                name: 'core-forms',
                meta: { auth: true },
                component: () => import('@/modules/studio/forms/views/FormsList.vue')
            },
            {
                path: 'forms/add',
                name: 'core-forms-add',
                meta: { auth: true },
                component: () => import('@/modules/studio/forms/views/FormsForm.vue')
            },
            {
                path: 'forms/:id(\\d+)',
                name: 'core-forms-edit',
                meta: { auth: true, model: 'Ideo.Core.Data.Model.CoreDynamicForm' },
                component: () => import('@/modules/studio/forms/views/FormsForm.vue')
            },
            // Wpisy
            {
                path: 'documents/:categoryId(\\d+)?/:formId(\\d+)?',
                name: 'core-entries',
                meta: { auth: true },
                component: () => import('@/modules/studio/entries/views/EntriesList.vue')
            },
            {
                path: 'documents/add/:categoryId(\\d+)/:formId(\\d+)',
                name: 'core-entries-add',
                props: true,
                meta: { auth: true },
                component: () => import('@/modules/studio/entries/views/EntriesForm.vue')
            },
            {
                path: 'documents/edit/:id(\\d+)',
                name: 'core-entries-edit',
                meta: { auth: true, model: 'Ideo.Core.Data.Model.CoreDynamicFormEntry' },
                component: () => import('@/modules/studio/entries/views/EntriesForm.vue')
            },
            // Komponenty
            {
                path: 'components',
                name: 'core-components',
                meta: { auth: true },
                component: () => import('@/modules/studio/components/views/ComponentList.vue')
            },
            {
                path: 'components/add',
                name: 'core-components-add',
                meta: { auth: true },
                component: () => import('@/modules/studio/components/views/ComponentForm.vue')
            },
            {
                path: 'components/:id(\\d+)',
                name: 'core-components-edit',
                meta: { auth: true },
                component: () => import('@/modules/studio/components/views/ComponentForm.vue')
            },
            // Moduły
            // {
            //     path: 'modules',
            //     name: 'core-modules',
            //     meta: { auth: true },
            //     component: () => import('@/modules/studio/modules/views/ModuleForm.vue')
            // },
        ]
    }
];
