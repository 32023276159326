import { axios } from '@/plugins/axios';
import merge from 'lodash/merge';
import Pager from '@/helpers/Pager';
import { Pagination, Statement, Resource, MetaSettingsModel, ElearningSettingsModel } from '@/helpers/Interfaces';

/**
 * WebsitesService
 */
export default class WebsitesService
{
    /**
     * @param filter FilterModel
     * @param pager Pager
     *
     * @returns Promise<Pagination<Resource<ListItemModel>>>
     */
    public static async getList(filter: FilterModel, pager: Pager): Promise<Pagination<Resource<ListItemModel>>>
    {
        return (await axios.get<Pagination<Resource<ListItemModel>>>('admin/cms/websites', {
            params: merge({}, filter, pager.data())
        })).data;
    }

    /**
     * @returns Promise<OptionsModel[]>
     */
    public static async getOptions(): Promise<OptionsModel[]>
    {
        return (await axios.get<OptionsModel[]>('admin/cms/websites/options')).data;
    }

    /**
     * @param id number
     *
     * @returns Promise<Resource<FormModel>>
     */
    public static async fetch(id: number): Promise<Resource<FormModel>>
    {
        return (await axios.get<Resource<FormModel>>(`admin/cms/websites/${id}`)).data;
    }

    /**
     * @param model FormModel
     *
     * @returns Promise<Resource<FormModel>>
     */
    public static async create(model: FormModel): Promise<Resource<FormModel>>
    {
        return (await axios.post<Resource<FormModel>>(`admin/cms/websites`, model)).data;
    }

    /**
     * @param id number
     * @param model FormModel
     *
     * @returns Promise<Statement>
     */
    public static async update(id: number, model: FormModel): Promise<Statement>
    {
        return (await axios.put<Statement>(`admin/cms/websites/${id}`, model)).data;
    }

    /**
     * @param id number
     *
     * @returns Promise<Statement>
     */
    public static async remove(id: number): Promise<Statement>
    {
        return (await axios.delete(`admin/cms/websites/${id}`)).data as Statement;
    }
}

export interface ListItemModel
{
    id: number;
    name: string;
    title: string;
    defaultTheme: string;
    isActive: boolean;
    isDefault: boolean;
}

export interface OptionsModel
{
    id: number;
    title: string;
    locales: string[];
    defaultLocale: string;
    defaultTheme: string;
    baseUrl: string;
    isSecured: boolean;
    modules: string[];
    widgets: string[];
}

export interface FilterModel
{
    name: string;
    title: string;
    theme: string;
    isActive?: boolean;
    isDefault: boolean;
}

export interface FormModel
{
    id: number;
    name: string;
    title: string;
    isActive: boolean;
    isDefault: boolean;
    isSecured: boolean;
    authOnly: boolean;
    cacheEnabled: boolean;
    baseUrl: string;
    locales: string[];
    activeLocales: string[];
    defaultLocale: string;
    defaultTheme: string;
    modules: string[];
    widgets: string[];
    meta: MetaSettingsModel|null;
    logo?: File;
    cookiesInfo: Record<string, string>;
    favIcon?: File;
    errorHtmlText: string;
    elSettings: ElearningSettingsModel|null;
}
