<template>
    <ideo-form-group :label="$t('[[[Opis]]]')" :invalid-feedback="form.$errors.first('content.value.description')" :state="form.$errors.state('content.value.description')">
        <editor :id="seconds('description-' + form.id)" v-model="form.content.value.description" data-cy="description"></editor>
    </ideo-form-group>
    <ideo-form-group :label="$t('[[[Pytania i odpowiedzi]]]')" :invalid-feedback="form.$errors.first('content.value.questions')" :state="form.$errors.state('content.value.questions')" required>
        <div v-for="(question, i) in form.content.value.questions" :key="i" class="row g-0 mb-3">
            <div class="bg-light col-12 col-lg-11 p-2">
                <div class="input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text">{{ i + 1 }}</span>
                    </div>
                    <ideo-form-input v-model="question.question" type="text" :name="`content.value.questions[${i}].question`" :placeholder="$t('[[[Pytanie]]]')" :data-cy="`question-${i}`"></ideo-form-input>
                    <div class="input-group-append me-2">
                        <action-button variant="danger" icon="far fa-trash" :title="$t('[[[Usuń pytanie]]]')" :confirm="$t('[[[Potwierdzenie usunięcia]]]')" @click="removeQuestion(i)" :disabled="form.content.value.questions.length < 2" />
                    </div>
                </div>
                <small class="form-text text-danger" v-if="!form.$errors.state(`content.value.questions[${i}].question`)">{{ form.$errors.first(`content.value.questions[${i}].question`) }}</small>
                <hr>
                <div v-for="(answer, j) in question.answers" :key="j" class="row mb-2">
                    <div class="col-md-9 col-sm-7">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text bg-primary border-primary text-white">{{ i + 1 }}.{{ j + 1 }}</span>
                            </div>
                            <ideo-form-textarea v-model="answer.text" :name="`content.value.questions[${i}].answers[${j}]`" :rows="2" :placeholder="$t('[[[Odpowiedź]]]')" :data-cy="`answer-${i}-${j}`"></ideo-form-textarea>
                            <div class="input-group-append">
                                <action-button :variant="answer.isCorrect ? 'success' : 'secondary'" icon="far fa-check" :title="$t('[[[Poprawna odpowiedź]]]')" @click="isCorrectChanged(question, j)" data-cy="isCorrect" />
                                <action-button variant="danger" icon="far fa-trash" :title="$t('[[[Usuń odpowiedź]]]')" :confirm="$t('[[[Potwierdzenie usunięcia]]]')" @click="removeAnswer(question, j)" :disabled="question.answers.length < 2" />
                            </div>
                        </div>
                        <small class="form-text text-danger" v-if="!form.$errors.state(`content.value.questions[${i}].answers[${j}]`)">{{ form.$errors.first(`content.value.questions[${i}].answers[${j}]`) }}</small>
                    </div>
                    <div class="col-md-3 col-sm-5 mt-2 mt-sm-0 d-flex">
                        <Move :items="question.answers" :index="j" />
                    </div>
                </div>
                <small class="form-text text-danger" v-if="!form.$errors.state(`content.value.questions[${i}].answers`)">{{ form.$errors.first(`content.value.questions[${i}].answers`) }}</small>
                <div class="d-flex mb-3">
                    <action-button variant="primary" class="mx-auto" icon="far fa-plus" :text="$t('[[[Dodaj odpowiedź]]]')" @click="addAnswer(question)" :data-cy="`addAnswer-${i}`" />
                </div>
            </div>
            <div class="col-12 col-lg-1">
                <div class="ms-2">
                    <Move :items="form.content.value.questions" :index="i" />
                </div>
            </div>
        </div>
        <div>
            <action-button variant="outline-dark" class="w-100" icon="far fa-plus" :text="$t('[[[Dodaj pytanie]]]')" @click="addQuestion()" data-cy="addQuestion" />
        </div>
    </ideo-form-group>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Emit, Prop, Watch } from '@/helpers/Decorators';
import merge from 'lodash/merge';
import { FormType } from '@/helpers/Form';
import { seconds } from '@/helpers/Utils';
import Move from '@/components/common/Move.vue';

interface FormSelectList
{
    content: {
        value: {
            description: string,
            questions: SingleSelect[]
        }
    }
}

interface SingleSelect
{
    id: number,
    question: string,
    answers: Answer[]
}

interface Answer {
    id: number,
    text: string,
    isCorrect: boolean
}

@Options({
    name: 'type-selectlist-settings',
    components: { Move }
})
export default class SelectListSettings extends Vue
{
    @Prop() public modelValue!: any;

    // rozwiązanie problemu inicjalizowania edytora, z powodu dwóch tych samych id-ków
    public seconds = seconds;

    public defaults: any = {
        description: '',
        questions: [] as SingleSelect[]
    };

    public get form(): FormType<FormSelectList>
    {
        return this.modelValue;
    }

    public created(): void
    {
        this.form.content.value = merge(this.defaults, this.form.content.value);
        this.form.file = null;

        if (!this.form.content.value.questions.length)
        {
            this.form.content.value.questions = [{
                id: 1,
                question: '',
                answers: [
                    { id: 1, text: '', isCorrect: true }
                ]
            }];
        }

        this.form.content.value.questions.forEach(q =>
        {
            if (!q.answers.length)
            {
                q.answers = [{ id: 1, text: '', isCorrect: true }];
            }
        });
    }

    public addQuestion(): void
    {
        const ids = this.form.content.value.questions.map((x: { id: any; }) => x.id);

        const maxId = this.form.content.value.questions.length > 0 ? Math.max(...ids) : 1;

        this.form.content.value.questions.push({
            id: maxId + 1,
            question: '',
            answers: [
                { id: 1, text: '', isCorrect: true }
            ]
        });
    }

    public removeQuestion(index: number): void
    {
        const items = this.form.content.value.questions.slice();

        items.splice(index, 1);
        this.form.content.value.questions = items;

        this.form.$errors.clear();
    }

    public addAnswer(question: any): void
    {
        const ids = question.answers.map((x: { id: any; }) => x.id);

        const maxId = question.answers.length > 0 ? Math.max(...ids) : 1;

        question.answers.push({
            id: maxId + 1,
            text: '',
            isCorrect: false
        });
    }

    public removeAnswer(question: any, index: number): void
    {
        question.answers.splice(index, 1);

        const questionIndex = this.form.content.value.questions.indexOf(question);

        this.form.$errors.clear(`content.value.questions[${questionIndex}].answers[${index}]`);
    }

    public isCorrectChanged(question: SingleSelect, index: number) : void
    {
        const clickedAnswer = question.answers[index];
        const clickedAnswerValue = clickedAnswer.isCorrect;

        const count = question.answers.filter(item =>
        {
            if (item.id == clickedAnswer.id)
                return item.isCorrect;
        }).length;

        if (count == 1) return;

        question.answers[index].isCorrect = !clickedAnswerValue;

        for (const answer of question.answers)
        {
            if (answer.id != question.answers[index].id)
            {
                answer.isCorrect = false;
            }
        }

        const questionIndex = this.form.content.value.questions.indexOf(question);

        this.form.$errors.clear(`content.value.questions[${questionIndex}].answers`);
    }

    @Watch('form.content.value', { deep: true })
    public onContentChanged(value: any): void
    {
        this.triggetModel();
    }

    @Emit('update:modelValue')
    public triggetModel(): any
    {
        return this.form;
    }
}
</script>
