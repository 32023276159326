<script lang="ts" setup>
import { computed } from 'vue';

defineOptions({
    name: 'field-checkbox'
});

const props = defineProps({
  "label": null,
  "modelValue": { type: Boolean,  },
  "disabled": { type: Boolean, default: false }
});

const emit = defineEmits(["update:modelValue"]);

const triggerInput = (value: any): void =>
{
    emit('update:modelValue', value);
};

const selected = computed({
    get(): any
    {
        return props.modelValue;
    },
    set(value: any)
    {
        triggerInput(value);
    }
});
</script>

<template>
    <div>
        <ideo-form-checkbox v-model="selected" :disabled="disabled">{{ label }}</ideo-form-checkbox>
    </div>
</template>
