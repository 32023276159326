<template>
    <ideo-form-group>
        <ideo-form-checkbox v-model="form.settings.nameIsVisible">
            {{ $t('[[[Wyświetlaj nazwę bloczka]]]') }}
        </ideo-form-checkbox>
    </ideo-form-group>
    <ideo-form-group :label="$t('[[[Podtytuł]]]')" :invalid-feedback="form.$errors.first('settings.subtitle')" :state="form.$errors.state('settings.subtitle')">
        <ideo-form-input v-model="form.settings.subtitle" type="text" name="subtitle"></ideo-form-input>
    </ideo-form-group>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Emit, Prop, Watch } from '@/helpers/Decorators';
import { CurrentWebsite } from '@/helpers/Interfaces';
import { FormType } from '@/helpers/Form';
import merge from 'lodash/merge';
import { wait } from '@/helpers/Utils';
import { useWebsitesStore } from '@/modules/cms/websites/store';

interface FormSettings
{
    settings: any;
}

@Options({
    name: 'widget-coursesstatistics-settings'
})
export default class CoursesStatisticsSettings extends Vue
{
    @Prop() public modelValue!: any;

    public get form(): FormType<FormSettings>
    {
        return this.modelValue;
    }

    public defaults: any = {
        nameIsVisible: true,
        subtitle: ''
    };

    public get current(): CurrentWebsite
    {
        return useWebsitesStore().current;
    }

    @Watch('form.settings', { deep: true })
    public onSettingsChanged(value: any): void
    {
        this.triggetModel();
    }

    @Emit('update:modelValue')
    public triggetModel(): any
    {
        return this.form;
    }

    public async created(): Promise<void>
    {
        await wait(() => this.current.website);
        this.form.settings = merge(this.defaults, this.form.settings);
    }
}
</script>
