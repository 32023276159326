<template>
    <div>
        <ideo-form-group :label="$t('[[[Wersje dokumentu]]]')">
            <div class="list-group">
                <div
                    v-for="item in versions" :key="item.id"
                    class="list-group-item list-group-item-action p-2 d-flex pointer"
                    :class="{'bg-primary text-white': item.id == current}"
                    @click="onClick(item.id)"
                >
                    <span class="d-flex align-items-center pe-2 me-2 border-end">
                        <i :class="statusIcon(item.status, item.id == current)" class="fa-2x" :title="statusName(item.status)"></i>
                    </span>
                    <span class="me-auto">
                        <span class="d-block" :title="$t('[[[Data utworzenia wersji]]]')">
                            <i class="fad fa-fw fa-clock" :class="{'text-secondary': item.id != current}"></i>
                            <span class="ms-2">{{ $filters.datetime(item.dateModifiedUtc) }}</span>
                        </span>
                        <span class="d-block" :title="$t('[[[Wersja utowrzona przez]]]')">
                            <i class="fad fa-fw fa-user" :class="{'text-secondary': item.id != current}"></i>
                            <span class="ms-2"><i>{{ item.modifiedByUser }}</i></span>
                        </span>
                    </span>
                    <span class="d-flex align-items-center" v-if="item.id != current">
                        <ideo-button :variant="item.id == compare ? 'success' : 'light'" size="sm" pill :title="$t('[[[Porównaj z edytowaną wersją]]]')" @click.stop.prevent="onCompare(item.id)">
                            <i class="far fa-hashtag"></i>
                        </ideo-button>
                    </span>
                </div>
                <div class="list-group-item list-group-item-light text-center" v-if="versions.length == 0">
                    {{ $t('[[[Brak wersji]]]') }}
                </div>
            </div>
            <action-button variant="secondary" class="w-100 mt-2" icon="fas fa-arrow-down" :text="$t('[[[Pokaż więcej]]]')" @click.prevent="onMore()" v-if="versions.length > 0 && pager.pageIndex < pager.totalPages" />
        </ideo-form-group>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Emit, Prop } from '@/helpers/Decorators';
import { PublicationEnum, PublicationName, PublicationStyle, PublicationIcon } from '@/helpers/Enums';
import { EntityVersion } from '@/helpers/Interfaces';
import Pager from '@/helpers/Pager';

@Options({
    name: 'entity-versions'
})
export default class EntityVersions extends Vue
{
    @Prop() public value: EntityVersion[];
    @Prop({ default: 0 }) public current: number;
    @Prop({ default: false }) public disabled: boolean;
    @Prop() public pager!: Pager;

    public compare: number = 0;

    public get versions(): EntityVersion[]
    {
        return this.value || [];
    }

    public get statusEnum(): typeof PublicationEnum
    {
        return PublicationEnum;
    }

    public statusName(status: PublicationEnum): string
    {
        return PublicationName(status);
    }

    public statusIcon(status: PublicationEnum, selected: boolean): string
    {
        return PublicationIcon(status).replace(new RegExp('text-(.+)', 'g'), selected ? 'text-white' : 'text-$1');
    }

    public statusStyle(classTemplate: string, status: PublicationEnum): Record<string, boolean>
    {
        return PublicationStyle(classTemplate, status);
    }

    @Emit('select')
    public onClick(id: number): number
    {
        this.onCompare(0);

        return id;
    }

    @Emit('compare')
    public onCompare(id: number): number
    {
        this.compare = id;

        return id;
    }

    @Emit('more')
    public onMore(): void
    {
    }
}
</script>

<style scoped>
</style>
