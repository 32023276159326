<template>
    <input :class="customClasses" :value="currentValue" @input="updateModel">
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Emit, Prop, Watch } from '@/helpers/Decorators';

@Options({
    name: 'ideo-form-input'
})
export default class IdeoFormInput extends Vue
{
    public currentValue: string = null;

    @Prop()
    public value: string;

    @Prop()
    public modelValue: string;

    @Prop({
        default: 'md',
        validator: (value: string) => ['sm', 'md', 'lg'].includes(value)
    })
    public size: string;

    public get customClasses(): Record<string, boolean>
    {
        return {
            'form-control': true,
            'form-control-sm': this.size == 'sm',
            'form-control-lg': this.size == 'lg'
        };
    }

    public focus(): void
    {
        this.$el.focus();
    }

    @Emit('input')
    @Emit('update:modelValue')
    public updateModel(e: Event): string
    {
        const target = e.target as HTMLInputElement;

        this.currentValue = target.value;

        return this.currentValue;
    }

    @Watch('value', { immediate: true })
    @Watch('modelValue', { immediate: true })
    public onModelChanged(value: any[] | any): void
    {
        if (value !== undefined)
        {
            this.currentValue = value;
        }
    }
}
</script>
