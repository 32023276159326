<template>
    <component :is="tagName" v-bind="attrs()" :class="classNames">
        <ideo-dropdown variant="secondary" :text="$t('[[[Akcje]]]')" dropleft boundary="body" no-flip no-caret v-if="loaded">
            <template #button-content>
                <i class="fal fa-ellipsis-v-alt"></i>
            </template>
            <slot name="default"></slot>
        </ideo-dropdown>
        <placeholder :width="37" :height="32" v-else></placeholder>
    </component>
</template>

<script lang="ts" setup>
import { computed, inject, useAttrs, type Ref } from 'vue';
import { only } from '@/helpers/Utils';
import { Header } from '../helpers';

export interface Props
{
    index: number;
    header: Header;
}

defineOptions({
    name: 'list-view-buttons',
    inheritAttrs: false
});

defineProps({
  "index": null,
  "header": null
});

const loaded = inject<Ref<boolean>>('loaded');
const layout = inject<Ref<string>>('layout');

const table = computed(() => layout.value == 'table');
const tagName = computed(() => table.value ? 'td' : 'div');
const classNames = computed(() => table.value ? 'actions-column text-end' : '');

const $attrs = useAttrs();
const attrs = (): Record<string, any> => only($attrs, 'class', 'style');
</script>
