/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import EmptyLayout from '@/views/layouts/EmptyLayout.vue';

export default [
    {
        path: 'chat',
        name: 'chat',
        meta: { auth: true },
        component: EmptyLayout,
        children: [
            {
                path: 'room',
                name: 'chat-room-list',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "system" */ '@/modules/system/chat/views/RoomsList.vue')
            },
            {
                path: 'room/:id/settings',
                name: 'chat-room-settings',
                meta: { auth: true },
                component: () => import(/* webpackChunkName: "system" */ '@/modules/system/chat/views/Room.vue')
            }
        ]
    }
];
