import { defineStore } from 'pinia';
import { reactive } from 'vue';
import Pager from '@/helpers/Pager';

export const useHomeStore = defineStore('core-home', () =>
{
    const pager = reactive(new Pager(1, 20, 'Id', 'DESC'));

    return { pager };
});
