import { Plugin } from 'vue';
import SettingsService, { ThemeSettings } from '@/modules/cms/common/services/SettingsService';

const ConfigPlugin: Plugin =
{
    install(app, options)
    {
        const vue = app.config.globalProperties;
        const config = SettingsService.getConfig();
        const themes: Record<string, Promise<ThemeSettings>> = {};

        vue.$config = (name: string, throwError: boolean = false): Promise<any> =>
        {
            return new Promise((resolve, reject) =>
            {
                config
                    .then((config) => resolve(config[name] || {}))
                    .catch(reason =>
                    {
                        vue.$log.error(`An error occurred while loading settings (${name}).`);
                        throwError ? reject(reason) : resolve(null);
                    });
            });
        };

        vue.$theme = (name: string, throwError: boolean = false): Promise<ThemeSettings> =>
        {
            return themes[name] || (themes[name] = new Promise((resolve, reject) =>
            {
                SettingsService.getThemeConfig(name)
                    .then((config) => resolve(config))
                    .catch(reason =>
                    {
                        vue.$log.error(`An error occurred while loading theme settings (${name}).`);
                        throwError ? reject(reason) : resolve(null);
                    });
            }));
        };
    }
};

export default ConfigPlugin;

declare module "@vue/runtime-core"
{
    interface ComponentCustomProperties
    {
        $config(name: string, throwError?: boolean): Promise<any>;
        $theme(name: string, throwError?: boolean): Promise<any>;
    }
}
