import { axios } from '@/plugins/axios';
import Fingerprint2 from 'fingerprintjs2';
import { UAParser } from 'ua-parser-js';
import { Statement, Pagination } from '@/helpers/Interfaces';
import { Resource as FileResource } from '@/modules/core/files/services/StorageService';
import { AccountType } from '@/helpers/Enums';
import { DateTime } from 'luxon';
import Pager from '@/helpers/Pager';
import { FormEntry } from '@/components/forms/blueprints/form';

/**
 * AuthService
 */
export default class AuthService
{
    /**
     * @returns Promise<any>
     */
    protected static getFingerprint(): Promise<any>
    {
        return new Promise(resolve =>
        {
            const options: any = {
                excludes: {
                    canvas: true,
                    webgl: true,
                    audio: true,
                    enumerateDevices: true,
                    userAgent: true,
                    timezoneOffset: true,
                    fontsFlash: true
                }
            };

            Fingerprint2.get(options, (components: any) =>
            {
                const device = new UAParser().getResult();

                components['userAgent'] = `${device.browser.name} on ${device.os.name} ${device.os.version}`;

                resolve({
                    result: Fingerprint2.x64hash128(JSON.stringify(components), 31),
                    components: components,
                    device: device
                });
            });
        });
    }

    /**
     * @param username string
     * @param password string
     * @param rememberMe boolean
     *
     * @returns Promise<TokenModel>
     */
    public static async login(username: string, password: string, captchaResult: string, rememberMe: boolean = false, forceLogin: boolean = false): Promise<TokenModel>
    {
        const device = await AuthService.getDeviceInfo();

        return (await axios.post<TokenModel>('elearning/auth/login', {
            username: username,
            password: password,
            captchaResult: captchaResult,
            deviceId: device.deviceId,
            deviceName: device.deviceName,
            rememberMe: rememberMe,
            forceLogin: forceLogin
        }, {
            withCredentials: true
        })).data;
    }

    /**
     * @param username string
     * @param password string
     * @param rememberMe boolean
     *
     * @returns Promise<TokenModel>
     */
    public static async loginWithMfa(username: string, password: string, captchaResult: string, rememberMe: boolean = false, forceLogin: boolean = false, code: string): Promise<TokenModel>
    {
        const device = await AuthService.getDeviceInfo();

        return (await axios.post<TokenModel>('elearning/auth/login/mfa-code', {
            username: username,
            password: password,
            captchaResult: captchaResult,
            deviceId: device.deviceId,
            deviceName: device.deviceName,
            rememberMe: rememberMe,
            forceLogin: forceLogin,
            code: code
        }, {
            withCredentials: true
        })).data;
    }

    /**
     * @returns Promise<Statement>
     */
    public static async logout(): Promise<Statement>
    {
        const device = await AuthService.getDeviceInfo();

        return (await axios.get<Statement>('auth/logout', {
            params: {
                id: device.deviceId,
                name: device.deviceName
            },
            withCredentials: true
        })).data;
    }

    /**
     * @returns Promise<TokenModel>
     */
    public static async ntlm(): Promise<TokenModel>
    {
        const device = await AuthService.getDeviceInfo();

        return (await axios.post<TokenModel>('auth/ntlm', {
            deviceId: device.deviceId,
            deviceName: device.deviceName
        }, {
            withCredentials: true
        })).data;
    }

    /**
     * @returns Promise<TokenModel>
     */
    public static async validateToken(): Promise<TokenModel>
    {
        return (await axios.get<TokenModel>('auth/token/validate', {
            withCredentials: true
        })).data;
    }

    /**
     * @param refreshToken string
     *
     * @returns Promise<TokenModel>
     */
    public static async refreshToken(): Promise<TokenModel>
    {
        const device = await AuthService.getDeviceInfo();

        return (await axios.get<TokenModel>('auth/token/refresh', {
            params: {
                id: device.deviceId,
                name: device.deviceName
            },
            withCredentials: true
        })).data;
    }

    /**
     * @param refreshToken string
     *
     * @returns Promise<TokenModel>
     */
    public static async recoverToken(refreshToken: string = null): Promise<TokenModel>
    {
        const device = await AuthService.getDeviceInfo();

        return (await axios.post<TokenModel>('auth/token/recover', {
            token: refreshToken, // Jeśli jest null, to będzie próbował odczytać z ciasteczka
            deviceId: device.deviceId,
            deviceName: device.deviceName
        }, {
            withCredentials: true
        })).data;
    }

    /**
     * @param refreshToken string
     *
     * @returns Promise<Statement>
     */
    public static async revokeToken(refreshToken: string): Promise<Statement>
    {
        return (await axios.post<Statement>('auth/token/revoke', {
            token: refreshToken
        })).data;
    }

    /**
     * @returns Promise<AuthModel>
     */
    public static async getIdentity(real: boolean = false): Promise<AuthModel>
    {
        return (await axios.get<AuthModel>('elearning/auth/profile', {
            transformRequest: [(data, headers) =>
            {
                if (real == true)
                    delete headers['Access-Impersonate'];

                return data;
            }]
        })).data;
    }

    /**
     * @param user ProfileModel
     *
     * @returns Promise<Statement>
     */
    public static async updateProfile(user: ProfileModel): Promise<Statement>
    {
        return (await axios.put<Statement>('elearning/auth/profile', user)).data;
    }

    /**
     * @param preferredLanguage string
     *
     * @returns Promise<Statement>
     */
    public static async updatePreferredLanguage(preferredLanguage: string): Promise<Statement>
    {
        return (await axios.put<Statement>(`auth/profile/language/${preferredLanguage}`)).data;
    }

    /**
     * @param email string
     * @param callbackUrl string
     *
     * @returns Promise<string>
     */
    public static async resetPassword(email: string): Promise<string>
    {
        return (await axios.post<string>('auth/password/reset', {
            email: email
        })).data;
    }

    /**
     * @param token string
     * @param email string
     * @param newPassword string
     * @param repeatPassword string
     *
     * @returns Promise<string>
     */
    public static async setPassword(token: string, email: string, newPassword: string, repeatPassword: string): Promise<string>
    {
        return (await axios.post<string>('auth/password/set', {
            token: token,
            email: email,
            newPassword: newPassword,
            repeatPassword: repeatPassword
        })).data;
    }

    /**
     * @param model PasswordModel
     *
     * @returns Promise<string>
     */
    public static async changePassword(model: PasswordModel): Promise<string>
    {
        return (await axios.post<string>('auth/password/change', model, {
            withCredentials: true
        })).data;
    }

    /**
     * @returns Promise<number>
     */
    public static async passwordExpires(): Promise<number>
    {
        return (await axios.get<number>('auth/password/expire')).data;
    }

    /**
     * @returns Promise<Substitution[]>
     */
    public static async getSubstitutions(): Promise<Substitution[]>
    {
        return (await axios.get<Substitution[]>('auth/substitutions')).data;
    }

    public static async getDeviceInfo(): Promise<DeviceInfo>
    {
        const fingerprint = await AuthService.getFingerprint();

        return {
            deviceId: fingerprint.result,
            deviceName: fingerprint.components.userAgent
        };
    }

    /**
     * @param pager Pager
     *
     * @returns Promise<Pagination<DeviceModel>>
     */
    public static async getDevices(pager: Pager): Promise<Pagination<DeviceModel>>
    {
        return (await axios.get<Pagination<DeviceModel>>('auth/devices', {
            params: pager.data()
        })).data;
    }

    /**
     * @param deviceId string
     *
     * @returns Promise<Statement>
     */
    public static async removeDevice(deviceId: string): Promise<Statement>
    {
        return (await axios.delete(`auth/devices/${deviceId}`)).data as Statement;
    }

    public static async getSessionSettings(): Promise<SessionSettings>
    {
        return (await axios.get<SessionSettings>('auth/session')).data;
    }

    public static async revokeSession(sessionId: number): Promise<Statement>
    {
        return (await axios.delete(`auth/session/${sessionId}/revoke`)).data as Statement;
    }
}

export interface TokenModel
{
    token: string;
    refresh: string;
    expires: string;
}

export interface AuthModel
{
    id: number;
    publicId: string;
    userName: string;
    email: string;
    givenName: string;
    surname: string;
    facePicture: FileResource;
    forcePasswordChange: boolean;
    accountType: AccountType;
    systemVersion: string;
    preferredLanguage: string;
    document: FormEntry;
    gender: string;
}

export interface ProfileModel
{
    userName: string;
    email: string;
    givenName: string;
    surname: string;
    facePicture: FileResource;
    document: FormEntry;
    gender: string;
}

export interface LoginModel
{
    username: string;
    password: string;
    rememberMe: boolean;
    forceLogin: boolean;
    code: string;
}

export interface PasswordModel
{
    currentPassword: string;
    newPassword: string;
    repeatPassword: string;
}

export interface ResetModel
{
    email: string;
    newPassword: string;
    repeatPassword: string;
}

export interface Substitution
{
    id: number;
    name: string;
    intervals: Interval[];
}

export interface Interval
{
    dateBeginUtc: DateTime;
    dateEndUtc: DateTime;
}

export interface DeviceInfo
{
    deviceId: string;
    deviceName: string;
}

export interface DeviceModel
{
    id: number;
    dateCreatedUtc: DateTime;
    dateModifiedUtc: DateTime;
    deviceId: string;
    deviceName: string;
    ipAddress: string;
}

export interface SessionSettings
{
    sessionDuration: number;
}
