<script lang="ts" setup>
import { ref, Ref, computed, watch, getCurrentInstance } from 'vue';
import properties from '@/components/forms/properties';
import { RootEntry } from '@/components/builder/form/entries/RootEntry';
import { Definition as RelatedParentDefinition } from '../related-parent';
import { instanceOfRelatedSelfEntry, RelatedSelfType, RelatedSelfEntry, Definition } from '.';
import { Option } from '@/helpers/Interfaces';
import { useLocalization } from '@/plugins/localization';
import { FormBuilderContract } from '@/components/builder/form';
import { AggregateBlueprint } from '@/components/builder/base/blueprints/AggregateBlueprint';
import { HasLabel } from '@/components/builder/form/traits/HasLabel';
import { Definition as TextDefinition, TextFieldTypes, TextType } from '../text';
import DocumentsService from '@/modules/studio/documents/services/DocumentsService';

const instance = getCurrentInstance();
const { $t } = useLocalization();

defineOptions({
    name: 'related-self-blueprint',
    components: {
        ...properties
    }
});

const props = defineProps({
  "blueprint": null,
  "entry": null,
  "form": null,
  "parent": null,
  "index": null
});

const blueprint = computed(() => props.blueprint);
const entryData = ref(new RelatedSelfEntry()) as Ref<RelatedSelfEntry>;

entryData.value = props.form.document.initEntry(props.blueprint, entryData.value, instanceOfRelatedSelfEntry, props.index);

const uid = computed(() => `form-control-${instance.uid}`);

const model = computed({
    get(): Option<number>
    {
        return {
            value: entryData.value.data.value,
            text: entryData.value.data.text
        };
    },
    set(value: Option<number>)
    {
        entryData.value.data.value = value?.value ?? null;
        entryData.value.data.text = value?.text ?? null;
    }
});

const placeholder = computed(() => props.form.localization.translate(props.blueprint.placeholder) || $t('[[[wybierz...]]]'));
const required = computed(() => props.form.expressions.required(props.blueprint));

const components = computed<Option[]>(() =>
{
    return props.form.schema
        .components([Definition.type, RelatedParentDefinition.type], props.blueprint)
        .map(p => ({ value: p.name, text: props.form.localization.translate((p as HasLabel).label) }));
});

const scope = computed<number[]>(() =>
{
    return components.value
        .where(p => props.blueprint.dependsOn.includes(p.value))
        .select(p => props.entry[p.value].values as Option<number>[])
        .where(p => p != null)
        .selectMany(p => p.select((q: any) => q.value).toArray())
        .toArray();
});

watch(scope, (value: number[], old:number[]): void =>
{
    if (value.join('|') != old.join('|'))
    {
        model.value = null;
    }
});

const disabled = computed(() =>
{
    if (props.blueprint.dependsOn.length > 0)
    {
        return props.blueprint.dependsOn.length != scope.value.length;
    }

    return false;
});

const fetch = async (value: string): Promise<Option<number>> =>
{
    return new Promise(resolve => resolve(model.value));
};

const search = async (query: string): Promise<Option<number>[]> =>
{
    if (!props.form.blueprintId || !props.blueprint.componentId)
        return [];

    const response = await DocumentsService.getOptions(props.form.blueprintId, props.blueprint.id, scope.value, null, query);

    return response.map(p => ({
        value: p.value,
        text: p.text
    }));
};

const onChanged = (value: Option<number>): void =>
{
    model.value = value;
};

const textComponents = computed(() =>
{
    return props.form.schema
        .components(TextDefinition.type)
        .filter(p => (p as TextType).fieldType == TextFieldTypes.Text)
        .map(p => ({ value: p.id, text: (p as TextType).label }));
});
</script>

<template>
    <form-component-wrapper class="related-parent-component" :form="form" :parent="parent" :blueprint="blueprint">
        <template #default>
            <div class="form-group">
                <form-label :form="form" :blueprint="blueprint" :required="required" :entry="entryData" />
                <ideo-autocomplete :name="uid" :fetch="fetch" :search="search" :modelValue="model.value" :placeholder="placeholder" :disabled="disabled" @changed="onChanged" />
                <form-error-message :entry="entryData" name="value" />
                <form-error-message :entry="entryData" name="custom" />
                <form-help :form="form" :blueprint="blueprint" />
            </div>
        </template>
        <template #properties>
            <field-name :form="form" :blueprint="blueprint" v-model="blueprint.name" />
            <ideo-form-localize v-slot="{ locale }">
                <field-text v-model="blueprint.label[locale]" :label="$t('[[[Etykieta]]]')" />
            </ideo-form-localize>
            <field-checkbox v-model="blueprint.showLabel" :label="$t('[[[Pokaż etykietę]]]')" />
            <div class="alert alert-light p-2">
                <h5>{{ $t('[[[Źródło danych]]]') }}</h5>
                <field-component
                    v-model="blueprint.componentId"
                    :options="textComponents"
                    :label="$t('[[[Komponent źródłowy]]]')"
                    :description="$t('[[[Komponent którego wartość zostanie użyta jako nazwa elementu nadrzędnego. Dostępne tylko komponenty typu ``Tekst``.]]]')"
                    :invalid-feedback="() => form.schema.errorMessage(blueprint, 'componentId')"
                    required
                />
                <div class="mt-3" v-if="components.length > 0">
                    <hr>
                    <field-checkboxlist v-model="blueprint.dependsOn" :options="components" :label="$t('[[[Zależna od kontrolki]]]')" class="mb-0" />
                    <small class="form-text text-muted d-block">
                        {{ $t('[[[Lista elementów zostanie zawężona tylko do tych, które są pozwiązane z wybranymi wartościami z zaznaczonych komponentów.]]]') }}
                    </small>
                </div>
            </div>
            <ideo-form-localize v-slot="{ locale }">
                <field-text v-model="blueprint.placeholder[locale]" :label="$t('[[[Tekst zastępczy]]]')" :placeholder="$t('[[[wybierz...]]]')" />
            </ideo-form-localize>
            <ideo-form-localize v-slot="{ locale }">
                <field-textarea v-model="blueprint.help[locale]" :label="$t('[[[Pomoc]]]')" />
            </ideo-form-localize>
            <field-visible :form="form" :blueprint="blueprint" />
            <field-readonly :form="form" :blueprint="blueprint" />
            <field-required :form="form" :blueprint="blueprint" />
            <field-error :form="form" :blueprint="blueprint" />
            <field-filter :form="form" :blueprint="blueprint" />
        </template>
    </form-component-wrapper>
</template>
