import { axios } from '@/plugins/axios';
import merge from 'lodash/merge';
import Pager from '@/helpers/Pager';
import { Pagination, Resource, Statement, KeyValue } from '@/helpers/Interfaces';
import { Resource as FileResource } from '@/modules/core/files/services/StorageService';
import { AccountType, UserGenderEnum } from '@/helpers/Enums';
import { DateTime } from 'luxon';
import { FormEntry } from '@/components/forms/blueprints/form';

/**
 * UsersService
 */
export default class UsersService
{
    /**
     * @param filter FilterModel
     * @param pager Pager
     *
     * @returns Promise<Pagination<Resource<ListItemModel>>>
     */
    public static async getList(filter: FilterModel, pager: Pager): Promise<Pagination<Resource<ListItemModel>>>
    {
        return (await axios.get<Pagination<Resource<ListItemModel>>>('admin/users', {
            params: merge({}, filter, pager.data())
        })).data;
    }

    /**
     * @param search string
     * @param limit number
     *
     * @returns Promise<ListItemModel[]>
     */
    public static async getAutocompleteList(search: string, limit: number = 10): Promise<ListItemModel[]>
    {
        const { data } = await axios.get<ListItemModel[]>('admin/users/autocomplete', {
            params: {
                search: search,
                limit: limit
            }
        });

        return data;
    }

    /**
     * @param id number
     *
     * @returns Promise<ListItemModel>
     */
    public static async fetchAutocompleteModel(id: number): Promise<ListItemModel>
    {
        const { data } = await axios.get<ListItemModel[]>(`admin/users/autocomplete/${id}`);

        return data.first();
    }

    /**
     * @param id number
     *
     * @returns Promise<Resource<FormModel>>
     */
    public static async fetch(id: number): Promise<Resource<FormModel>>
    {
        return (await axios.get<Resource<FormModel>>(`admin/users/${id}`)).data;
    }

    /**
     * @param model FormModel
     *
     * @returns Promise<Resource<FormModel>>
     */
    public static async create(model: FormModel): Promise<Resource<FormModel>>
    {
        return (await axios.post<Resource<FormModel>>(`admin/users`, model)).data;
    }

    /**
     * @param id number
     * @param model FormModel
     *
     * @returns Promise<Statement>
     */
    public static async update(id: number, model: FormModel): Promise<Statement>
    {
        return (await axios.put<Statement>(`admin/users/${id}`, model)).data;
    }

    /**
     * @param id number
     *
     * @returns Promise<Statement>
     */
    public static async remove(id: number): Promise<Statement>
    {
        return (await axios.delete(`admin/users/${id}`)).data as Statement;
    }

    /**
     * @param id number
     * @param resource FileResource
     *
     * @returns Promise<Statement>
     */
    public static async updateFacePicture(id: number, resource: FileResource): Promise<Statement>
    {
        return (await axios.put<Statement>(`admin/users/${id}/avatar`, resource)).data;
    }

    /**
     *
     * @returns Promise<Blob>
     */
    public static async exportUsers(idUsers: number[]): Promise<Blob>
    {
        return (await axios.post<Blob>(`admin/elearning/users/export`, idUsers, { responseType: 'blob' })).data;
    }

    /**
     *
     * @returns Promise<Blob>
     */
    public static async importTemplate(): Promise<Blob>
    {
        return (await axios.get<Blob>(`admin/elearning/users/import/template`, { responseType: 'blob' })).data;
    }

    /**
     *
     * @returns  Promise<Statement>
     */
    public static async importUsers(file: File):  Promise<ImportUserInfomationsModel>
    {
        const data = new FormData();

        data.append('file', file);

        return (await axios.post<ImportUserInfomationsModel>(`admin/elearning/users/import`, data)).data;
    }

    public static async getCourses(userId: number, pager: Pager): Promise<Pagination<CourseItemModel>>
    {
        return (await axios.get<Pagination<CourseItemModel>>(`admin/users/${userId}/courses`, {
            params: merge({}, pager.data())
        })).data;
    }
}

export enum UsersRangeEnum {
    All = 'all',
    Admin = 'admin',
    Inactive = 'inactive'
}

export interface ListItemModel
{
    id: number;
    dateCreatedUtc: DateTime;
    userName: string;
    email: string;
    givenName: string;
    surname: string;
    isAdmin: boolean;
    facePictureUrl: string;
    accountType: AccountType;
    isImported: boolean;
    enabled: boolean;
    publicRegistration: boolean;
}

export interface FilterModel
{
    userName: string;
    givenName: string;
    surname: string;
    roleId?: number;
    unitId?: number,
    unitsBelow: boolean,
    dateFromUtc?: DateTime;
    dateDueUtc?: DateTime;
    range: UsersRangeEnum;
    publicRegistration: boolean;
    tab: string;
    accountType?: 'Local'|'Ldap'|'AzureAD'|'Google'|'Facebook'|'Other';
}

export interface FormModel
{
    id: number;
    userName: string;
    email: string;
    emailConfirmed: boolean;
    isEnabled: boolean;
    newPassword: string;
    repeatPassword: string;
    givenName: string;
    surname: string;
    roles: number[];
    isAdmin: boolean;
    facePicture?: FileResource;
    forcePasswordChange: boolean;
    lockoutEndDateUtc?: DateTime;
    failedAttempts: number;
    accountType: AccountType;
    accountPayload: string;
    accountDisabledUtc?: DateTime;
    publicRegistration: boolean;
    permissions: KeyValue<string, Record<string, string>>[];
    unitId?: number;
    preferredLanguage: string;
    phoneNumber:string;
    document: FormEntry;
    gender: UserGenderEnum;
    isLeader: boolean;
    jobPositionId? : number;
    managerId? : number;
    employeeId?: number;
    companyName: string;
    nip: string;
    address: string;
    postalCode: string;
    city: string;
}

export interface AuthorModel
{
    id: number;
    userName: string;
    givenName: string;
    surname: string;
    email: string;
    facePictureUrl: string;
}

export interface CourseItemModel
{
    courseId: number;
    courseName: string;
    dateFromUtc: DateTime;
    dateToUtc: DateTime;
    manuallyAdded: boolean;
    teamsJson: string;
}

export interface ImportUserInfomationsModel
{
    importedAccounts: number;
    errors: ImportErrorModel[];
    file: {
        fileContent: string;
        fileName: string;
    };
}

export interface ImportErrorModel
{
    columnName: string;
    actualRow: number;
    actualValue: string;
    error: string;
}
