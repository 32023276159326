import { defineStore } from 'pinia';
import { reactive } from 'vue';
import Pager from '@/helpers/Pager';
import { Form } from '@/helpers/Form';
import { Columns, CurrentWebsite } from '@/helpers/Interfaces';
import { FilterModel, OptionsModel } from '@/modules/cms/websites/services/WebsitesService';

export default {
    namespaced: true,
    state: {
        options: [],
        current: {
            website: null,
            locale: null
        }
    }
};

export const useWebsitesStore = defineStore('cms-websites', () =>
{
    const options = reactive<OptionsModel[]>([]);
    const current = reactive<CurrentWebsite>({
        website: null,
        locale: null
    });
    const columns = reactive<Columns>({
        visible: {},
        positions: {}
    });
    const filter = reactive(Form.create<FilterModel>({
        name: '',
        title: '',
        theme: '',
        isActive: null,
        isDefault: false
    }));
    const pager = reactive(new Pager(1, 20, 'Name', 'ASC'));

    return { options, current, columns, filter, pager };
});

