<template>
    <ideo-form-group :label="$t('[[[Tytuł]]]')" :invalid-feedback="form.$errors.first('settings.title')" :state="form.$errors.state('settings.title')" required>
        <ideo-form-input v-model="form.settings.title" type="text" name="title"></ideo-form-input>
    </ideo-form-group>
    <ideo-form-group>
        <ideo-form-checkbox v-model="form.settings.nameIsVisible">
            {{ $t('[[[Wyświetlaj nazwę bloczka]]]') }}
        </ideo-form-checkbox>
    </ideo-form-group>
    <ideo-form-group :label="$t('[[[Wybór listy stron]]]')" :invalid-feedback="form.$errors.first('settings.pages')" :state="form.$errors.state('settings.pages')" required>
        <multiselect
            v-model="form.settings.pages"
            label="text"
            track-by="value"
            :options="selectedPageOptions"
            :multiple="true"
            :placeholder="$t('[[[Wybierz strony]]]')"
        >
            <template #noOptions>{{ $t('[[[Lista jest pusta]]]') }}</template>
            <template #noResult>{{ $t('[[[Nie znaleziono żadnych wyników.]]]') }}</template>
        </multiselect>
    </ideo-form-group>
    <ideo-form-group :label="$t('[[[Wybór strony przekierowującej]]]')" :invalid-feedback="form.$errors.first('settings.redirectPage')" :state="form.$errors.state('settings.redirectPage')">
        <multiselect
            v-model="form.settings.redirectPage"
            label="text"
            track-by="value"
            :options="selectedPageOptions"
            :multiple="false"
            :placeholder="$t('[[[Wybierz stronę]]]')"
        >
            <template #noOptions>{{ $t('[[[Lista jest pusta]]]') }}</template>
            <template #noResult>{{ $t('[[[Nie znaleziono żadnych wyników.]]]') }}</template>
        </multiselect>
        <template #description>
            <span>{{ $t('[[[Przycisk "zobacz wszystkie" znajdujący się przy bloczku, będzie przekierowywał na wybraną stronę z szkoleniami. Jeśli strona nie zostanie wybrana zostanie on ukryty.]]]') }}</span>
        </template>
    </ideo-form-group>
    <ideo-form-group :label="$t('[[[Kategorie szkoleń]]]')" :invalid-feedback="form.$errors.first('source')" :state="form.$errors.state('source')">
        <ideo-form-radio-group class="mb-3">
            <ideo-form-radio v-model="form.settings.allCategories" :value="true" name="selectCategories">{{ $t('[[[Wszystkie kategorie]]]') }}</ideo-form-radio>
            <ideo-form-radio v-model="form.settings.allCategories" :value="false" name="selectCategories">{{ $t('[[[Wybrane kategorie]]]') }}</ideo-form-radio>
        </ideo-form-radio-group>
    </ideo-form-group>
    <ideo-form-group v-if="form.settings.allCategories == false" :label="$t('[[[Wybór kategorii]]]')" :invalid-feedback="form.$errors.first('settings.categories')" :state="form.$errors.state('settings.categories')">
        <multiselect
            v-model="form.settings.categories"
            label="text"
            track-by="value"
            :options="selectedCategoriesOptions"
            :multiple="true"
            :placeholder="$t('[[[Wybierz strony]]]')"
        >
            <template #noOptions>{{ $t('[[[Lista jest pusta]]]') }}</template>
            <template #noResult>{{ $t('[[[Nie znaleziono żadnych wyników.]]]') }}</template>
        </multiselect>
    </ideo-form-group>
    <ideo-form-group :label="$t('[[[Wyświetlanie]]]')" :invalid-feedback="form.$errors.first('settings.display')" :state="form.$errors.state('settings.display')">
        <ideo-form-select v-model="form.settings.display" :options="displayOptions" :disabled="disabled"></ideo-form-select>
    </ideo-form-group>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Emit, Prop, Watch } from '@/helpers/Decorators';
import { CurrentWebsite, Option } from '@/helpers/Interfaces';
import { FormType } from '@/helpers/Form';
import merge from 'lodash/merge';
import CourseCategoriesService, { ListItemModel } from '@/modules/elearning/courses/services/CourseCategoriesService';
import { $t } from '@/plugins/localization';
import { wait } from '@/helpers/Utils';
import PagesService, { PageListItemModel } from '@/modules/cms/pages/services/PagesService';
import Multiselect from 'vue-multiselect/src/Multiselect.vue';
import { useWebsitesStore } from '@/modules/cms/websites/store';

interface FormSettings
{
    settings: any;
}

@Options({
    name: 'widget-courses-settings',
    components: {
        'multiselect': Multiselect
    }
})
export default class CoursesSettings extends Vue
{
    @Prop() public modelValue!: any;

    public get form(): FormType<FormSettings>
    {
        return this.modelValue;
    }

    public module: string = 'el_course_list';
    public defaults: any = {
        pages: [],
        redirectPage: null,
        nameIsVisible: true,
        title: "",
        display: 'Latest',
        categories: [],
        allCategories: true
    };

    public pageOptions: PageListItemModel[] = [];
    public categoriesOptions: ListItemModel[] = [];

    public get displayOptions(): Option[]
    {
        return [
            { value: 'Latest', text: $t('[[[Najnowsze]]]') },
            { value: 'LastViewed', text: $t('[[[Ostatnio oglądane]]]') },
            { value: 'UpcomingEnd', text: $t('[[[Zbliżająca się data zakończenia]]]') }
        ];
    }

    public get current(): CurrentWebsite
    {
        return useWebsitesStore().current;
    }

    public get selectedPageOptions(): Option[]
    {
        return this.pageOptions.map(p =>
        {
            return { value: p.id.toString(), text: p.option };
        });
    }

    public get selectedCategoriesOptions(): Option[]
    {
        return this.categoriesOptions.map(p =>
        {
            return { value: p.id.toString(), text: p.name };
        });
    }

    public async created(): Promise<void>
    {
        await wait(() => this.current.website);
        await this.loadPages();
        await this.loadCategories();
        this.form.settings = merge(this.defaults, this.form.settings);
    }

    @Watch('form.settings', { deep: true })
    public onSettingsChanged(value: any): void
    {
        this.triggetModel();
    }

    @Emit('update:modelValue')
    public triggetModel(): any
    {
        return this.form;
    }

    public async loadPages(): Promise<void>
    {
        try
        {
            this.pageOptions = await PagesService.getPagesByModule(this.current.website, this.current.locale, this.module);
            this.pageOptions = this.pageOptions.filter(x => x.id != 0);
        }
        catch (ex)
        {
            this.pageOptions = [];
            this.$log.debug(ex);
        }
    }

    public async loadCategories(): Promise<void>
    {
        try
        {
            this.categoriesOptions = await CourseCategoriesService.getOptions();
        }
        catch (ex)
        {
            this.categoriesOptions = [];
            this.$log.debug(ex);
        }
    }
}
</script>
