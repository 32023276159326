<template>
    <li :id="container" :class="{'active': isActive}" @mouseover.stop="mouseOver" @mouseout.stop="mouseOut">
        <span class="d-flex align-items-baseline">
            <ideo-form-checkbox v-if="node.module!='el_topics'" v-model="node.complete" :disabled="node.isPassed" type="text" name="isPublished" @update:modelValue="updateNode(node)" />
            <i class="fa fa-fw" v-if="!hasChildren"></i>
            <i class="fal fa-fw fa-plus" v-if="isCollapsed" @click.prevent="onToggle(node)"></i>
            <i class="fal fa-fw fa-minus" v-if="isExpanded" @click.prevent="onToggle(node)"></i>
            <i :class="icon('fad fa-fw fa-file fa-swap')" v-if="!hasChildren"></i>
            <i :class="icon('fad fa-fw fa-folder')" v-if="isCollapsed"></i>
            <i :class="icon('fad fa-fw fa-folder-open fa-swap-opacity')" v-if="isExpanded"></i>
            <a href="#" @click.prevent="onClick(node)" class="flex-fill" :class="{'text-dark': isActive}">{{ node.name }}</a>
            <span class="d-flex justify-content-end">
                <span v-if="node.module != 'el_topics'" class="d-flex justify-content-center align-items-center" style="width: 140px;">
                    <i :class="['fad fa-fw fa-circle me-2', node.isRequired ? 'text-success' : 'text-default']" :title="node.isRequired ? $t('[[[element wymagany]]]') : $t('[[[element niewymagany]]]')"></i>
                    <i class="fas fa-fw fa-arrows-rotate cursor-pointer" :id="`btn-reset-${node.id}`" :title="$t('[[[zresetuj podejście do elementu szkolenia]]]')"></i>
                    <confirmation :message="$t('[[[Potwierdzenie resetu podejścia do elementu szkolenia]]]')" :target="`btn-reset-${node.id}`" :value="node.id" @confirm="resetUnitResult(node)" />
                </span>
                <span v-if="hasMenu">
                    <template v-for="(item, index) in menuItems" :key="index">
                        <span v-show="item.name" :id="menuItemId(index)" @click.prevent="onMenuItemIcon(node, item, !item.confirm)" :title="item.name">
                            <i class="me-2 icon" :class="[item.icon, item.class]"></i>
                            <confirmation v-if="item.confirm" :target="menuItemId(index)" :message="item.confirm" :value="item" @confirm="onMenuItem(node, item, true)" />
                        </span>
                    </template>
                </span>
            </span>
        </span>
        <ideo-tree-courses-program-nodes :pages="pages" :user-program="true" :expanded="expanded" :parent="node" :menu="menu" :move="move" @toggle="onToggle" @click="onClick" @sort="onSort" @restore="onRestore" @resetUnit="resetUnitResult" class="tree tree-nested" />
    </li>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Emit, Prop, Ref } from '@/helpers/Decorators';
import { TreeNodeCoursesProgram } from '../utils';
import IdeoPopover from '../../../../components/bootstrap/popover/IdeoPopover.vue';

@Options({
    name: 'ideo-tree-user-course-program-node'
})
export default class IdeoTreeUserCourseProgramNode extends Vue
{
    public hover: boolean = false;
    public contextMenu: boolean = false;
    public menuItems: any[] = [];

    @Prop()
    public node!: TreeNodeCoursesProgram;

    @Prop()
    public pages!: TreeNodeCoursesProgram[];

    @Prop()
    public expanded!: number[];

    @Prop()
    public menu: (node: TreeNodeCoursesProgram) => Promise<any[]>;

    @Prop()
    public move: (e: any) => boolean;

    @Prop()
    public iconVisible: (e: any) => boolean;

    @Ref('popover')
    public popover: () => IdeoPopover;

    public get hasChildren(): boolean
    {
        return this.node.hasChildren;
    }

    public get isExpanded(): boolean
    {
        return this.node.hasChildren && this.expanded.indexOf(this.node.id) >= 0;
    }

    public get isCollapsed(): boolean
    {
        return this.node.hasChildren && this.expanded.indexOf(this.node.id) === -1;
    }

    public get isActive(): boolean
    {
        return this.$route.params.id === this.node.id.toString();
    }

    public get target(): string
    {
        return `node-menu-${this.node.id}`;
    }

    public get container(): string
    {
        return `node-container-${this.node.id}`;
    }

    public get hasMenu(): boolean
    {
        return this.menu != null;
    }

    public get courseId(): number
    {
        return Number(this.$route.params.id) || 0;
    }

    public async mounted(): Promise<void>
    {
        this.menuItems = await this.getMenu(this.node);
    }

    public unmounted(): void
    {
        this.$events.$off('tree::popover::hide', this.hidePopover);
    }

    public async getMenu(node: TreeNodeCoursesProgram): Promise<any[]>
    {
        if (this.menu)
            return await this.menu(node);
        else
            return [];
    }

    public menuItemId(index: number): string
    {
        return `dropdown-${this.node.id}-item-${index}`;
    }

    public icon(icon: string): string
    {
        let icons = icon;

        if (this.node.isActive)
        {
            icons += ' text-success';
        }

        return this.node.icon ? this.node.icon : icons;
    }

    public mouseOver(event: MouseEvent): void
    {
        this.hover = true;
    }

    public mouseOut(event: MouseEvent): void
    {
        if (this.contextMenu == false)
        {
            this.hover = false;
        }
    }

    public async togglePopover(): Promise<void>
    {
        this.menuItems = await this.getMenu(this.node);

        if (this.menuItems.length == 0)
        {
            this.$alert.info(this.$t('[[[Ten element nie zawiera menu kontekstowego.]]]'));
            this.contextMenu = false;
        }
    }

    public hidePopover(node: TreeNodeCoursesProgram): void
    {
        if (node === null || node != this.node)
        {
            this.menuItems = [];
            this.contextMenu = false;
            this.hover = false;
        }
    }

    @Emit('toggle')
    public onToggle(node: TreeNodeCoursesProgram): TreeNodeCoursesProgram
    {
        return node;
    }

    @Emit('click')
    public onClick(node: TreeNodeCoursesProgram): TreeNodeCoursesProgram
    {
        return node;
    }

    public onMenuItem(node: TreeNodeCoursesProgram, item: any, confirmed: boolean): void
    {
        if (confirmed)
        {
            this.$events.$emit('tree::popover::hide', node);
            this.togglePopover();
            item.click(node);
        }
    }

    public async onMenuItemIcon(node: TreeNodeCoursesProgram, item: any, confirmed: boolean): Promise<void>
    {
        if (confirmed)
        {
            item.click(node);
        }
    }

    @Emit('delete')
    public onDelete(node: TreeNodeCoursesProgram): TreeNodeCoursesProgram
    {
        this.$events.$emit('tree::popover::hide', node);

        return node;
    }

    @Emit('sort')
    public onSort(pages: TreeNodeCoursesProgram[]): TreeNodeCoursesProgram[]
    {
        return pages;
    }

    @Emit('restore')
    public onRestore(node: TreeNodeCoursesProgram): TreeNodeCoursesProgram
    {
        return node;
    }

    public updateNode(node: TreeNodeCoursesProgram): void
    {
        if (!node.isPassed)
        {
            this.$events.$emit('all-items', node);
        }
    }

    @Emit('resetUnit')
    public emitResetUnitResult(node: TreeNodeCoursesProgram): TreeNodeCoursesProgram
    {
        return node;
    }

    public resetUnitResult(node: TreeNodeCoursesProgram): void
    {
        if (node.module != 'el_topics')
            this.emitResetUnitResult(node);
    }
}
</script>

<style scoped>
.text-default {
    color: #cdd1d9 !important;
}
</style>
