<template>
    <ideo-form-group v-if="false" :label="$t('[[[Tagi]]]')" :invalid-feedback="form.$errors.first('settings.tags')" :state="form.$errors.state('settings.tags')">
        <edito-tags v-model="form.settings.tags"></edito-tags>
    </ideo-form-group>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Emit, Prop, Watch } from '@/helpers/Decorators';
import merge from 'lodash/merge';

@Options({
    name: 'module-informations-settings'
})
export default class InformationsSettings extends Vue
{
    @Prop() public modelValue!: any;

    public defaults: any = {
        tags: []
    };

    public get form(): any
    {
        return this.modelValue;
    }

    public created(): void
    {
        this.form.settings = merge(this.defaults, this.form.settings);
    }

    @Watch('form.settings', { deep: true })
    public onSettingsChanged(value: any): void
    {
        this.triggetModel();
    }

    @Emit('update:modelValue')
    public triggetModel(): any
    {
        return this.form;
    }
}
</script>
