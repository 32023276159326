<template>
    <div class="widget-tiles__settings--grid">
        <div v-for="(tile,i) in form.settings.rows[id].tiles" :key="tile.id" class="widget-tiles">
            <ideo-form-group :label="$t('[[[Ikona]]]')" :invalid-feedback="form.$errors.first(`image`)" :state="form.$errors.state(`image`)">
                <filepicker v-model="form.settings.rows[id].tiles[i].image" media="images" />
            </ideo-form-group>
            <div>
                <ideo-form-group :label="$t('[[[Tytuł kafelka]]]')" :invalid-feedback="form.$errors.first(`tileTitle`)" :state="form.$errors.state(`tileTitle`)" required>
                    <ideo-form-textarea v-model="form.settings.rows[id].tiles[i].tileTitle" name="tileTitle"></ideo-form-textarea>
                </ideo-form-group>
                <ideo-form-group :label="$t('[[[Link]]]')" :invalid-feedback="form.$errors.first(`link`)" :state="form.$errors.state(`link`)">
                    <ideo-form-input v-model="form.settings.rows[id].tiles[i].tileLink" name="link"></ideo-form-input>
                </ideo-form-group>
                <ideo-form-group :label="$t('[[[Opis]]]')" :invalid-feedback="form.$errors.first(`tileDescription`)" :state="form.$errors.state(`tileDescription`)">
                    <ideo-form-textarea v-model="form.settings.rows[id].tiles[i].tileDescription" name="tileDescription"></ideo-form-textarea>
                </ideo-form-group>
                <div class="input-group-append">
                    <action-button variant="danger" class="w-100" icon="far fa-trash" :text="$t('[[[Usuń kafelek]]]')" :title="$t('[[[Usuń kafelek]]]')" :confirm="$t('[[[Potwierdzenie usunięcia]]]')" @click="removeTile(tile)" :disabled="form.settings.rows[id].tiles.length < 2" />
                </div>
            </div>
        </div>
    </div>
    <div class="widget-tiles__buttons">
        <action-button variant="light" class="w-100" icon="far fa-plus" :text="$t('[[[Dodaj kafelek]]]')" @click="addTile()" :disabled="form.settings.rows[id].tiles.length >= 3" />
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Emit, Prop, Watch } from '@/helpers/Decorators';
import { ValidatableComponent } from '@/components/cms';
import merge from 'lodash/merge';
import { FormType } from '@/helpers/Form';

interface FormSettings
{
    setings: any;
}

@Options({
    name: 'widget-tilesrow'
})
export default class TilesRow extends Vue implements ValidatableComponent
{
    @Prop() public modelValue!: any;
    @Prop() public id: number;

    public defaults: any = {
        rows: [{
            tiles: []
        }]
    };

    public get form(): FormType<FormSettings>
    {
        return this.modelValue;
    }

    public async created(): Promise<void>
    {
        this.form.settings = merge(this.defaults, this.form.settings);

        if (!this.form.settings.rows[this.id].tiles.length)
        {
            this.form.settings.rows[this.id].tiles = [{
                id: 1,
                image: null,
                tileTitle: '',
                tileDescription: '',
                tileLink: ''
            }];
        }
    }

    @Watch('modelValue', { deep: true })
    public onSettingsChanged(value: any): void
    {
        this.triggetModel();
    }

    @Emit('update:modelValue')
    public triggetModel(): any
    {
        return this.form;
    }

    public async validate(): Promise<void>
    {
    }

    public addTile(): void
    {
        const ids = this.form.settings.rows[this.id].tiles.map((x: { id: any; }) => x.id);

        const maxId = this.form.settings.rows[this.id].tiles.length > 0 ? Math.max(...ids) : 1;

        this.form.settings.rows[this.id].tiles.push({
            id: maxId + 1,
            image: null,
            tileTitle: '',
            tileDescription: '',
            tileLink: ''
        });
    }

    public removeTile(tile: any): void
    {
        this.form.settings.rows[this.id].tiles = this.form.settings.rows[this.id].tiles.filter((x: any) => x !== tile);
    }
}
</script>
<style lang="scss">
.widget-tiles {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
    border: 1px solid #eee;
    padding: 8px;
    box-shadow: 0 0 5px #efefef;
    @media only screen and (min-width: 960px)
    {
        grid-template-columns: 1fr 1fr;
    }
}
.widget-tiles__buttons {
    box-shadow: none;
    border-width: 0;
}
.widget-tiles__settings--grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
    margin-bottom: 1rem;
    @media only screen and (min-width: 1024px)
    {
        grid-template-columns: 1fr 1fr;
    }
    @media only screen and (min-width: 1366px)
    {
        grid-template-columns: 1fr 1fr 1fr;
    }
}
</style>
