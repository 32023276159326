<template>
    <div :class="containerClasses" ref="dropdown">
        <div :class="dropdownClasses">
            <button :type="splitButtonType" :class="splitClasses" :disabled="disabled" ref="split" @click.stop="clickSplit" v-if="flag(split) && !flag(dropleft)">
                <slot name="button-content">
                    {{ text }}
                </slot>
            </button>
            <button type="button" :class="toggleClasses" :disabled="disabled" ref="toggle" @click.stop="toggleMenu">
                <slot name="button-content" v-if="split === false">
                    {{ text }}
                </slot>
            </button>
            <div :class="menuClasses" ref="menu">
                <slot name="default"></slot>
            </div>
        </div>
        <button :type="splitButtonType" :class="splitClasses" :disabled="disabled" ref="split" @click.stop="clickSplit" v-if="flag(split) && flag(dropleft)">
            <slot name="button-content">
                {{ text }}
            </slot>
        </button>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Emit, Prop, Ref } from '@/helpers/Decorators';
import { createPopper, Instance as Popper } from '@popperjs/core';
import { normalizeClasses } from '@/helpers/Utils';
import { onClickOutside } from '@vueuse/core';

const VARIANTS = ['primary', 'secondary', 'success', 'danger', 'warning', 'info', 'light', 'dark', 'link'];

@Options({
    name: 'ideo-dropdown'
})
export default class IdeoDropdown extends Vue
{
    public popper: Popper = null;
    public visible: boolean = false;

    @Ref()
    public dropdown: () => HTMLDivElement;

    @Ref()
    public toggle: () => HTMLButtonElement;

    @Ref()
    public menu: () => HTMLDivElement;

    @Prop({ default: false })
    public block: boolean;

    @Prop({ default: null })
    public boundary: string;

    @Prop({ default: false })
    public disabled: boolean;

    @Prop({ default: false })
    public dropleft: boolean;

    @Prop({ default: false })
    public dropright: boolean;

    @Prop({ default: false })
    public dropup: boolean;

    @Prop({ default: () => ({}) })
    public menuClass: Record<string, boolean> | string[] | string;

    @Prop({ default: false })
    public noCaret: boolean;

    @Prop({ default: false })
    public noFlip: boolean;

    @Prop({ default: false })
    public right: boolean;

    @Prop({ default: 'md', validator: (value: string) => ['sm', 'md', 'lg'].includes(value) })
    public size: string;

    @Prop({ default: false })
    public split: boolean;

    @Prop({ default: 'button', validator: (value: string) => ['button', 'submit', 'reset'].includes(value) })
    public splitButtonType: 'button' | 'submit' | 'reset';

    @Prop({ default: () => ({}) })
    public splitClass: Record<string, boolean> | string[] | string;

    @Prop({
        default: null,
        validator: (value: string) => [null, ...VARIANTS].includes(value?.replace('outline-', ''))
    })
    public splitVariant: string;

    @Prop({ default: 'Toggle dropdown' })
    public text: string;

    @Prop({ default: () => ({}) })
    public toggleClass: Record<string, boolean> | string[] | string;

    @Prop({
        default: 'secondary',
        validator: (value: string) => VARIANTS.includes(value?.replace('outline-', ''))
    })
    public variant: string;

    public get showClass(): Record<string, boolean>
    {
        return {
            'show': this.visible
        };
    }

    public get positionClass(): Record<string, boolean>
    {
        return {
            'position-static': this.boundary !== null
        };
    }

    public get containerClasses(): Record<string, boolean>
    {
        return {
            'dropdown-container': true,
            'btn-group': true,
            'w-100': this.flag(this.block),
            ...this.positionClass
        };
    }

    public get dropdownClasses(): Record<string, boolean>
    {
        return {
            'dropdown': true,
            'btn-group': true,
            'd-flex w-100': this.flag(this.block),
            ...this.positionClass,
            ...this.directionClasses,
            ...this.showClass
        };
    }

    public get splitClasses(): Record<string, boolean>
    {
        const variant = this.splitVariant || this.variant;
        const btnClasses = `btn btn-${variant}`;

        return {
            [btnClasses]: true,
            'dropdown-btn': true,
            'btn-block': this.flag(this.block) && this.flag(this.split),
            ...this.sizeClasses,
            ...normalizeClasses(this.splitClass)
        };
    }

    public get toggleClasses(): Record<string, boolean>
    {
        const btnClasses = `btn btn-${this.variant}`;

        return {
            [btnClasses]: true,
            'btn-block': this.flag(this.block) && !this.flag(this.split),
            'dropdown-btn': true,
            'dropdown-toggle': true,
            'dropdown-toggle-split': this.flag(this.split),
            'dropdown-toggle-no-caret': this.flag(this.noCaret),
            ...this.sizeClasses,
            ...normalizeClasses(this.toggleClass)
        };
    }

    public get menuClasses(): Record<string, boolean>
    {
        return {
            'dropdown-menu shadow': true,
            'dropdown-menu-right': this.right,
            ...normalizeClasses(this.menuClass),
            ...this.showClass
        };
    }

    public get sizeClasses(): Record<string, boolean>
    {
        return {
            'btn-sm': this.size == 'sm',
            'btn-lg': this.size == 'lg'
        };
    }

    public get directionClasses(): Record<string, boolean>
    {
        return {
            'dropup': this.flag(this.dropup),
            'dropright': this.flag(this.dropright),
            'dropleft': this.flag(this.dropleft)
        };
    }

    public mounted(): void
    {
        onClickOutside(this.dropdown(), () =>
        {
            this.hide();
        },
        {ignore: ['.modal']});
    }

    public beforeUnmount(): void
    {
        this.destroyPopper();
    }

    public flag(value: any): boolean
    {
        return value !== false;
    }

    public show(): void
    {
        if (this.disabled == false && this.visible == false)
        {
            this.triggerBeforeEvents();
            this.visible = true;
            this.triggerAfterEvents();
            this.triggerToggle();
        }
    }

    public hide(): void
    {
        if (this.disabled == false && this.visible == true)
        {
            this.triggerBeforeEvents();
            this.visible = false;
            this.triggerAfterEvents();
            this.triggerToggle();
        }
    }

    public toggleMenu(): void
    {
        this.visible ? this.hide() : this.show();
    }

    public clickSplit(e: Event): void
    {
        if (this.disabled == false)
        {
            this.triggerClick(e);
        }
    }

    @Emit('toggle')
    public triggerToggle(): boolean
    {
        return this.visible;
    }

    @Emit('click')
    public triggerClick(e: Event): Event
    {
        return e;
    }

    public triggerBeforeEvents(): void
    {
        if (this.visible == false)
            this.triggerShow();
        else
            this.triggerHide();
    }

    @Emit('show')
    public triggerShow(): void
    {
    }

    @Emit('hide')
    public triggerHide(): void
    {
    }

    public triggerAfterEvents(): void
    {
        if (this.visible == true)
            this.triggerShown();
        else
            this.triggerHidden();
    }

    @Emit('shown')
    public triggerShown(): void
    {
        const el = (this.flag(this.dropup) && this.flag(this.right)) || this.flag(this.split) ? this.$el : this.toggle();

        this.createPopper(el);
    }

    @Emit('hidden')
    public triggerHidden(): void
    {
        this.destroyPopper();
    }

    public createPopper(element: HTMLElement): void
    {
        this.destroyPopper();

        this.popper = createPopper(element, this.menu(), this.getPopperConfig());
    }

    public destroyPopper(): void
    {
        this.popper && this.popper.destroy();
        this.popper = null;
    }

    public getPopperConfig(): any
    {
        let placement = 'bottom-start';

        if (this.flag(this.dropup))
        {
            placement = this.flag(this.right) ? 'top-end' : 'top-start';
        }
        else if (this.flag(this.dropright))
        {
            placement = 'right-start';
        }
        else if (this.flag(this.dropleft))
        {
            placement = 'left-start';
        }
        else if (this.flag(this.right))
        {
            placement = 'bottom-end';
        }

        const config: any = {
            placement: placement,
            modifiers: [
                {
                    name: 'flip',
                    enabled: !this.flag(this.noFlip),
                    options: {
                        padding: 0
                    }
                }
            ]
        };

        if (this.boundary)
        {
            config.modifiers.push({
                name: 'preventOverflow',
                options: {
                    boundary: document.querySelector(this.boundary),
                    padding: 0
                }
            });
        }

        return config;
    }
}
</script>

<style lang="scss">
.dropdown .dropdown-menu {
    margin: 0 !important;
}

.dropdown .dropdown-toggle.dropdown-toggle-no-caret::before,
.dropdown .dropdown-toggle.dropdown-toggle-no-caret::after {
    display: none !important;
}

.dropdown .dropdown-toggle::before,
.dropdown .dropdown-toggle::after {
    vertical-align: middle;
}
</style>
