<script lang="ts" setup>
import { computed, getCurrentInstance } from 'vue';
import { Option } from '@/helpers/Interfaces';

defineOptions({
    name: 'field-choice'
});

defineProps({
  "label": null,
  "modelValue": null,
  "options": null
});

const emit = defineEmits(["update:modelValue"]);

const instance = getCurrentInstance();

const uid = computed(() => `form-field-${instance.uid}`);

const update = (value: any): void =>
{
    emit('update:modelValue', value);
};

</script>

<template>
    <div class="form-group">
        <label :for="uid">{{ label }}</label>
        <div>
            <div class="btn-group w-100">
                <button
                    class="text-truncate"
                    v-for="option in options"
                    :key="option.value" type="button"
                    :class="{
                        'btn btn-primary': option.value == modelValue,
                        'btn btn-light': option.value != modelValue
                    }"
                    :title="$t(option.text)"
                    @click="update(option.value)"
                >
                    {{ $t(option.text) }}
                </button>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.btn {
    text-transform: inherit;
    font-weight: normal;
    padding: 0.25rem;
}
</style>
