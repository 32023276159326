import { axios } from '@/plugins/axios';
import { Statement, Resource, Pagination, MetaSettingsModel } from '@/helpers/Interfaces';
import { PageModeEnum } from '@/helpers/Enums';

/**
 * PagesService
 */
export default class PagesService
{
    /**
     * @param website number
     * @param locale string
     *
     * @returns Promise<Pagination<Resource<FolderListItemModel>>>
     */
    public static async getFolders(website: number, locale: string): Promise<Pagination<Resource<FolderListItemModel>>>
    {
        return (await axios.get<Pagination<Resource<FolderListItemModel>>>('admin/cms/pages/folders', {
            params: {
                website: website,
                locale: locale
            }
        })).data;
    }

    /**
     * @param id number
     *
     * @returns Promise<Resource<FolderFormModel>>
     */
    public static async fetchFolder(id: number): Promise<Resource<FolderFormModel>>
    {
        return (await axios.get<Resource<FolderFormModel>>(`admin/cms/pages/folders/${id}`)).data;
    }

    /**
     * @param model FolderFormModel
     *
     * @returns Promise<Resource<FolderFormModel>>
     */
    public static async createFolder(model: FolderFormModel): Promise<Resource<FolderFormModel>>
    {
        return (await axios.post<Resource<FolderFormModel>>(`admin/cms/pages/folders`, model)).data;
    }

    /**
     * @param id number
     * @param model FolderFormModel
     *
     * @returns Promise<Statement>
     */
    public static async updateFolder(id: number, model: FolderFormModel): Promise<Statement>
    {
        return (await axios.put<Statement>(`admin/cms/pages/folders/${id}`, model)).data;
    }

    /**
     * @param id number
     *
     * @returns Promise<Statement>
     */
    public static async removeFolder(id: number): Promise<Statement>
    {
        return (await axios.delete(`admin/cms/pages/folders/${id}`)).data as Statement;
    }

    // ----------------------------------------------------------------------------------------------------

    /**
     * @param folders number[]
     * @param expanded number[]
     *
     * @returns Promise<Pagination<Resource<PageListItemModel>>>
     */
    public static async getPages(folders: number[], expanded: number[]): Promise<Pagination<Resource<PageListItemModel>>>
    {
        return (await axios.get<Pagination<Resource<PageListItemModel>>>('admin/cms/pages', {
            params: {
                folders: folders.join(','),
                expanded: expanded.join(',')
            }
        })).data;
    }

    /**
     * @param folderId number
     *
     * @returns Promise<PageListItemModel[]>
     */
    public static async getPagesByFolder(folderId: number): Promise<PageListItemModel[]>
    {
        return (await axios.get<PageListItemModel[]>('admin/cms/pages/options', {
            params: {
                folder: folderId
            }
        })).data;
    }

    /**
     * @param folderId number
     *
     * @returns Promise<PageListItemModel[]>
     */
    public static async getPagesWithoutElementsByFolder(folderId: number): Promise<PageListItemModel[]>
    {
        return (await axios.get<PageListItemModel[]>('admin/cms/pages/options-without-elements', {
            params: {
                folder: folderId
            }
        })).data;
    }

    /**
     * @param websiteId number
     * @param locale string
     * @param moduleName string
     *
     * @returns Promise<PageListItemModel[]>
     */
    public static async getPagesByModule(websiteId: number, locale: string, moduleName: string): Promise<PageListItemModel[]>
    {
        return (await axios.get<PageListItemModel[]>('admin/cms/pages/modules', {
            params: {
                website: websiteId,
                locale: locale,
                module: moduleName
            }
        })).data;
    }

    /**
     * @param id number
     *
     * @returns Promise<Resource<PageFormModel>>
     */
    public static async fetchPage(id: number): Promise<Resource<PageFormModel>>
    {
        return (await axios.get<Resource<PageFormModel>>(`admin/cms/pages/${id}`)).data;
    }

    /**
     * @param model PageFormModel
     *
     * @returns Promise<Resource<PageFormModel>>
     */
    public static async createPage(model: PageFormModel): Promise<Resource<PageFormModel>>
    {
        return (await axios.post<Resource<PageFormModel>>(`admin/cms/pages`, model)).data;
    }

    /**
     * @param id number
     * @param model PageFormModel
     *
     * @returns Promise<Statement>
     */
    public static async updatePage(id: number, model: PageFormModel): Promise<Statement>
    {
        return (await axios.put<Statement>(`admin/cms/pages/${id}`, model)).data;
    }

    /**
     * @param id number
     *
     * @returns Promise<Statement>
     */
    public static async removePage(id: number): Promise<Statement>
    {
        return (await axios.delete(`admin/cms/pages/${id}`)).data as Statement;
    }

    /**
     * @param positions PagePosition[]
     *
     * @returns Promise<Statement>
     */
    public static async sortPages(positions: PagePosition[]): Promise<Statement>
    {
        return (await axios.put<Statement>(`admin/cms/pages/positions`, positions)).data;
    }
}

export interface FolderListItemModel
{
    id: number;
    name: string;
    locale: string;
}

export interface FolderFormModel
{
    id: number;
    name: string;
    websiteId: number;
    locale: string;
}

export interface PageListItemModel
{
    id: number;
    parentId?: number;
    folderId: number;
    name: string;
    option: string;
    module: string;
    pageMode: PageModeEnum;
    level: number;
    hasChildren: boolean;
    position: number;
    hasCustomLayout: boolean;
}

export interface PageFormModel
{
    id: number;
    folderId: number;
    parentId?: number;
    name: string;
    symbol: string;
    isActive: boolean;
    isHidden: boolean;
    authOnly: boolean | null;
    requireAccess: boolean;
    isSluggable: boolean;
    slug: string;
    pageMode: string;
    layout: string;
    module: string;
    aliasId?: number;
    linkUrl: string;
    linkTarget: boolean;
    settings: any;
    icon?: File;
    iconColor: string;
    meta: MetaSettingsModel|null;
}

export interface PagePosition
{
    id: number;
    position: number;
}
