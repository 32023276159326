import { axios } from '@/plugins/axios';
import Pager from '@/helpers/Pager';
import { Pagination, Statement, Resource, EntityVersion } from '@/helpers/Interfaces';
import { PublicationEnum } from '@/helpers/Enums';
import { Option } from '@/helpers/Interfaces';
import { DateTime } from 'luxon';
import { FormEntry } from '@/components/forms/blueprints/form';
import { FeaturePermissions } from '../../modules/services/types';
import { Resource as Attachment } from '@/modules/core/files/services/StorageService';

export default class DocumentsService
{
    public static endpoint(module: string, submodule: string): string
    {
        return `admin/studio/documents/${module}${[module, null].includes(submodule) ? '' : `/${submodule}`}`;
    }

    public static async index(module: string, submodule: string, filter: FilterModel, pager: Pager): Promise<Pagination<Resource<ListItemModel>>>
    {
        return (await axios.post<Pagination<Resource<ListItemModel>>>(`${this.endpoint(module, submodule)}/index`, filter, { params: pager.data() })).data;
    }

    public static async indexMeta(module: string, submodule: string): Promise<Record<string, boolean>>
    {
        return (await axios.get<Record<string, boolean>>(`${this.endpoint(module, submodule)}/index/meta`)).data;
    }

    public static async fetch(module: string, submodule: string, id: number): Promise<Resource<FormModel>>
    {
        return (await axios.get<Resource<FormModel>>(`${this.endpoint(module, submodule)}/${id}`)).data;
    }

    public static async fetchMeta(module: string, submodule: string, id: number, required: string[] = []): Promise<Record<string, boolean>>
    {
        return (await axios.get<Record<string, boolean>>(`${this.endpoint(module, submodule)}/${id}/meta`, {
            params: {
                required
            }
        })).data;
    }

    public static async create(module: string, submodule: string, model: UpsertModel, entry: any): Promise<Resource<FormModel>>
    {
        return (await axios.post<Resource<FormModel>>(this.endpoint(module, submodule), {...model, ...{ document: entry }})).data;
    }

    public static async update(module: string, submodule: string, id: number, model: UpsertModel, entry: any): Promise<Resource<FormModel>>
    {
        return (await axios.put<Resource<FormModel>>(`${this.endpoint(module, submodule)}/${id}`, {...model, ...{ document: entry }})).data;
    }

    public static async remove(module: string, submodule: string, id: number): Promise<Statement>
    {
        return (await axios.delete(`${this.endpoint(module, submodule)}/${id}`)).data as Statement;
    }

    public static async status(module: string, submodule: string, id: number, status: PublicationEnum): Promise<Statement>
    {
        return (await axios.patch(`${this.endpoint(module, submodule)}/${id}/status`, {
            status: status
        })).data;
    }

    public static async getVersions(module: string, submodule: string, id: number, pager: Pager): Promise<Pagination<EntityVersion>>
    {
        return (await axios.get<Pagination<EntityVersion>>(`${this.endpoint(module, submodule)}/${id}/versions`, {
            params: pager.data()
        })).data;
    }

    public static async exportToExcel(module: string, submodule: string, filter: FilterModel): Promise<any>
    {
        const filename = `export-${DateTime.now().toFormat('yyyy-MM-dd_HH-mm-ss')}.xlsx`;

        return axios
            .post(`${this.endpoint(module, submodule)}/export`, filter, {
                responseType: 'blob'
            })
            .then(res =>
            {
                if (res.status == 200)
                {
                    const blob = res.data;

                    if ((window.navigator as any).msSaveOrOpenBlob)
                    {
                        (navigator as any).msSaveBlob(blob, filename);
                    }
                    else
                    {
                        const a = document.createElement('a');

                        document.body.appendChild(a);
                        a.href = window.URL.createObjectURL(blob);
                        a.download = filename;
                        a.target = '_blank';
                        a.click();
                        a.remove();
                    }
                }
            });
    }

    public static async upload(module: string, submodule: string, field: string, id: number, fileId: number, file: File): Promise<Attachment>
    {
        const data = new FormData();

        data.append('field', field);
        data.append('fileId', String(fileId));
        data.append('file', file);

        const result = await axios.post<Attachment>(`${this.endpoint(module, submodule)}/${id}/upload`, data);

        return result.data;
    }

    public static async getForm(module: string, submodule: string): Promise<Resource<FormModel>>
    {
        return (await axios.get<Resource<FormModel>>(`${this.endpoint(module, submodule)}/form`)).data;
    }

    public static async saveForm(module: string, submodule: string, model: UpsertModel, entry: any): Promise<Resource<FormModel>>
    {
        return (await axios.post<Resource<FormModel>>(`${this.endpoint(module, submodule)}/form`, {...model, ...{ document: entry }})).data;
    }

    public static async getOptions(formId: number, componentId: string, scope: number[], id: number|null, search: string = null, limit: number = 10): Promise<Option<number>[]>
    {
        return (await axios.get<Option<number>[]>(`admin/studio/documents/options/${formId}/${componentId}`, {
            params: {scope, id, search, limit }
        })).data;
    }
}

export function checkPermissions(permissions: Record<string, boolean>, feature: FeaturePermissions)
{
    const allowed = Object
        .entries(permissions)
        .where(([_, value]) => value == true)
        .select(([key, _]) => key)
        .toArray();

    // Jak nie wymagamy jakichkolwiek uprawnień to znaczy, ze chcemy wpuścić
    if (feature.permissions.length == 0)
    {
        return true;
    }

    if (feature.condition == 'And')
    {
        for (let i = 0; i < feature.permissions.length; i++)
        {
            if (!allowed.includes(feature.permissions[i]))
                return false;
        }

        return true;
    }
    else
    {
        for (let i = 0; i < feature.permissions.length; i++)
        {
            if (allowed.includes(feature.permissions[i]))
                return true;
        }

        return false;
    }
}

export interface ListItemModel
{
    id: number;
    dateCreatedUtc: DateTime;
    dateModifiedUtc: DateTime;
    createdBy: string;
    modifiedBy: string;
    title: string;
    status: PublicationEnum;
    ownerId: number;
    resourceId: number;
    document: Record<string, string>;
}

export interface FilterModel
{
    formId?: number;
    userId: number;
    status?: PublicationEnum;
    title: string;
    dateFromUtc?: DateTime;
    dateDueUtc?: DateTime;
    onlyMine: boolean;
    document: FormEntry;
    relatedTo: number[];
}

export interface UpsertModel
{
    id: number;
    formId: number;
    cohesionId?: string;
    document: FormEntry;
}

export interface FormModel extends UpsertModel
{
    status: PublicationEnum;
    ownerId: number;
    resourceId: number;
}
