<template>
    <ideo-form-group :label="$t('[[[Opis]]]')" :invalid-feedback="form.$errors.first('content.value.description')" :state="form.$errors.state('content.value.description')">
        <editor :id="seconds('matching-' + form.id)" v-model="form.content.value.description" data-cy="description"></editor>
    </ideo-form-group>
    <ideo-form-group :label="$t('[[[Elementy]]]')" :invalid-feedback="form.$errors.first('content.value.questions')" :state="form.$errors.state('content.value.questions')">
        <div class="mb-3 row">
            <div v-for="(question, i) in form.content.value.questions" :key="question.id" class="col-12 col-md-6 mb-3">
                <div class="d-flex bg-light p-2">
                    <div class="d-flex flex-column flex-grow-1">
                        <ideo-form-group :label="$t('[[[Obrazek]]]')" required>
                            <filepicker v-model="question.picture" media="images" :data-cy="`image-${i}`" />
                        </ideo-form-group>
                        <ideo-form-group :label="$t('[[[Opis obrazka]]]')">
                            <ideo-form-textarea v-model="question.description" rows="4" :placeholder="$t('[[[Opis obrazka]]]')" :data-cy="`image-${i}-description`"></ideo-form-textarea>
                        </ideo-form-group>
                        <ideo-form-group :label="$t('[[[Odpowiedź]]]')" required>
                            <ideo-form-textarea v-model="question.answer" rows="3" :placeholder="$t('[[[Odpowiedź]]]')" :data-cy="`image-${i}-answer`"></ideo-form-textarea>
                        </ideo-form-group>
                    </div>
                    <div class="input-group-append d-flex align-items-start px-2 py-4 mt-1">
                        <action-button variant="danger" icon="far fa-trash" class="me-2" :title="$t('[[[Usuń element]]]')" :confirm="$t('[[[Potwierdzenie usunięcia]]]')" @click="removeImage(i)" :disabled="form.content.value.questions.length < 2" />
                        <Move :items="form.content.value.questions" :index="i" />
                    </div>
                </div>
                <small class="form-text text-danger" v-if="!form.$errors.state(`content.value.questions[${i}].answer`)">{{ form.$errors.first(`content.value.questions[${i}].answer`) }}</small>
                <small class="form-text text-danger" v-if="!form.$errors.state(`content.value.questions[${i}].picture`)">{{ form.$errors.first(`content.value.questions[${i}].picture`) }}</small>
            </div>
        </div>
        <div>
            <action-button variant="outline-dark" class="w-100" icon="far fa-plus" :text="$t('[[[Dodaj odpowiedź]]]')" @click="addImage()" data-cy="addImage" />
        </div>
    </ideo-form-group>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Emit, Prop, Watch } from '@/helpers/Decorators';
import merge from 'lodash/merge';
import { FormType } from '@/helpers/Form';
import { seconds } from '@/helpers/Utils';
import Move from '@/components/common/Move.vue';

interface FormImagesMatching
{
    content: {
        value: {
            questions: SingleImageMatch[]
        }
    }
}

interface SingleImageMatch
{
    id: number,
    picture: File,
    description : string,
    answer: string
}

@Options({
    name: 'type-imagesmatching-settings',
    components: {
        Move
    }
})
export default class ImagesMatchingSettings extends Vue
{
    @Prop() public modelValue!: any;

    // rozwiązanie problemu inicjalizowania edytora, z powodu dwóch tych samych id-ków
    public seconds = seconds;

    public defaults: any = {
        questions: []
    };

    public get form(): FormType<FormImagesMatching>
    {
        return this.modelValue;
    }

    public created(): void
    {
        this.form.content.value = merge(this.defaults, this.form.content.value);
        this.form.file = null;

        if (!this.form.content.value.questions.length)
        {
            this.form.content.value.questions = [{
                id: 1,
                picture: null,
                description: '',
                answer: ''
            }];
        }
    }

    public addImage(): void
    {
        const ids = this.form.content.value.questions.map((x: { id: any; }) => x.id);

        const maxId = this.form.content.value.questions.length > 0 ? Math.max(...ids) : 1;

        const lastIndex = this.form.content.value.questions.length - 1;

        if (this.form.content.value.questions[lastIndex].answer != '')
        {
            this.form.content.value.questions.push({
                id: maxId + 1,
                picture: null,
                description: '',
                answer: ''
            });
        }
        else
        {
            this.$alert.warning(this.$t('[[[Nie podano odpowiedzi dla ostatniego elementu.]]]'));
        }
    }

    public removeImage(index: number): void
    {
        const items = this.form.content.value.questions.slice();

        items.splice(index, 1);
        this.form.content.value.questions = items;

        this.form.$errors.clear(`content.value.questions[${index}]`);
    }

    @Watch('form.content.value', { deep: true })
    public onContentChanged(value: any): void
    {
        this.triggetModel();
    }

    @Emit('update:modelValue')
    public triggetModel(): any
    {
        return this.form;
    }
}

</script>
