<template>
    <div class="widget-tiles__settings">
        <ideo-form-group :label="$t('[[[Tytuł]]]')" :invalid-feedback="form.$errors.first('settings.title')" :state="form.$errors.state('settings.title')">
            <ideo-form-input v-model="form.settings.title" type="text" name="settings.title"></ideo-form-input>
        </ideo-form-group>
        <ideo-form-group :label="$t('[[[Podtytuł]]]')" :invalid-feedback="form.$errors.first('settings.subtitle')" :state="form.$errors.state('settings.subtitle')">
            <ideo-form-input v-model="form.settings.subtitle" type="text" name="subtitle"></ideo-form-input>
        </ideo-form-group>
        <ideo-form-group :label="$t('[[[Kolor tła]]]')" :invalid-feedback="form.$errors.first('settings.bgColor')" :state="form.$errors.state('settings.bgColor')" required>
            <ideo-form-input v-model="form.settings.bgColor" type="color" name="settings.bgColor"></ideo-form-input>
        </ideo-form-group>
    </div>
    <div v-for="(row,id) in form.settings.rows" :key="row.id">
        <widget-tilesrow v-model="form" :id="id" :origin-id="id" />
    </div>
    <div class="widget-tiles widget-tiles__buttons">
        <action-button variant="light" class="w-100" icon="far fa-plus" :text="$t('[[[Dodaj wiersz]]]')" @click="addRow()" />
        <div class="input-group-append">
            <action-button variant="danger" class="w-100" icon="far fa-trash" :text="$t('[[[Usuń wiersz]]]')" :title="$t('[[[Usuń obrazek]]]')" :confirm="$t('[[[Potwierdzenie usunięcia]]]')" @click="removeRow()" :disabled="form.settings.rows.length < 2" />
        </div>
    </div>
    <ideo-form-group :label="$t('[[[Opis]]]')" :invalid-feedback="form.$errors.first('settings.description')" :state="form.$errors.state('settings.description')">
        <editor id="description" v-model="form.settings.description" name="settings.description"></editor>
    </ideo-form-group>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Emit, Prop, Watch } from '@/helpers/Decorators';
import merge from 'lodash/merge';
import { FormType } from '@/helpers/Form';
import TilesRow from './TilesRow.vue';

interface FormSettings
{
    settings: any;
}

@Options({
    name: 'widget-tiles-settings',
    components: {
        'widget-tilesrow': TilesRow
    }
})
export default class TilesSettings extends Vue
{
    @Prop() public modelValue!: any;

    public defaults: any = {
        title: '',
        subtitle: '',
        bgColor: '#ffffff',
        description: '',
        rows: []
    };

    public get form(): FormType<FormSettings>
    {
        return this.modelValue;
    }

    public async created(): Promise<void>
    {
        this.form.settings = merge(this.defaults, this.form.settings);

        if (!this.form.settings.rows.length)
        {
            this.form.settings.rows = [{
                id: 1,
                tiles: []
            }];
        }
    }

    @Watch('form.settings', { deep: true })
    public onSettingsChanged(value: any): void
    {
        this.triggetModel();
    }

    @Emit('update:modelValue')
    public triggetModel(): any
    {
        return this.form;
    }

    public addRow(): void
    {
        const ids = this.form.settings.rows.map((x: { id: any; }) => x.id);

        const maxId = this.form.settings.rows.length > 0 ? Math.max(...ids) : 1;

        const lastIndex = this.form.settings.rows.length - 1;

        if (this.form.settings.rows[lastIndex].tiles.length < 3)
        {
            this.$alert.warning(this.$t('[[[Nie wypełniono poprzedniego wiersza.]]]'));
        }
        else
        {
            this.form.settings.rows.push({
                id: maxId + 1,
                tiles: []
            });
        }
    }

    public removeRow(): void
    {
        this.form.settings.rows.pop();
    }
}
</script>
<style lang="scss" scoped>
.widget-tiles__settings {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
    @media only screen and (min-width: 960px)
    {
        grid-template-columns: 1fr 1fr;
    }
}
</style>
