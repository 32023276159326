<template>
    <div class="widget-cta">
        <div class="widget-cta__box widget-cta__box--left">
            <ideo-form-group :label="$t('[[[Kolor tła]]]')" :invalid-feedback="form.$errors.first('settings.bgColor')" :state="form.$errors.state('settings.bgColor')" required>
                <ideo-form-input v-model="form.settings.bgColor" type="color" name="settings.bgColor"></ideo-form-input>
            </ideo-form-group>
            <ideo-form-group :label="$t('[[[Obrazek]]]')" :invalid-feedback="form.$errors.first('settings.image')" :state="form.$errors.state('settings.image')">
                <filepicker v-model="form.settings.image" media="images" />
            </ideo-form-group>
            <ideo-form-group :label="$t('[[[Link przycisku]]]')" :invalid-feedback="form.$errors.first('settings.buttonLink')" :state="form.$errors.state('settings.buttonLink')">
                <ideo-form-input v-model="form.settings.buttonLink" type="text" name="buttonLink"></ideo-form-input>
            </ideo-form-group>
            <ideo-form-group :label="$t('[[[Nazwa przycisku]]]')" :invalid-feedback="form.$errors.first('settings.buttonName')" :state="form.$errors.state('settings.buttonName')">
                <ideo-form-input v-model="form.settings.buttonName" type="text" name="buttonName"></ideo-form-input>
            </ideo-form-group>
            <ideo-form-group :label="$t('[[[Tło przycisku]]]')" :invalid-feedback="form.$errors.first('settings.buttonBg')" :state="form.$errors.state('settings.buttonBg')">
                <ideo-form-input v-model="form.settings.buttonBg" type="color" name="buttonBg"></ideo-form-input>
            </ideo-form-group>
        </div>
        <ideo-form-group class="widget-cta__box widget-cta__box--right" :label="$t('[[[Opis]]]')" :invalid-feedback="form.$errors.first('settings.description')" :state="form.$errors.state('settings.description')" required>
            <editor id="description" v-model="form.settings.description" name="settings.description"></editor>
        </ideo-form-group>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Emit, Prop, Watch } from '@/helpers/Decorators';
import { ValidatableComponent } from '@/components/cms';
import merge from 'lodash/merge';
import { FormType } from '@/helpers/Form';

interface FormSettings
{
    settings: any;
}

@Options({
    name: 'widget-ctawithparalaxa-settings'
})
export default class CTAWithParalaxaSettings extends Vue implements ValidatableComponent
{
    @Prop() public modelValue!: any;

    public defaults: any = {
        description: '',
        bgColor: '#ffffff',
        image: null,
        buttonLink: '',
        buttonName: '',
        buttonBg: '#ffffff'
    };

    public get form(): FormType<FormSettings>
    {
        return this.modelValue;
    }

    public async created(): Promise<void>
    {
        this.form.settings = merge(this.defaults, this.form.settings);
    }

    @Watch('form.settings', { deep: true })
    public onSettingsChanged(value: any): void
    {
        this.triggetModel();
    }

    @Emit('update:modelValue')
    public triggetModel(): any
    {
        return this.form;
    }

    public async validate(): Promise<void>
    {
        this.form.validate(schema => ({
            'settings.bgColor': schema.string().required(this.$t("[[[Wybór koloru jest wymagany]]]"))
        }));
    }
}
</script>
<style lang="scss" scoped>
.widget-cta {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
    &__box {
        &--left {
            order: 1;
        }
        &--right {
            order: 0;
        }
    }
    @media only screen and (min-width: 960px)
    {
        grid-template-columns: 1fr 1fr;

        &__box {
            &--left {
                order: 0;
            }
            &--right {
                order: 1;
            }
        }
    }
}
</style>
