<script lang="ts" setup>
import { ref, computed, Ref } from 'vue';
import { TableEntry, TableType, instaceOfTableEntry } from '.';
import { v4 as uuidv4 } from 'uuid';
import { RootEntry } from '@/components/builder/form/entries/RootEntry';
import { FormBuilderContract } from '@/components/builder/form';
import { AggregateBlueprint } from '@/components/builder/base/blueprints/AggregateBlueprint';
import TableView from './TableView.vue';
import properties from '@/components/forms/properties';

defineOptions({
    name: 'table-blueprint',
    components: {
        ...properties,
    },
});

const props = defineProps({
  "blueprint": null,
  "entry": null,
  "form": null,
  "parent": null
});

const entryData = ref(new TableEntry()) as Ref<TableEntry>;
const idsRows = ref<string[]>([]);

const value = computed({
    get()
    {
        const { data: value } = entryData.value;

        return value;
    },
    set(value: Record<string, any>[])
    {
        entryData.value.data = value;
    },
});

const blueprint = computed(() => props.blueprint);
const design = computed(() => props.form.designMode());
const required = computed(() => props.form.expressions.required(props.blueprint));

const setDefaultNumberOfRows = (_value: number) =>
{
    value.value.length = _value;
    idsRows.value.length = _value;
    blueprint.value.numberOfRows = _value;

    for (let i = 0; i < value.value.length; i++)
    {
        if (value.value[i] == null)
        {
            value.value[i] = {};
            idsRows.value[i] = uuidv4();
        }
    }
};

const addRow = () =>
{
    value.value.push({});
    idsRows.value.push(uuidv4());
};

const init = () =>
{
    entryData.value = props.form.document.initEntry(props.blueprint, entryData.value, instaceOfTableEntry);

    if (value.value.length === 0)
    {
        const numberOfRows = blueprint.value.numberOfRows;

        for (let i = 0; i < numberOfRows; i++)
        {
            value.value.push({});
        }
    }

    idsRows.value = Array(value.value.length ?? blueprint.value.numberOfRows).fill(() => uuidv4());
};

init();
</script>

<template>
    <form-component-wrapper
        class="table-component"
        :form="form"
        :parent="parent"
        :blueprint="blueprint"
        :disabled="false"
    >
        <template #default>
            <div class="form-group">
                <form-label :form="form" :blueprint="blueprint" :required="required" :entry="entryData" />
                <table-view
                    v-model:value="value"
                    v-model:ids-rows="idsRows"
                    :form="form"
                    :blueprint="blueprint"
                    :entry="entry"
                    :design="design"
                />
                <form-help :form="form" :blueprint="blueprint" />
                <form-error-message :entry="entryData" name="values" />
                <form-error-message :entry="entryData" name="custom" />
                <div class="d-flex my-2">
                    <action-button
                        v-if="blueprint.canAddRow"
                        variant="success"
                        :disabled="design"
                        class="flex-fill"
                        icon="fas fa-plus"
                        :text="$t('[[[Dodaj wiersz]]]')"
                        @click="addRow"
                    />
                </div>
            </div>
        </template>
        <template #properties>
            <field-name v-model="blueprint.name" :form="form" :blueprint="blueprint" />
            <ideo-form-localize v-slot="{ locale }">
                <field-text v-model="blueprint.label[locale]" :label="$t('[[[Etykieta]]]')" />
            </ideo-form-localize>
            <field-checkbox v-model="blueprint.showLabel" :label="$t('[[[Pokaż etykietę]]]')" />
            <field-columns
                v-model="blueprint.components"
                v-model:columns="blueprint.columns"
                :form="form"
                :blueprint="blueprint"
                :label="$t('[[[Kolumny]]]')"
            />
            <field-numeric
                :model-value="blueprint.numberOfRows"
                :label="$t('[[[Początkowa ilość wierszy]]]')"
                :naturals="true"
                :min="1"
                :max="20"
                @update:model-value="setDefaultNumberOfRows"
            />
            <field-checkbox v-model="blueprint.canAddRow" :label="$t('[[[Pozwól na dodawanie wierszy]]]')" />
            <ideo-form-localize v-slot="{ locale }">
                <field-textarea v-model="blueprint.help[locale]" :label="$t('[[[Pomoc]]]')" />
            </ideo-form-localize>
            <field-visible :form="form" :blueprint="blueprint" :label="$t('[[[Widoczność]]]')" />
            <field-required :form="form" :blueprint="blueprint" :label="$t('[[[Pole wymagane]]]')" />
            <field-error :form="form" :blueprint="blueprint" />
        </template>
    </form-component-wrapper>
</template>
