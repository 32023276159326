import { axios } from '@/plugins/axios';
import Pager from '@/helpers/Pager';
import { Pagination, Resource } from '@/helpers/Interfaces';
import { Resource as FileResource } from '@/modules/core/files/services/StorageService';
import { DateTime } from 'luxon';

/**
 * DashboardService
 */
export default class DashboardService
{
    public static async getActiveCoordinatorsCount(): Promise<CountModel>
    {
        return (await axios.get<CountModel>('admin/elearning/dashboard/ActiveCoordinatorsCount')).data;
    }

    public static async getActiveUsersCount(): Promise<CountModel>
    {
        return (await axios.get<CountModel>('admin/elearning/dashboard/ActiveUsersCount')).data;
    }

    public static async getCoursesCounters(): Promise<CoursesCountModel>
    {
        return (await axios.get<CoursesCountModel>('admin/elearning/dashboard/CoursesCounters')).data;
    }

    public static async getLoginsCount(): Promise<LoginsModel[]>
    {
        return (await axios.get<LoginsModel[]>('admin/elearning/dashboard/LoginsCount')).data;
    }

    public static async getNewUsers(pager: Pager): Promise<Pagination<Resource<UserModel>>>
    {
        return (await axios.get<Pagination<Resource<UserModel>>>('admin/elearning/dashboard/NewUsers', {
            params: pager.data()
        })).data;
    }

    public static async getCoordinatorsCount(courseId: number): Promise<number>
    {
        return (await axios.get<number>(`admin/elearning/dashboard/${courseId}/coordinators-count`)).data;
    }

    public static async getUsersProgress(courseId: number, year: number): Promise<UsersProgressModel>
    {
        return (await axios.get<UsersProgressModel>(`admin/elearning/dashboard/${courseId}/users-progress/${year}`)).data;
    }
}

export interface UsersProgressModel {
    inProgress: number[];
    passed: number[];
    notStarted: number[];
    notPassed: number[];
    usersCount: number[];
}

export interface CountModel {
    count: number;
}

export interface LoginsModel {
    count: number;
    date: DateTime;
}

export interface CoursesCountModel {
    allCoursesCount: number;
    allCoursesPublishedCount: number;
    allCoursesInCurrentMonthCount: number;
    newCoursesPublishedInCurrentMonthCount: number;
    newCoursesUnpublishedInCurrentMonthCount: number;
}

export interface UserModel {
    id: number;
    givenName: string;
    surname: string;
    userName: string;
    facePicture: FileResource;
    dateCreatedUtc: DateTime;
}
