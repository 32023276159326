export default [
    {
        name: '[[[Strona główna]]]',
        route: 'dashboard',
        icon: 'far fa-fw fa-home',
        chevron: 'dashboard.svg'
    },
    {
        name: '[[[Moje konto]]]',
        icon: 'far fa-fw fa-user',
        visible: false,
        children: [
            {
                name: '[[[Profil użytkownika]]]',
                route: 'core-account-profile',
                icon: 'far fa-fw fa-user-circle'
            },
            {
                name: '[[[Zmiana hasła]]]',
                route: 'core-account-password',
                icon: 'far fa-fw fa-toggle-on'
            },
            {
                name: '[[[Powiadomienia]]]',
                route: 'core-account-notifications',
                icon: 'far fa-fw fa-envelope-open'
            },
            {
                name: '[[[Bezpieczeństwo]]]',
                route: 'core-account-security',
                icon: 'far fa-fw fa-phone-laptop'
            },
            {
                name: '[[[Indywidualne tokeny]]]',
                route: 'core-pats',
                icon: 'far fa-fw fa-key'
            }
        ]
    },
    {
        name: '[[[Usługi]]]',
        icon: 'far fa-fw fa-server',
        chevron: '',
        namespace: 'Ideo.Core.Common.Application.Permissions.Saas',
        children: [
            {
                name: '[[[Klienci]]]',
                route: 'saas-tenants',
                icon: 'far fa-fw fa-users-class',
                auth: { all: ['TenantsPermissions@View'], any: [] },
                children: [
                    {
                        name: '[[[Dodanie klienta]]]',
                        route: 'saas-tenants-add',
                        auth: { all: ['TenantsPermissions@Edit'], any: [] },
                        visible: false
                    },
                    {
                        name: '[[[Edycja klienta]]]',
                        route: 'saas-tenants-edit',
                        auth: { all: ['TenantsPermissions@Edit'], any: [] },
                        visible: false
                    }
                ]
            },
            {
                name: '[[[Bazy danych]]]',
                route: 'saas-databases',
                icon: 'far fa-fw fa-database',
                auth: { all: ['DatabasesPermissions@View'], any: [] },
                children: [
                    {
                        name: '[[[Dodanie serwera]]]',
                        route: 'saas-databases-add',
                        auth: { all: ['DatabasesPermissions@Edit'], any: [] },
                        visible: false
                    },
                    {
                        name: '[[[Edycja serwera]]]',
                        route: 'saas-databases-edit',
                        auth: { all: ['DatabasesPermissions@Edit'], any: [] },
                        visible: false
                    }
                ]
            },
            {
                name: '[[[Serwery plików]]]',
                route: 'saas-storages',
                icon: 'far fa-fw fa-hdd',
                auth: { all: ['StoragesPermissions@View'], any: [] },
                children: [
                    {
                        name: '[[[Dodanie serwera]]]',
                        route: 'saas-storages-add',
                        auth: { all: ['StoragesPermissions@Edit'], any: [] },
                        visible: false
                    },
                    {
                        name: '[[[Edycja serwera]]]',
                        route: 'saas-storages-edit',
                        auth: { all: ['StoragesPermissions@Edit'], any: [] },
                        visible: false
                    }
                ]
            }
        ]
    },
    {
        name: '[[[Administracja]]]',
        icon: 'far fa-fw fa-desktop',
        chevron: 'settings.svg',
        namespace: 'Ideo.Core.Common.Application.Permissions.Core',
        children: [
            {
                name: '[[[Użytkownicy]]]',
                route: 'core-users',
                icon: 'far fa-fw fa-user',
                auth: { all: ['UsersPermissions@View'], any: [] },
                children: [
                    {
                        name: '[[[Dodanie użytkownika]]]',
                        route: 'core-users-add',
                        auth: { all: ['UsersPermissions@Edit'], any: [] },
                        visible: false
                    },
                    {
                        name: '[[[Edycja użytkownika]]]',
                        route: 'core-users-edit',
                        auth: { all: ['UsersPermissions@Edit'], any: [] },
                        visible: false
                    }
                ]
            },
            {
                name: '[[[Role i uprawnienia]]]',
                route: 'core-roles',
                icon: 'far fa-fw fa-users',
                auth: { all: ['RolesPermissions@View'], any: [] },
                children: [
                    {
                        name: '[[[Dodanie roli]]]',
                        route: 'core-roles-add',
                        auth: { all: ['RolesPermissions@Edit'], any: [] },
                        visible: false
                    },
                    {
                        name: '[[[Edycja roli]]]',
                        route: 'core-roles-edit',
                        auth: { all: ['RolesPermissions@Edit'], any: [] },
                        visible: false
                    }
                ]
            },
            {
                name: '[[[Organizacja]]]',
                children: [
                    {
                        name: '[[[Struktura organizacyjna]]]',
                        route: 'core-units',
                        icon: 'fas fa-fw fa-sitemap',
                        namespace: 'Ideo.Core.Common.Application.Permissions.Core',
                        auth: { all: ['OrganizationPermissions@View'], any: [] },
                        children: [
                            {
                                name: '[[[Dodanie działu]]]',
                                route: 'core-units-add',
                                auth: { all: ['OrganizationPermissions@Edit'], any: [] },
                                visible: false
                            },
                            {
                                name: '[[[Edycja działu]]]',
                                route: 'core-units-edit',
                                auth: { all: ['OrganizationPermissions@Edit'], any: [] },
                                visible: false
                            }
                        ]
                    },
                    // {
                    //     name: '[[[Stanowiska]]]',
                    //     route: 'core-positions',
                    //     namespace: 'Ideo.Core.Edito.Application.Permissions.Modules',
                    //     auth: { all: ['JobPositionsPermissions@View'], any: [] },
                    //     children: [
                    //         {
                    //             name: '[[[Dodanie stanowiska]]]',
                    //             route: 'core-positions-add',
                    //             auth: { all: ['JobPositionsPermissions@Edit'], any: [] },
                    //             visible: false
                    //         },
                    //         {
                    //             name: '[[[Edycja stanowiska]]]',
                    //             route: 'core-positions-edit',
                    //             auth: { all: ['JobPositionsPermissions@Edit'], any: [] },
                    //             visible: false
                    //         }
                    //     ]
                    // }
                ]
            },
            {
                name: '[[[Zespoły]]]',
                route: 'core-teams',
                icon: 'far fa-fw fa-user-friends',
                auth: { all: ['TeamsPermissions@View'], any: [] },
                children: [
                    {
                        name: '[[[Dodanie zespołu]]]',
                        route: 'core-teams-add',
                        auth: { all: ['TeamsPermissions@Edit'], any: [] },
                        visible: false
                    },
                    {
                        name: '[[[Edycja zespołu]]]',
                        route: 'core-teams-edit',
                        auth: { all: ['TeamsPermissions@Edit'], any: [] },
                        visible: false
                    }
                ]
            },
            // {
            //     name: '[[[Zastępstwa]]]',
            //     route: 'core-substitutions',
            //     icon: 'far fa-fw fa-user',
            //     auth: { all: ['SubstitutionsPermissions@View'], any: [] },
            //     children: [
            //         {
            //             name: '[[[Dodanie zastępstwa]]]',
            //             route: 'core-substitutions-add',
            //             auth: { all: ['SubstitutionsPermissions@Edit'], any: [] },
            //             visible: false
            //         },
            //         {
            //             name: '[[[Edycja zastępstwa]]]',
            //             route: 'core-substitutions-edit',
            //             auth: { all: ['SubstitutionsPermissions@Edit'], any: [] },
            //             visible: false
            //         }
            //     ]
            // },
            {
                name: '[[[Ustawienia]]]',
                icon: 'far fa-fw fa-cogs',
                children: [
                    // {
                    //     name: '[[[Konta użytkowników]]]',
                    //     route: 'core-accounts',
                    //     icon: 'far fa-fw fa-users',
                    //     auth: { all: ['AdminPermissions@Accounts'], any: [] }
                    // },
                    {
                        name: '[[[Bezpieczeństwo]]]',
                        route: 'core-security',
                        icon: 'far fa-fw fa-shield-alt',
                        auth: { all: ['AdminPermissions@Security'], any: [] }
                    },
                    {
                        name: '[[[ReCaptcha]]]',
                        route: 'core-recaptcha',
                        icon: 'far fa-fw fa-shield-alt',
                        auth: { all: ['AdminPermissions@Recaptcha'], any: [] }
                    },
                    // Zgłoszenie #139899
                    // {
                    //     name: '[[[OpenAI]]]',
                    //     namespace: 'Ideo.Core.Elearning.Application.Permissions.Core',
                    //     route: 'core-openai',
                    //     icon: 'fas fa-fw fa-microchip-ai',
                    //     auth: { all: ['AdminPermissions@OpenAi'], any: [] }
                    // },
                    {
                        name: '[[[Poczta]]]',
                        route: 'core-mailing',
                        icon: 'fa-regular fa-envelope',
                        auth: { all: ['AdminPermissions@Mailing'], any: [] }
                    },
                    {
                        name: '[[[Powiadomienia]]]',
                        route: 'core-notifications-templates',
                        icon: 'far fa-fw fa-ballot-check',
                        auth: { all: ['NotificationsPermissions@Access'], any: [] },
                        children: [
                            {
                                name: '[[[Dodaj szablon]]]',
                                route: 'core-notifications-templates-add',
                                auth: { all: ['NotificationsPermissions@Access'], any: [] },
                                visible: false
                            },
                            {
                                name: '[[[Edytuj szablon]]]',
                                route: 'core-notifications-templates-edit',
                                auth: { all: ['NotificationsPermissions@Access'], any: [] },
                                visible: false
                            }
                        ]
                    },
                    // Zgłoszenie #139899
                    // {
                    //     name: '[[[Źródła LDAP]]]',
                    //     route: 'core-ldap',
                    //     icon: 'far fa-fw fa-shield-alt',
                    //     auth: { all: ['AdminPermissions@LdapConfig'], any: [] },
                    //     children: [
                    //         {
                    //             name: '[[[Dodanie źródła]]]',
                    //             route: 'core-ldap-add',
                    //             auth: { all: ['AdminPermissions@LdapConfig'], any: [] },
                    //             visible: false
                    //         },
                    //         {
                    //             name: '[[[Edycja źródła]]]',
                    //             route: 'core-ldap-edit',
                    //             auth: { all: ['AdminPermissions@LdapConfig'], any: [] },
                    //             visible: false
                    //         }
                    //     ]
                    // },
                    // Zgłoszenie #139899
                    // {
                    //     name: '[[[Bramka SMS]]]',
                    //     route: 'core-sms',
                    //     icon: 'far fa-fw fa-shield-alt',
                    //     auth: { all: ['AdminPermissions@Sms'], any: [] }
                    // },
                    {
                        name: '[[[Ustawienia sesji]]]',
                        route: 'core-session-settings',
                        icon: 'far fa-fw fa-shield-alt',
                        auth: { all: ['AdminPermissions@Session'], any: [] }
                    },
                    // Zgłoszenie #139899
                    //{
                    //    name: '[[[Paleta kolorów]]]',
                    //    route: 'core-colors',
                    //    icon: 'far fa-fw fa-palette',
                    //    auth: { all: ['AdminPermissions@Colors'], any: [] }
                    //},
                    {
                        name: '[[[Monitoring]]]',
                        route: 'core-monitoring',
                        icon: 'far fa-fw fa-shield-alt',
                        auth: { all: ['AdminPermissions@Monitoring'], any: [] }
                    },
                    {
                        name: '[[[Logi]]]',
                        route: 'core-logs-targets',
                        icon: 'far fa-fw fa-shield-alt',
                        auth: { all: ['AdminPermissions@Logs'], any: [] },
                        children: [
                            {
                                name: '[[[Cele]]]',
                                route: 'core-logs-targets',
                                icon: 'far fa-fw fa-shield-alt',
                                auth: { all: ['AdminPermissions@Logs'], any: [] },
                                visible: true,
                                children: [
                                    {
                                        name: '[[[Dodanie celu]]]',
                                        route: 'core-logs-targets-add',
                                        auth: { all: ['AdminPermissions@Logs'], any: [] },
                                        visible: false
                                    },
                                    {
                                        name: '[[[Edycja celu]]]',
                                        route: 'core-logs-targets-edit',
                                        auth: { all: ['AdminPermissions@Logs'], any: [] },
                                        visible: false
                                    }
                                ]
                            },
                            {
                                name: '[[[Role]]]',
                                route: 'core-logs-rules',
                                icon: 'far fa-fw fa-shield-alt',
                                auth: { all: ['AdminPermissions@Logs'], any: [] },
                                visible: true,
                                children: [
                                    {
                                        name: '[[[Dodanie celu]]]',
                                        route: 'core-logs-rules-add',
                                        auth: { all: ['AdminPermissions@Logs'], any: [] },
                                        visible: false
                                    },
                                    {
                                        name: '[[[Edycja celu]]]',
                                        route: 'core-logs-rules-edit',
                                        auth: { all: ['AdminPermissions@Logs'], any: [] },
                                        visible: false
                                    }
                                ]
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        name: '[[[Narzędzia]]]',
        icon: 'far fa-fw fa-cogs',
        chevron: 'designer.svg',
        namespace: 'Ideo.Core.Common.Application.Permissions.Core',
        children: [
            {
                name: '[[[Źródła danych]]]',
                route: 'core-datasources',
                icon: 'far fa-fw fa-database',
                auth: { all: ['DataSourcesPermissions@View'], any: [] },
                children: [
                    {
                        name: '[[[Dodanie źródła danych]]]',
                        route: 'core-datasources-add',
                        auth: { all: ['DataSourcesPermissions@Edit'], any: [] },
                        visible: false
                    },
                    {
                        name: '[[[Edycja źródła danych]]]',
                        route: 'core-datasources-edit',
                        auth: { all: ['DataSourcesPermissions@Edit'], any: [] },
                        visible: false
                    }
                ]
            },
            {
                name: '[[[Designer Studio]]]',
                icon: 'far fa-fw fa-ballot-check',
                children: [
                    {
                        name: '[[[Definicje formularzy]]]',
                        auth: { all: ['DynamicFormsPermissions@Access'], any: [] },
                        route: 'core-forms',
                        children: [
                            {
                                name: '[[[Dodanie formularza]]]',
                                route: 'core-forms-add',
                                auth: { all: [], any: ['DynamicFormsPermissions@Edit', 'DynamicFormsPermissions@EditAll'] },
                                visible: false
                            },
                            {
                                name: '[[[Edycja formularza]]]',
                                route: 'core-forms-edit',
                                auth: { all: [], any: ['DynamicFormsPermissions@Edit', 'DynamicFormsPermissions@EditAll'] },
                                visible: false
                            }
                        ]
                    },
                    {
                        name: '[[[Kolekcje dokumentów]]]',
                        auth: { all: ['DynamicFormEntriesPermissions@Access'], any: [] },
                        route: 'core-entries',
                        children: [
                            {
                                name: '[[[Dodanie dokumentu]]]',
                                route: 'core-entries-add',
                                auth: { all: [], any: ['DynamicFormEntriesPermissions@Edit', 'DynamicFormEntriesPermissions@EditAll'] },
                                visible: false
                            },
                            {
                                name: '[[[Podgląd dokumentu]]]',
                                route: 'core-entries-edit',
                                auth: { all: [], any: ['DynamicFormEntriesPermissions@Edit', 'DynamicFormEntriesPermissions@EditAll'] },
                                visible: false
                            }
                        ]
                    },
                    // #16095 ukrycie zakładki - nie działają customowe komponenty
                    // {
                    //     name: '[[[Komponenty]]]',
                    //     auth: { all: ['DynamicFormsPermissions@Components'], any: [] },
                    //     route: 'core-components',
                    //     children: [
                    //         {
                    //             name: '[[[Dodanie komponentu]]]',
                    //             route: 'core-components-add',
                    //             auth: { all: ['DynamicFormsPermissions@Components'], any: [] },
                    //             visible: false
                    //         },
                    //         {
                    //             name: '[[[Edycja komponentu]]]',
                    //             route: 'core-components-edit',
                    //             auth: { all: ['DynamicFormsPermissions@Components'], any: [] },
                    //             visible: false
                    //         }
                    //     ]
                    //     }
                ]
            },
            {
                name: '[[[Wyszukiwarka]]]',
                icon: 'far fa-fw fa-input-pipe',
                children: [
                    {
                        name: '[[[Lista indeksów]]]',
                        auth: { all: ['SearchPermissions@Access'], any: [] },
                        route: 'search-indices'
                    },
                    {
                        name: '[[[Słowa wykluczone]]]',
                        auth: { all: ['StopWordsPermissions@Access'], any: [] },
                        route: 'search-stopwords'
                    },
                    {
                        name: '[[[Synonimy]]]',
                        auth: { all: ['SynonymsPermissions@Access'], any: [] },
                        route: 'search-synonyms'
                    }
                ]
            },
            {
                name: '[[[Monitoring]]]',
                icon: 'far fa-fw fa-analytics',
                children: [
                    {
                        name: '[[[Historia logowania]]]',
                        route: 'core-authlog',
                        icon: 'far fa-fw fa-file-import',
                        auth: { all: ['AdminPermissions@Authlog'], any: [] }
                    },
                    {
                        name: '[[[Rejestr zmian]]]',
                        route: 'core-changelog',
                        icon: 'far fa-fw fa-file-signature',
                        auth: { all: ['AdminPermissions@Changelog'], any: [] },
                        children: [
                            {
                                name: '[[[Szczegóły zdarzenia]]]',
                                route: 'core-changelog-diff',
                                visible: false
                            }
                        ]
                    },
                    {
                        name: '[[[Harmonogram zadań]]]',
                        route: 'core-scheduler',
                        icon: 'far fa-fw fa-clock',
                        auth: { all: ['SchedulerPermissions@View'], any: [] },
                        children: [
                            {
                                name: '[[[Historia zadania]]]',
                                route: 'core-scheduler-history',
                                visible: false
                            },
                            {
                                name: '[[[Szczegóły zadania]]]',
                                route: 'core-scheduler-details',
                                visible: false
                            }
                        ]
                    },
                    {
                        name: '[[[Monitor kolejek]]]',
                        route: 'core-queues',
                        icon: 'far fa-fw fa-clock',
                        auth: { all: ['MessagePermissions@View'], any: [] },
                        children: [
                            {
                                name: "[[[Szczegóły zadania]]]",
                                route: 'core-queues-details',
                                visible: false
                            }
                        ]
                    },
                    {
                        name: '[[[Pamięć podręczna]]]',
                        route: 'elearning-cache',
                        namespace: 'Ideo.Core.Elearning.Application.Permissions.Cache',
                        auth: { all: ['CachePermissions@Manage'], any: [] },
                    },
                ]
            },
            // Zgłoszenie #139899
            // {
            //     name: '[[[Chat]]]',
            //     route: 'chat-room-list',
            //     icon: 'fas fa-fw fa-comments',
            //     namespace: 'Ideo.Core.Workspace.Application.Permissions.Chat',
            //     auth: { all: ['ChatPermissions@Manage'], any: [] },
            //     children: [
            //         {
            //             name: '[[[Ustawienia]]]',
            //             route: 'chat-room-settings',
            //             auth: { all: ['ChatPermissions@Manage'], any: [] },
            //             visible: false
            //         }
            //     ]
            // }
        ]
    },
    {
        name: '[[[E-learning]]]',
        icon: 'fad fa-fw fa-chalkboard-teacher',
        children: [
            {
                name: '[[[Szkolenia]]]',
                icon: 'fas fa-fw fa-book-open',
                namespace: 'Ideo.Core.Elearning.Application.Permissions.Course',
                auth: { all: ['CoursesPermissions@View'], any: [] },
                children: [
                    {
                        name: '[[[Lista szkoleń]]]',
                        route: 'elearning-courses-courses',
                        icon: 'fas fa-fw fa-grip-vertical',
                        children: [
                            {
                                name: '[[[Dodanie szkolenia]]]',
                                route: 'elearning-courses-courses-add',
                                auth: { all: ['CoursesPermissions@Edit'], any: [] },
                                visible: false
                            },
                            {
                                name: '[[[Edycja szkolenia]]]',
                                route: 'elearning-courses-courses-edit',
                                auth: { all: ['CoursesPermissions@Edit'], any: [] },
                                visible: false
                            },
                            {
                                name: '[[[Dashboard szkolenia]]]',
                                route: 'elearning-courses-courses-dashboard',
                                auth: { all: ['CoursesPermissions@View'], any: [] },
                                visible: false
                            },
                            {
                                name: '[[[Program szkolenia]]]',
                                route: 'elearning-courses-courses-program',
                                namespace: 'Ideo.Core.Elearning.Application.Permissions.Unit',
                                auth: { all: ['UnitsPermissions@View'], any: [] },
                                visible: false,
                                children: [
                                    {
                                        name: '[[[Edycja lekcji]]]',
                                        route: 'elearning-courses-lessons-edit',
                                        auth: { all: ['UnitsPermissions@Edit'], any: [] },
                                        visible: false
                                    },
                                    {
                                        name: '[[[Dodanie lekcji]]]',
                                        route: 'elearning-courses-lessons-add',
                                        auth: { all: ['UnitsPermissions@Edit'], any: [] },
                                        visible: false
                                    },
                                    {
                                        name: '[[[Edycja testu]]]',
                                        route: 'elearning-courses-tests-edit',
                                        auth: { all: ['UnitsPermissions@Edit'], any: [] },
                                        visible: false
                                    },
                                    {
                                        name: '[[[Dodanie testu]]]',
                                        route: 'elearning-courses-tests-add',
                                        auth: { all: ['UnitsPermissions@Edit'], any: [] },
                                        visible: false
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        name: '[[[Kategorie szkoleń]]]',
                        route: 'elearning-courses-categories',
                        icon: 'fas fa-fw fa-grip-vertical',
                        children: [
                            {
                                name: '[[[Dodanie kategorii]]]',
                                route: 'elearning-courses-categories-add',
                                auth: { all: ['CourseCategoriesPermissions@Edit'], any: [] },
                                visible: false
                            },
                            {
                                name: '[[[Edycja kategorii]]]',
                                route: 'elearning-courses-categories-edit',
                                auth: { all: ['CourseCategoriesPermissions@Edit'], any: [] },
                                visible: false
                            }
                        ]
                    }
                ]
            },
            {
                name: '[[[Materiały szkoleniowe]]]',
                icon: 'fas fa-fw fa-book-open',
                namespace: 'Ideo.Core.Elearning.Application.Permissions.Material',
                auth: { all: ['MaterialsPermissions@View'], any: [] },
                children: [
                    {
                        name: '[[[Kategorie materiałów]]]',
                        route: 'elearning-materials-categories',
                        icon: 'fas fa-fw fa-grip-vertical',
                        auth: { all: ['MaterialsPermissions@View'], any: [] },
                        children: [
                            {
                                name: '[[[Dodanie kategorii]]]',
                                route: 'elearning-materials-categories-add',
                                auth: { all: ['MaterialCategoriesPermissions@Edit'], any: [] },
                                visible: false
                            },
                            {
                                name: '[[[Edycja kategorii]]]',
                                route: 'elearning-materials-categories-edit',
                                auth: { all: ['MaterialCategoriesPermissions@Edit'], any: [] },
                                visible: false
                            }
                        ]
                    },
                    {
                        name: '[[[Slajdy]]]',
                        route: 'elearning-materials-materials-list',
                        icon: 'fas fa-fw fa-book-open',
                        children: [
                            {
                                name: '[[[Dodanie slajdów]]]',
                                route: 'elearning-materials-materials-add',
                                auth: { all: ['MaterialsPermissions@Edit'], any: [] },
                                visible: false
                            },
                            {
                                name: '[[[Edycja slajdów]]]',
                                route: 'elearning-materials-materials-edit',
                                auth: { all: ['MaterialsPermissions@Edit'], any: [] },
                                visible: false
                            },
                            {
                                name: '[[[Import slajdów z PDF]]]',
                                route: 'elearning-materials-materials-import',
                                auth: { all: ['MaterialsPermissions@Edit'], any: [] },
                                visible: false
                            }
                        ]
                    }
                ]
            },
            // {
            //     name: '[[[Kreator szkoleń]]]',
            //     route: 'elearning-courses-creator',
            //     namespace: 'Ideo.Core.Elearning.Application.Permissions.Course',
            //     auth: { all: ['CoursesPermissions@Edit'], any: [] },
            //     visible: true
            // },
            {
                name: '[[[Paczki SCORM]]]',
                icon: 'fas fa-fw fa-book-open',
                namespace: 'Ideo.Core.Elearning.Application.Permissions.Scorm',
                auth: { all: ['ScormPackagesPermission@View'], any: [] },
                children: [
                    {
                        name: '[[[Lista paczek SCORM]]]',
                        route: 'elearning-scorm-scormpackages',
                        icon: 'fas fa-fw fa-grip-vertical',
                        children: [
                            {
                                name: '[[[Dodanie paczki SCORM]]]',
                                route: 'elearning-scorm-scormpackages-add',
                                auth: { all: ['ScormPackagesPermission@Edit'], any: [] },
                                visible: false
                            },
                            {
                                name: '[[[Edycja paczek SCORM]]]',
                                route: 'elearning-scorm-scormpackages-edit',
                                auth: { all: ['ScormPackagesPermission@Edit'], any: [] },
                                visible: false
                            }
                        ]
                    },
                    {
                        name: '[[[Monitor aktywności]]]',
                        route: 'elearning-scorm-scormdebug',
                        namespace: 'Ideo.Core',
                        auth: { all: ['StandardPermissions@Superuser'], any: [] }
                    }
                ]
            },
            {
                name: '[[[Certyfikaty]]]',
                icon: 'fas fa-fw fa-book-open',
                namespace: 'Ideo.Core.Elearning.Application.Permissions.Certificate',
                auth: { all: ['CertificatesPermissions@View'], any: [] },
                children: [
                    {
                        name: '[[[Lista certyfikatów]]]',
                        route: 'elearning-certificates-certificates',
                        icon: 'fas fa-fw fa-grip-vertical',
                        children: [
                            {
                                name: '[[[Dodanie certyfikatu]]]',
                                route: 'elearning-certificates-certificates-add',
                                auth: { all: ['CertificatesPermissions@Edit'], any: [] },
                                visible: false
                            },
                            {
                                name: '[[[Edycja certyfikatu]]]',
                                route: 'elearning-certificates-certificates-edit',
                                auth: { all: ['CertificatesPermissions@Edit'], any: [] },
                                visible: false
                            },
                            {
                                name: '[[[Dashboard szkolenia]]]',
                                route: 'elearning-courses-courses-dashboard',
                                namespace: 'Ideo.Core.Elearning.Application.Permissions.Course',
                                auth: { all: ['CoursesPermissions@View'], any: [] },
                                visible: false
                            }
                        ]
                    }
                ]
            },
            {
                name: '[[[Ścieżka szkoleniowa]]]',
                namespace: 'Ideo.Core.Elearning.Application.Permissions.LearningPath',
                auth: { all: ['LearningPathsPermissions@View'], any: [] },
                children: [
                    {
                        name: '[[[Lista ścieżek szkoleniowych]]]',
                        route: 'elearning-learningPath-learningPaths',
                        children: [
                            {
                                name: '[[[Dodanie ścieżki szkoleniowej]]]',
                                route: 'elearning-learningPath-learningPaths-add',
                                auth: { all: ['LearningPathsPermissions@Edit'], any: [] },
                                visible: false
                            },
                            {
                                name: '[[[Edycja ścieżki szkoleniowej]]]',
                                route: 'elearning-learningPath-learningPaths-edit',
                                auth: { all: ['LearningPathsPermissions@Edit'], any: [] },
                                visible: false
                            }
                        ]
                    }
                ]
            },
            {
                name: '[[[Profil koordynatora]]]',
                namespace: 'Ideo.Core.Elearning.Application.Permissions.CoordinatorProfile',
                children: [
                    {
                        name: '[[[Lista koordynatorów]]]',
                        route: 'elearning-coordinatorProfile-coordinatorProfiles',
                        auth: { all: ['CoordinatorProfilesPermissions@View'], any: [] }
                    }
                ]
            },
            {
                name: '[[[Kalendarz]]]',
                namespace: 'Ideo.Core.Elearning.Application.Permissions.Events',
                children: [
                    {
                        name: '[[[Lista wydarzeń]]]',
                        route: 'elearning-events',
                        auth: { all: ['EventsPermissions@View'], any: [] }
                    },
                    {
                        name: '[[[Kategorie wydarzeń]]]',
                        route: 'elearning-events-categories',
                        auth: { all: ['EventCategoriesPermissions@Edit'], any: [] }
                    }
                ]
            },
            // {
            //     name: '[[[Grywalizacja]]]',
            //     children: [
            //         {
            //             name: '[[[Lista]]]',
            //             route: 'elearning-gamification-list'
            //         },
            //         {
            //             name: '[[[Przyznawanie punktów]]]',
            //             route: 'elearning-gamification-points'
            //         },
            //         {
            //             name: '[[[Odznaki]]]',
            //             route: 'elearning-gamification-badges',
            //             children: [
            //                 {
            //                     name: '[[[Dodawanie odznaki]]]',
            //                     route: 'elearning-gamification-badges-add',
            //                     visible: false
            //                 },
            //                 {
            //                     name: '[[[Edycja odznaki]]]',
            //                     route: 'elearning-gamification-badges-edit',
            //                     visible: false
            //                 }
            //             ]
            //         }
            //     ]
            // },
            // Zgłoszenie #139899
            // {
            //     name: '[[[Raporty]]]',
            //     namespace: 'Ideo.Core.Elearning.Application.Permissions.CourseReports',
            //     auth: { all: ['CourseReportsPermissions@View'], any: [] },
            //     route: 'elearning-reports',
            //     icon: 'fas fa-fw fa-chart-bar',
            //     children: [
            //         {
            //             name: '[[[Szczegóły szkolenia]]]',
            //             route: 'elearning-reports-details',
            //             visible: false
            //         }
            //     ]
            // },
        ]
    },
    {
        name: '[[[Struktura serwisu]]]',
        icon: 'fad fa-fw fa-globe fa-swap-opacity',
        namespace: 'Ideo.Core.Edito.Application.Permissions.Cms',
        children: [
            {
                name: '[[[Strony]]]',
                route: 'cms-pages',
                icon: 'fad fa-fw fa-newspaper',
                auth: { all: ['PagesPermissions@View'], any: [] },
                children: [
                    {
                        name: '[[[Dodanie strony]]]',
                        route: 'cms-pages-add',
                        auth: { all: ['PagesPermissions@Edit'], any: [] },
                        visible: false
                    },
                    {
                        name: '[[[Edycja strony]]]',
                        route: 'cms-pages-edit',
                        auth: { all: ['PagesPermissions@Edit'], any: [] },
                        visible: false
                    },
                    {
                        name: '[[[Edycja aranżacji]]]',
                        route: 'cms-pages-layout',
                        auth: { all: ['PagesPermissions@Edit'], any: [] },
                        visible: false
                    }
                ]
            },
            {
                name: '[[[Aranżacje]]]',
                route: 'cms-layouts',
                icon: 'fas fa-fw fa-palette',
                auth: { all: ['LayoutsPermissions@View'], any: [] }
            },
            {
                name: '[[[Witryny]]]',
                route: 'cms-websites',
                icon: 'fad fa-fw fa-browser',
                auth: { all: ['WebsitesPermissions@View'], any: [] },
                children: [
                    {
                        name: '[[[Dodanie witryny]]]',
                        route: 'cms-websites-add',
                        auth: { all: ['WebsitesPermissions@Edit'], any: [] },
                        visible: false
                    },
                    {
                        name: '[[[Edycja witryny]]]',
                        route: 'cms-websites-edit',
                        auth: { all: ['WebsitesPermissions@Edit'], any: [] },
                        visible: false
                    }
                ]
            },
            {
                name: '[[[Szablony]]]',
                route: 'cms-themes',
                icon: 'fad fa-fw fa-images',
                auth: { all: ['ThemesPermissions@View'], any: [] },
                children: [
                    {
                        name: '[[[Dodanie szablonu]]]',
                        route: 'cms-themes-add',
                        auth: { all: ['ThemesPermissions@Edit'], any: [] },
                        visible: false
                    },
                    {
                        name: '[[[Edycja szablonu]]]',
                        route: 'cms-themes-edit',
                        auth: { all: ['ThemesPermissions@Edit'], any: [] },
                        visible: false
                    }
                ]
            }
        ]
    },
    {
        name: '[[[Moduły]]]',
        icon: 'fad fa-fw fa-cogs',
        namespace: 'Ideo.Core.Edito.Application.Permissions.Modules',
        children: [
            {
                name: '[[[Artykuły]]]',
                children: [
                    {
                        name: '[[[Lista artykułów]]]',
                        route: 'cms-articles',
                        icon: 'far fa-fw fa-file-alt',
                        auth: { all: ['ArticlesPermissions@View'], any: [] },
                        children: [
                            {
                                name: '[[[Dodanie artykułu]]]',
                                route: 'cms-articles-add',
                                auth: { all: [], any: ['ArticlesPermissions@Edit', 'ArticlesPermissions@EditAll'] },
                                visible: false
                            },
                            {
                                name: '[[[Edycja artykułu]]]',
                                route: 'cms-articles-edit',
                                auth: { all: [], any: ['ArticlesPermissions@Edit', 'ArticlesPermissions@EditAll'] },
                                visible: false
                            }
                        ]
                    },
                    {
                        name: '[[[Kategorie]]]',
                        route: 'cms-articles-categories',
                        icon: 'far fa-fw fa-file-alt',
                        auth: { all: ['ArticlesCategoriesPermissions@View'], any: [] },
                        children: [
                            {
                                name: '[[[Dodanie kategorii]]]',
                                route: 'cms-articles-categories-add',
                                auth: { all: ['ArticlesCategoriesPermissions@Edit'], any: [] },
                                visible: false
                            },
                            {
                                name: '[[[Edycja kategorii]]]',
                                route: 'cms-articles-categories-edit',
                                auth: { all: ['ArticlesCategoriesPermissions@Edit'], any: [] },
                                visible: false
                            },
                            {
                                name: '[[[Sortowanie kategorii]]]',
                                route: 'cms-articles-categories-sort',
                                auth: { all: ['ArticlesCategoriesPermissions@Edit'], any: [] },
                                visible: false
                            }
                        ]
                    },
                    {
                        name: '[[[Etykiety]]]',
                        route: 'cms-articles-labels',
                        icon: 'far fa-fw fa-file-alt',
                        auth: { all: ['ArticleLabelsPermissions@View'], any: [] },
                        children: [
                            {
                                name: '[[[Dodanie etykiety]]]',
                                route: 'cms-articles-labels-add',
                                auth: { all: ['ArticleLabelsPermissions@Edit'], any: [] },
                                visible: false
                            },
                            {
                                name: '[[[Edycja etykiety]]]',
                                route: 'cms-articles-labels-edit',
                                auth: { all: ['ArticleLabelsPermissions@Edit'], any: [] },
                                visible: false
                            }
                        ]
                    },
                    // {
                    //     name: '[[[Czołówka]]]',
                    //     route: 'cms-articles-headline-covers',
                    //     icon: 'far fa-fw fa-file-alt',
                    //     auth: { all: ['ArticlesHeadlineCoversPermissions@View'], any: [] },
                    //     children: [
                    //         {
                    //             name: '[[[Dodanie czołówki]]]',
                    //             route: 'cms-articles-headline-covers-add',
                    //             auth: { all: ['ArticlesHeadlineCoversPermissions@Edit'], any: [] },
                    //             visible: false
                    //         },
                    //         {
                    //             name: '[[[Edycja czołówki]]]',
                    //             route: 'cms-articles-headline-covers-edit',
                    //             auth: { all: ['ArticlesHeadlineCoversPermissions@Edit'], any: [] },
                    //             visible: false
                    //         }
                    //     ]
                    // }
                ]
            },
            // {
            //     name: '[[[Autorzy]]]',
            //     route: 'cms-authors',
            //     icon: 'far fa-fw fa-copy',
            //     auth: { all: ['AuthorsPermissions@View'], any: [] },
            //     children: [
            //         {
            //             name: '[[[Sortowanie autorów]]]',
            //             route: 'cms-authors-sort',
            //             auth: { all: ['AuthorsPermissions@Edit'], any: [] },
            //             visible: false
            //         },
            //         {
            //             name: '[[[Dodanie autora]]]',
            //             route: 'cms-authors-add',
            //             auth: { all: ['AuthorsPermissions@Edit'], any: [] },
            //             visible: false
            //         },
            //         {
            //             name: '[[[Edycja autora]]]',
            //             route: 'cms-authors-edit',
            //             auth: { all: ['AuthorsPermissions@Edit'], any: [] },
            //             visible: false
            //         }
            //     ]
            // },
            {
                name: '[[[Aktualności]]]',
                route: 'cms-informations',
                icon: 'far fa-fw fa-copy',
                auth: { all: ['InformationsPermissions@View'], any: [] },
                children: [
                    {
                        name: '[[[Dodanie aktualności]]]',
                        route: 'cms-informations-add',
                        auth: { all: [], any: ['InformationsPermissions@Edit', 'InformationsPermissions@EditAll'] },
                        visible: false
                    },
                    {
                        name: '[[[Edycja aktualności]]]',
                        route: 'cms-informations-edit',
                        auth: { all: [], any: ['InformationsPermissions@Edit', 'InformationsPermissions@EditAll'] },
                        visible: false
                    }
                ]
            },
            {
                name: '[[[Pytania i odpowiedzi]]]',
                route: 'cms-faq',
                icon: 'far fa-fw fa-file-alt',
                auth: { all: ['FaqPermissions@View'], any: [] },
                children: [
                    {
                        name: '[[[Dodanie poradnika]]]',
                        route: 'cms-faq-add',
                        auth: { all: [], any: ['FaqPermissions@Edit', 'FaqPermissions@EditAll'] },
                        visible: false
                    },
                    {
                        name: '[[[Edycja poradnika]]]',
                        route: 'cms-faq-edit',
                        auth: { all: [], any: ['FaqPermissions@Edit', 'FaqPermissions@EditAll'] },
                        visible: false
                    }
                ]
            },
            {
                name: '[[[Ankiety]]]',
                route: 'cms-surveys',
                icon: 'far fa-fw fa-file-alt',
                auth: { all: ['SurveysPermissions@View'], any: [] },
                children: [
                    {
                        name: '[[[Dodanie ankiety]]]',
                        route: 'cms-surveys-add',
                        auth: { all: [], any: ['SurveysPermissions@Edit', 'SurveysPermissions@EditAll'] },
                        visible: false
                    },
                    {
                        name: '[[[Edycja ankiety]]]',
                        route: 'cms-surveys-edit',
                        auth: { all: [], any: ['SurveysPermissions@Edit', 'SurveysPermissions@EditAll'] },
                        visible: false
                    }
                ]
            },
            {
                name: '[[[Slajdery]]]',
                route: 'cms-sliders',
                auth: { all: ['SlidersPermissions@View'], any: [] },
                icon: 'fas fa-fw fa-book-open',
                children: [
                    {
                        name: '[[[Dodanie slajdera]]]',
                        route: 'cms-sliders-add',
                        auth: { all: ['SlidersPermissions@Edit'], any: [] },
                        visible: false
                    },
                    {
                        name: '[[[Edycja slajdera]]]',
                        route: 'cms-sliders-edit',
                        auth: { all: ['SlidersPermissions@Edit'], any: [] },
                        visible: false
                    }
                ]
            },
            {
                name: '[[[Tagi]]]',
                route: 'cms-tags',
                icon: 'far fa-fw fa-tags',
                auth: { all: ['TagsPermissions@View'], any: [] }
            },
            {
                name: '[[[Katalog linków]]]',
                children: [
                    {
                        name: '[[[Lista linków]]]',
                        route: 'cms-link-links',
                        auth: { all: ['LinksPermissions@View'], any: [] },
                        children: [
                            {
                                name: '[[[Dodanie linku]]]',
                                route: 'cms-link-links-add',
                                auth: { all: [], any: ['LinksPermissions@Edit', 'LinksPermissions@EditAll'] },
                                visible: false
                            },
                            {
                                name: '[[[Edycja linku]]]',
                                route: 'cms-link-links-edit',
                                auth: { all: [], any: ['LinksPermissions@Edit', 'LinksPermissions@EditAll'] },
                                visible: false
                            }
                        ]
                    },
                    {
                        name: '[[[Kategorie linków]]]',
                        route: 'cms-link-categories',
                        auth: { all: ['LinkCategoriesPermissions@View'], any: [] },
                        children: [
                            {
                                name: '[[[Dodanie kategorii]]]',
                                route: 'cms-link-categories-add',
                                auth: { all: ['LinkCategoriesPermissions@Edit'], any: [] },
                                visible: false
                            },
                            {
                                name: '[[[Edycja kategorii]]]',
                                route: 'cms-link-categories-edit',
                                auth: { all: ['LinkCategoriesPermissions@Edit'], any: [] },
                                visible: false
                            }
                        ]

                    }
                ]
            },
            // {
            //     name: '[[[RSS]]]',
            //     route: 'cms-rss',
            //     icon: 'far fa-fw fa-tags',
            //     auth: { all: ['RssPermissions@View'], any: [] }
            // },
            // Zgłoszenie #139899
            // {
            //     name: '[[[Baza dokumentów]]]',
            //     children: [
            //         {
            //             name: '[[[Dokumenty]]]',
            //             route: 'cms-documents',
            //             auth: { all: ['DocumentsPermission@View'], any: [] },
            //             children: [
            //                 {
            //                     name: '[[[Dodanie dokumentu]]]',
            //                     route: 'cms-documents-add',
            //                     auth: { all: [], any: ['DocumentsPermission@Edit', 'DocumentsPermission@EditAll'] },
            //                     visible: false
            //                 },
            //                 {
            //                     name: '[[[Edycja dokumentu]]]',
            //                     route: 'cms-documents-edit',
            //                     auth: { all: [], any: ['DocumentsPermission@Edit', 'DocumentsPermission@EditAll'] },
            //                     visible: false
            //                 }
            //             ]
            //         },
            //         {
            //             name: '[[[Kategorie]]]',
            //             route: 'cms-documents-categories',
            //             auth: { all: ['DocumentsCategoriesPermissions@View'], any: [] },
            //             children: [
            //                 {
            //                     name: '[[[Dodanie kategorii]]]',
            //                     route: 'cms-documents-categories-add',
            //                     auth: { all: ['DocumentsCategoriesPermissions@Edit'], any: [] },
            //                     visible: false
            //                 },
            //                 {
            //                     name: '[[[Edycja kategorii]]]',
            //                     route: 'cms-documents-categories-edit',
            //                     auth: { all: ['DocumentsCategoriesPermissions@Edit'], any: [] },
            //                     visible: false
            //                 }
            //             ]
            //         }
            //     ]
            // },
            {
                name: '[[[Galeria zdjęć]]]',
                route: 'cms-gallery',
                auth: { all: ['PhotoGalleryPermissions@View'], any: [] },
                children: [
                    {
                        name: '[[[Dodanie galerii]]]',
                        route: 'cms-gallery-add',
                        auth: { all: [], any: ['PhotoGalleryPermissions@Edit', 'PhotoGalleryPermissions@EditAll'] },
                        visible: false
                    },
                    {
                        name: '[[[Edycja galerii]]]',
                        route: 'cms-gallery-edit',
                        auth: { all: [], any: ['PhotoGalleryPermissions@Edit', 'PhotoGalleryPermissions@EditAll'] },
                        visible: false
                    }
                ]
            },
        ]
    },
    {
        name: '[[[Marketing]]]',
        icon: 'fad fa-search-dollar',
        namespace: 'Ideo.Core.Edito.Application.Permissions.Marketing',
        children: [
            {
                name: '[[[Regulaminy]]]',
                icon: 'fad fa-gavel',
                auth: { all: [], any: ['RegulationsSetsPermissions@View', 'RegulationsPermissions@View'] },
                children: [
                    {
                        name: '[[[Lista regulaminów]]]',
                        route: 'cms-regulations',
                        auth: { all: ['RegulationsPermissions@View'], any: [] },
                        children: [
                            {
                                name: '[[[Dodanie regulaminu]]]',
                                route: 'cms-regulations-add',
                                auth: { all: [], any: ['RegulationsPermissions@Edit', 'RegulationsPermissions@EditAll'] },
                                visible: false
                            },
                            {
                                name: '[[[Edycja regulaminu]]]',
                                route: 'cms-regulations-edit',
                                auth: { all: [], any: ['RegulationsPermissions@Edit', 'RegulationsPermissions@EditAll'] },
                                visible: false
                            }
                        ]
                    },
                    {
                        name: '[[[Ustawienia]]]',
                        route: 'cms-regulations-sets',
                        auth: { all: ['RegulationsSetsPermissions@View'], any: [] },
                        children: [
                            {
                                name: '[[[Dodanie zestawu regulaminów]]]',
                                route: 'cms-regulations-sets-add',
                                auth: { all: ['RegulationsSetsPermissions@Edit'], any: [] },
                                visible: false
                            },
                            {
                                name: '[[[Edycja zestawu regulaminów]]]',
                                route: 'cms-regulations-sets-edit',
                                auth: { all: ['RegulationsSetsPermissions@Edit'], any: [] },
                                visible: false
                            }
                        ]
                    }
                ]
            },
            // {
            //     name: '[[[Komentarze]]]',
            //     icon: 'fad fa-gavel',
            //     auth: { all: ['CommentsPermissions@View'], any: [] },
            //     children: [
            //         {
            //             name: '[[[Lista komentarzy]]]',
            //             route: 'cms-comments',
            //             auth: { all: ['CommentsPermissions@View'], any: [] },
            //             children: [
            //                 {
            //                     name: '[[[Edycja komentarza]]]',
            //                     route: 'cms-comments-edit',
            //                     auth: { all: ['CommentsPermissions@Edit'], any: [] },
            //                     visible: false
            //                 }
            //             ]
            //         },
            //         {
            //             name: '[[[Ustawienia]]]',
            //             route: 'cms-comments-sets',
            //             auth: { all: ['CommentsSetsPermissions@View'], any: [] },
            //             children: [
            //                 {
            //                     name: '[[[Edycja ustawień]]]',
            //                     route: 'cms-comments-sets-edit',
            //                     auth: { all: ['CommentsSetsPermissions@Edit'], any: [] },
            //                     visible: false
            //                 }
            //             ]
            //         }
            //     ]
            // },
            {
                name: '[[[Top tematy]]]',
                route: 'cms-top-topics',
                auth: { all: ['TopTopicsPermissions@View'], any: [] },
                children: [
                    {
                        name: '[[[Dodanie top tematu]]]',
                        route: 'cms-top-topics-add',
                        auth: { all: ['TopTopicsPermissions@Edit'], any: [] },
                        visible: false
                    },
                    {
                        name: '[[[Edycja top tematu]]]',
                        route: 'cms-top-topics-edit',
                        auth: { all: ['TopTopicsPermissions@Edit'], any: [] },
                        visible: false
                    }
                ]
            }
        ]
    },
    {
        name: '[[[Repozytorium plików]]]',
        route: 'filemanager',
        icon: 'far fa-fw fa-folders',
        chevron: 'dam.svg',
        namespace: 'Ideo.Core.Common.Application.Permissions.Storage',
        auth: { all: ['StoragePermissions@Access'], any: [] }
    },
    {
        name: '[[[Kosz]]]',
        route: 'trash',
        chevron: 'trash.svg',
        icon: 'far fa-fw fa-trash',
        namespace: 'Ideo.Core.Common.Application.Permissions.Core',
        auth: { all: ['TrashPermissions@View'], any: [] },
        children: [
            {
                name: '[[[Grupa]]]',
                route: 'trash-group',
                visible: false
            }
        ]
    }
];
